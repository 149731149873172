import { useState, useContext } from 'react';
import {
  aerisOptions,
  distanceUnitOptions,
  speedUnitOptions,
  widgetOptions,
} from './survey-overlay-header.models';
import {
  Header,
  RunName,
  ToggleButton,
  DocumentationButton,
} from './overlay-survey-header.style';
import SettingsStore from 'app/modules/settings/settings.context';
import SurveyStore from 'app/modules/survey/survey.context';
import { timezoneListModel } from 'app/models/timezones';
import Skeleton from 'app/components/skeleton/skeleton';
import HelpButtonLegend from 'app/components/help-button-icons-legend/helpButtonIconsLegend';
import DropdownButton from 'app/components/dropdown-button/dropdown-button';

/**
 *
 * @param param
 * @returns
 */
const SurveyOverlayHeader = ({
  name,
  checkedTiles,
  setCheckedTiles,
  opened,
  setOpened,
  widgets,
  setWidgets,
  hideToggleButton,
  hideName,
  hideWeather,
  hideHelp,
  hidden,
  hideTimezone,
}: any) => {
  const [showMenu, setShowMenu] = useState<string>('');
  const settingsContext = useContext(SettingsStore);
  const surveyContext = useContext(SurveyStore);

  // useEffect(() => {
  //   if (!window.navigator.onLine) {
  //     dispatch({
  //       type: 'NO_CONNECTION',
  //       data: new Date(),
  //     });
  //   }
  //   window.addEventListener('offline', () => {
  //     dispatch({
  //       type: 'NO_CONNECTION',
  //       data: new Date(),
  //     });
  //   });
  //   window.addEventListener('online', () => {
  //     dispatch({
  //       type: 'NO_CONNECTION',
  //       data: null,
  //     });
  //   });
  //   return () => {
  //     window.removeEventListener('offline', () => '');
  //     window.removeEventListener('online', () => '');
  //   };
  // }, []);

  return (
    <Header hidden={hidden} opened={opened}>
      <div>
        <Skeleton
          hidden={hideName}
          loading={surveyContext.state.loading}
          width="120px"
          height="16px"
          inline
        >
          <RunName>{name}</RunName>
        </Skeleton>
        {/* <ConnectionErrorLabel show={state.noConnectionSince}>
          {`Connection lost at ${formatTimezoneDate({
            date: state.noConnectionSince || '',
            timezone: timezone?.id,
            format: 'HH:mm',
          })}`}
          <ConnectionErrorLabelTimezone>
            {`${timezone?.label} time.`}
          </ConnectionErrorLabelTimezone>
        </ConnectionErrorLabel> */}
      </div>
      <div>
        <DocumentationButton
          onClick={() => {
            window.open('/docs/', '__blank');
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            enableBackground="new 0 0 24 24"
            height="16px"
            viewBox="0 0 24 24"
            width="16px"
            fill="#ffffff"
            opacity={0.8}
          >
            <g>
              <rect fill="none" height="24" width="24" />
            </g>
            <g>
              <g />
              <g>
                <path d="M21,5c-1.11-0.35-2.33-0.5-3.5-0.5c-1.95,0-4.05,0.4-5.5,1.5c-1.45-1.1-3.55-1.5-5.5-1.5S2.45,4.9,1,6v14.65 c0,0.25,0.25,0.5,0.5,0.5c0.1,0,0.15-0.05,0.25-0.05C3.1,20.45,5.05,20,6.5,20c1.95,0,4.05,0.4,5.5,1.5c1.35-0.85,3.8-1.5,5.5-1.5 c1.65,0,3.35,0.3,4.75,1.05c0.1,0.05,0.15,0.05,0.25,0.05c0.25,0,0.5-0.25,0.5-0.5V6C22.4,5.55,21.75,5.25,21,5z M21,18.5 c-1.1-0.35-2.3-0.5-3.5-0.5c-1.7,0-4.15,0.65-5.5,1.5V8c1.35-0.85,3.8-1.5,5.5-1.5c1.2,0,2.4,0.15,3.5,0.5V18.5z" />
                <g>
                  <path d="M17.5,10.5c0.88,0,1.73,0.09,2.5,0.26V9.24C19.21,9.09,18.36,9,17.5,9c-1.7,0-3.24,0.29-4.5,0.83v1.66 C14.13,10.85,15.7,10.5,17.5,10.5z" />
                  <path d="M13,12.49v1.66c1.13-0.64,2.7-0.99,4.5-0.99c0.88,0,1.73,0.09,2.5,0.26V11.9c-0.79-0.15-1.64-0.24-2.5-0.24 C15.8,11.66,14.26,11.96,13,12.49z" />
                  <path d="M17.5,14.33c-1.7,0-3.24,0.29-4.5,0.83v1.66c1.13-0.64,2.7-0.99,4.5-0.99c0.88,0,1.73,0.09,2.5,0.26v-1.52 C19.21,14.41,18.36,14.33,17.5,14.33z" />
                </g>
              </g>
            </g>
          </svg>
        </DocumentationButton>
        <HelpButtonLegend
          hidden={hideHelp}
          show={showMenu === 'help'}
          handleShow={() => setShowMenu(showMenu === 'help' ? '' : 'help')}
        />
        <DropdownButton
          text={`${settingsContext.state.speedUnit?.label} / ${settingsContext.state.distanceUnit?.label
            } / ${settingsContext.state.weatherUnit?.label?.toLowerCase()}`}
          type="radio"
          show={showMenu === 'unit'}
          handleShow={(show: boolean) => setShowMenu(show ? 'unit' : '')}
          groupTitles={['Speed', 'Distance']}
          groups={[
            speedUnitOptions.map((option) => ({
              ...option,
              selected: option.id === settingsContext.state.speedUnit?.id,
            })), // 0
            distanceUnitOptions.map((option) => ({
              ...option,
              selected: option.id === settingsContext.state.distanceUnit?.id,
            })), // 1
          ]}
          onClick={(option: any, index: number) => {
            const handles = [
              // setSpeedUnit,
              (option) => settingsContext.dispatch({ type: 'SET_SPEED_UNIT', data: option }),
              // setDistanceUnit
              (option) => settingsContext.dispatch({ type: 'SET_DISTANCE_UNIT', data: option }),
            ]; // 0, 1
            handles[index](option);
            setShowMenu('');
          }}
        />
        <DropdownButton
          text="weather"
          type="radio"
          hideButton={hideWeather}
          show={showMenu === 'overlay'}
          handleShow={(show: boolean) => setShowMenu(show ? 'overlay' : '')}
          groups={[
            aerisOptions({ weatherUnit: settingsContext.state.weatherUnit }).map((option) => ({
              ...option,
              selected:
                checkedTiles?.findIndex(({ id }: any) => id === option.id) !==
                -1,
            })),
          ]}
          onClick={(option: any) => {
            if (option.selected) setCheckedTiles([]);
            else setCheckedTiles([option]);

            setShowMenu('');
          }}
        />
        <DropdownButton
          text="widgets"
          type="checkbox"
          hideButton={!widgets}
          show={showMenu === 'widgets'}
          handleShow={(show: boolean) => setShowMenu(show ? 'widgets' : '')}
          groups={[
            widgetOptions.map((option: any) => ({
              ...option,
              checked:
                widgets?.findIndex((id: string) => id === option.id) !== -1,
            })),
          ]}
          onClick={(option: any) => {
            let newWidgets = [...widgets];
            const index = newWidgets.findIndex(
              (id: string) => id === option.id
            );

            if (index === -1) {
              newWidgets.push(option.id);
            } else {
              newWidgets.splice(index, 1);
            }

            if (option.id === 'all' && index === -1) {
              newWidgets = widgetOptions.map((opt) => opt.id);
            } else if (option.id === 'all') {
              newWidgets = [];
            }

            if (newWidgets.length !== widgetOptions.length) {
              newWidgets = newWidgets.filter((o) => o !== 'all');
            }

            if (newWidgets.length === widgetOptions.length - 1) {
              newWidgets.push('all');
            }

            setWidgets(newWidgets);
            setShowMenu('');
          }}
        />
        <DropdownButton
          show={showMenu === 'timezone'}
          text={settingsContext.state.timezone?.label}
          hideButton={hideTimezone}
          customStyle={'z-index: 35;'}
          type="radio"
          handleShow={(show: boolean) => setShowMenu(show ? 'timezone' : '')}
          groups={[
            timezoneListModel?.map((tzone: any) => ({
              id: tzone?.id,
              label: tzone?.label,
              selected: tzone?.id === settingsContext.state.timezone?.id,
            })),
          ]}
          onClick={(option: any) => {
            settingsContext.dispatch({
              type: 'SET_TIMEZONE_UNIT',
              data: option
            })
            setShowMenu('');
          }}
        />
      </div>
      {!hideToggleButton ? (
        <ToggleButton
          onClick={() => setOpened(!opened)}
          hidden={!setOpened}
          className="toggle-button"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="32px"
            viewBox="0 0 24 24"
            width="32px"
            fill="#ffffff"
            transform={opened ? 'rotate(180)' : 'rotate(0)'}
          >
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" />
          </svg>
        </ToggleButton>
      ) : null}
    </Header>
  );
};

export default SurveyOverlayHeader;
