import { SettingsContext } from "app/modules/settings/settings.context.d";
import { formatTimezoneDate } from "@/lib/formatter";
import { convertXLXSPoint } from "./exporters.formatter";
import { InspectionContext } from "../../inspection.context.d";
import { utils, write } from "xlsx";
import { saveAs } from "file-saver";



/**
 * @param 
 * @returns 
 */
export const exportxlxs = (
  inspectionState: InspectionContext,
  settingsState: SettingsContext
) => {
  const columns = inspectionState.grid?.getColumns().filter((c) => {
    if (c.field === 'ete') return false;
    else if (c.field === 'input_type') return false;
    else if (c.field === 'triggers') return false;
    else {
      return true;
    }
  }) as string[];
  const columnFields = columns.map((column: any) => column.field);
  const columnNames = columns.map((column: any) => column.name);
  const pointList = inspectionState.run?.trackingpoint_set?.map((p) => {
    const point = inspectionState.trackingpoints_dic[p];
    return convertXLXSPoint(point, columnFields, columnNames, inspectionState, settingsState)
  }) as string[][];

  const assingAt = formatTimezoneDate({
    date: new Date(),
    timezone: settingsState.timezone?.id || 'UTC',
    format: 'YYYY-MM-DD_-_HH:mm:ss'
  }).replace('-', 'Y').replace('-','M').replace('_', 'D_').replace(':', 'H').replace(':', 'M') + 'S';

  const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  // Desired file extesion
  const fileExtension = ".xlsx";

  //Create a new Work Sheet using the data stored in an Array of Arrays.
  const workSheet = utils.json_to_sheet(pointList as unknown as string[][]);
  // Generate a Work Book containing the above sheet.
  const workBook = {
    Props: {
      Application: 'Global Track - CDI',
    },
    Sheets: { data: workSheet, cols: [] },
    SheetNames: ["data"],
  };
  const timezoneStr = settingsState.timezone?.label?.replace(/ /g, '_');

  // Exporting the file with the desired name and extension.
  const excelBuffer = write(workBook, { bookType: "xlsx", type: "array" });
  const fileData = new Blob([excelBuffer], { type: fileType });
  const filenameStr = `${inspectionState.run?.name}_-_${assingAt}_-_${timezoneStr}`;

  saveAs(fileData, filenameStr + fileExtension);
}
