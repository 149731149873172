import { useContext, useEffect, useMemo, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import MediaCarrouselItem from '../media-carrousel-item/media-carrousel-item';
import {
  ArrowButton,
  ArrowNext,
  ArrowPrev,
  EmptyMediaWrapper,
  EmptyMediaMainText,
  TrackingPointFloatCloseButton,
  TrackingPointMediasWrapper,
} from './tracking-point-medias.style';
import ConfirmationModal from '../confirmation-modal/confirmation-modal';
import InspectionStore from 'app/modules/inspection/inspection.context';
import { deleteMedia, updateMediaCaption, updateSiteSketch } from 'app/modules/inspection/run/components/tracking-point-overlay/tracking-point-overlay.controller';

interface Props {
  medias: number[];
  selectedMediaId?: number;
  show: boolean;
  onClose: () => void;
  permissionType: 'editor' | 'observer';
  token: string;
}

/**
 * render tracking point medias carousel
 * @param props
 * @returns
 */
const TrackingPointMedias = (props: Props) => {
  const inspectionContext = useContext(InspectionStore);
  const [selectedMedia, setSelectedMedia] = useState<number>(0);
  const [confirmationModal, setConfirmationModal] = useState<any>({});
  const [allowArrowsNavigation, setAllowArrowsNavigation] = useState<boolean>(true);
  const medias = useMemo(() => {
    const copyMedias = [...props.medias];
    return copyMedias
      .reverse()
      .map((id) => {
        return inspectionContext.state.medias_dic[id];
      })
      .sort((mediaA, mediaB) =>
        mediaA.is_sketch > mediaB.is_sketch ? -1 : 1
      );
  }, [inspectionContext.state.medias_dic, props.medias]);

  useEffect(() => {
    if (props.selectedMediaId) {
      const index = medias.findIndex((media) => media.id === props.selectedMediaId);
      setSelectedMedia(index);
    }
  }, [props.selectedMediaId]);

  return (
    <>
      <TrackingPointMediasWrapper show={props.show}>
        <TrackingPointFloatCloseButton onClick={() => props.onClose()}>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="14.2427"
              y="4.34375"
              width="2"
              height="14"
              rx="1"
              transform="rotate(45 14.2427 4.34375)"
              fill="white"
            />
            <rect
              x="15.6567"
              y="14.2422"
              width="2"
              height="14"
              rx="1"
              transform="rotate(135 15.6567 14.2422)"
              fill="white"
            />
          </svg>
        </TrackingPointFloatCloseButton>

        {medias.length ? (
          <Carousel
            showIndicators={false}
            selectedItem={selectedMedia}
            showThumbs={false}
            showStatus={false}
            useKeyboardArrows={allowArrowsNavigation}
            renderArrowPrev={(clickHandler, hasPrev) => (
              <ArrowPrev hide={!hasPrev}>
                <ArrowButton
                  onClick={() => {
                    if (selectedMedia > 0)
                      setSelectedMedia(selectedMedia - 1);
                    clickHandler();
                  }}
                >
                  <svg
                    width="17"
                    height="27"
                    viewBox="0 0 17 27"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.65"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M16.1546 26.3467C17.0257 25.4756 17.0257 24.0632 16.1546 23.1921L6.6915 13.729L16.1553 4.2652C17.0264 3.39409 17.0264 1.98174 16.1553 1.11063C15.2842 0.239515 13.8718 0.239517 13.0007 1.11063L3.53693 10.5744L3.53634 10.5738L0.381763 13.7284L13.0001 26.3467C13.8712 27.2178 15.2835 27.2178 16.1546 26.3467Z"
                      fill="white"
                    />
                  </svg>
                </ArrowButton>
              </ArrowPrev>
            )}
            renderArrowNext={(clickHandler, hasNext) => (
              <ArrowNext hide={!hasNext}>
                <ArrowButton
                  onClick={() => {
                    if (selectedMedia < medias.length - 1)
                      setSelectedMedia(selectedMedia + 1);

                    clickHandler();
                  }}
                >
                  <svg
                    width="17"
                    height="27"
                    viewBox="0 0 17 27"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.65"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M16.1546 26.3467C17.0257 25.4756 17.0257 24.0632 16.1546 23.1921L6.6915 13.729L16.1553 4.2652C17.0264 3.39409 17.0264 1.98174 16.1553 1.11063C15.2842 0.239515 13.8718 0.239517 13.0007 1.11063L3.53693 10.5744L3.53634 10.5738L0.381763 13.7284L13.0001 26.3467C13.8712 27.2178 15.2835 27.2178 16.1546 26.3467Z"
                      fill="white"
                    />
                  </svg>
                </ArrowButton>
              </ArrowNext>
            )}
          >
            {medias.map((media: any) => (
              <MediaCarrouselItem
                onDelete={() => {
                  setConfirmationModal({
                    hide: false,
                    title: 'Are you sure?',
                    text: 'This media will be deleted permanently.',
                    onConfirm: () =>
                      deleteMedia(media.id, props.token, setConfirmationModal),
                    onCancel: () => setConfirmationModal({}),
                  });
                }}
                onToggleSiteSketch={() => {
                  updateSiteSketch(!media.is_sketch, media.id, props.token);
                }}
                onEditCaption={(caption: string) => {
                  updateMediaCaption(caption, media.id, props.token);
                }}
                setAllowArrowNavigation={setAllowArrowsNavigation}
                caption={media.caption}
                media={media.media}
                mediaId={media.id}
                isSiteSketch={media.is_sketch}
                permissionType={props.permissionType}
                key={media.id}
              />
            ))}
          </Carousel>
        ) : (
          <EmptyMediaWrapper>
            <EmptyMediaMainText>
              This tracking point doesn’t have any media yet...
            </EmptyMediaMainText>
          </EmptyMediaWrapper>
        )}
      </TrackingPointMediasWrapper>
      <ConfirmationModal {...confirmationModal} />
    </>
  );
};

export default TrackingPointMedias;
