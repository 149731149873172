import InspectionStore from "app/modules/inspection/inspection.context";
import { TrackingPointPlainType } from "app/modules/inspection/inspection.interfaces";
import { useContext, useEffect, useState } from "react";
import { changeTrackingPointMarker, renderMarker } from "./tp-marker.controller";

interface Props {
  point: TrackingPointPlainType;
  zoom: TrackingPointPlainType;
  hasLabels: boolean;
  hasRuler: boolean;
  measureTool: any;
  map: google.maps.Map;
}

const TPMarker = ({ point, map, zoom, hasLabels, hasRuler, measureTool }: Props) => {
  const inspectionContext = useContext(InspectionStore);
  const [marker, setMarker] = useState<google.maps.Marker | undefined>();
  const [isClicked, setIsClicked] = useState<number>();
  
  /** */
  const handleClick = () => {
    if (marker && measureTool && hasRuler) {
      measureTool._checkClick({
        latLng: marker?.getPosition(),
      });
      return;
    }
    
    inspectionContext.dispatch({
      type: 'SET_SELECTED_POINT',
      data: point.id,
    });

    inspectionContext.dispatch({
      type: 'SET_SELECTED_QUBE',
      data: point.geolocks[0],
    }); 
  };

  useEffect(() => {
    renderMarker(setIsClicked, point, map, setMarker, marker);
    return () => {
      marker?.setMap(null);
    };
  }, []);

  useEffect(() => {
    if (point) {
      changeTrackingPointMarker({ marker, point, map, hasLabels });
    }
  }, [point, zoom, hasLabels])

  useEffect(() => {
    if (isClicked) handleClick();
  }, [isClicked])

  return null;
};

export default TPMarker;