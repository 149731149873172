import styled from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 18px 1fr;
  align-items: center;
  gap: 8px;

  > svg {
    cursor: pointer;
  }
`;

export const Label = styled.label`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 13px;

  color: #6b7280;
`;
