import { getByMonth } from 'app/services/run.service';
import { Auth } from 'app/store/store.interfaces';
import { RunsDictType } from './calendar.page.d';

interface InitParams {
  setLoading: (loading: boolean) => void;
  setRunsDict: (runs: RunsDictType) => void;
  auth: Auth;
  month: number;
  year: number;
}

/**
 *
 * @param params init params
 * @param params.setLoading control loading on component state
 * @param params.setLoaddedKeys control runs already loadded on component state
 * @param params.seRuns control runs on component state
 * @param params.auth auth user
 * @param params.month month number
 * @param params.year year number
 */
export const init = async ({
  setLoading,
  setRunsDict,
  auth,
  month,
  year,
}: InitParams) => {
  setLoading(true);
  const runsDict: RunsDictType = {};
  const nextMonth = month !== 12 ? month + 1 : 1;
  const prevMonth = month !== 1 ? month - 1 : 12;
  const nextMonthYear = month !== 12 ? year : year + 1;
  const prevMonthYear = month !== 1 ? year : year - 1;
  const monthKey = `${month}/${year}`;
  const nextMonthKey = `${nextMonth}/${nextMonthYear}`;
  const prevMonthKey = `${prevMonth}/${prevMonthYear}`;

  let response = await getByMonth(month, year, auth.token);
  runsDict[monthKey] = response.data;

  response = await getByMonth(prevMonth, prevMonthYear, auth.token);
  runsDict[prevMonthKey] = response.data;

  response = await getByMonth(nextMonth, nextMonthYear, auth.token);
  runsDict[nextMonthKey] = response.data;

  setRunsDict(runsDict);
  setLoading(false);
};

interface LoadMoreParams {
  runsDict: RunsDictType;
  setRunsDict: (runs: RunsDictType) => void;
  month: number;
  year: number;
  auth: Auth;
}

/**
 * it loads runs of next month and the prev month, but before checks if already was
 * loadded preveously
 * @param params.setLoading
 * @param params.setLoaddedKeys
 * @param params.seRuns
 * @param params.auth
 * @param params.month
 * @param params.year
 */
export const loadMore = async ({
  runsDict,
  setRunsDict,
  month,
  year,
  auth,
}: LoadMoreParams) => {
  const nextMonth = month !== 12 ? month + 1 : 1;
  const prevMonth = month !== 1 ? month - 1 : 12;
  const nextMonthYear = month !== 12 ? year : year + 1;
  const prevMonthYear = month !== 1 ? year : year - 1;
  const monthKey = `${month}/${year}`;
  const nextMonthKey = `${nextMonth}/${nextMonthYear}`;
  const prevMonthKey = `${prevMonth}/${prevMonthYear}`;

  const newRunsDict: RunsDictType = {};
  let response;

  if (!runsDict[monthKey]) {
    response = await getByMonth(month, year, auth.token);
    newRunsDict[monthKey] = response.data;
  }

  if (!runsDict[nextMonthKey]) {
    response = await getByMonth(nextMonth, nextMonthYear, auth.token);
    newRunsDict[nextMonthKey] = response.data;
  }

  if (!runsDict[prevMonthKey]) {
    response = await getByMonth(prevMonth, prevMonthYear, auth.token);
    newRunsDict[prevMonthKey] = response.data;
  }

  setRunsDict({ ...runsDict, ...newRunsDict });
};
