import { useContext, useEffect, useState } from 'react';
import VMasker from 'vanilla-masker';
import { formatMps, formatTimezoneDate } from '@/lib/formatter';
import InspectionStore from 'app/modules/inspection/inspection.context';
import SettingsStore from 'app/modules/settings/settings.context';
import DefaultButton from 'app/components/default-button/default-button';
import InputDate from 'app/components/input-date/input-date';
import InputGroup from 'app/components/input-group/inputGroup';
import Input from 'app/components/input/input';
import SecondaryButton from 'app/components/secondary-button/secondary-button';
import {
  MobileScheduleModalContent,
  MobileScheduleModalContentCloseButton,
  MobileScheduleModalContentForm,
  MobileScheduleModalContentFormFooter,
  MobileScheduleModalContentHeader,
  MobileScheduleModalContentTitle,
  MobileScheduleModalWrapper,
} from './mobile-schedule-modal.style';

interface Props {
  opened: boolean;
  onClose: () => void;
  onApply: (date: string, time: string, speed: string) => void;
}


/**
 *
 */
const MobileScheduleModal = (props: Props) => {
  const inspectionContext = useContext(InspectionStore);
  const settingsContext = useContext(SettingsStore);
  const [scheduledTime, setScheduledTime] = useState<string>('');
  const [scheduledDate, setScheduledDate] = useState<string>('');
  const [predictedLaunchSpeed, setPredictedLaunchSpeed] = useState<string>('');

  useEffect(() => {
    if (inspectionContext.state.run?.scheduled_launch_at) {
      setScheduledDate(inspectionContext.state.run.scheduled_launch_at);

      const time = formatTimezoneDate({
        date: inspectionContext.state.run.scheduled_launch_at,
        timezone: settingsContext.state.timezone?.id || 'utc',
        format: 'HH:mm:ss.SSS',
      });

      setScheduledTime(time);
    }
    if (inspectionContext.state.run?.predicted_launch_speed) {
      setPredictedLaunchSpeed(
        formatMps({
          distance: inspectionContext.state.run.predicted_launch_speed,
          unit: settingsContext.state.speedUnit,
          returnNumber: true,
        })
      );
    }
  }, [inspectionContext.state.run, settingsContext.state.timezone, settingsContext.state.speedUnit]);

  const handleApply = () => {
    const serializedTiem = VMasker.toPattern(scheduledTime, '99:99:99.999')
    props.onApply(scheduledDate, serializedTiem, predictedLaunchSpeed);
    props.onClose();
  }

  return (
    <MobileScheduleModalWrapper opened={props.opened}>
      <MobileScheduleModalContent>
        <MobileScheduleModalContentHeader>
          <MobileScheduleModalContentTitle>
            Schedule run
            <MobileScheduleModalContentCloseButton onClick={props.onClose}>
              <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="16.2637" y="4.94922" width="2" height="16" transform="rotate(45 16.2637 4.94922)" fill="#99A2AD"/>
                <rect x="17.6777" y="16.2637" width="2" height="16" transform="rotate(135 17.6777 16.2637)" fill="#99A2AD"/>
              </svg>
            </MobileScheduleModalContentCloseButton>
          </MobileScheduleModalContentTitle>
        </MobileScheduleModalContentHeader>
        <MobileScheduleModalContentForm>
          <InputGroup label="Date">
            <InputDate
              value={scheduledDate}
              handleChange={(event) => {
                if (event.value) {
                  setScheduledDate(event.value.toISOString());
                }
              }}
              format="yyyy/MM/dd"
            />
          </InputGroup>
          <InputGroup label="Time">
            <Input
              type="tel"
              value={VMasker.toPattern(scheduledTime, '99:99:99.999')}
              handleChange={(e) => {
                setScheduledTime(e.target.value);
              }}
            />
          </InputGroup>
          <InputGroup label="Predicted Launch Speed">
            <Input
              type="tel"
              value={predictedLaunchSpeed}
              handleChange={(e) => {
                setPredictedLaunchSpeed(e.target.value);
              }}
            />
          </InputGroup>

          <MobileScheduleModalContentFormFooter>
            <DefaultButton onClick={handleApply}>Apply</DefaultButton>
            <SecondaryButton handleClick={props.onClose}>Cancel</SecondaryButton>
          </MobileScheduleModalContentFormFooter>
        </MobileScheduleModalContentForm>
      </MobileScheduleModalContent>
    </MobileScheduleModalWrapper>
  );
};

export default MobileScheduleModal;
