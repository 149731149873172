import { InputWrapper, Input } from './search-input.style';

interface Props {
  placeholder?: string;
  id?: string;
  value: string;
  customStyle?: string;
  onChange: (event: any) => void;
}

const SearchInput = ({
  placeholder,
  id,
  value,
  onChange,
  customStyle,
}: Props) => (
  <InputWrapper customStyle={customStyle}>
    <Input
      placeholder={placeholder}
      value={value}
      type="search"
      id={id}
      onChange={onChange}
    />
    <figure>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.2935 15.7078C14.6844 16.0979 15.3176 16.0973 15.7078 15.7065C16.0979 15.3156 16.0973 14.6824 15.7065 14.2922L14.2935 15.7078ZM11.5678 11.5741L10.8612 10.8665L11.5678 11.5741ZM7.19231 12.3846C4.32468 12.3846 2 10.0599 2 7.19231H0C0 11.1645 3.22011 14.3846 7.19231 14.3846V12.3846ZM2 7.19231C2 4.32468 4.32468 2 7.19231 2V0C3.22011 0 0 3.22011 0 7.19231H2ZM7.19231 2C10.0599 2 12.3846 4.32468 12.3846 7.19231H14.3846C14.3846 3.22011 11.1645 0 7.19231 0V2ZM12.3846 7.19231C12.3846 8.62774 11.8035 9.92554 10.8612 10.8665L12.2744 12.2817C13.577 10.981 14.3846 9.18 14.3846 7.19231H12.3846ZM10.8612 10.8665C9.92067 11.8056 8.62506 12.3846 7.19231 12.3846V14.3846C9.17626 14.3846 10.9742 13.58 12.2744 12.2817L10.8612 10.8665ZM10.8613 12.2818L14.2935 15.7078L15.7065 14.2922L12.2742 10.8663L10.8613 12.2818Z"
          fill="#8E949F"
        />
      </svg>
    </figure>
  </InputWrapper>
);

export default SearchInput;
