import VMasker from 'vanilla-masker';
import IMask from 'imask';
import ReactGA from "react-ga4";
import moment from 'moment-timezone';
import { createPassage, deletePassage, launch, toggleActiveTrackingPoint, updatePassage, updateTrackingPoint } from "app/services/run.service";
import { Bound } from 'app/components/common/map/map.d';
import { RunType, TrackingPointPlainType } from 'app/modules/inspection/inspection.interfaces';
import { NotificationProviderValue } from 'app/modules/notification/notification.context.d';

export const updateIcon = async ({ value, id, token, dispatch }: any) => {
  dispatch({
    type: 'SET_TOAST',
    data: {
      title: 'Loading . . . ',
      type: 'loading',
    },
  });

  try {
    const updatePointData: any = {
      id: id,
      type: value,
    };
    await updateTrackingPoint(id, updatePointData, token);
  } catch (err) {
    dispatch({
      type: 'SET_TOAST',
      data: {
        type: 'error',
        title: 'Error',
        text: `Error to update tracking point`,
      },
    });
  }
};

/**
 *
 * @param args
 * @param token
 */
export const handlePassageChange = async (
  tstamp: number | null,
  run: RunType,
  point: TrackingPointPlainType,
  token: string,
) => {
  if (!run) return;
  const trackingPointId = point.id;
  const runId = point.run as number;
  const passage = point.passage;
  const distance = point.distance;
  const speed = point.speed;

  const isDate = tstamp ? new Date(tstamp).toISOString() : null;

  const data = {
    run: runId,
    distance: parseFloat(distance),
    tracking_point: trackingPointId,
    tstamp: isDate,
    id: passage,
    speed: undefined as number | null | undefined,
  };

  if (distance === '0') data.speed = speed;

  if (data.distance === 0) {
      const launchData = {
        id: runId,
        tstamp: data.tstamp,
        speed: run.predicted_launch_speed,
        as_test: false,
      };

      // on staging there was implemented error handling with toast to this and was removed
      // TODO: reimplement that
      await launch(runId.toString(), launchData, token);
      
      ReactGA.event({
        category: "Run",
        action: "Launch",
        label: "mobile",
      });

      return;
  }

  // on staging there was implemented error handling with toast here to
  // passages registered without run is launched
  // TODO: reimplement that

  if (tstamp && passage) {
    await updatePassage(passage.toString(), data, token);
  }

  if (tstamp && !passage) {
    await createPassage(data, token);

    ReactGA.event({
      category: "Run",
      action: "Create Passage",
      label: "mobile",
    });
  }

  if (!tstamp && passage) {
    await deletePassage(passage, token);
  }
};

/**
 *
 * @returns
 */
export const handleCommonTextChange = async (trackingPointId: number, data: any, token: string) => {
  await updateTrackingPoint(trackingPointId, data, token);
};

/**
 *
 * @param args
 * @param token
 */
export const handlePassageDelete = async (point: TrackingPointPlainType, token: string) => {
  if (!point?.passage) return;
  await deletePassage(point.passage, token)
};


/**
 *
 * @returns
 */
export const handleHold = async (
  point: TrackingPointPlainType,
  token: string,
  notificationDispatch: NotificationProviderValue['dispatch']
) => {
  const data: any = {
    id: point.id,
    active: !point.active,
  };
  notificationDispatch({ type: 'SET_TOAST', data: { type: 'loading', text: 'loading...' }})
  await toggleActiveTrackingPoint(`${point.id}`, data, token);
  notificationDispatch({ type: 'CLOSE_TOAST' })
};

/**
 *
 */
export const serializePassageTime = (value: string, timezone: string, tstamp?: string | null) => {
  let h = '0';
  let m = '0';
  let s = '0';
  let mm = '0';

  if (value.length === 3) {
    const nValue = `0${value}`;
    const time = VMasker.toPattern(`${nValue}`, '99:99');
    const [hour, minute] = time.split(':');

    h = hour;
    m = minute;
  } else {
    const mask = new IMask.MaskedPattern({
      mask: 'HH:MM:SS.MMM',
      blocks: {
        HH: { mask: /^[0-9]{1,2}$/ },
        MM: { mask: /^[0-9]{1,2}$/ },
        SS: { mask: /^[0-9]{1,2}$/ },
        MMM: { mask: /^[0-9]{1,3}$/ },
      },
    });

    const [hour, minute, smm] = mask.resolve(value).split(':');

    h = hour;
    m = minute || '0';

    if (smm) {
      const [second, milisecond] = smm.split('.');
      s = second;
      mm = milisecond;
    }
  }

  const passage = tstamp;
  const momentDate = passage ? moment.tz(passage, 'UTC') : moment();

  momentDate.tz(timezone);
  momentDate.set('hour', parseInt(h));
  momentDate.set('minute', parseInt(m));
  if (s) momentDate.set('second', parseInt(s));
  if (mm) momentDate.set('millisecond', parseInt(mm));

  momentDate.tz('UTC');
  return momentDate.valueOf();
}

/**
   *
   * @param row
   * @param nextPoint
   * @param prevPoint
   */
export const getMapBounds = (row: TrackingPointPlainType, nextPoint?: TrackingPointPlainType, prevPoint?: TrackingPointPlainType) => {
  if (!prevPoint?.geometry?.coordinates[1] || !nextPoint?.geometry?.coordinates[1]) {
    return [
      {
        lat: row.geometry?.coordinates[1],
        lng: row.geometry?.coordinates[0],
      },
    ];
  }

  const bounds: any = [
    { lat: row.geometry?.coordinates[1], lng: row.geometry?.coordinates[0] },
    {
      lat: prevPoint?.geometry?.coordinates[1],
      lng: prevPoint?.geometry?.coordinates[0],
    },
    {
      lat: nextPoint?.geometry?.coordinates[1],
      lng: nextPoint?.geometry?.coordinates[0],
    },
  ];

  return bounds;
};

/**
 *
 * @param bounds
 * @param setBounds
 */
export const updateMapBounds = (bounds: Bound[], setBounds: (bounds: Bound[]) => void) => {
  setBounds(bounds);
};
