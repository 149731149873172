import styled from 'styled-components';

export const Wrapper = styled.div<{ hidden?: boolean }>`
  position: relative;
  ${({ hidden }) => (hidden ? 'display: none;' : '')}
`;

export const ScheduledOnWrapper = styled.div`
  display: flex;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
`;

export const ScheduleOnDateWrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(200px, 1fr) minmax(200px, 1fr);
  gap: 8px;
`;

export const ScheduledOn = styled.label<{ disabled: boolean }>`
  padding: 8px 12px;

  background: #ffffff;

  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  color: #5850ec;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

export const LaunchedAt = styled.label`
  padding: 8px 12px;

  background: #ffffff;
  color: #5850ec;

  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
`;

export const LaunchButton = styled.button`
  padding: 8px 12px;
  background: #5850ec;
  color: #ffffff;

  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 13px;

  cursor: pointer;

  &:disabled {
    display: none;
  }
`;

export const MenuButton = styled.button`
  padding: 8px 12px;
  background: #5850ec;
  color: #ffffff;

  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 13px;

  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }
`;

export const SchedulerPicker = styled.div<any>`
  position: absolute;
  top: 100%;
  z-index: 10;
  padding: 8px 12px;
  background: #fff;
  border-radius: 8px;
  margin: 5px 0;

  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 1em;
  line-height: 13px;

  .MuiFormLabel-root {
    font-size: 13px;
  }
  .MuiFormControl-marginNormal {
    margin: 0px;
  }

  ${(props) => (props.visible ? '' : 'display: none;')}
  ${(props) => (props.right ? 'right: 0;' : '')}
`;

export const TimeInputWrapper = styled.div`
  input {
    padding: 5px 10px;
    border: 1px solid #e5e5e5;
    border-radius: 3px;
    width: 100%;

    &:active {
      padding: 5px 10px;
      border: 1px solid #5850ec;
    }

    &:focus {
      padding: 5px 10px;
      border: 1px solid #5850ec;
    }
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const BackButton = styled.button`
  padding: 8px 12px;
  display: block;
  background: #fff;
  cursor: pointer;
  img {
    cursor: pointer;
  }
`;

export const RunningOptions = styled.ul`
  display: block;
  background: #fff;
`;

export const RunningOption = styled.li<any>`
  padding: 8px 12px;
  cursor: pointer;
  display: block;
  background: #fff;
  font-weight: normal;

  ${(props) => (props.disabled ? 'opacity: .5;' : '')}
  ${(props) => (props.disabled ? 'cursor: default;' : '')}
`;
