import styled from 'styled-components';

export const Wrapper = styled.div<{ customStyle: string }>`
  display: flex;
  align-items: center;
  gap: 8px;

  > svg {
    cursor: pointer;
  }
  ${({ customStyle }) => customStyle}
`;

export const Label = styled.label`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 13px;

  color: #6b7280;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 210px;
`;
