import { useContext, useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import SettingsStore from 'app/modules/settings/settings.context';
import ConfirmationModal from '../confirmation-modal/confirmation-modal';
import { ConfirmationModalType } from '../confirmation-modal/confirmation-modal.d';
import { loadSurveys, saveRun } from './createRunModal.controller';
import {
  ModalWrapper,
  Modal,
  Header,
  CloseIcon,
  Container,
} from './createRunModal.style';
import Form from './components/form/form';

const CreateRunModal = ({ hide, onClose, projectId, customerId, auth }: any) => {
  const { id } = useParams<{ id: string }>();
  const settingsContext = useContext(SettingsStore);
  const history = useHistory();
  const [surveys, setSurveys] = useState([]);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [confirmationModal, setConfirmationModal] =
    useState<ConfirmationModalType>();

  useEffect(() => {
    if (!hide) loadSurveys({ projectId, auth, setSurveys });
  }, [hide]);

  return (
    <ModalWrapper hide={hide}>
      <Modal>
        <Header>
          <CloseIcon onClick={() => onClose()}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="14.2427"
                y="4.34375"
                width="2"
                height="14"
                rx="1"
                transform="rotate(45 14.2427 4.34375)"
                fill="#000"
              />
              <rect
                x="15.6567"
                y="14.2422"
                width="2"
                height="14"
                rx="1"
                transform="rotate(135 15.6567 14.2422)"
                fill="#000"
              />
            </svg>
          </CloseIcon>
        </Header>
        <Container>
          <Form
            auth={auth}
            onSubmit={(run: any, cleanFields: any) =>
              saveRun({
                run,
                auth: auth,
                history,
                setErrors,
                timezone: settingsContext.state.timezone,
                speedUnit: settingsContext.state.speedUnit,
                projectId: id,
                setIsLoading,
                cleanFields,
                setConfirmationModal,
                customerId,
              })
            }
            surveys={surveys}
            errors={errors}
            isLoading={isLoading}
          />
        </Container>
      </Modal>

      {confirmationModal ? <ConfirmationModal {...confirmationModal} /> : null}
    </ModalWrapper>
  );
};

CreateRunModal.defaultProps = {};

export default CreateRunModal;
