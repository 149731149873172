import styled from 'styled-components';

export const ModalWrapper = styled.div<{ opened: boolean }>`
  position: fixed;
  top: 0;

  display: ${({ opened }) => (opened ? 'flex' : 'none')};
  justify-content: center;

  width: 100%;
  min-height: 100vh;

  background-color: rgba(35, 42, 51, 0.65);

  z-index: 30;
`;

export const Modal = styled.div`
  width: 100%;
  height: 100vh;

  overflow: hidden;

  background-color: #ffffff;

  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: 220px 1fr;
    grid-template-rows: 100%;

    margin-top: 10vh;

    max-width: 860px;
    max-height: 555px;

    border-radius: 6px;
  }
`;

export const ContentWrapper = styled.div`
  position: relative;

  overflow: auto;
  padding: 28px;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 14px;
  right: 15px;

  background-color: transparent;
  cursor: pointer;
`;
