import VMasker from 'vanilla-masker';
import IMask from 'imask';
import moment from 'moment-timezone';

const icons = {
  passage_time:
    '<svg xmlns="http://www.w3.org/2000/svg" height="14px" viewBox="0 0 24 24" width="14px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm.5-13H11v6l5.25 3.15.75-1.23-4.5-2.67z"/></svg>',
  passage_date:
    '<svg xmlns="http://www.w3.org/2000/svg" height="14px" viewBox="0 0 24 24" width="14px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V10h16v11zm0-13H4V5h16v3z"/></svg>',
};

const formats = {
  passage_time: 'HH:mm:ss.SSS',
  passage_date: 'YYYY-MM-DD',
};

interface SerializerOptions {
  passage_tstamp?: string;
  timezone?: string
}

class PassageEditor {
  args: any;
  input: any;
  button: any;
  // eta: string;
  keyCaptureList: number[];

  constructor(args: any) {
    const inputWrapper = document.createElement('div');
    inputWrapper.className = 'gt-editable-input-with-clock';

    const input = document.createElement('input');
    input.className = 'gt-clock-input';
    input.type = 'text';

    const span = document.createElement('span');
    span.className = 'gt-clock';

    span.innerHTML = `
      ${icons[args.column?.field as 'passage_time' | 'passage_date']}
    `;

    span.addEventListener('click', this.setCurrentTime.bind(this), false);

    inputWrapper.appendChild(input);
    inputWrapper.appendChild(span);

    args.container?.append(inputWrapper);

    this.args = args;
    this.input = input;
    this.button = span;
    // [LEFT_ARROW, RIGHT_ARROW]
    this.keyCaptureList = [37, 39];
  }

  setCurrentTime(e): void {
    e.stopPropagation();
    const value = moment.tz(this.args.item.timezone);
    const format = this.args.column.field as 'passage_time' | 'passage_date';
    this.input.value = value.format(formats[format]);
    this.input.select();
  }

  validate(): { valid: boolean; msg: string | null } {
    return {
      valid: true,
      msg: null,
    };
  }

  applyValue(item: any, state: any): void {
    item[this.args.column.field] = state;
  }

  destroy(): void {
    this.input.remove();
    this.button.remove();
  }

  focus(): void {
    this.input.focus();
  }

  getValue(): string {
    return this.input.value;
  }

  setValue(value: string): void {
    this.input.value = value;
  }

  loadValue(context: any): void {
    const format = this.args.column.field as 'passage_time' | 'passage_date';
    const value = context[this.args.column.field];
    const formattedValue = moment.tz(new Date(value), context.timezone).format(formats[format]);

    this.input.value = value ? formattedValue : '';
    this.input.defaultValue = value ? formattedValue : '';
    this.input.select();
  }

  private serializePassageTimeValue(
    value: string,
    args: any,
    options?: SerializerOptions
  ): string {
    let h = '0';
    let m = '0';
    let s = '0';
    let mm = '0';

    if (value.length === 3) {
      const nValue = `0${value}`;
      const time = VMasker.toPattern(`${nValue}`, '99:99');
      const [hour, minute] = time.split(':');

      h = hour;
      m = minute;
    } else {
      const mask = new IMask.MaskedPattern({
        mask: 'HH:MM:SS.MMM',
        blocks: {
          HH: { mask: /^[0-9]{1,2}$/ },
          MM: { mask: /^[0-9]{1,2}$/ },
          SS: { mask: /^[0-9]{1,2}$/ },
          MMM: { mask: /^[0-9]{1,3}$/ },
        },
      });

      const [hour, minute, smm] = mask.resolve(value).split(':');

      h = hour;
      m = minute || '0';

      if (smm) {
        const [second, milisecond] = smm.split('.');
        s = second;
        mm = milisecond;
      }
    }

    const passage = options?.passage_tstamp || args.item.passage?.tstamp;
    const momentDate = passage ? moment.tz(passage, 'UTC') : moment();

    momentDate.tz(options?.timezone || args.item.timezone);
    momentDate.set('hour', parseInt(h));
    momentDate.set('minute', parseInt(m));
    if (s) momentDate.set('second', parseInt(s));
    if (mm) momentDate.set('millisecond', parseInt(mm));

    momentDate.tz('UTC');
    return momentDate.toISOString();
  }

  private serializePassageDateValue(
    value: string,
    args: any,
    options?: SerializerOptions
  ): string {
    const passage = args.item?.passage?.tstamp;
    const momentDate = passage ? moment.tz(passage, 'UTC') : moment();

    if (!value) throw new Error('Invalid Date');
    const date = VMasker.toPattern(`${value}`, '9999-99-99');

    const year = +date.slice(0, 4);
    const month = +date.slice(5, 7) - 1;
    const day = +date.slice(8, 10);

    momentDate.tz(options?.timezone || args.item?.timezone);
    momentDate.set('date', day);
    momentDate.set('month', month);
    momentDate.set('year', year);

    momentDate.tz('UTC');
    return momentDate.toISOString();
  }

  serializeValue(
    opitionalFormat?: 'passage_time' | 'passage_date',
    optionalValue?: string,
    tstamp?: string,
    timezone?: string
  ): string {
    if (!this.input.value && !optionalValue) return '';
    const format = (opitionalFormat || this.args.column.field) as 'passage_time' | 'passage_date';
    const value = optionalValue || this.input.value;

    const serializers = {
      passage_time: this.serializePassageTimeValue,
      passage_date: this.serializePassageDateValue,
    };

    return serializers[format](value, this.args, { timezone, passage_tstamp: tstamp });
  }

  isValueChanged(): boolean {
    return this.input.value !== this.input.defaultValue;
  }
}

export default PassageEditor;
