/* eslint-disable react/jsx-no-useless-fragment */
import { ButtonsWrapper, Button } from './follow-buttons.style';

const FollowButtons = ({
  hidePigButton,
  hideLoggedUserButton,
  followingPig,
  followingCurrentUserPosition,
  onFollowingPigToggle,
  onFollowingCurrentUserPositionToggle,
}: {
  hidePigButton: boolean;
  hideLoggedUserButton: boolean;
  followingPig: boolean;
  followingCurrentUserPosition: boolean;
  onFollowingPigToggle: () => void;
  onFollowingCurrentUserPositionToggle: () => void;
}) => (
  <>
    {!hidePigButton || !hideLoggedUserButton ? (
      <ButtonsWrapper>
        {!hidePigButton ? (
          <Button
            title="Follow pig"
            onClick={onFollowingPigToggle}
            active={followingPig}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 0 24 24"
              width="24px"
              fill="#000000"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm8.94 3c-.46-4.17-3.77-7.48-7.94-7.94V1h-2v2.06C6.83 3.52 3.52 6.83 3.06 11H1v2h2.06c.46 4.17 3.77 7.48 7.94 7.94V23h2v-2.06c4.17-.46 7.48-3.77 7.94-7.94H23v-2h-2.06zM12 19c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z" />
            </svg>
          </Button>
        ) : null}
        {!hideLoggedUserButton ? (
          <Button
            title="Follow Current Position"
            onClick={onFollowingCurrentUserPositionToggle}
            active={followingCurrentUserPosition}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              enableBackground="new 0 0 24 24"
              height="24px"
              viewBox="0 0 24 24"
              width="24px"
              fill="#000000"
            >
              <g>
                <path d="M0,0h24v24H0V0z" fill="none" />
              </g>
              <g>
                <g>
                  <path d="M13.02,19.93v2.02c2.01-0.2,3.84-1,5.32-2.21l-1.42-1.43C15.81,19.17,14.48,19.75,13.02,19.93z" />
                  <path d="M4.03,12c0-4.05,3.03-7.41,6.95-7.93V2.05C5.95,2.58,2.03,6.84,2.03,12c0,5.16,3.92,9.42,8.95,9.95v-2.02 C7.06,19.41,4.03,16.05,4.03,12z" />
                  <path d="M19.95,11h2.02c-0.2-2.01-1-3.84-2.21-5.32l-1.43,1.43C19.19,8.21,19.77,9.54,19.95,11z" />
                  <path d="M18.34,4.26c-1.48-1.21-3.32-2.01-5.32-2.21v2.02c1.46,0.18,2.79,0.76,3.9,1.62L18.34,4.26z" />
                  <path d="M18.33,16.9l1.43,1.42c1.21-1.48,2.01-3.31,2.21-5.32h-2.02C19.77,14.46,19.19,15.79,18.33,16.9z" />
                  <path d="M16,11.1C16,8.61,14.1,7,12,7s-4,1.61-4,4.1c0,1.66,1.33,3.63,4,5.9C14.67,14.73,16,12.76,16,11.1z M12,12 c-0.59,0-1.07-0.48-1.07-1.07c0-0.59,0.48-1.07,1.07-1.07s1.07,0.48,1.07,1.07C13.07,11.52,12.59,12,12,12z" />
                </g>
              </g>
            </svg>
          </Button>
        ) : null}
      </ButtonsWrapper>
    ) : null}
  </>
);

export default FollowButtons;
