import { useContext, useEffect, useState } from 'react';
import MobileDetect from 'mobile-detect';
import { init } from './slick-table.controller';
import { SlickRowProps, SlickTableProps } from './slick-table.d';
import { GtTable } from './slick-table.style';
import SettingsStore from 'app/modules/settings/settings.context';

/**
 *
 * @param grid
 * @param index
 * @param data
 */
export const reloadPoint = (index: number, data: any, grid: any) => {
  if (!data) return;

  const columns = grid.getColumns();
  const gridData = grid.getData();
  const rowData = gridData[index];
  gridData[index] = data;

  for (const column of columns) {
    if (!rowData || rowData[column.field] !== data[column.field]) {
      const col = grid.getColumnIndex(column.field);
      grid.invalidateRow(index)
      grid.updateCell(index, col);
    }

    if (column.affected_by) {
      for (const affected of column.affected_by) {
        if (!rowData || rowData[affected] !== data[affected]) {
          const col = grid.getColumnIndex(column.field);
          grid.invalidateRow(index)
          grid.updateCell(index, col);
        }
      }
    }
  }

  grid.render()
};

/**
 *
 * @returns
 */
const SlickRow = (props: SlickRowProps) => {
  const settingsContext = useContext(SettingsStore);

  useEffect(() => {
    reloadPoint(props.index, props.data, props.grid)
  }, [props.data]);

  useEffect(() => {
    const gridData = props.grid?.getData();
    gridData[props.index] = props.data;
    props.grid?.invalidateRow(props.index);
    props.grid?.resizeCanvas();
    props.grid?.render();
    props.grid?.resizeCanvas();
  }, [
    settingsContext.state.speedUnit,
    settingsContext.state.distanceUnit,
    settingsContext.state.timezone,
  ]);

  return null;
};

/**
 *
 * @param param0
 * @returns
 */
const SlickTable = (props: SlickTableProps) => {
  const [grid, setGrid] = useState<Slick.Grid<any>>();
  const md = new MobileDetect(window.navigator.userAgent);

  const overideOnLoad = (grid: any) => {
    setGrid(grid);
    if (props.onLoad) props.onLoad(grid);
  }

  useEffect(() => {
    // init table
    init(
      props.columns,
      [],
      {
        editable: true,
        alwaysAllowHorizontalScroll: true,
        enableRowSelection: true,
        enableCellNavigation: true,
        asyncEditorLoading: true,
        autoEdit: md.mobile(),
        autoResize: true,
        headerRowHeight: 18,
        gridMenu: {
          columnTitle: 'Columns',
          hideForceFitButton: true,
          hideSyncResizeButton: true,
          iconImage: '/assets/slickgrid/drag-handle.png',
          resizeOnShowHeaderRow: true,
        },
        gridHeight: '100%',
        rowHeight: 25,
        ...props.options,
      },
      overideOnLoad,
    );
  }, []);

  return (
    <>
      <GtTable customStyle={props.customStyle} id="grid" />;
      {
        grid ? props.data.map((data, index) => (
          <SlickRow key={index} data={data} index={index} grid={props.grid} />
        )) : null
      }
    </>
  )
};

SlickTable.defaultProps = {};

export default SlickTable;
