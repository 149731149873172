import { InspectionContext } from "app/modules/inspection/inspection.context.d";

/* eslint-disable @typescript-eslint/ban-ts-comment */
interface Point {
  lat: number;
  lng: number;
}
interface getBoundsBoxResponse {
  east: number;
  north: number;
  south: number;
  west: number;
}

export const getBoundsBox = (points: Point[]): getBoundsBoxResponse => {
  let gtLat = 0;
  let ltLat = 0;
  let gtLng = 0;
  let ltLng = 0;

  const array: Point[] = points.filter((o) => o.lat);

  array.forEach((e: Point) => {
    if (e.lat > gtLat || !gtLat) gtLat = e.lat;
    if (e.lat < ltLat || !ltLat) ltLat = e.lat;
    if (e.lng > gtLng || !gtLng) gtLng = e.lng;
    if (e.lng < ltLng || !ltLng) ltLng = e.lng;
  });

  return {
    east: gtLng,
    north: gtLat,
    south: ltLat,
    west: ltLng,
  };
};

const getIconType = (point: any, type?: string): string => {
  const types: any = {
    blue: 'default',
    green: 'green',
    yellow: 'yellow',
    red: 'gray',
  };

  if (type === 'animated') {
    return `${type}-${types[point.color] || 'default'}`;
  }

  return types[point.color] || 'default';
};

export const getTrackingPointIconSrc = (point: any, type?: string): any => {
  // @ts-ignore
  const legends: any = {
    default: 'Waiting',
    blue: 'Waiting',
    green: 'Recorded',
    yellow: 'Overdue',
    grey: 'Skipped/Missed',
    gray: 'Skipped/Missed',
  };

  const geolockedIcons = {
    default: '/assets/icons/qube/blue-geolock.svg',
    blue: '/assets/icons/qube/blue-geolock.svg',
    gray: '/assets/icons/qube/gray-geolock.svg',
    grey: '/assets/icons/qube/gray-geolock.svg',
    red: '/assets/icons/qube/gray-geolock.svg',
    yellow: '/assets/icons/qube/yellow-geolock.svg',
    green: '/assets/icons/qube/green-geolock.svg',
  };

  const domain = 'https://cdi-gt-medias.s3.us-east-2.amazonaws.com';
  const noBorder = type === 'no-border';
  const noBorderPath = noBorder ? 'no-border/' : '';
  const icon: any = {
    url: `${domain}/${noBorderPath}${getIconType(point, type)}/agm.svg`,
    legend: legends[`${getIconType(point)}`],
  };

  if (point.geolocks && point.geolocks.length) {
    icon.url = geolockedIcons[point.color];
    return icon;
  }

  if (point?.type) {
    icon.url = `${domain}/${noBorderPath}${getIconType(point, type)}/${
      point.type
    }.svg`;

    const geolockedIcons = {
      default: '/assets/icons/qube/blue-geolock.svg',
      blue: '/assets/icons/qube/blue-geolock.svg',
      gray: '/assets/icons/qube/gray-geolock.svg',
      grey: '/assets/icons/qube/gray-geolock.svg',
      red: '/assets/icons/qube/gray-geolock.svg',
      yellow: '/assets/icons/qube/yellow-geolock.svg',
      green: '/assets/icons/qube/green-geolock.svg',
    };

    if (point.geolocks && point.geolocks.length) {
      icon.url = geolockedIcons[point.color];
      return icon;
    }

    const deviceTriggerIcons = {
      default: '/assets/icons/qube/blue/geolock.svg',
      blue: '/assets/icons/qube/blue/ad-hoc-point.svg',
      gray: '/assets/icons/qube/gray/ad-hoc-point.svg',
      red: '/assets/icons/qube/gray/ad-hoc-point.svg',
      yellow: '/assets/icons/qube/yellow/ad-hoc-point.svg',
      green: '/assets/icons/qube/green/ad-hoc-point.svg',
    };

    if (point.is_device_trigger) {
      icon.url = deviceTriggerIcons[point.color];
      return icon;
    }

    return icon;
  }

  const launcherRegex = /launch/gi;
  const receiverRegex = /receiver/gi;
  const mlvRegex = /mlv/gi;

  if (launcherRegex.test(point.name)) {
    icon.url = `${domain}/${noBorderPath}${getIconType(
      point,
      type
    )}/launcher.svg`;
  }
  if (receiverRegex.test(point.name)) {
    icon.url = `${domain}/${noBorderPath}${getIconType(
      point,
      type
    )}/receiver.svg`;
  }

  if (mlvRegex.test(point.name)) {
    icon.url = `${domain}/${noBorderPath}${getIconType(point, type)}/valve.svg`;
  }

  return icon;
};

export const getTrackingpointIcon = (
  point: any,
  type?: string,
  zoom?: number
): any => {
  // @ts-ignore
  if (!point || !window?.google) return null;
  const legends: any = {
    default: 'Waiting',
    blue: 'Waiting',
    green: 'Recorded',
    yellow: 'Overdue',
    grey: 'Skipped/Missed',
    gray: 'Skipped/Missed',
  };

  let scaledSize = new window.google.maps.Size(18, 18);
  let anchor = new window.google.maps.Point(9, 9);

  if (zoom && zoom > 11) {
    scaledSize = new window.google.maps.Size(36, 36);
    anchor = new window.google.maps.Point(18, 18);
  }

  if (zoom && zoom <= 11 && zoom > 9) {
    scaledSize = new window.google.maps.Size(24, 24);
    anchor = new window.google.maps.Point(12, 12);
  }

  const domain = 'https://cdi-gt-medias.s3.us-east-2.amazonaws.com';
  const noBorder = type === 'no-border';
  const noBorderPath = noBorder ? 'no-border/' : '';
  const icon: any = {
    url: `${domain}/${noBorderPath}${getIconType(point, type)}/agm.svg`,
    // @ts-ignore
    scaledSize, // scaled size
    // @ts-ignore
    origin: new window.google.maps.Point(0, 0), // origin
    // @ts-ignore
    anchor, // anchor
    legend: legends[`${getIconType(point)}`],
  };

  const geolockedIcons = {
    default: '/assets/icons/qube/blue-geolock.svg',
    blue: '/assets/icons/qube/blue-geolock.svg',
    gray: '/assets/icons/qube/gray-geolock.svg',
    grey: '/assets/icons/qube/gray-geolock.svg',
    red: '/assets/icons/qube/gray-geolock.svg',
    yellow: '/assets/icons/qube/yellow-geolock.svg',
    green: '/assets/icons/qube/green-geolock.svg',
  };

  if (point.geolocks && point.geolocks.length) {
    icon.url = geolockedIcons[point.color];
    return icon;
  }

  const deviceTriggerIcons = {
    default: '/assets/icons/qube/blue-geolock.svg',
    blue: '/assets/icons/qube/blue/ad-hoc-point.svg',
    gray: '/assets/icons/qube/gray/ad-hoc-point.svg',
    red: '/assets/icons/qube/gray/ad-hoc-point.svg',
    yellow: '/assets/icons/qube/yellow/ad-hoc-point.svg',
    green: '/assets/icons/qube/green/ad-hoc-point.svg',
  };

  if (point.is_device_trigger) {
    icon.url = deviceTriggerIcons[point.color];
    return icon;
  }

  if (point?.type) {
    icon.url = `${domain}/${noBorderPath}${getIconType(point, type)}/${
      point.type
    }.svg`;

    return icon;
  }

  const launcherRegex = /launch/gi;
  const receiverRegex = /receiver/gi;
  const mlvRegex = /mlv/gi;

  if (launcherRegex.test(point.name)) {
    icon.url = `${domain}/${noBorderPath}${getIconType(
      point,
      type
    )}/launcher.svg`;
  }
  if (receiverRegex.test(point.name)) {
    icon.url = `${domain}/${noBorderPath}${getIconType(
      point,
      type
    )}/receiver.svg`;
  }

  if (mlvRegex.test(point.name)) {
    icon.url = `${domain}/${noBorderPath}${getIconType(point, type)}/valve.svg`;
  }

  return icon;
};

/**
 * 
 * @param points 
 * @param pointsDic 
 * @param passagesDic 
 * @returns 
 */
export const getLastPassage = (
  points: number[],
  pointsDic: InspectionContext['trackingpoints_dic'],
  passagesDic: InspectionContext['passages_dic']
) => {
  const indexedPoints = objectDeepClone(
    points?.map((id: any, index: number) => ({ ...pointsDic[id], index }))
  );

  const filtered = indexedPoints?.filter((point) => {
    const passage = passagesDic[point.passage];
    return passage?.tstamp
  });

  const lastPassagePoint = objectDeepClone(filtered.slice(-1)[0]);
  // TODO move this to backend
  let i = lastPassagePoint.index + 1;
  while (indexedPoints[i] && !indexedPoints[i].active) {
    i++;
  }
  const nextPassagePoint = indexedPoints[i] || indexedPoints[1];
  const endPoint = indexedPoints.slice(-1)[0];

  let point;

  if (!lastPassagePoint?.index || !!lastPassagePoint?.passage) {
    point = points[0];
  } else {
    point = points[lastPassagePoint?.index + 1] || points[lastPassagePoint?.index];
  }
  return {
    lastPassagePoint,
    nextPassagePoint,
    point,
    endPoint,
  };
};

export const objectDeepClone = (data: any) =>
  JSON.parse(JSON.stringify(data || {}));
