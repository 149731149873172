import styled from 'styled-components';

export const Button = styled.div<{ active: boolean }>`
  background-color: rgba(255, 255, 255, 0.45);
  backdrop-filter: blur(10px);
  box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;

  padding: 5px;
  border-radius: 4px;
  cursor: pointer;

  width: 34px;
  height: 36.5px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 1024px) {
    display: none;
  }

  > svg {
    width: ${({ active }) => (active ? '24px' : '20px')};
    height: ${({ active }) => (active ? '24px' : '20px')};
  }
`;
