import { verifySurveysAccess, verifyToken } from 'app/services/auth.service';
import { AccountDispatchType } from './account.context.d';
import { permissions } from './account.context';
import { UserType } from 'app/interfaces/account.interfaces';

/**
 * 
 * @param user 
 * @param token 
 * @param dispatch 
 * @returns 
 */
export const verifySurveyAdmin = async (
  user: UserType,
  token: string,
  dispatch: (data: AccountDispatchType) => void
) => {
  if (!user?.managed_customer?.id) return;
  const response = await verifySurveysAccess(
    user.managed_customer?.id,
    token
  );

  if (response.data) {
    dispatch({
      type: 'SET_PERMISSIONS',
      data: [permissions.SURVEY_MANAGER],
    });
  }
};

/**
 * 
 * @param token 
 * @param dispatch 
 */
export const verifyAuth = async (
  token: string,
  dispatch: (data: AccountDispatchType) => void
) => {
  try {
    const user = await verifyToken(token);
    verifySurveyAdmin(user.data.user, token, dispatch);
    dispatch({
      type: 'LOGIN',
      data: { token, user: user.data.user },
    });
  } catch (err) {
    dispatch({ type: 'LOGOUT' });
  } finally {
    dispatch({
      type: 'SET_LOADING',
      data: false,
    });

    dispatch({
      type: 'SET_VERIFIED',
      data: true,
    });
  }
};
