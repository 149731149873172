import { LinkButtonComponent } from './link-button.style';

interface Props {
  handleClick(): void;
  children: string;
  type?: string;
  customStyle: string;
}

/**
 * Button component
 * @returns
 */
const LinkButton = ({ children, type, handleClick, customStyle }: Props) => {
  return (
    <LinkButtonComponent
      customStyle={customStyle}
      type={type}
      onClick={handleClick}
    >
      {children}
    </LinkButtonComponent>
  );
};

LinkButton.defaultProps = {
  customStyle: '',
  type: 'button',
  handleClick: () => '',
};

export default LinkButton;
