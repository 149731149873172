export const speedUnitOptions = [
  {
    id: 'mps',
    label: 'm/s',
  },
  {
    id: 'kmh',
    label: 'km/h',
  },
  {
    id: 'mph',
    label: 'mph',
  },
];

export const distanceUnitOptions = [
  {
    id: 'm',
    label: 'm',
  },
  {
    id: 'km',
    label: 'km',
  },
  {
    id: 'ft',
    label: 'ft',
  },
  {
    id: 'mi',
    label: 'mi',
  },
];

export const weatherUnitOptions = [
  {
    id: 'celsius',
    label: '°C',
  },
  {
    id: 'farenheit',
    label: '°F',
  },
];

export const widgetOptions = [
  {
    id: 'weather',
    label: 'Weather',
  },
  {
    id: 'speed',
    label: 'Speed',
  },
  {
    id: 'elevation',
    label: 'Elevation',
  },
  {
    id: 'inclination',
    label: 'Inclination',
  },
  {
    id: 'arrival',
    label: 'Estimated Time of Arrival',
  },
  {
    id: 'next-point',
    label: 'Estimate to next Tracking Point',
  },
];
