import { verifyToken } from 'app/services/auth.service';
import {
  acceptShareableInvitation,
  getShareableInvitation,
} from 'app/services/invitation.service';
import { RegisterDataType } from 'app/store/store.interfaces';
import { InvitationType } from './shareable-invitation.page.d';
import { AccountProviderValue } from 'app/modules/account/account.context.d';
import { NotificationProviderValue } from 'app/modules/notification/notification.context.d';

export const getInvitation = async (
  token: string,
  setInvitation: (invitation: InvitationType) => void,
  setExpired: (expired: boolean) => void
) => {
  let invitationResponse: { data: InvitationType } | null = null;

  try {
    invitationResponse = await getShareableInvitation(token);
    setInvitation(invitationResponse.data);
  } catch (err: any) {
    setInvitation(err.response.data.data);
    if (err.response.data.error === 'shareable-invitation/expired')
      setExpired(true);

    if (err.response.data.error === 'shareable-invitation/invalid')
      setExpired(true);
  }
};

export const accept = async (
  token: string,
  userId: number | undefined,
  registerData: RegisterDataType | undefined,
  dispatch: AccountProviderValue['dispatch'],
  notificationDispatch: NotificationProviderValue['dispatch'],
  history: any
) => {
  try {
    const acceptResponse = await acceptShareableInvitation(token, {
      ...registerData,
      user_id: userId,
    });
    const verifyResponse = await verifyToken(acceptResponse.data.auth_token);

    window.localStorage.setItem(
      'CDI@auth-token',
      acceptResponse.data.auth_token
    );
    window.localStorage.setItem(
      'CDI@auth-email',
      verifyResponse.data.user.email
    );

    dispatch({
      type: 'LOGIN',
      data: {
        token: acceptResponse.data.auth_token,
        user: verifyResponse.data.user,
      },
    });

    history.push(`/projects`);
  } catch (err: any) {
    if (err.response?.data?.type) {
      return notificationDispatch({
        type: 'SET_TOAST',
        data: {
          type: 'error',
          title: err.response?.data?.title,
          text: err.response?.data?.message,
        },
      });
    }
    notificationDispatch({
      type: 'SET_TOAST',
      data: {
        type: 'error',
        title: 'Error',
        text: 'Unexpected error, try again later.',
      },
    });
  }
};
