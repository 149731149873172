import { formatPipePointsToPath } from '@/lib/formatter';
import { PipelineType } from '../../../../../interfaces/inspection.interfaces';
import { getBoundsBox } from '../../../../../utils/map.utils';


/**
 *
 * @param param0
 */
const renderPipeline = ({
  map,
  pipeline,
}: {
  map: google.maps.Map;
  pipeline: PipelineType;
}) => {
  if (!pipeline) return;

  const points = formatPipePointsToPath({
    project: null,
    name: null,
    length: null,
    id: null,
    start: { coordinates: [], type: '' },
    geometry: { coordinates: pipeline.geometry?.coordinates || [], type: '' },
  });

  new window.google.maps.Polyline({
    path: points,
    geodesic: true,
    strokeColor: '#f1f1f1',
    strokeOpacity: 0.45,
    strokeWeight: 4,
    zIndex: 1,
    map,
  });

  new window.google.maps.Polyline({
    path: points,
    geodesic: true,
    strokeColor: '#6e7881',
    strokeOpacity: 1,
    strokeWeight: 8,
    zIndex: 0,
    map,
  });
};

/**
 *
 */
export const init = ({
  map,
  pipeline,
}: {
  map: google.maps.Map;
  pipeline: PipelineType;
}) => {
  renderPipeline({ map, pipeline });
};

/**
 *
 * @param points
 * @param map
 */
export const centerMap = ({
  pipeline,
  map,
}: {
  pipeline: PipelineType;
  map: google.maps.Map;
}) => {
  const center: any = getBoundsBox(
    pipeline.geometry?.coordinates.map((coords: any) => ({
      lng: coords[0],
      lat: coords[1],
    })) || []
  );

  map.fitBounds(center, 70);
};
