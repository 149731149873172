import styled from 'styled-components';

export const WidgetsContainer = styled.div<{
  selectedTrackingPoint: boolean;
  hide?: boolean;
  displayNone?: boolean;
}>`
  position: absolute;
  bottom: 40px;
  z-index: 1;
  left: ${({ hide }) => (hide ? '-250px' : 0)};
  border-radius: 8px;
  background-color: #ffffff;
  ${({ hide }) =>
    hide ? '' : 'box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;'}

  transition-duration: 100ms;
  will-change: left;

  ${({ hide }) =>
    hide
      ? `
      
    `
      : `
        @media (max-width: 1024px) {
          border-radius: 0;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          bottom: 0;
          z-index: 90;
          position: fixed;
          background-color: rgba(0, 0, 0, 0.5);
        }
        `}
  ${({ displayNone }) => (displayNone ? 'display: none;' : '')}
`;

export const WidgetList = styled.ul<{
  hide?: boolean;
}>`
  position: relative;
  z-index: 2;

  justify-content: start;
  width: 250px;
  gap: 5px;
  background-color: #ffffff;
  border-radius: 8px;
  min-height: 48px;
`;

export const Menu = styled.div<{
  isShowing?: boolean;
}>`
  position: absolute;
  width: 28px;
  right: -28px;
  bottom: 5px;
  background-color: #ffffff;
  height: 40px;
  border-radius: 0px 6px 6px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: rgb(0 0 0 / 30%) 0px 0px 4px -1px;
  z-index: 1;

  > svg {
    width: 8px;
    ${({ isShowing }) => (!isShowing ? '' : 'transform: rotate(180deg);')}
  }

  @media (max-width: 1024px) {
    left: -20px;
    display: none;
  }
`;

export const Widget = styled.li<{
  hide: boolean;
  pointer?: boolean;
  minWidth?: string;
}>`
  display: ${({ hide }) => (!hide ? 'grid' : 'none')};
  align-items: center;
  grid-template-columns: 50px auto;

  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.08);

  padding: 4px 10px;
  ${({ pointer }) => (pointer ? 'cursor: pointer;' : '')}

  > figure {
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    > img {
      max-width: 100%;
      padding: 5px;
    }

    &.large {
      width: 40px;

      > img {
        padding: 0;
      }
    }

    &.xlarge {
      /* width: 70px; */

      > img {
        padding: 0;
      }
    }

    > svg {
      /* width: 100%; */
    }
  }
`;

export const WidgetContent = styled.div`
  display: block;
`;

export const WidgetLabel = styled.label`
  display: inline-block;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  white-space: nowrap;

  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 170px;

  color: rgba(26, 31, 54, 0.65);
`;

export const WidgetValue = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  white-space: nowrap;
  display: block;

  /* color: #ffffff; */
`;

export const Timer = styled.span`
  padding: 0 5px 0 0;
  font-style: normal;
`;

export const TrackingIcon = styled.img`
  width: 40px;
  padding: 5px;
`;

export const CloseDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;

  @media (min-width: 1024px) {
    display: none;
  }
`;

export const IconWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
