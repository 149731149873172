import { InspectionContext } from '../inspection.context.d';
import { PassageType } from '../inspection.interfaces';

export const updatePassage = (state: InspectionContext, passage: PassageType) => {
  const stateCopy = { ...state };
  const passageSet = [...stateCopy.passage_set]
  const passagesDic = {...stateCopy.passages_dic}
  const tpToPassage = {...stateCopy.tp_to_passages_dic}

  if (!state.passages_dic[passage.id]) {
    passageSet.push(passage.id);
  }

  if (passage.tracking_point) {
    tpToPassage[passage.tracking_point] = passage.id
  }
  
  passagesDic[passage.id] = passage;
  stateCopy.passage_set = passageSet;
  stateCopy.passages_dic = passagesDic;
  stateCopy.tp_to_passages_dic = tpToPassage;

  return stateCopy;
}

export const deletePassage = (state: InspectionContext, passage: PassageType) => {
  const stateCopy = { ...state };
  const passageSet = [...stateCopy.passage_set]
  const tpToPassage = {...stateCopy.tp_to_passages_dic}
  const passagesDic = {...stateCopy.passages_dic}

  const pIndex = passageSet.indexOf(passage.id);
  passageSet.splice(pIndex, 1);
  
  const oldPassage = state.passages_dic[passage.id];
  if (oldPassage?.tracking_point) {
    delete tpToPassage[oldPassage.tracking_point]
  }
  delete passagesDic[passage.id];
  
  stateCopy.passage_set = passageSet;
  stateCopy.tp_to_passages_dic = tpToPassage;
  stateCopy.passages_dic = passagesDic;

  return stateCopy;
}