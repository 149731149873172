import { RouterHistory } from '@sentry/react/types/reactrouter';
import { createProject } from '../../services/projects.service';
import { AuthType } from 'app/modules/account/account.context.d';
import { ErrorType } from './createProjectModal.interfaces';

/**
 * 
 * @param name 
 * @param description 
 * @param auth 
 * @param history 
 * @param onClose 
 * @param setCreateLoading 
 * @param setErrors 
 * @returns 
 */
export const saveProject = async (
  name: string,
  description: string,
  auth: AuthType,
  history: RouterHistory,
  onClose: () => void,
  setCreateLoading: (boolean) => void,
  setErrors: (data: ErrorType) => void
) => {
  setCreateLoading(true);


  if (!name) {
    setErrors({ name: 'Name is required' });
    setCreateLoading(false);
    return;
  }

  const project = {
    name,
    description,
    customer_id: auth?.user.managed_customer?.id,
  };

  const createdProject = await createProject(project, auth.token);
  const projectId = createdProject.data;

  onClose()
  history.push(`/projects/${projectId}`);
  setCreateLoading(false);
};
