import { useContext, useEffect, useMemo, useState } from "react";
import Interact from 'interactjs';
import { Carousel } from 'react-responsive-carousel';
import InspectionStore from "app/modules/inspection/inspection.context";
import AccountStore from "app/modules/account/account.context";
import { TrackingPointPlainType } from "app/modules/inspection/inspection.interfaces";
import { AuthType } from "app/modules/account/account.context.d";
import { deleteMedia, updateMediaCaption, updateSiteSketch } from "./tracking-point-gallery.controller";
import MediaCarrouselItem from "app/components/media-carrousel-item/media-carrousel-item";
import {
  TrackingPointGalleryArrowButton,
  TrackingPointGalleryArrowNext,
  TrackingPointGalleryArrowPrev,
  TrackingPointGalleryCloseButton,
  TrackingPointGalleryContainer,
  TrackingPointGalleryContainerHeader,
  TrackingPointGalleryContainerHeaderTitle,
  TrackingPointGalleryEmptyContainer,
  TrackingPointGalleryEmptyText,
  TrackingPointGalleryFixedContainer,
  TrackingPointGalleryMedias,
} from './tracking-point-gallery.style';
import ConfirmationModal from "app/components/confirmation-modal/confirmation-modal";

interface Props {
  tpoint: TrackingPointPlainType;
  selected: number;
  permissionType?: string;
  onSelectedChange: (selected: number) => void; 
  onCloseGallery: () => void; 
}

/**
 * 
 * @param event 
 */
const dragMoveListener = (event)  => {
  const target = event.target
  // keep the dragged position in the data-x/data-y attributes
  const x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx
  const y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy

  // translate the element
  target.style.transform = 'translate(' + x + 'px, ' + y + 'px)'

  // update the posiion attributes
  target.setAttribute('data-x', x)
  target.setAttribute('data-y', y)
};

/**
 * 
 */
const TrackingPointGallery = (props: Props) => {
  const inspectionContext = useContext(InspectionStore);
  const accountContext = useContext(AccountStore);
  const auth = accountContext.state.auth as AuthType;
  const [confirmationModal, setConfirmationModal] = useState<any>();
  const medias = useMemo(() => {
    const dic = inspectionContext.state.medias_dic;
    return props.tpoint.media_set?.map((id) => dic[id]) || [];
  }, [
    props.tpoint.media_set,
    inspectionContext.state.medias_dic
  ]);

  // set inital position
  useEffect(() => {
    // target elements with the "#GT_DRAGGABLE_TRACKING_POINT" class
    Interact('#GT_DRAGGABLE_TRACKING_POINT_GALLERY')
      .draggable({
        inertia: true,
        modifiers: [
          Interact.modifiers.restrictRect({
            restriction: 'parent',
            endOnly: true
          })
        ],
        listeners: {
          move: dragMoveListener
        }
      })
      .styleCursor(false);
  }, []);
  
  return (
    <TrackingPointGalleryFixedContainer onClick={props.onCloseGallery}>
      <TrackingPointGalleryContainer onClick={(e) => e.stopPropagation()}>
        <TrackingPointGalleryContainerHeader>
          <TrackingPointGalleryContainerHeaderTitle>Media Gallery - {props.tpoint.name}</TrackingPointGalleryContainerHeaderTitle>
          <TrackingPointGalleryCloseButton onClick={props.onCloseGallery}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="14.2427"
                y="4.34375"
                width="2"
                height="14"
                rx="1"
                transform="rotate(45 14.2427 4.34375)"
                fill="#1a1f36"
              />
              <rect
                x="15.6567"
                y="14.2422"
                width="2"
                height="14"
                rx="1"
                transform="rotate(135 15.6567 14.2422)"
                fill="#1a1f36"
              />
            </svg>
          </TrackingPointGalleryCloseButton>
        </TrackingPointGalleryContainerHeader>

        <TrackingPointGalleryMedias>
            {medias.length ? (
              <Carousel
                showIndicators={false}
                selectedItem={props.selected}
                showThumbs={false}
                showStatus={false}
                renderArrowPrev={(clickHandler, hasPrev) => (
                  <TrackingPointGalleryArrowPrev hide={!hasPrev}>
                    <TrackingPointGalleryArrowButton
                      onClick={() => {
                        if (props.selected > 0) {
                          props.onSelectedChange(props.selected - 1);
                        }

                        clickHandler();
                      }}
                    >
                      <svg
                        width="17"
                        height="27"
                        viewBox="0 0 17 27"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          opacity="0.65"
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M16.1546 26.3467C17.0257 25.4756 17.0257 24.0632 16.1546 23.1921L6.6915 13.729L16.1553 4.2652C17.0264 3.39409 17.0264 1.98174 16.1553 1.11063C15.2842 0.239515 13.8718 0.239517 13.0007 1.11063L3.53693 10.5744L3.53634 10.5738L0.381763 13.7284L13.0001 26.3467C13.8712 27.2178 15.2835 27.2178 16.1546 26.3467Z"
                          fill="white"
                        />
                      </svg>
                    </TrackingPointGalleryArrowButton>
                  </TrackingPointGalleryArrowPrev>
                )}
                renderArrowNext={(clickHandler, hasNext) => (
                  <TrackingPointGalleryArrowNext hide={!hasNext}>
                    <TrackingPointGalleryArrowButton
                      onClick={() => {
                        if (props.selected < medias.length - 1) {
                          props.onSelectedChange(props.selected + 1);
                        }

                        clickHandler();
                      }}
                    >
                      <svg
                        width="17"
                        height="27"
                        viewBox="0 0 17 27"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          opacity="0.65"
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M16.1546 26.3467C17.0257 25.4756 17.0257 24.0632 16.1546 23.1921L6.6915 13.729L16.1553 4.2652C17.0264 3.39409 17.0264 1.98174 16.1553 1.11063C15.2842 0.239515 13.8718 0.239517 13.0007 1.11063L3.53693 10.5744L3.53634 10.5738L0.381763 13.7284L13.0001 26.3467C13.8712 27.2178 15.2835 27.2178 16.1546 26.3467Z"
                          fill="white"
                        />
                      </svg>
                    </TrackingPointGalleryArrowButton>
                  </TrackingPointGalleryArrowNext>
                )}
              >
                {[...medias]
                  .reverse()
                  .sort((mediaA: any, mediaB: any) =>
                    mediaA.is_sketch > mediaB.is_sketch ? -1 : 1
                  )
                  .map((media: any) => (
                    <MediaCarrouselItem
                      onDelete={() => {
                      setConfirmationModal({
                          hide: false,
                          title: 'Are you sure?',
                          text: 'This media will be deleted permanently.',
                          onConfirm: () => {
                            deleteMedia(media.id, auth.token, setConfirmationModal);
                          },
                          onCancel: () => setConfirmationModal({}),
                        });
                      }}
                      onToggleSiteSketch={() => {
                        updateSiteSketch(!media.is_sketch, media.id, auth.token);
                      }}
                      onEditCaption={(caption: string) => {
                        updateMediaCaption(caption, media.id, auth.token);
                      }}
                      setAllowArrowNavigation={() => ''}
                      caption={media.caption}
                      media={media.media}
                      mediaId={media.id}
                      isSiteSketch={media.is_sketch}
                      permissionType={props.permissionType || 'observer'}
                      key={media.id}
                    />
                  ))}
              </Carousel>
            ) : (
              <TrackingPointGalleryEmptyContainer>
                <TrackingPointGalleryEmptyText>
                  This tracking point doesn’t have any media yet...
                </TrackingPointGalleryEmptyText>
              </TrackingPointGalleryEmptyContainer>
            )}
          </TrackingPointGalleryMedias>

          {confirmationModal ? <ConfirmationModal {...confirmationModal} /> : null}
      </TrackingPointGalleryContainer>
    </TrackingPointGalleryFixedContainer>
  );
};

export default TrackingPointGallery;