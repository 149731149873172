import moment from 'moment-timezone';
import { useEffect, useState, useContext } from 'react';
import {
  fetchSurvey,
  onDeleteSurvey,
  onStepChange,
  saveAction,
} from './survey.controller';
import {
  MapContainer,
  TemplateContainer,
  SurveyPageWrapper,
  MenuButton,
  MenuListWrapper,
  MenuListItem,
} from './survey.style';
import PointForm from 'app/components/survey-point-form/point-form';
import { useHistory, useParams } from 'react-router-dom';
import Breadcrumbs, { Breadcrumb } from 'app/components/breadcrumbs/breadcrumbs';
import ConfirmationModal from 'app/components/confirmation-modal/confirmation-modal';
import Map from 'app/components/common/map/map';
import AccountStore from 'app/modules/account/account.context';
import SettingsStore from 'app/modules/settings/settings.context';
import NotificationStore from 'app/modules/notification/notification.context';
import { AuthType } from 'app/modules/account/account.context.d';
import { TrackingPointPlainType } from 'app/modules/inspection/inspection.interfaces';
import UpdateSurveyModal from '../components/update-survey-modal/updateSurveyModal';
import SurveyStore from '../survey.context';
import SurveyOverlayHeader from '../components/overlay-survey-header/overlay-survey-header';
import SurveyTrackingSheet from 'app/components/survey-sheet/survey-sheet';
import Toast from 'app/components/toast/toast';

/**
 * Map component
 * @returns
 */
const SurveyPage = () => {
  const { id }: { id: string } = useParams();
  const history = useHistory<{ isEditing?: boolean }>();
  const accountContext = useContext(AccountStore);
  const settingsContext = useContext(SettingsStore)
  const notificationContext = useContext(NotificationStore)
  const surveyContext = useContext(SurveyStore)
  const [selectedPoint, setSelectedPoint] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [grid, setGrid] = useState<Slick.Grid<TrackingPointPlainType>>();
  const [confirmationModal, setConfirmationModal] = useState<any>({});
  const [bounds, setBounds] = useState<
    { latitude: number; longitude: number }[]
  >([]);

  const breadcrumbs: Breadcrumb[] = [
    {
      id: '#1',
      text: 'Surveys',
      href: `/surveys`,
      loading: false,
    },
    {
      id: '#2',
      text: surveyContext.state.survey?.name || '',
      href: `/surveys/${id}`,
      loading: !surveyContext.state.survey,
    },
  ];

  // called when DOM is ready
  useEffect(() => {
    // initializing google map appending on div with GTMAP id
    fetchSurvey({
      id: parseInt(id, 10),
      auth: accountContext.state.auth,
      dispatch: surveyContext.dispatch,
    });
    if (history.location?.state?.isEditing) {
      setConfirmationModal({
        hide: false,
        title: '',
        hideCancelButton: true,
        confirmationText: 'OK',
        text: "Changes on this survey won't affect runs already created",
        onConfirm: () => {
          setConfirmationModal({});
        },
        onCancel: () => setConfirmationModal({}),
      });
    }
  }, []);



  return (
    <SurveyPageWrapper>
      <Breadcrumbs withoutAvatar routes={breadcrumbs} />
      <MenuButton id="survey-page-menu" hide={false} onClick={() => setShowMenu(!showMenu)}>
        <svg
          width="16"
          height="4"
          viewBox="0 0 16 4"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="2" cy="2" r="2" fill="#8E949F" />
          <circle cx="8" cy="2" r="2" fill="#8E949F" />
          <circle cx="14" cy="2" r="2" fill="#8E949F" />
        </svg>
        <MenuListWrapper hide={!showMenu}>
          <MenuListItem
            id="survey-page-menu-edit"
            onClick={() => {
              surveyContext.dispatch({
                type: 'OPEN_MODAL',
                data: 'update-survey-modal'
              })
            }}
          >
            <svg
              width="18px"
              height="18px"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7 4L2 9L2 13H6L11 8M7 4L10 1L14 5L11 8M7 4L11 8"
                stroke="#8C929D"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path d="M7 4L11 8" stroke="#8C929D" strokeWidth="2" />
            </svg>
            Edit Survey Info
          </MenuListItem>
          <MenuListItem
            id="survey-page-menu-delete"
            onClick={() => {
              setConfirmationModal({
                hide: false,
                title: 'Are you sure?',
                text: 'This survey will be deleted permanently.',
                onConfirm: () => {
                  setConfirmationModal({});
                  onDeleteSurvey({
                    survey: surveyContext.state.survey,
                    auth: accountContext.state.auth,
                    dispatch: notificationContext.dispatch,
                    onDone: () => history.push('/surveys'),
                  });
                },
                onCancel: () => setConfirmationModal({}),
              });
            }}
          >
            <svg
              width="16px"
              height="16px"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.6667 2.33333H17M15.6667 2.33333V14.3333C15.6667 15.8061 14.4728 17 13 17H5C3.52724 17 2.33333 15.8061 2.33333 14.3333V2.33333M15.6667 2.33333H11.6667M1 2.33333H2.33333M2.33333 2.33333H6.33333M11.6667 2.33333C11.6667 1.59695 11.0697 1 10.3333 1H7.66667C6.93029 1 6.33333 1.59695 6.33333 2.33333M11.6667 2.33333H6.33333M9 11.6667V7.66667"
                stroke="#8C929D"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Delete
          </MenuListItem>
        </MenuListWrapper>
      </MenuButton>
      <SurveyOverlayHeader
        hidden={false}
        opened={true}
        speedUnit={settingsContext.state.speedUnit}
        distanceUnit={settingsContext.state.distanceUnit}
        timezone={settingsContext.state.timezone}
        hideScheduleButton={true}
        hideName={true}
        hideTimezone={true}
        hideWeather={true}
        hideToggleButton={true}
        hideHelp={true}
        setTimezone={(data: any) =>
          settingsContext.dispatch({
            type: 'SET_TIMEZONE_UNIT',
            data: {
              id: data.id,
              label: data.label,
            },
          })
        }
        setCheckedTiles={() => null}
        setSpeedUnit={(data: any) =>
          settingsContext.dispatch({
            type: 'SET_SPEED_UNIT',
            data: {
              id: data.id,
              label: data.label,
            },
          })
        }
        setDistanceUnit={(data: any) =>
          settingsContext.dispatch({
            type: 'SET_DISTANCE_UNIT',
            data: {
              id: data.id,
              label: data.label,
            },
          })
        }
        weatherUnit={settingsContext.state.weatherUnit}
        setWeatherUnit={(data: any) =>
          settingsContext.dispatch({
            type: 'SET_WEATHER_UNIT',
            data: {
              id: data.id,
              label: data.label,
            },
          })
        }
        timezoneList={settingsContext.state.timezoneList}
      />
      <TemplateContainer>
        <MapContainer>
          <Map
            options={{
              streetViewControl: true,
              streetViewControlOptions: { position: 1 },
            }}
            modules={['survey']}
            controls={['zoom', 'map-type', 'measure', 'labels']}
            bounds={bounds}
            onMarkerClick={({ point }) => {
              setSelectedPoint(point);
            }}
            onLoad={() => null}
          />
          {surveyContext.state.selected_point ? (
            <PointForm
              grid={grid}
              reload={() => {
                fetchSurvey({
                  id: parseInt(id, 10),
                  auth: accountContext.state.auth,
                  dispatch: surveyContext.dispatch,
                });
              }}
              point={selectedPoint}
              closeAction={() => setSelectedPoint(null)}
              saveAction={saveAction}
              isLoading={isLoading}
              onStepChange={(data: any, index: number) =>
                onStepChange(
                  data,
                  surveyContext,
                  index,
                  accountContext.state.auth as AuthType,
                  setIsLoading,
                )
              }
            />
          ) : null}
        </MapContainer>
        <SurveyTrackingSheet
          setSelectedPoint={setSelectedPoint}
          setBounds={setBounds}
          grid={grid}
          setGrid={setGrid}
        />
      </TemplateContainer>
      <UpdateSurveyModal />
      <ConfirmationModal {...confirmationModal} />
      <Toast />
    </SurveyPageWrapper>
  );
};

SurveyPage.defaultProps = {
  speedUnit: {
    id: 'mph',
    label: 'mph',
  },
  distanceUnit: {
    id: 'ft',
    label: 'ft',
  },
  timezone: {
    id: moment.tz.guess(),
    label: moment.tz.guess().toLocaleLowerCase(),
  },
};

export default SurveyPage;
