import styled from 'styled-components';

export const Wrapper = styled.div``;

export const HelpActionWrapper = styled.div<{
  displayNone: boolean;
  top: number;
  left: number;

  }>`
  position: fixed;

  width: 310px;

  background: #FFF;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 14px;

  padding: 20px;
  border-radius: 3px;

  align-items: center;

  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

  >svg.cloose-button {
    position: absolute;
    top: 5px;
    right: 5px;
  }

  ${({ displayNone }) => displayNone && 'display: none;'}
  ${({ top }) => top && `top: ${top - 45}px;`}
  ${({ left }) => left && `left: ${left + 20}px;`}
`;

export const HelpActionTitle = styled.h4`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  color: #5850EC;
  background: #F5F5F8;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 11px 49px;
  width: 100%;
`;

export const HelpSubtitle = styled.h5`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  text-align: center;

  color: #656978;
`;

export const HelpDescription = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  text-align: center;

  color: #656978;
`;
