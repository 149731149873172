import { useContext, useEffect, useMemo, useState } from 'react';
import { formatMps, formatTimezoneDate } from '@/lib/formatter';
import SettingsStore from 'app/modules/settings/settings.context';
import InspectionStore from 'app/modules/inspection/inspection.context';
import { getTrackingPointIconSrc } from 'app/utils/map.utils';
import { TrackingPointPlainType } from 'app/modules/inspection/inspection.interfaces';
import {
  Card,
  CardAddButton,
  CardContent,
  CardHeader,
  CardMainData,
  CardMainDataGroup,
  CardMainDataLabel,
  CardMainDataValue,
  CardMoreButton,
  CardPassage,
  CardPassageInput,
  CardSidebar,
  CardTrackingPointName,
  CardTrackingPointTypeIcon,
} from './tracking-point-card.style';
import InputDate from 'app/components/input-date/input-date';
import { serializePassageTime } from '../../devices/mobile/mobile.controller';
import IMask from 'imask';

interface Props {
  trackingpoint: TrackingPointPlainType;
  focused: boolean;
  permissionType: string | undefined;
  onAddClick: () => void;
  onMoreClick: () => void;
  onPassageDelete: () => void;
  onPassageChange: (tstamp: number) => void;
}

/**
 *
 */
const TrackingPointCard = (props: Props) => {
  const settingsContext = useContext(SettingsStore);
  const inspectionContext = useContext(InspectionStore);
  const tpoint = useMemo(() => props.trackingpoint, [props.trackingpoint]);
  const icon = useMemo(() => getTrackingPointIconSrc(props.trackingpoint), [props.trackingpoint.color]);
  const passage = useMemo(() => {
    if (!tpoint.passage) return undefined;
    return inspectionContext.state.passages_dic[tpoint.passage];
  }, [tpoint.passage, inspectionContext.state.passages_dic]);
  const [passageTimeValue, setPassageTimeValue] = useState<string>('');
  const [passageDateValue, setPassageDateValue] = useState<Date>();

  useEffect(() => {
    if (passage?.tstamp) {
      const time = formatTimezoneDate({
        date: passage.tstamp,
        timezone: settingsContext.state.timezone?.id || 'utc',
        format: 'HH:mm:ss.SSS',
      });

      setPassageTimeValue(time);
      setPassageDateValue(new Date(passage.tstamp));
    }

    if (!passage?.tstamp) {
      setPassageTimeValue('');
      setPassageDateValue(undefined);
    }
  }, [settingsContext.state.timezone, passage?.tstamp]);

  const eta = useMemo(() => {
    if (!props.trackingpoint.eta) return '-';
    return formatTimezoneDate({
      date: props.trackingpoint.eta,
      timezone: settingsContext.state.timezone?.id || 'utc',
      format: 'MM/DD HH:mm:ss',
    });
  }, [props.trackingpoint.eta, settingsContext.state.timezone]);
  const speed = useMemo(() => {
    if (!props.trackingpoint.speed) return '-';
    return formatMps({
      distance: props.trackingpoint.speed,
      unit: settingsContext.state.speedUnit,
    });
  }, [props.trackingpoint.speed, settingsContext.state.speedUnit]);

  const mask = new IMask.MaskedPattern({
    mask: 'HH:MM:SS.MMM',
    blocks: {
      HH: { mask: /^[0-9]{1,2}$/ },
      MM: { mask: /^[0-9]{1,2}$/ },
      SS: { mask: /^[0-9]{1,2}$/ },
      MMM: { mask: /^[0-9]{1,3}$/ },
    },
  });

  return (
    <Card id={`tp-list-id-${tpoint.id}`} data-testid={tpoint.name} focused={props.focused}>
      <CardContent>
        <CardHeader>
          <CardTrackingPointTypeIcon src={icon.url} alt="" />
          <CardTrackingPointName>{tpoint.name}</CardTrackingPointName>
        </CardHeader>

        <CardPassage isObserver={props.permissionType !== 'editor'}>
          <CardAddButton disabled={props.permissionType !== 'editor'} onClick={props.onAddClick}>Add</CardAddButton>
          <CardPassageInput isObserver={props.permissionType !== 'editor'} invalid={passage && !passage.valid}>
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 0C2.24288 0 0 2.24301 0 5C0 7.75698 2.24302 10 5 10C7.75698 10 10 7.75698 10 5C10 2.24301 7.75698 0 5 0ZM5 9.3752C2.58757 9.3752 0.625029 7.41261 0.625029 5.00023C0.625029 2.58785 2.58762 0.625262 5 0.625262C7.41238 0.625262 9.37497 2.58785 9.37497 5.00023C9.37497 7.41261 7.41238 9.3752 5 9.3752ZM6.99848 7.37779C7.0956 7.52037 7.05888 7.71486 6.91622 7.81198C6.86238 7.8486 6.80107 7.86618 6.74058 7.86618C6.64064 7.86618 6.54252 7.81844 6.48203 7.72962L4.74172 5.1761C4.70637 5.12426 4.68752 5.06295 4.68752 5.00009V1.65128C4.68752 1.47874 4.82745 1.33882 4.99998 1.33882C5.17252 1.33882 5.31245 1.47875 5.31245 1.65128V4.90377L6.99848 7.37779Z" fill="#5850EC"></path></svg>

            <input
              type="tel"
              placeholder={props.permissionType !== 'editor' ? '---' : ''}
              value={mask.resolve(passageTimeValue)}
              disabled={props.permissionType !== 'editor'}
              onChange={(e) => setPassageTimeValue(e.target.value)}
              onBlur={() => {
                if (settingsContext.state.timezone?.id && passageTimeValue && !passage?.tstamp) {
                  const serializedPassage = serializePassageTime(passageTimeValue, settingsContext.state.timezone.id)
                  props.onPassageChange(serializedPassage);
                }
                if (settingsContext.state.timezone?.id && passageTimeValue && passage?.tstamp) {
                  const serializedPassage = serializePassageTime(passageTimeValue, settingsContext.state.timezone.id, passage.tstamp)

                  const pTstamp = new Date(passage.tstamp);
                  if (serializedPassage !== pTstamp.valueOf()) {
                    props.onPassageChange(serializedPassage);
                  }
                }
                if (settingsContext.state.timezone?.id && !passageTimeValue && passage) {
                  props.onPassageDelete();
                }
              }}
            />
          </CardPassageInput>
          <CardPassageInput isObserver={props.permissionType !== 'editor'} invalid={passage && !passage.valid}>
            <svg width="11" height="11" viewBox="0 0 11 11" fill="#5850EC">
              <path fillRule="evenodd" clipRule="evenodd" d="M9.6666 1.08698H9.10046V0H7.96819V1.08698H3.43909V0H2.30681V1.08698H1.74067C1.11792 1.08698 0.608398 1.57613 0.608398 2.17397V9.78286C0.608398 10.3807 1.11792 10.8698 1.74067 10.8698H9.6666C10.2894 10.8698 10.7989 10.3807 10.7989 9.78286V2.17397C10.7989 1.57613 10.2894 1.08698 9.6666 1.08698ZM9.6666 9.78286H1.74067V3.80444H9.6666V9.78286Z" fill="#5850EC" />
            </svg>

            <InputDate
              placeholder={props.permissionType !== 'editor' ? '---' : ''}
              disabled={props.permissionType !== 'editor'}
              value={passageDateValue?.toISOString()}
              handleChange={(e) => {
                setPassageDateValue(e.value)
                props.onPassageChange(e.value.valueOf())
              }}
              format="MMM dd, yyyy"
              customStyle={`
                border: none;
                padding: 0;

                input {
                  color: ${passage && !passage.valid ? 'rgba(237, 32, 36, 1)' : '#5850ec'} !important;

                  font-family: 'Inter';
                  font-style: normal;
                  font-weight: 700;
                  font-size: 10px !important;
                  line-height: 12px;
                }
              `}
            />
          </CardPassageInput>
        </CardPassage>

        <CardMainData>
          <CardMainDataGroup>
            <CardMainDataLabel>Speed:</CardMainDataLabel>
            <CardMainDataValue>{speed}</CardMainDataValue>
          </CardMainDataGroup>

          <CardMainDataGroup>
            <CardMainDataLabel>ETA:</CardMainDataLabel>
            <CardMainDataValue>{eta}</CardMainDataValue>
          </CardMainDataGroup>
        </CardMainData>
      </CardContent>

      <CardSidebar>
        <CardMoreButton onClick={props.onMoreClick}>
          <svg width="16" height="4" viewBox="0 0 16 4" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="2" cy="2" r="2" fill="#4B5066" />
            <circle cx="8" cy="2" r="2" fill="#4B5066" />
            <circle cx="14" cy="2" r="2" fill="#4B5066" />
          </svg>
        </CardMoreButton>
        <label>more</label>
      </CardSidebar>
    </Card>
  );
};

export default TrackingPointCard;
