import InputMask from 'react-input-mask';
import moment from 'moment-timezone';
import { formatTimezoneDate } from '@/lib/formatter';
import { MouseEventHandler, useState, useContext, useEffect, useMemo } from 'react';
import {
  ScheduledOnWrapper,
  ScheduledOn,
  LaunchButton,
  SchedulerPicker,
  ScheduleOnDateWrapper,
  Wrapper,
  BackButton,
  Row,
  MenuButton,
  LaunchedAt,
  RunningOptions,
  RunningOption,
  TimeInputWrapper,
} from './scheduledOnButton.style';
import { getStatusLabel, initComponent } from './scheduledOnButton.controller';
import Skeleton from 'app/components/skeleton/skeleton';
import InputGroup from 'app/components/input-group/inputGroup';
import InputDate from 'app/components/input-date/input-date';
import Button from 'app/components/button/button';
import Input from 'app/components/input/input';
import InspectionStore from 'app/modules/inspection/inspection.context';
import SettingsStore from 'app/modules/settings/settings.context';
import { InspectionContext } from 'app/modules/inspection/inspection.context.d';
import { SettingsContext } from 'app/modules/settings/settings.context.d';

/**
 *
 * extractNumbers - that function extract all numbers of an string
 *
 * @function extractNumbers
 * @param  {string} text  that function extract all numbers of an string
 * @returns {string} it's the string that have only the filtered numbers
 */
const extractNumbers = (text: string) => {
  let number = '';

  Object.keys(text).forEach((i: any) => {
    const n = text[i];
    if (
      parseInt(n, 10) ||
      parseInt(n, 10) === 0 ||
      n === '+' ||
      n === ':' ||
      n === '-' ||
      n === '.'
    )
      number += n;
  });

  return number;
};

interface Props {
  speedUnit: string;
  hidden?: boolean;
  submit: (data: unknown) => void;
  launchAction: MouseEventHandler;
  abortAction: () => void;
  finishAction: () => void;
  permissionType: string;
}

interface RenderLaunchedState {
  inspectionState: InspectionContext;
  settingsState: SettingsContext;
  showPicker: boolean;
  loading: boolean;
  setShowPicker: (data: boolean) => void;
  abortAction: () => void;
  finishAction: () => void;
  permissionType: string;
}

const LaunchedState = ({
  inspectionState,
  settingsState,
  showPicker,
  setShowPicker,
  abortAction,
  finishAction,
  permissionType,
  loading,
}: RenderLaunchedState) => {
  const launchPoint = useMemo(() => {
    if (!inspectionState.trackingpoint_set) return undefined;
    const tpId = inspectionState.trackingpoint_set[0];
    return inspectionState.trackingpoints_dic[tpId];
  }, [inspectionState.trackingpoint_set]);

  const launchedAt = useMemo(() => {
    const tstamp = inspectionState.run?.launch_time || inspectionState.run?.scheduled_launch_at;
    if (!tstamp) return '';
    return formatTimezoneDate({
      date: tstamp,
      timezone: settingsState.timezone?.id || 'utc',
      format: 'll',
    });
  }, [settingsState.timezone?.id, inspectionState.run, launchPoint]);

  

  const verifyAbortLabel = /FINISHED/gi;
  const hasResetLabel = verifyAbortLabel.test(`${inspectionState.run?.status}`);

  const pickerAvailable =
  inspectionState.estimation?.can_be_aborted || !inspectionState.run?.is_finished;
  const can_be_aborted = inspectionState.estimation
    ? inspectionState.estimation.can_be_aborted
    : true;
  const disabled = !pickerAvailable || permissionType !== 'editor';
  
  return (
    <>
      <ScheduledOnWrapper>
        <LaunchedAt>
          {`LAUNCHED ON  ${launchedAt}`}
        </LaunchedAt>
        <MenuButton
          onClick={() => setShowPicker(pickerAvailable && !showPicker)}
          disabled={disabled}
        >
          <Skeleton loading={loading} width="120px" height="16px" inline>
            {getStatusLabel(inspectionState)}
          </Skeleton>
        </MenuButton>
      </ScheduledOnWrapper>
      <SchedulerPicker visible={showPicker} right={true}>
        <RunningOptions>
          {can_be_aborted ? (
            <RunningOption
              onClick={() => {
                abortAction();
                setShowPicker(!showPicker);
              }}
            >
              {hasResetLabel ? 'Reset Run' : 'Abort Run'}
            </RunningOption>
          ) : null}
          {!hasResetLabel ? (
            <RunningOption
              onClick={() => {
                finishAction();
                setShowPicker(!showPicker);
              }}
            >
              Finish Run
            </RunningOption>
          ) : null}
        </RunningOptions>
      </SchedulerPicker>
    </>
  );
};

interface RenderUnlaunchedState {
  inspectionState: InspectionContext;
  settingsState: SettingsContext;
  showPicker: boolean;
  loading: boolean;
  setShowPicker: (data: boolean) => void;
  launchAction: MouseEventHandler;
  permissionType: string;
  initialDate: Date | null;
  setInitialDate: (data: Date) => void;
  handleFieldChange: (data: string) => void;
  initialTime: string;
  speed: string;
  setSpeed: (data: string) => void;
  submit: (data: unknown) => void;
}
const renderUnlaunchedState = ({
  inspectionState,
  settingsState,
  permissionType,
  loading,
  launchAction,
  showPicker,
  setShowPicker,
  initialDate,
  setInitialDate,
  initialTime,
  handleFieldChange,
  speed,
  setSpeed,
  submit,
}: RenderUnlaunchedState) => {
  const dateText = moment.utc(initialDate).format('YYYY/MM/DD');
  return (
    <>
      <ScheduledOnWrapper>
        <ScheduledOn
          onClick={() => {
            if (permissionType === 'editor') setShowPicker(!showPicker);
          }}
          disabled={permissionType !== 'editor'}
        >
          <Skeleton loading={loading} width="160px" height="16px" inline>
            {`SCHEDULED ON ${formatTimezoneDate({
              date: inspectionState.run?.scheduled_launch_at,
              timezone: settingsState.timezone?.id || '',
              format: 'll',
            })}`}
          </Skeleton>
        </ScheduledOn>
        <LaunchButton
          onClick={launchAction}
          disabled={permissionType !== 'editor'}
        >
          Launch Now
        </LaunchButton>
      </ScheduledOnWrapper>
      <SchedulerPicker visible={showPicker}>
        <BackButton onClick={() => setShowPicker(!showPicker)}>
          <img src="/assets/icons/back-arrow.svg" alt="back" />
          Back
        </BackButton>
        <Row>
          <ScheduleOnDateWrapper>
            <InputGroup label="Date" error="">
              <InputDate
                placeholder="YYYY/MM/DD"
                handleChange={({ value }: { value: Date }) => {
                  const timeGet = moment(value);
                  setInitialDate(timeGet.toDate());
                }}
                value={dateText}
                id="SchedulerEditInitialDate"
              />
            </InputGroup>

            <InputGroup label="Time">
              <TimeInputWrapper>
                <InputMask
                  maskChar=""
                  mask="99:99:99.9999"
                  placeholder="HH:mm:ss"
                  value={initialTime}
                  onChange={(e) => handleFieldChange(e.target.value)}
                />
              </TimeInputWrapper>
            </InputGroup>
          </ScheduleOnDateWrapper>
        </Row>
        <Row>
          <ScheduleOnDateWrapper>
            <InputGroup
              label={`Predicted launch Speed (${settingsState.speedUnit?.label})`}
              error=""
            >
              <Input
                placeholder=""
                value={speed}
                handleChange={(e) => setSpeed(extractNumbers(e.target.value))}
              />
            </InputGroup>
          </ScheduleOnDateWrapper>
        </Row>
        <Button
          value="Apply"
          customStyle={`
            margin-top: 16px;
            margin-bottom: 8px;

            &:focus {
              margin-top: 16px;
              margin-bottom: 8px;
            }
          `}
          handleClick={() =>
            submit({
              initialTime,
              initialDate,
              speed,
              hidePicker: setShowPicker,
            })
          }
        />
      </SchedulerPicker>
    </>
  );
};

/**
 * ScheduledOnButton component
 * @returns
 */
const ScheduledOnButton = ({
  speedUnit,
  submit,
  launchAction,
  abortAction,
  finishAction,
  hidden,
  permissionType,
}: Props) => {
  // const { state } = useContext<ContextInterface>(Store);
  const inspectionContext = useContext(InspectionStore);
  const settingsContext = useContext(SettingsStore);
  const [showPicker, setShowPicker] = useState<boolean>(false);
  const [initialTime, setInitialTime] = useState<string>('');
  const [initialDate, setInitialDate] = useState<Date | null>(null);
  const [speed, setSpeed] = useState<string>('');

  const handleFieldChange = (value: string) => {
    setInitialTime(extractNumbers(value));
  };

  useEffect(() => {
    initComponent({
      run: inspectionContext.state.run,
      settingsContext: settingsContext,
      setSpeed,
      setInitialTime,
      setInitialDate,
    });
  }, [
    inspectionContext.state.run,
    speedUnit,
    settingsContext.state.timezone,
    inspectionContext.state.estimation,
  ]);

  return (
    <Wrapper hidden={hidden}>
      {inspectionContext.state.run?.launched
        ? <LaunchedState
            inspectionState={inspectionContext.state}
            settingsState={settingsContext.state}
            showPicker={showPicker}
            setShowPicker={setShowPicker}
            abortAction={abortAction}
            finishAction={finishAction}
            permissionType={permissionType}
            loading={inspectionContext.state.loading}
         />
        : renderUnlaunchedState({
            inspectionState: inspectionContext.state,
            settingsState: settingsContext.state,
            showPicker,
            setShowPicker,
            initialDate,
            setInitialDate,
            initialTime,
            handleFieldChange,
            speed,
            setSpeed,
            submit,
            launchAction,
            permissionType,
            loading: inspectionContext.state.loading,
          })
        }
    </Wrapper>
  );
};

ScheduledOnButton.defaultProps = {
  value: '',
  customStyle: '',
  placeholder: '',
  handleChange: () => '',
};

export default ScheduledOnButton;
