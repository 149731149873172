import {
  MobileIngormationFigureDriveline,
  MobileIngormationFigureGrayPipeline,
  MobileIngormationFigureGreenPipeline,
  MobileIngormationFigureRedHighlight,
  MobileInformationModalContent,
  MobileInformationModalContentCloseButton,
  MobileInformationModalContentHeader,
  MobileInformationModalContentTitle,
  MobileInformationModalInformations,
  MobileInformationModalInformation,
  MobileInformationModalInformationDescription,
  MobileInformationModalInformationFigure,
  MobileInformationModalInformationLabel,
  MobileInformationModalInformationTitle,
  MobileInformationModalMapPointInformations,
  MobileInformationModalPipelinePointsInformations,
  MobileInformationModalWrapper,
} from './mobile-information-modal.style';

interface Props {
  onClose: () => void;
}

/**
 *
 */
const MobileInformationModal = (props: Props) => (
  <MobileInformationModalWrapper>
    <MobileInformationModalContent>
      <MobileInformationModalContentHeader>
        <MobileInformationModalContentTitle>
          Informations
        </MobileInformationModalContentTitle>

        <MobileInformationModalContentCloseButton onClick={props.onClose}>
          <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="16.2637" y="4.94922" width="2" height="16" transform="rotate(45 16.2637 4.94922)" fill="#99A2AD" />
            <rect x="17.6777" y="16.2637" width="2" height="16" transform="rotate(135 17.6777 16.2637)" fill="#99A2AD" />
          </svg>
        </MobileInformationModalContentCloseButton>
      </MobileInformationModalContentHeader>

      <MobileInformationModalInformations>
        <MobileInformationModalMapPointInformations>
          <MobileInformationModalInformationTitle>Map Points</MobileInformationModalInformationTitle>

          <MobileInformationModalInformation>
            <MobileInformationModalInformationFigure>
              <svg width="20" height="20" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="96" height="96" rx="48" fill="#4E46E9" />
                <path fillRule="evenodd" clipRule="evenodd" d="M48 96C74.5097 96 96 74.5097 96 48C96 21.4903 74.5097 0 48 0C21.4903 0 0 21.4903 0 48C0 74.5097 21.4903 96 48 96ZM48 90C71.196 90 90 71.196 90 48C90 24.804 71.196 6 48 6C24.804 6 6 24.804 6 48C6 71.196 24.804 90 48 90Z" fill="white" />
                <path d="M12.751 59V56.0972L14.1953 55.8848L20.3408 38.3828H25.7217L31.8813 55.8848L33.3257 56.0972V59H25.6226V56.0972L26.9678 55.8423L26.3447 53.605H19.7178L19.123 55.8423L20.4683 56.0972V59H12.751ZM20.7656 50.1216H25.2827L23.272 43.877L23.0454 43.1548H22.9604L22.7197 43.9478L20.7656 50.1216ZM43.2378 59.2974C41.1043 59.2974 39.2965 58.882 37.8145 58.0513C36.3418 57.2205 35.2184 56.0688 34.4443 54.5962C33.6702 53.1141 33.2832 51.4007 33.2832 49.4561V48.5215C33.2832 46.473 33.6655 44.6652 34.4302 43.0981C35.1948 41.5311 36.304 40.3039 37.7578 39.4165C39.2116 38.5291 40.9674 38.0854 43.0254 38.0854C44.432 38.0854 45.8433 38.3026 47.2593 38.7368C48.6753 39.1616 49.9214 39.8366 50.9976 40.7617V45.5903H47.4575L46.707 42.9707C46.3861 42.6403 45.9282 42.3665 45.3335 42.1494C44.7482 41.9323 44.0874 41.8237 43.3511 41.8237C41.6424 41.8237 40.3586 42.4421 39.4995 43.6787C38.6405 44.9059 38.2109 46.5107 38.2109 48.4932V49.4561C38.2109 51.3252 38.6546 52.812 39.542 53.9165C40.4388 55.021 41.7368 55.5732 43.436 55.5732C44.0308 55.5732 44.6208 55.5119 45.2061 55.3892C45.7913 55.2664 46.2492 55.1296 46.5796 54.9785V52.3164L44.2573 52.0332V48.6064H51.564V57.1592C50.45 57.8766 49.1898 58.4147 47.7832 58.7734C46.3861 59.1227 44.8709 59.2974 43.2378 59.2974ZM53.0366 59V56.0972L55.1606 55.644V41.7529L53.0366 41.2998V38.3828H62.7646L67.749 51.9624H67.834L72.6768 38.3828H82.2915V41.2998L80.1533 41.7529V55.644L82.2915 56.0972V59H73.0591V56.0972L75.2822 55.644V52.9536L75.3955 43.2539L75.3105 43.2397L69.4766 59H65.5684L59.6494 43.4238L59.5503 43.438L59.8901 52.2739V55.644L62.2832 56.0972V59H53.0366Z" fill="white" />
              </svg>
            </MobileInformationModalInformationFigure>
            <div>
              <MobileInformationModalInformationLabel>
                Blue points: Default
              </MobileInformationModalInformationLabel>
              <MobileInformationModalInformationDescription>
                The Tool will arive and Hold here, awaiting a time input.
              </MobileInformationModalInformationDescription>
            </div>
          </MobileInformationModalInformation>
          <MobileInformationModalInformation>
            <MobileInformationModalInformationFigure>
              <svg width="20" height="20" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="96" height="96" rx="48" fill="#FEDE00" />
                <path fillRule="evenodd" clipRule="evenodd" d="M48 96C74.5097 96 96 74.5097 96 48C96 21.4903 74.5097 0 48 0C21.4903 0 0 21.4903 0 48C0 74.5097 21.4903 96 48 96ZM48 90C71.196 90 90 71.196 90 48C90 24.804 71.196 6 48 6C24.804 6 6 24.804 6 48C6 71.196 24.804 90 48 90Z" fill="white" />
                <path d="M12.751 59V56.0972L14.1953 55.8848L20.3408 38.3828H25.7217L31.8813 55.8848L33.3257 56.0972V59H25.6226V56.0972L26.9678 55.8423L26.3447 53.605H19.7178L19.123 55.8423L20.4683 56.0972V59H12.751ZM20.7656 50.1216H25.2827L23.272 43.877L23.0454 43.1548H22.9604L22.7197 43.9478L20.7656 50.1216ZM43.2378 59.2974C41.1043 59.2974 39.2965 58.882 37.8145 58.0513C36.3418 57.2205 35.2184 56.0688 34.4443 54.5962C33.6702 53.1141 33.2832 51.4007 33.2832 49.4561V48.5215C33.2832 46.473 33.6655 44.6652 34.4302 43.0981C35.1948 41.5311 36.304 40.3039 37.7578 39.4165C39.2116 38.5291 40.9674 38.0854 43.0254 38.0854C44.432 38.0854 45.8433 38.3026 47.2593 38.7368C48.6753 39.1616 49.9214 39.8366 50.9976 40.7617V45.5903H47.4575L46.707 42.9707C46.3861 42.6403 45.9282 42.3665 45.3335 42.1494C44.7482 41.9323 44.0874 41.8237 43.3511 41.8237C41.6424 41.8237 40.3586 42.4421 39.4995 43.6787C38.6405 44.9059 38.2109 46.5107 38.2109 48.4932V49.4561C38.2109 51.3252 38.6546 52.812 39.542 53.9165C40.4388 55.021 41.7368 55.5732 43.436 55.5732C44.0308 55.5732 44.6208 55.5119 45.2061 55.3892C45.7913 55.2664 46.2492 55.1296 46.5796 54.9785V52.3164L44.2573 52.0332V48.6064H51.564V57.1592C50.45 57.8766 49.1898 58.4147 47.7832 58.7734C46.3861 59.1227 44.8709 59.2974 43.2378 59.2974ZM53.0366 59V56.0972L55.1606 55.644V41.7529L53.0366 41.2998V38.3828H62.7646L67.749 51.9624H67.834L72.6768 38.3828H82.2915V41.2998L80.1533 41.7529V55.644L82.2915 56.0972V59H73.0591V56.0972L75.2822 55.644V52.9536L75.3955 43.2539L75.3105 43.2397L69.4766 59H65.5684L59.6494 43.4238L59.5503 43.438L59.8901 52.2739V55.644L62.2832 56.0972V59H53.0366Z" fill="white" />
              </svg>
            </MobileInformationModalInformationFigure>
            <div>
              <MobileInformationModalInformationLabel>
                Yellow points: Awaiting tool arrival
              </MobileInformationModalInformationLabel>
              <MobileInformationModalInformationDescription>
                The point is presently Holding for a time to input.
              </MobileInformationModalInformationDescription>
            </div>
          </MobileInformationModalInformation>
          <MobileInformationModalInformation>
            <MobileInformationModalInformationFigure>
              <svg width="20" height="20" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="96" height="96" rx="48" fill="#888888" />
                <path fillRule="evenodd" clipRule="evenodd" d="M48 96C74.5097 96 96 74.5097 96 48C96 21.4903 74.5097 0 48 0C21.4903 0 0 21.4903 0 48C0 74.5097 21.4903 96 48 96ZM48 90C71.196 90 90 71.196 90 48C90 24.804 71.196 6 48 6C24.804 6 6 24.804 6 48C6 71.196 24.804 90 48 90Z" fill="white" />
                <path d="M12.751 59V56.0972L14.1953 55.8848L20.3408 38.3828H25.7217L31.8813 55.8848L33.3257 56.0972V59H25.6226V56.0972L26.9678 55.8423L26.3447 53.605H19.7178L19.123 55.8423L20.4683 56.0972V59H12.751ZM20.7656 50.1216H25.2827L23.272 43.877L23.0454 43.1548H22.9604L22.7197 43.9478L20.7656 50.1216ZM43.2378 59.2974C41.1043 59.2974 39.2965 58.882 37.8145 58.0513C36.3418 57.2205 35.2184 56.0688 34.4443 54.5962C33.6702 53.1141 33.2832 51.4007 33.2832 49.4561V48.5215C33.2832 46.473 33.6655 44.6652 34.4302 43.0981C35.1948 41.5311 36.304 40.3039 37.7578 39.4165C39.2116 38.5291 40.9674 38.0854 43.0254 38.0854C44.432 38.0854 45.8433 38.3026 47.2593 38.7368C48.6753 39.1616 49.9214 39.8366 50.9976 40.7617V45.5903H47.4575L46.707 42.9707C46.3861 42.6403 45.9282 42.3665 45.3335 42.1494C44.7482 41.9323 44.0874 41.8237 43.3511 41.8237C41.6424 41.8237 40.3586 42.4421 39.4995 43.6787C38.6405 44.9059 38.2109 46.5107 38.2109 48.4932V49.4561C38.2109 51.3252 38.6546 52.812 39.542 53.9165C40.4388 55.021 41.7368 55.5732 43.436 55.5732C44.0308 55.5732 44.6208 55.5119 45.2061 55.3892C45.7913 55.2664 46.2492 55.1296 46.5796 54.9785V52.3164L44.2573 52.0332V48.6064H51.564V57.1592C50.45 57.8766 49.1898 58.4147 47.7832 58.7734C46.3861 59.1227 44.8709 59.2974 43.2378 59.2974ZM53.0366 59V56.0972L55.1606 55.644V41.7529L53.0366 41.2998V38.3828H62.7646L67.749 51.9624H67.834L72.6768 38.3828H82.2915V41.2998L80.1533 41.7529V55.644L82.2915 56.0972V59H73.0591V56.0972L75.2822 55.644V52.9536L75.3955 43.2539L75.3105 43.2397L69.4766 59H65.5684L59.6494 43.4238L59.5503 43.438L59.8901 52.2739V55.644L62.2832 56.0972V59H53.0366Z" fill="white" />
              </svg>
            </MobileInformationModalInformationFigure>
            <div>
              <MobileInformationModalInformationLabel>
                Grey points: Do not hold Will not Hold
              </MobileInformationModalInformationLabel>
              <MobileInformationModalInformationDescription>
                Will not Hold for a time input (often a preset AGM), or was skipped due to downstream point having been tracked
              </MobileInformationModalInformationDescription>
            </div>
          </MobileInformationModalInformation>
        </MobileInformationModalMapPointInformations>
        <MobileInformationModalPipelinePointsInformations>
          <MobileInformationModalInformationTitle>Pipeline Routes</MobileInformationModalInformationTitle>

          <MobileInformationModalInformation>
            <MobileInformationModalInformationFigure>
              <MobileIngormationFigureGrayPipeline />
            </MobileInformationModalInformationFigure>
            <div>
              <MobileInformationModalInformationLabel>
                Gray: Default
              </MobileInformationModalInformationLabel>
              <MobileInformationModalInformationDescription>
                {`Pipeline's standard route across the map.`}
              </MobileInformationModalInformationDescription>
            </div>
          </MobileInformationModalInformation>
          <MobileInformationModalInformation>
            <MobileInformationModalInformationFigure>
              <MobileIngormationFigureGreenPipeline />
            </MobileInformationModalInformationFigure>
            <div>
              <MobileInformationModalInformationLabel>
                Green: Successfully tracked
              </MobileInformationModalInformationLabel>
              <MobileInformationModalInformationDescription>
                Pipeline behind the tool that has been successfully tracked.
              </MobileInformationModalInformationDescription>
            </div>
          </MobileInformationModalInformation>
          <MobileInformationModalInformation>
            <MobileInformationModalInformationFigure>
              <MobileIngormationFigureRedHighlight />
            </MobileInformationModalInformationFigure>
            <div>
              <MobileInformationModalInformationLabel>
                {`Red highlight: Tool's positional margin of error`}
              </MobileInformationModalInformationLabel>
              <MobileInformationModalInformationDescription>
                {`A region that represents the earliest likely arrival of the tool and the latest likely arrival of the tool (i.e. the tool's positional standard deviation).`}
              </MobileInformationModalInformationDescription>
            </div>
          </MobileInformationModalInformation>
          <MobileInformationModalInformation>
            <MobileInformationModalInformationFigure>
              <MobileIngormationFigureDriveline />
            </MobileInformationModalInformationFigure>
            <div>
              <MobileInformationModalInformationLabel>
                Blue line: Vehicle driveline
              </MobileInformationModalInformationLabel>
              <MobileInformationModalInformationDescription>
                Vehicle route taken to move between tracking points
              </MobileInformationModalInformationDescription>
            </div>
          </MobileInformationModalInformation>
        </MobileInformationModalPipelinePointsInformations>
      </MobileInformationModalInformations>
    </MobileInformationModalContent>
  </MobileInformationModalWrapper>
);

export default MobileInformationModal;
