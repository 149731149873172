import { useContext, useEffect, useRef, useState } from 'react';
import { DesktopRunContainer, MapContainer } from './desktop.style';
import OverlayHeader from '../../components/overlay-run-header/overlay-run-header';
import InspectionStore from 'app/modules/inspection/inspection.context';
import SettingsStore from 'app/modules/settings/settings.context';
import ConfirmationLaunchModal from '../../components/confirmation-launch-modal/confirmation-launch-modal';
import {
  abortRunAction,
  finishRunAction,
  handleDrag,
  launchRun,
  updateLaunchSpeed,
  updateScheduledRun
} from './desktop.controller';
import AccountStore from 'app/modules/account/account.context';
import { AuthType } from 'app/modules/account/account.context.d';
import Map from 'app/components/common/map/map';
import WidgetsOverlay from 'app/components/common/map/modules/run/components/widgets-overlay/widgets-overlay';
import ConfirmationModal from 'app/components/confirmation-modal/confirmation-modal';
import TrackingPointOverlay from '../../components/tracking-point-overlay/tracking-point-overlay';
import ResizableWrapper from 'app/components/resizable-wrapper/resizable-wrapper';
import TrackingSheet from 'app/components/tracking-sheet/tracking-sheet';
import SatelitePassagesModal from '../../components/satelite-passages-modal/satelite-passages-modal';
import TrackingPointGallery from '../../components/tracking-point-gallery/tracking-point-gallery';
import QubeModal from '../../components/qube-modal/qube-modal';
import NotificationStore from 'app/modules/notification/notification.context';
import { Bound } from 'app/components/common/map/map.d';

interface Props {
  observerMode: boolean;
  setObserverMode: (observerMode: boolean) => void;
  permissionType?: string;
  passageAGM?: undefined;
}

/**
 *
 * @returns
 */
const Desktop = ({
  observerMode,
  setObserverMode,
  permissionType,
}: Props) => {
  const container = useRef<any>({});
  const inspectionContext = useContext(InspectionStore);
  const settingsContext = useContext(SettingsStore);
  const notificationContext = useContext(NotificationStore);
  const accountContext = useContext(AccountStore);
  const auth = accountContext.state.auth as AuthType;
  const [checkedTiles, setCheckedTiles] = useState([]);
  const [confirmation, setConfirmation] = useState<any>({});
  const [openGallery, setOpenGallery] = useState<boolean>(false);
  const [selectedGalleryMedia, setSelectedGalleryMedia] = useState<number>(0);
  const [showConfirmationLaunch, setShowConfirmationLaunch] = useState<boolean>(false);
  const [bounds, setBounds] = useState<Bound[]>();
  const [widgets, setWidgets] = useState([
    'all',
    'weather',
    'speed',
    'elevation',
    'inclination',
    'arrival',
    'next-point',
  ]);
  
  useEffect(() => {
    if (observerMode) {
      container.current.style.gridTemplateRows = 'auto 1fr';
    }
    
    if (!observerMode) {
      container.current.style.gridTemplateRows = 'auto 1fr 40vh';    
    }
  }, [observerMode]);

  useEffect(() => {
    if (observerMode) {
      container.current.style.gridTemplateRows = 'auto 1fr';
    }

    if (!observerMode) {
      container.current.style.gridTemplateRows = 'auto 1fr 40vh';
    }
  }, [observerMode]);

  return (
    <DesktopRunContainer isObserver={observerMode}  ref={container}>
      <OverlayHeader
        observerMode={observerMode}
        id={inspectionContext.state.run?.id}
        showEventsButton={true}
        setObserverMode={setObserverMode}
        onToggleOpenEventModal={() => {
          inspectionContext.dispatch({
            type: 'SET_SHOW_TIMEPINS_MODAL',
            data: true,
          });
        }}
        setOpened={() => console.log('setOpened')}
        name={inspectionContext.state.run?.name}
        checkedTiles={checkedTiles}
        widgets={widgets}
        setWidgets={setWidgets}
        setCheckedTiles={setCheckedTiles}
        hideToggleButton={!!inspectionContext.state.selected_point}
        permissionType={permissionType}
        launchRunAction={() => setShowConfirmationLaunch(true)}
        abortRunAction={() => {
          return setConfirmation({
            hide: false,
            title: 'Are you sure?',
            text: 'The Run will be reset and all passage information will be lost.',
            onConfirm: () =>
              abortRunAction(
                `${inspectionContext.state.run?.id}`,
                auth.token,
                setConfirmation,
                notificationContext.dispatch,
              ),
            onCancel: () => setConfirmation({}),
          });
        }}
        finishRunAction={() => {
          return inspectionContext.state.run?.is_finished
            ? () => ''
            : setConfirmation({
                hide: false,
                title: 'Are you sure?',
                text: "The Run isn't finished yet. - Passage data will be saved to this point and Run wi ll be flagged as Complete.",
                onConfirm: () =>
                  finishRunAction(
                    `${inspectionContext.state.run?.id}`,
                    auth.token,
                    setConfirmation,
                    notificationContext.dispatch,
                  ),
                onCancel: () => setConfirmation({}),
              });
        }}
        ScheduleSubmit={(data: any) =>
          updateScheduledRun(
            data.initialDate,
            data.initialTime,
            data.speed,
            inspectionContext.state.run?.id,
            auth.token,
            settingsContext.state.timezone,
            notificationContext.dispatch,
            settingsContext.state.speedUnit,
            data.hidePicker
          )
        }
      />
      <MapContainer>
        {permissionType && inspectionContext.state.run?.id ? (
          <Map
            observerMode={observerMode}
            setObserverMode={setObserverMode}
            options={{
              streetViewControl: true,
              streetViewControlOptions: { position: 1 },
            }}
            modules={['run']}
            controls={[
              'labels',
              'follow',
              'users',
              'measure',
              'zoom',
              'map-type',
            ]}

            bounds={bounds}
            widgets={widgets}
            parentWeatherOverlay={checkedTiles?.[0]}
            hidePigButton={!inspectionContext.state.run?.launched || inspectionContext.state.run?.status.toLowerCase() === 'finished'}
            isFollowingPig={inspectionContext.state.following_pig}
            isFollowingLoggedUser={inspectionContext.state.following_logged_user}
            setIsFollowingPig={(data: boolean) => inspectionContext.dispatch({
              type: 'SET_IS_FOLLOWING_PIG',
              data,
            })}
            setIsFollowingLoggedUser={(data: boolean) => inspectionContext.dispatch({
              type: 'SET_IS_FOLLOWING_LOGGED_USER',
              data,
            })}
            loggedUserPosition={inspectionContext.state.logged_user_position}
          />
        ) : null}

        {observerMode ? <WidgetsOverlay /> : null}
      </MapContainer>

      {!inspectionContext.state.run || observerMode ? null : (
        <ResizableWrapper
          onDragEnd={() => ''}
          onDrag={({ deltaY }) =>
            handleDrag(deltaY, container.current, inspectionContext.state.grid)
          }
          disabled={false}
          grid={inspectionContext.state.grid}
        >
          <TrackingSheet
            id={inspectionContext.state.run?.id}
            timezone={settingsContext.state.timezone?.id}
            distanceUnit={settingsContext.state.distanceUnit?.id}
            speedUnit={settingsContext.state.speedUnit?.id}
            permissionType={permissionType}
            tableContentHeight={`calc(50vh - ${110}px)`}
            diff={0}
            setBounds={setBounds}
            showConfimationModal={setConfirmation}
            setTriggerPoint={(data) => {
              inspectionContext.dispatch({
                type: 'SET_SELECTED_TRIGGER_POINT',
                data
              });
            }}
          />
        </ResizableWrapper>
      )}

      <ConfirmationModal
        {...confirmation}
        speedUnit={settingsContext.state.speedUnit}
        initialLaunchSpeed={inspectionContext.state.run?.predicted_launch_speed}
        updateSpeed={(speed: string, cb: any) =>
          updateLaunchSpeed(
            speed,
            auth,
            inspectionContext.state.run?.id,
            settingsContext.state.speedUnit,
            notificationContext.dispatch,
            cb
          )
        }
      />
      {
        showConfirmationLaunch ? (
          <ConfirmationLaunchModal
          onConfirm={(data) => launchRun(
            `${inspectionContext.state.run?.id}`,
            accountContext.state?.auth?.token || '',
            data.launchDate,
            data.launchSpeed,
            data.isTest,
            setShowConfirmationLaunch,
            notificationContext.dispatch,
          )}
          onCancel={() => setShowConfirmationLaunch(false)}
          show={showConfirmationLaunch}
        />
        ) : null
      }
      {
        inspectionContext.state.selected_point ?
        (
          <TrackingPointOverlay
            permissionType={`${permissionType}`}
            onOpenGallery={(index?: number) => {
              setOpenGallery(true)
              setSelectedGalleryMedia(index || 0);
            }}
          />
        ): null
      }

      {inspectionContext.state.selected_point && openGallery ? (
        <TrackingPointGallery
          permissionType={permissionType || 'observer'}
          tpoint={inspectionContext.state.trackingpoints_dic[inspectionContext.state.selected_point]}
          selected={selectedGalleryMedia}
          onSelectedChange={setSelectedGalleryMedia}
          onCloseGallery={() => setOpenGallery(false)}
        />
      ) : null}

      {inspectionContext.state.selected_trigger_point ? (
        <SatelitePassagesModal />
      ) : null}


      {
        inspectionContext.state.selected_qube ? (
          <QubeModal
            qube={inspectionContext.state.qubes_dic[inspectionContext.state.selected_qube]}
            geolocked={
              !!inspectionContext.state.selected_point &&
              inspectionContext.state.trackingpoints_dic[inspectionContext.state.selected_point].geolocks.length > 0
            }
          />
        ) : null
      }

    </DesktopRunContainer>
  )
};

export default Desktop;
