import InputMask from 'react-input-mask';
import { extractNumbers, formatTimezoneDate } from '@/lib/formatter';
import SettingsStore from 'app/modules/settings/settings.context';
import { useState, useContext, useEffect } from 'react';
import InputGroup from '../../../input-group/inputGroup';
import Input from '../../../input/input';
import {
  Title,
  Form,
  FormWrapper,
  MapWrapper,
  Container,
  Row,
  SelectInput,
  LinkButton,
  Label,
  TimeInputWrapper,
  Explanation,
} from './form.style';
import ButtonComponent from '../../../button/button';
import Checkbox from '../../../checkbox/checkbox';
import { loadSurvey } from './form.controller';
import Map from '../../../common/map/map';
import InputDate from '../../../input-date/input-date';
import MobileDetect from 'mobile-detect';

const FirstStep = ({ onSubmit, surveys, errors, isLoading, auth }: any) => {
  const settingsContext = useContext(SettingsStore);
  const [name, setName] = useState<string>('');
  const [surveyId, setSurveyId] = useState<string>('');
  const [surveyData, setSurveyData] = useState<any>(null);
  const [type, setType] = useState<string>('');
  const [startDate, setStartDate] = useState<string>('');
  const [startTime, setStartTime] = useState<string>('');
  const [storedSurveys, setStoredSurveys] = useState<any>({});
  const [predictedSpeed, setPredictedSpeed] = useState<string>('');
  const [reverse, setReverse] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [sms, setSMS] = useState<boolean>(false);
  const [email, setEmail] = useState<boolean>(false);
  const [types] = useState([
    {
      id: 'smart',
      label: 'Smart',
    },
    {
      id: 'geometry',
      label: 'Geometry',
    },
    {
      id: 'cleaning',
      label: 'Cleaning',
    },
  ]);

  useEffect(() => {
    loadSurvey({
      surveyId: +surveyId, 
      auth,
      setStoredSurveys,
      storedSurveys,
      setSurveyData,
    });
  }, [surveyId]);
  useEffect(() => {
    const md = new MobileDetect(window.navigator.userAgent);
    setIsMobile(!!md.mobile());
  }, []);

  const cleanFields = () => {
    setName('');
    setSurveyId('');
    setType('');
    setStartDate('');
    setStartTime('');
    setPredictedSpeed('');
  };

  return (
    <Container>
      <FormWrapper>
        <Form>
          <Title>Create a new Run</Title>
          <Row>
            <InputGroup
              error={errors?.name}
              label="Run Name"
              customStyle={`
                label {
                  font-size: 13px;
                }
              `}
            >
              <Input
                value={name}
                handleChange={({ target }) => setName(target.value)}
                testid="run-creation-name-field"
              />
            </InputGroup>
            <InputGroup
              error={errors?.survey}
              label="Choose Survey"
              customStyle={`
                label {
                  font-size: 13px;
                }
              `}
            >
              <SelectInput
                value={surveyId}
                onChange={({ target }) => setSurveyId(target.value)}
                name="Survey"
                data-testid="run-creation-survey-field"
              >
                <option value={''}>-----</option>
                {surveys.map((t: any) => (
                  <option id={`survey-option-${t.id}`} key={t.id} value={t.id}>
                    {t.name}
                  </option>
                ))}
              </SelectInput>
            </InputGroup>
          </Row>
          <Row>
            <InputGroup
              error={errors?.startDate}
              label="Run start date"
              customStyle={`
                label {
                  font-size: 13px;
                }
              `}
            >
              <InputDate
                placeholder="YYYY/MM/DD"
                handleChange={({ value }) =>
                  setStartDate(
                    formatTimezoneDate({
                      date: value,
                      timezone: settingsContext.state.timezone?.id || '',
                      format: 'YYYY/MM/DD',
                    })
                  )
                }
                value={startDate}
                id="create-run-start-date"
                testid="create-run-start-date"
              />
            </InputGroup>
            <InputGroup
              error={errors?.startTime}
              label="Run start time"
              customStyle={`
                label {
                  font-size: 13px;
                }
              `}
            >
              <TimeInputWrapper>
                <InputMask
                  maskChar=""
                  id="create-run-start-time"
                  mask="99:99:99.9999"
                  placeholder="HH:mm:ss"
                  value={startTime}
                  onChange={(e) => setStartTime(e.target.value)}
                />
              </TimeInputWrapper>
            </InputGroup>
          </Row>
          <Row>
            <InputGroup
              error={errors?.predictedSpeed}
              label={`Predicted Launch Speed (${settingsContext.state.speedUnit?.id})`}
              customStyle={`
                label {
                  font-size: 13px;
                }
              `}
            >
              <Input
                id="create-run-predict-launch-speed"
                value={predictedSpeed}
                handleChange={({ target }) =>
                  setPredictedSpeed(extractNumbers(target.value))
                }
              />
            </InputGroup>
            <InputGroup
              error={errors?.type}
              label="Run Type"
              customStyle={`
                label {
                  font-size: 13px;
                }
              `}
            >
              <SelectInput
                value={type}
                onChange={({ target }) => setType(target.value)}
                name="Run Type"
              >
                <option value={''}>-----</option>
                {types.map((t: any) => (
                  <option key={t.id} value={t.id}>
                    {t.label}
                  </option>
                ))}
              </SelectInput>
            </InputGroup>
          </Row>
          <div>
            <InputGroup label="">
              {surveyData?.surveypoint_set.length ? (
                <Label>
                  Running from{' '}
                  <strong>
                    {!reverse
                      ? surveyData.surveypoint_set[0].name
                      : surveyData.surveypoint_set[
                        surveyData.surveypoint_set.length - 1
                      ].name}
                  </strong>{' '}
                  to{' '}
                  <strong>
                    {reverse
                      ? surveyData.surveypoint_set[0].name
                      : surveyData.surveypoint_set[
                        surveyData.surveypoint_set.length - 1
                      ].name}
                  </strong>
                </Label>
              ) : null}
              <Checkbox
                checked={reverse}
                onClick={() => setReverse(!reverse)}
                text="Reverse"
              />
            </InputGroup>
          </div>
          <div>
            <InputGroup label="">
              <Checkbox checked={sms} onClick={() => setSMS(!sms)} text="SMS" />
              <Explanation>
                {
                  '(users on this project will be notified when the run launches, passages are registered and when it finishes)'
                }
              </Explanation>
            </InputGroup>
          </div>
          <div>
            <InputGroup label="">
              <Checkbox
                checked={email}
                onClick={() => setEmail(!email)}
                text="Email"
              />
              <Explanation>
                {
                  '(users on this project will be notified when passages are registered)'
                }
              </Explanation>
            </InputGroup>
          </div>
          <InputGroup error={errors.other} />
          <Row customStyle="margin-bottom: 50px;">
            <ButtonComponent
              customStyle="margin-top: 32px;"
              testid="create-run-form-submit"
              handleClick={() =>
                onSubmit(
                  {
                    name,
                    surveyId,
                    type,
                    predictedSpeed,
                    startDate,
                    startTime,
                    reverse,
                    sms,
                    email,
                  },
                  cleanFields
                )
              }
              value={isLoading ? 'loading...' : 'Save'}
              disabled={isLoading}
            />
            <LinkButton onClick={cleanFields}>Clean Fields</LinkButton>
          </Row>
        </Form>
      </FormWrapper>
      <MapWrapper data-testid="create-run-survey-map-wrapper">
        {!isMobile ? (
          <Map
            options={{
              streetViewControl: true,
              streetViewControlOptions: { position: 1 },
            }}
            controls={['zoom', 'map-type']}
            modules={['survey']}
            survey={surveyData}
            testid="create-run-survey-map"
          />
        ) : null}
      </MapWrapper>
    </Container>
  );
};

FirstStep.defaultProps = {};

export default FirstStep;
