import { useState, useContext, useEffect, ChangeEvent } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import moment from 'moment-timezone';
// context
import AccountStore from '../account.context';
// interfaces
import { RegisterData } from '../account.context.d';
import PublicWrapper from '../public-wrapper';
import Input from 'app/components/input/input';
import PhoneInput from 'app/components/new-phone-input/phone-input';
import InputPassword from 'app/components/input-password/inputPassword';
import InputGroup from 'app/components/input-group/inputGroup';
import { Link } from "react-router-dom";
import Button from 'app/components/button/button';
import {
  Container,
  Form,
  ActionGroup,
  Banner,
  GtLogo,
  GtSolutions,
  LogoContainer,
  FormWrapper,
  Title,
  GtNameLogo,
  submitCustomStyle,
  Terms,
  Row,
  LoginLink,
} from './register.page.style';
import {
  acceptInvitation,
  acceptSharableInvitation,
  getInvitation,
  verifyAuth,
} from './register.controller';
import NotificationStore from 'app/modules/notification/notification.context';
import Toast from 'app/components/toast/toast';

interface ErrorType {
  email?: string;
  firstName?: string;
  phoneNumber?: string;
  lastName?: string;
  timezone?: string;
  password?: string;
  confirmationPassword?: string;
  phone?: string;
}

const queryStringToObject = (queryString: string) => {
  const obj: any = {};
  if (queryString) {
    queryString
      .slice(1)
      .split('&')
      .map((item: string) => {
        const [k, v] = item.split('=');
        return v ? (obj[k] = v) : null;
      });
  }
  return obj;
};

/**
 * Register component
 * @returns
 */
const Register = () => {
  // router
  const history = useHistory<RouteComponentProps['history']>();
  const { t, redirect_to } = queryStringToObject(history.location.search);
  // context
  const accountContext = useContext(AccountStore);
  const notificationContext = useContext(NotificationStore);
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setlastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [countryCode, setCountryCode] = useState<string>('US');
  const [countryCallingCode, setCountryCallingCode] = useState<string>('1');
  const [confirmationPassword, setConfirmationPassword] = useState<string>('');
  const [error, setError] = useState<ErrorType>({});
  const [verified, setVerified] = useState<boolean>(false);
  const emailRxp = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/;
  const phoneRxp = /^([0-9])*$/;

  useEffect(() => {
    const token: string | null = window.localStorage.getItem('CDI@auth-token');
    verifyAuth(token, history, setVerified);

    if (redirect_to === 'invitation') getInvitation(t, setEmail, history);
  }, []);

  if (!verified) return null;

  return (
    <PublicWrapper>

      <Container>
        <Banner>
          <LogoContainer>
            <GtLogo src="/assets/icons/gt-logo.svg" />
            <GtNameLogo
              id="Layer_1"
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 91.34 7.92"
            >
              <path
                d="M90.91,163.13V162h3.7a2.28,2.28,0,0,1,0,.45c-.06,2-2,3.23-4.43,3.23-2.9,0-4.78-1.45-4.78-3.49s1.93-3.44,4.73-3.44a8.67,8.67,0,0,1,3.48.63v1.24a7,7,0,0,0-3.3-.71c-1.94,0-3.46.76-3.46,2.28s1.4,2.33,3.32,2.33c1.43,0,2.63-.44,2.91-1.37Z"
                transform="translate(-85.44 -157.74)"
                fill="#231f20"
              />
              <path
                d="M96.07,165.54v-6h1.31v4.89h4.39v1.13Z"
                transform="translate(-85.44 -157.74)"
                fill="#231f20"
              />
              <path
                d="M106.54,165.66c-2.34,0-4.22-1.19-4.22-3.12s1.88-3.12,4.22-3.12,4.22,1.17,4.22,3.12S108.89,165.66,106.54,165.66Zm0-5.09c-1.63,0-2.82.73-2.82,2s1.19,2,2.82,2,2.82-.76,2.82-2S108.17,160.57,106.54,160.57Z"
                transform="translate(-85.44 -157.74)"
                fill="#231f20"
              />
              <path
                fill="#231f20"
                d="M112.34,165.54v-6h3.9c1.09,0,2.43.07,2.43,1.65a1.18,1.18,0,0,1-.79,1.06,1.47,1.47,0,0,1,1.32,1.49c0,1.49-1.33,1.82-2.49,1.82Zm1.31-5V162h2.67c.55,0,1-.18,1-.7s-.43-.7-1-.7Zm0,2.36v1.58h3c.63,0,1.11-.16,1.11-.78s-.48-.8-1.11-.8Z"
                transform="translate(-85.44 -157.74)"
              />
              <path
                fill="#231f20"
                d="M125.78,164.52h-4.09l-.66,1h-1.47l4.18-6.12,4.17,6.12h-1.46Zm-3.38-1.07h2.68l-1.34-2Z"
                transform="translate(-85.44 -157.74)"
              />
              <path
                d="M128.92,165.54v-6h1.31v4.89h4.39v1.13Z"
                transform="translate(-85.44 -157.74)"
                fill="#231f20"
              />
              <path
                d="M136.62,165.54V160h-3v-1.15H141V160h-3v5.54Z"
                transform="translate(-85.44 -157.74)"
                fill="#231f20"
              />
              <path
                d="M147.25,165.54,146,163.66H143.4v1.88h-1.31v-6h4.11a2.11,2.11,0,0,1,2.38,2.06,2,2,0,0,1-1.26,1.88l1.39,2.08Zm-3.85-4.86v1.82H146c1,0,1.16-.49,1.16-.92s-.16-.9-1.16-.9Z"
                transform="translate(-85.44 -157.74)"
                fill="#231f20"
              />
              <path
                d="M155.43,164.52h-4.09l-.66,1h-1.46l4.17-6.12,4.17,6.12H156.1Zm-3.38-1.07h2.68l-1.34-2Z"
                transform="translate(-85.44 -157.74)"
                fill="#231f20"
              />
              <path
                d="M164.88,165.12a6.61,6.61,0,0,1-2.77.54c-2.67,0-4.34-1.12-4.34-3.12s1.67-3.12,4.34-3.12a6.43,6.43,0,0,1,2.77.55v1.24a5.45,5.45,0,0,0-2.74-.65c-1.86,0-3,.74-3,2s1.09,2,3,2a6.07,6.07,0,0,0,2.71-.56Z"
                transform="translate(-85.44 -157.74)"
                fill="#231f20"
              />
              <path
                d="M170.43,162l3,3.56h-1.71l-2.29-2.71-1.58,1.34v1.37h-1.31v-6h1.31v3.14l3.77-3.14h1.71Z"
                transform="translate(-85.44 -157.74)"
                fill="#231f20"
              />
              <path
                d="M174.6,158.08a1.43,1.43,0,0,0-.44-.07c-.19,0-.38,0-.38.18,0,.31.92-.07.92.62,0,.41-.32.47-.62.47a2.38,2.38,0,0,1-.55-.06V159a2.07,2.07,0,0,0,.5.06c.23,0,.43,0,.43-.19,0-.34-.93.05-.93-.62,0-.4.34-.46.58-.46a1.9,1.9,0,0,1,.49.07Z"
                transform="translate(-85.44 -157.74)"
                fill="#231f20"
              />
              <path
                d="M176.53,159.25l-.24-.87-.48.9-.48-.9-.25.87h-.24l.43-1.51.54,1.06.54-1.06.43,1.51Z"
                transform="translate(-85.44 -157.74)"
                fill="#231f20"
              />
            </GtNameLogo>
          </LogoContainer>
          <GtSolutions
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 159.95 50.13"
          >
            <path
              fill="#231f20"
              d="M91.55,189.69H87.09L86,191.34H84.86l4.46-6.81,4.48,6.81H92.62Zm-3.89-.89H91l-1.66-2.58Z"
              transform="translate(-84.86 -184.53)"
            />
            <path
              fill="#231f20"
              d="M105.58,190.82a7.8,7.8,0,0,1-3.33.64c-2.87,0-4.84-1.33-4.84-3.45s2-3.48,4.82-3.48a7.77,7.77,0,0,1,3.35.66v.9a7.36,7.36,0,0,0-3.3-.69c-2.32,0-3.75,1-3.75,2.6s1.4,2.6,3.78,2.6a7.55,7.55,0,0,0,3.27-.68Z"
              transform="translate(-84.86 -184.53)"
            />
            <path
              fill="#231f20"
              d="M107.24,191.34v-6h1v5.17h4.64v.85Z"
              transform="translate(-84.86 -184.53)"
            />
            <path
              fill="#231f20"
              d="M117.61,191.46c-2.4,0-4.25-1.18-4.25-3.11s1.85-3.12,4.25-3.12,4.24,1.19,4.24,3.12S120,191.46,117.61,191.46Zm0-5.37c-1.84,0-3.18.85-3.18,2.26s1.34,2.26,3.18,2.26,3.17-.87,3.17-2.26S119.43,186.09,117.61,186.09Z"
              transform="translate(-84.86 -184.53)"
            />
            <path
              fill="#231f20"
              d="M129.2,185.32h1v3.11c0,2.23-1.63,3-3.43,3s-3.42-.8-3.42-3v-3.11h1v3.11c0,1.66,1.08,2.23,2.41,2.23s2.44-.57,2.44-2.23Z"
              transform="translate(-84.86 -184.53)"
            />
            <path
              fill="#231f20"
              d="M132.21,191.34v-6h3.55c2.14,0,3.91,1.1,3.91,3s-1.77,3-3.91,3Zm3.46-.85c1.65,0,2.92-.72,2.92-2.16s-1.27-2.14-2.92-2.14h-2.46v4.3Z"
              transform="translate(-84.86 -184.53)"
            />
            <path
              fill="#231f20"
              d="M141.13,189.13v-.87H144v.87Z"
              transform="translate(-84.86 -184.53)"
            />
            <path
              fill="#231f20"
              d="M146,191.34v-6.69h4.43c1.35,0,2.46.3,2.46,1.78a1.27,1.27,0,0,1-.93,1.27,1.66,1.66,0,0,1,1.55,1.7c0,1.44-1,1.94-2.7,1.94Zm1-5.81v1.89h3.39c.7,0,1.34-.17,1.34-1s-.67-.92-1.35-.92Zm0,2.77v2.18h3.76c.87,0,1.6-.19,1.6-1.09s-.69-1.09-1.58-1.09Z"
              transform="translate(-84.86 -184.53)"
            />
            <path
              fill="#231f20"
              d="M160.27,190H156l-.85,1.3H154l4.12-6.11,4.12,6.11h-1.1Zm-3.78-.82h3.24l-1.62-2.46Z"
              transform="translate(-84.86 -184.53)"
            />
            <path
              fill="#231f20"
              d="M168.72,186.38a8.21,8.21,0,0,0-2.57-.32c-1,0-2.26.09-2.26.86,0,1.52,5.3-.09,5.3,2.67,0,1.59-1.75,1.87-3.34,1.87a15.49,15.49,0,0,1-3.06-.29l0-.82a14.45,14.45,0,0,0,2.91.3c1.23,0,2.5-.18,2.5-1,0-1.67-5.31,0-5.31-2.66,0-1.55,1.82-1.74,3.09-1.74a10.17,10.17,0,0,1,2.77.33Z"
              transform="translate(-84.86 -184.53)"
            />
            <path
              fill="#231f20"
              d="M170.77,191.34v-6h6v.8h-5.05v1.78H176v.75h-4.19v1.9h5.05v.79Z"
              transform="translate(-84.86 -184.53)"
            />
            <path
              fill="#231f20"
              d="M178.47,191.34v-6H182c2.14,0,3.91,1.1,3.91,3s-1.77,3-3.91,3Zm3.46-.85c1.65,0,2.92-.72,2.92-2.16s-1.27-2.14-2.92-2.14h-2.46v4.3Z"
              transform="translate(-84.86 -184.53)"
            />
            <path
              fill="#231f20"
              d="M87.08,203.18v2.57H86v-6.7h4.39a2.09,2.09,0,1,1,0,4.13Zm0-3.25v2.37H90.2c1,0,1.53-.47,1.53-1.2s-.51-1.17-1.53-1.17Z"
              transform="translate(-84.86 -184.53)"
            />
            <path
              fill="#231f20"
              d="M94.32,205.75v-6h1v6Z"
              transform="translate(-84.86 -184.53)"
            />
            <path
              fill="#231f20"
              d="M98.44,203.57v2.18h-1v-6h4.08c1.54,0,2.29.92,2.29,1.92s-.75,1.93-2.29,1.93Zm0-3v2.11h2.89c1.19,0,1.42-.58,1.42-1.07s-.23-1-1.42-1Z"
              transform="translate(-84.86 -184.53)"
            />
            <path
              fill="#231f20"
              d="M105.27,205.75v-6h6v.8h-5v1.78h4.18v.75h-4.18V205h5v.8Z"
              transform="translate(-84.86 -184.53)"
            />
            <path
              fill="#231f20"
              d="M113,205.75v-6h1v5.17h4.64v.86Z"
              transform="translate(-84.86 -184.53)"
            />
            <path
              fill="#231f20"
              d="M120,205.75v-6h1v6Z"
              transform="translate(-84.86 -184.53)"
            />
            <path
              fill="#231f20"
              d="M124.08,201.46v4.29h-1v-6.12l6,4.4v-4.31h1v6.15Z"
              transform="translate(-84.86 -184.53)"
            />
            <path
              fill="#231f20"
              d="M132.26,205.75v-6h6v.8h-5.05v1.78h4.19v.75h-4.19V205h5.05v.8Z"
              transform="translate(-84.86 -184.53)"
            />
            <path
              fill="#231f20"
              d="M144.61,203.18v2.57h-1.06v-6.7h4.39a2.09,2.09,0,1,1,0,4.13Zm0-3.25v2.37h3.12c1,0,1.53-.47,1.53-1.2s-.52-1.17-1.53-1.17Z"
              transform="translate(-84.86 -184.53)"
            />
            <path
              fill="#231f20"
              d="M151.84,205.75v-6h1v6Z"
              transform="translate(-84.86 -184.53)"
            />
            <path
              fill="#231f20"
              d="M159.46,203.41v-.75h3.23a2.81,2.81,0,0,1,0,.3c0,1.81-1.8,2.91-4.09,2.91s-4.15-1.11-4.15-3.16c0-2.24,2.24-3.08,4.2-3.08a6.77,6.77,0,0,1,3.14.73v.9a6.3,6.3,0,0,0-3.11-.8c-1.34,0-3.15.48-3.15,2.23,0,1.51,1.35,2.32,3.12,2.32,1.21,0,2.65-.4,2.94-1.6Z"
              transform="translate(-84.86 -184.53)"
            />
            <path
              fill="#231f20"
              d="M169.63,205.75v-5.82h-3.21v-.88h7.45v.88h-3.2v5.82Z"
              transform="translate(-84.86 -184.53)"
            />
            <path
              fill="#231f20"
              d="M180.14,205.75l-1.43-2.08h-2.88v2.08h-1v-6h4a2,2,0,0,1,2.29,2,1.85,1.85,0,0,1-1.39,1.86l1.51,2.2Zm-4.31-5.15v2.19h2.89c1.13,0,1.36-.55,1.36-1.1s-.23-1.09-1.36-1.09Z"
              transform="translate(-84.86 -184.53)"
            />
            <path
              fill="#231f20"
              d="M188,204.44H183.7l-.85,1.31h-1.1l4.11-6.12,4.13,6.12h-1.11Zm-3.78-.82h3.24l-1.62-2.46Z"
              transform="translate(-84.86 -184.53)"
            />
            <path
              fill="#231f20"
              d="M197.45,205.26a6.65,6.65,0,0,1-2.91.61c-2.59,0-4.31-1.13-4.31-3.12s1.71-3.12,4.31-3.12a6.65,6.65,0,0,1,2.91.62v.93a6,6,0,0,0-2.9-.7c-2,0-3.24.84-3.24,2.27s1.27,2.26,3.28,2.26a6.34,6.34,0,0,0,2.86-.62Z"
              transform="translate(-84.86 -184.53)"
            />
            <path
              fill="#231f20"
              d="M202.66,202.16l3.13,3.59h-1.31l-2.61-3-1.76,1.42v1.54h-1v-6h1v3.35l4.24-3.35h1.35Z"
              transform="translate(-84.86 -184.53)"
            />
            <path
              fill="#231f20"
              d="M207,205.75v-6h1v6Z"
              transform="translate(-84.86 -184.53)"
            />
            <path
              fill="#231f20"
              d="M211.08,201.46v4.29h-1v-6.12l6,4.4v-4.31h1v6.15Z"
              transform="translate(-84.86 -184.53)"
            />
            <path
              fill="#231f20"
              d="M223.74,203.41v-.75H227a2.81,2.81,0,0,1,0,.3c0,1.81-1.8,2.91-4.09,2.91s-4.15-1.11-4.15-3.16c0-2.24,2.25-3.08,4.2-3.08a6.74,6.74,0,0,1,3.14.73v.9a6.29,6.29,0,0,0-3.1-.8c-1.35,0-3.16.48-3.16,2.23,0,1.51,1.36,2.32,3.12,2.32,1.21,0,2.65-.4,2.94-1.6Z"
              transform="translate(-84.86 -184.53)"
            />
            <path
              fill="#231f20"
              d="M227.6,207.17l.6-2.17h1l-.89,2.17Z"
              transform="translate(-84.86 -184.53)"
            />
            <path
              fill="#231f20"
              d="M91.85,220.14l-1.78-2.48h-3v2.48H86v-6.69h4.45A2.2,2.2,0,0,1,93,215.56a2,2,0,0,1-1.77,2l1.86,2.6Zm-4.77-5.81v2.47h3.18c1,0,1.62-.42,1.62-1.24s-.58-1.23-1.62-1.23Z"
              transform="translate(-84.86 -184.53)"
            />
            <path
              fill="#231f20"
              d="M94.7,220.14v-6h6v.8h-5v1.78h4.18v.75H95.7v1.9h5v.79Z"
              transform="translate(-84.86 -184.53)"
            />
            <path
              fill="#231f20"
              d="M103.4,218v2.17h-1v-6h4.08c1.55,0,2.29.92,2.29,1.92S108,218,106.48,218Zm0-3v2.11h2.89c1.19,0,1.42-.57,1.42-1.07s-.23-1-1.42-1Z"
              transform="translate(-84.86 -184.53)"
            />
            <path
              fill="#231f20"
              d="M114,220.26c-2.4,0-4.25-1.18-4.25-3.11S111.56,214,114,214s4.24,1.19,4.24,3.12S116.37,220.26,114,220.26Zm0-5.37c-1.84,0-3.18.85-3.18,2.26s1.34,2.26,3.18,2.26,3.18-.87,3.18-2.26S115.78,214.89,114,214.89Z"
              transform="translate(-84.86 -184.53)"
            />
            <path
              fill="#231f20"
              d="M125.13,220.14l-1.43-2.07h-2.88v2.07h-1v-6h4a2,2,0,0,1,2.29,2,1.86,1.86,0,0,1-1.39,1.86l1.51,2.19ZM120.82,215v2.19h2.89c1.13,0,1.36-.55,1.36-1.1s-.23-1.09-1.36-1.09Z"
              transform="translate(-84.86 -184.53)"
            />
            <path
              fill="#231f20"
              d="M129.8,220.14V215h-2.9v-.87h6.8V215h-2.89v5.15Z"
              transform="translate(-84.86 -184.53)"
            />
            <path
              fill="#231f20"
              d="M135,220.14v-6h1v6Z"
              transform="translate(-84.86 -184.53)"
            />
            <path
              fill="#231f20"
              d="M139.12,215.86v4.28h-1V214l6,4.4v-4.31h1v6.14Z"
              transform="translate(-84.86 -184.53)"
            />
            <path
              fill="#231f20"
              d="M151.79,217.81v-.75H155a2.81,2.81,0,0,1,0,.3c0,1.81-1.8,2.9-4.09,2.9s-4.15-1.1-4.15-3.15c0-2.24,2.24-3.08,4.2-3.08a6.74,6.74,0,0,1,3.14.73v.9a6.29,6.29,0,0,0-3.1-.8c-1.35,0-3.16.47-3.16,2.23,0,1.51,1.36,2.32,3.12,2.32,1.21,0,2.65-.4,2.94-1.6Z"
              transform="translate(-84.86 -184.53)"
            />
            <path
              fill="#231f20"
              d="M168.51,219.44a5,5,0,0,1-3,.82c-2,0-3.35-.77-3.35-2a2.29,2.29,0,0,1,1.12-1.81,1.72,1.72,0,0,1-.56-1.24c0-1.28,1.29-1.85,3-1.85a7,7,0,0,1,2.09.3v.87a6.38,6.38,0,0,0-2.07-.32c-1.14,0-2,.3-2,1s1,1.41,4.5,3.15a1.86,1.86,0,0,0,.41-1.12,3.25,3.25,0,0,0-.06-.49h.92a3.44,3.44,0,0,1,.07.69,2.23,2.23,0,0,1-.53,1.44l.66.33v.89ZM163.92,217c-.53.42-.7.75-.7,1.06,0,.71.85,1.35,2.41,1.35a3.76,3.76,0,0,0,2-.49A28.15,28.15,0,0,1,163.92,217Z"
              transform="translate(-84.86 -184.53)"
            />
            <path
              fill="#231f20"
              d="M176,217.58v2.56h-1v-6.69h4.38a2.09,2.09,0,1,1,0,4.13Zm0-3.25v2.37h3.12c1,0,1.53-.46,1.53-1.2s-.51-1.17-1.53-1.17Z"
              transform="translate(-84.86 -184.53)"
            />
            <path
              fill="#231f20"
              d="M188.55,220.14l-1.43-2.07h-2.88v2.07h-1v-6h4a2,2,0,0,1,2.29,2,1.86,1.86,0,0,1-1.4,1.86l1.52,2.19ZM184.24,215v2.19h2.89c1.13,0,1.36-.55,1.36-1.1s-.23-1.09-1.36-1.09Z"
              transform="translate(-84.86 -184.53)"
            />
            <path
              fill="#231f20"
              d="M195,220.26c-2.4,0-4.25-1.18-4.25-3.11S192.62,214,195,214s4.24,1.19,4.24,3.12S197.43,220.26,195,220.26Zm0-5.37c-1.84,0-3.18.85-3.18,2.26s1.34,2.26,3.18,2.26,3.18-.87,3.18-2.26S196.84,214.89,195,214.89Z"
              transform="translate(-84.86 -184.53)"
            />
            <path
              fill="#231f20"
              d="M199.21,221.55a5.16,5.16,0,0,0,.84.08c.55,0,.84-.23.84-1v-6.47h1v6.47c0,1.32-.4,1.83-1.62,1.83a5.18,5.18,0,0,1-1.06-.12Z"
              transform="translate(-84.86 -184.53)"
            />
            <path
              fill="#231f20"
              d="M204,220.14v-6h6v.8H205v1.78h4.19v.75H205v1.9h5.05v.79Z"
              transform="translate(-84.86 -184.53)"
            />
            <path
              fill="#231f20"
              d="M218.23,219.67a6.72,6.72,0,0,1-2.91.59c-2.59,0-4.3-1.12-4.3-3.11s1.7-3.12,4.3-3.12a6.65,6.65,0,0,1,2.91.62v.93a6,6,0,0,0-2.9-.7c-2,0-3.24.84-3.24,2.27s1.28,2.26,3.28,2.26a6.34,6.34,0,0,0,2.86-.62Z"
              transform="translate(-84.86 -184.53)"
            />
            <path
              fill="#231f20"
              d="M222,220.14V215H219v-.87h6.8V215H223v5.15Z"
              transform="translate(-84.86 -184.53)"
            />
            <path
              fill="#231f20"
              d="M96.37,234.54l-1.78-4.79-3.29,4.92L88,229.75l-1.79,4.79H85.12l2.67-6.81L91.3,233l3.53-5.26,2.66,6.81Z"
              transform="tself.accepted_on = timezone.now()
              self.valid = Falseranslate(-84.86 -184.53)"
            />
            <path
              fill="#231f20"
              d="M104,233.24H99.69l-.85,1.3h-1.1l4.11-6.11,4.13,6.11h-1.11Zm-3.78-.82h3.24L101.85,230Z"
              transform="translate(-84.86 -184.53)"
            />
            <path
              fill="#231f20"
              d="M108,230.26v4.28h-1v-6.11l6,4.4v-4.31h1v6.15Z"
              transform="translate(-84.86 -184.53)"
            />
            <path
              fill="#231f20"
              d="M121.43,233.24h-4.31l-.86,1.3h-1.1l4.11-6.11,4.13,6.11h-1.1Zm-3.77-.82h3.23L119.27,230Z"
              transform="translate(-84.86 -184.53)"
            />
            <path
              fill="#231f20"
              d="M128.65,232.21v-.75h3.22a2.81,2.81,0,0,1,0,.3c0,1.81-1.8,2.91-4.09,2.91s-4.15-1.11-4.15-3.16c0-2.24,2.25-3.08,4.2-3.08a6.71,6.71,0,0,1,3.14.73v.9a6.26,6.26,0,0,0-3.1-.8c-1.35,0-3.16.48-3.16,2.23,0,1.51,1.36,2.32,3.12,2.32,1.21,0,2.65-.4,2.94-1.6Z"
              transform="translate(-84.86 -184.53)"
            />
            <path
              fill="#231f20"
              d="M133.34,234.54v-6h6v.8h-5v1.78h4.18v.75h-4.18v1.9h5v.79Z"
              transform="translate(-84.86 -184.53)"
            />
            <path
              fill="#231f20"
              d="M150.2,234.54l-1.56-4.11-2.92,4.24-2.91-4.24-1.55,4.11h-1l2.34-6.11,3.15,4.71,3.17-4.71,2.34,6.11Z"
              transform="translate(-84.86 -184.53)"
            />
            <path
              fill="#231f20"
              d="M152.55,234.54v-6h6v.8h-5v1.78h4.18v.75h-4.18v1.9h5v.79Z"
              transform="translate(-84.86 -184.53)"
            />
            <path
              fill="#231f20"
              d="M161.25,230.26v4.28h-1v-6.11l6,4.4v-4.31h1v6.15Z"
              transform="translate(-84.86 -184.53)"
            />
            <path
              fill="#231f20"
              d="M171.49,234.54v-5.15h-2.91v-.87h6.8v.87h-2.89v5.15Z"
              transform="translate(-84.86 -184.53)"
            />
            <path
              fill="#231f20"
              d="M181,232v2.56h-1.06v-6.69h4.39a2.09,2.09,0,1,1,0,4.13Zm0-3.25v2.37h3.12c1,0,1.53-.46,1.53-1.2s-.51-1.17-1.53-1.17Z"
              transform="translate(-84.86 -184.53)"
            />
            <path
              fill="#231f20"
              d="M188.22,234.54v-6h1v5.17h4.64v.85Z"
              transform="translate(-84.86 -184.53)"
            />
            <path
              fill="#231f20"
              d="M200.39,233.24h-4.31l-.86,1.3h-1.1l4.11-6.11,4.13,6.11h-1.1Zm-3.77-.82h3.23L198.23,230Z"
              transform="translate(-84.86 -184.53)"
            />
            <path
              fill="#231f20"
              d="M204.53,234.54v-5.15h-2.9v-.87h6.8v.87h-2.89v5.15Z"
              transform="translate(-84.86 -184.53)"
            />
            <path
              fill="#231f20"
              d="M210.73,232.06v2.48h-1v-6h6v.87h-5v1.87h4.11v.8Z"
              transform="translate(-84.86 -184.53)"
            />
            <path
              fill="#231f20"
              d="M220.72,234.67c-2.4,0-4.24-1.19-4.24-3.12s1.84-3.12,4.24-3.12,4.25,1.19,4.25,3.12S223.13,234.67,220.72,234.67Zm0-5.38c-1.83,0-3.18.85-3.18,2.26s1.35,2.26,3.18,2.26,3.18-.87,3.18-2.26S222.54,229.29,220.72,229.29Z"
              transform="translate(-84.86 -184.53)"
            />
            <path
              fill="#231f20"
              d="M231.89,234.54l-1.42-2.07h-2.88v2.07h-1v-6h4a2,2,0,0,1,2.29,2,1.86,1.86,0,0,1-1.39,1.86l1.51,2.19Zm-4.3-5.14v2.19h2.89c1.12,0,1.35-.55,1.35-1.1s-.23-1.09-1.35-1.09Z"
              transform="translate(-84.86 -184.53)"
            />
            <path
              fill="#231f20"
              d="M243.78,234.54l-1.56-4.11-2.91,4.24-2.92-4.24-1.54,4.11h-1l2.33-6.11,3.16,4.71,3.17-4.71,2.33,6.11Z"
              transform="translate(-84.86 -184.53)"
            />
          </GtSolutions>
        </Banner>
        <FormWrapper>
          <Form onSubmit={(e) => e.preventDefault()} autoComplete="off">
            <Title>Join CDI Software Workspace</Title>
            <InputGroup label="E-mail" error={error.email}>
              <Input
                disabled={redirect_to === 'invitation'}
                value={email}
                customStyle="display: block;"
                placeholder="name@example.com"
                handleChange={({ target }: any) => setEmail(target.value)}
                testid="register-email-field"
              />
            </InputGroup>
            <Row>
              <InputGroup label="First Name" error={error.firstName}>
                <Input
                  value={firstName}
                  customStyle="display: block;"
                  placeholder=""
                  name="firstName"
                  handleChange={({ target }: any) => setFirstName(target.value)}
                  testid="register-firstname-field"
                />
              </InputGroup>
              <InputGroup label="Last Name" error={error.lastName}>
                <Input
                  value={lastName}
                  customStyle="display: block;"
                  placeholder=""
                  name="lastName"
                  handleChange={({ target }: any) => setlastName(target.value)}
                  testid="register-lastname-field"
                />
              </InputGroup>
            </Row>
            <InputGroup
              label="Phone Number (Optional)"
              error={error.phoneNumber}
              description="example: +15556450274"
            >
              <PhoneInput
                phone={phoneNumber}
                testid="register-phone-field"
                country={countryCode}
                onCountryChange={(code, country) => {
                  setCountryCode(country);
                  setCountryCallingCode(code);
                }}
                onPhoneChange={(e: ChangeEvent<HTMLInputElement>) => {
                  if (phoneRxp.test(e.target.value)) {
                    setPhoneNumber(e.target.value);

                    const newErrors = { ...error };
                    delete newErrors.phone;
                    setError(newErrors);
                  }
                }}
              />
            </InputGroup>
            <InputGroup label="Password" error={error.password}>
              <InputPassword
                value={password}
                customStyle="display: block;"
                placeholder=""
                handleChange={({ target }: any) => {
                  if (target.value?.length < 6) {
                    setError({
                      ...error,
                      password: 'Password must be at least 6 characters long',
                    });
                  }

                  if (target.value?.length >= 6) {
                    setError({
                      ...error,
                      password: '',
                    });
                  }

                  if (target.value === confirmationPassword) {
                    setError({
                      ...error,
                      confirmationPassword: '',
                    });
                  }

                  setPassword(target.value);
                }}
                testid="register-password-field"
              />
            </InputGroup>
            <InputGroup
              label="Confirm Password"
              error={error.confirmationPassword}
            >
              <InputPassword
                value={confirmationPassword}
                customStyle="display: block;"
                placeholder=""
                handleChange={({ target }: any) => {
                  if (target.value !== password) {
                    setError({
                      ...error,
                      confirmationPassword: 'Passwords need to match',
                    });
                  } else {
                    setError({
                      ...error,
                      confirmationPassword: '',
                    });
                  }

                  setConfirmationPassword(target.value);
                }}
                testid="register-confirmpassword-field"
              />
            </InputGroup>
            <ActionGroup>
              <Button
                testid="rs-button"
                disabled={
                  !firstName ||
                  !lastName ||
                  !password ||
                  !countryCallingCode ||
                  !email ||
                  !emailRxp.test(email) ||
                  !phoneRxp.test(phoneNumber) ||
                  confirmationPassword !== password
                }
                handleClick={() => {
                  const data: RegisterData = {
                    email,
                    first_name: firstName,
                    last_name: lastName,
                    phone_number: phoneNumber,
                    country_code: countryCallingCode,
                    password,
                    timezone: moment.tz.guess(),
                  };

                  const redirect = {
                    invitation: () => {
                      accountContext.dispatch({
                        type: 'SET_REGISTER_DATA',
                        data,
                      });
                      acceptInvitation(t, data, accountContext.dispatch, history, setError);
                    },
                    'shareable-invitation': () =>
                      acceptSharableInvitation(
                        t,
                        data,
                        accountContext.dispatch,
                        notificationContext,
                        history,
                        setError
                      ),
                  };

                  redirect[
                    redirect_to as 'invitation' | 'shareable-invitation'
                  ]();
                }}
                customStyle={submitCustomStyle}
                value={redirect_to === 'invitation' ? 'Next' : 'Create Account'}
                type="submit"
              />
              <LoginLink>or&nbsp;<Link to="/">go to login</Link></LoginLink>
            </ActionGroup>

            <Terms>
              By clicking the &quot;Create Account&quot; button, you are creating
              a GlobalTrack account, and you agree to GlobalTrack&apos;s&nbsp;
              <a>Terms of Use</a> and&nbsp;
              <a>Privacy Policy</a>.
            </Terms>
          </Form>
        </FormWrapper>
      </Container>
      <Toast />
    </PublicWrapper>
  );
};

export default Register;