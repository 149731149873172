import { useState, useEffect, ReactNode } from 'react';
import { ToltipMessage, ToltipWrapper } from './toltip.style';

interface Props {
  children: ReactNode;
  text: string;
  active: boolean;
  hover?: boolean;
}

const Toltip = ({ children, text, active, hover }: Props) => {
  const [internalActive, setInternalActive] = useState<boolean>(active);
  let timeout: NodeJS.Timeout | null = null;

  useEffect(() => {
    if (timeout) clearTimeout(timeout);
    if (internalActive) {
      timeout = setTimeout(() => setInternalActive(false), 4000);
    }
  }, [internalActive]);

  useEffect(() => {
    if (active) setInternalActive(active);
  }, [active]);

  return (
    <ToltipWrapper
      onMouseEnter={() => {
        if (hover && !active) setInternalActive(true);
      }}
      onMouseLeave={() => {
        if (hover && !active) setInternalActive(false);
      }}
    >
      {internalActive ? <ToltipMessage>{text}</ToltipMessage> : null}
      {children}
    </ToltipWrapper>
  );
};

Toltip.defaultProps = {
  hover: true,
};

export default Toltip;
