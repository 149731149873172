import InspectionStore from "app/modules/inspection/inspection.context";
import { EstimationType } from "app/modules/inspection/inspection.interfaces";
import { useContext, useEffect, useState } from "react";
import { changeTrackingPointMarker, renderMarker } from "./pig-marker.controller";

interface Props {
  estimation: EstimationType;
  map: google.maps.Map;
}

const PigMarker = ({ estimation, map }: Props) => {
  const inspectionContext = useContext(InspectionStore);
  const [marker, setMarker] = useState<google.maps.Marker | undefined>();

  useEffect(() => {
    const nmarker = renderMarker(inspectionContext, estimation, map, marker);
    setMarker(nmarker);

    google.maps.event.addListener(map, 'drag', () => {
      inspectionContext.dispatch({
        type: 'SET_IS_FOLLOWING_PIG',
        data: false,
      });
      inspectionContext.dispatch({
        type: 'SET_IS_FOLLOWING_LOGGED_USER',
        data: false,
      });
    });

    return () => {
      nmarker.setMap(null);
    };
  }, []);

  useEffect(() => {
    changeTrackingPointMarker({ run: inspectionContext.state.run, marker, estimation, map })
  }, [inspectionContext.state.run, estimation])

  return null;
};

export default PigMarker;