import styled from 'styled-components';

export const DesktopRunContainer = styled.div<{ isObserver: boolean }>`
  display: none;

  @media (min-width: 1024px) {
    display: grid;
    width: 100%;
    height: calc(100vh - 42px);

    overflow: hidden;
    grid-template-rows: ${({isObserver}) => isObserver ? 'auto 1fr' : 'auto 1fr 40vh'};
  }
`;

export const MapContainer = styled.div`
  position: relative;
`;
