import Skeleton from '../../../../../../skeleton/skeleton';
import Elevation from './icons/Elevation';
import Speed from './icons/Speed';
import {
  formatDistance,
  formatMps,
  formatTimezoneDate,
} from '@/lib/formatter';
import {
  WidgetsContainer,
  WidgetList,
  Widget,
  WidgetContent,
  WidgetValue,
  WidgetLabel,
  Timer,
  TrackingIcon,
  Menu,
  CloseDiv,
} from './widgets.style';
import { getTrackingpointIcon } from '../../../../../../../utils/map.utils';
import { TrackingPointPlainType } from 'app/modules/inspection/inspection.interfaces';

const getInclination = (
  isFinished: boolean,
  launched: boolean,
  inclination: number,
  lastPoint: TrackingPointPlainType
) => {
  if (launched && isFinished && lastPoint?.inclination) {
    const data = +lastPoint.inclination;
    return `${data.toFixed(1)}º`;
  }

  if (launched && !isFinished && inclination) {
    const data = +inclination;
    return `${data.toFixed(1)}º`;
  }

  return '---';
};

/**
 *
 * @returns
 */
const Widgets = ({
  elevation,
  inclination,
  displayNone,
  isFinished,
  lastPassage,
  weatherForecast,
  speedUnit,
  weatherUnit,
  distanceUnit,
  timezone,
  nextPoint,
  launched,
  widgets,
  selectedTrackingPoint,
  endPoint,
  speed,
  openChartModal,
  hide,
  setHide,
}: any) => widgets ? (
  <WidgetsContainer
    displayNone={displayNone}
    hide={hide || !widgets}
    selectedTrackingPoint={!!selectedTrackingPoint}
  >
    <WidgetList hide={hide}>
      <CloseDiv onClick={() => setHide(true)}>
        <svg
          width="25"
          height="25"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M24 0H0V24H24V0Z" fill="white" fillOpacity="0.01" />
          <path
            d="M12 22C17.5229 22 22 17.5229 22 12C22 6.47715 17.5229 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5229 6.47715 22 12 22Z"
            fill="#9CA4AC"
          />
          <path
            d="M14.8282 9.17155L9.17139 14.8284"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9.17139 9.17155L14.8282 14.8284"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </CloseDiv>
      {}
      <Widget hide={widgets.findIndex((id: string) => id === 'weather') === -1}>
        <figure className="large">
          <img
            alt=""
            src={`/assets/icons/weather/${weatherForecast?.icon || 'sunny.png'
              }`}
          />
        </figure>
        <WidgetContent>
          <Skeleton
            loading={launched && !weatherForecast?.feelslikeF}
            width="59px"
            height="9px"
          >
            <WidgetValue>
              {parseInt(
                weatherUnit?.id === 'farenheit'
                  ? weatherForecast?.feelslikeF
                  : weatherForecast?.feelslikeC,
                10
              ) || '---'}
              {weatherUnit?.label || '---'}
            </WidgetValue>
          </Skeleton>
          <Skeleton
            loading={launched && !weatherForecast?.weather}
            width="32px"
            height="6px"
          >
            <WidgetLabel>{weatherForecast?.weather || 'Weather'}</WidgetLabel>
          </Skeleton>
        </WidgetContent>
      </Widget>
      <Widget
        hide={widgets.findIndex((id: string) => id === 'speed') === -1}
        onClick={() => openChartModal('speed')}
        data-testid="w-speed"
        pointer
      >
        <figure>
          <Speed />
        </figure>
        <WidgetContent>
          <Skeleton
            loading={launched && !lastPassage?.speed}
            width="59px"
            height="9px"
          >
            <WidgetValue>
              {formatMps({
                distance: isFinished
                  ? lastPassage?.speed
                  : speed || lastPassage?.speed,
                unit: speedUnit,
              }) || '---'}
            </WidgetValue>
          </Skeleton>
          <Skeleton loading={false} width="32px" height="6px">
            <WidgetLabel>Speed</WidgetLabel>
          </Skeleton>
        </WidgetContent>
      </Widget>
      <Widget
        hide={widgets.findIndex((id: string) => id === 'elevation') === -1}
        onClick={() => openChartModal('elevation')}
        data-testid="w-elevation"
        pointer
      >
        <figure className="xlarge">
          <Elevation />
        </figure>
        <WidgetContent>
          <Skeleton loading={launched && !elevation} width="59px" height="9px">
            <WidgetValue>
              {formatDistance({
                distance: isFinished
                  ? +lastPassage?.elevation
                  : +elevation || +lastPassage?.elevation,
                unit: distanceUnit?.id,
              }) || '---'}
            </WidgetValue>
          </Skeleton>
          <Skeleton loading={false} width="32px" height="6px">
            <WidgetLabel>Elevation</WidgetLabel>
          </Skeleton>
        </WidgetContent>
      </Widget>
      <Widget
        hide={widgets.findIndex((id: string) => id === 'inclination') === -1}
        onClick={() => openChartModal('inclination')}
        pointer
        data-testid="w-inclination"
      >
        <figure>
          <img alt="" src="/assets/icons/inclination.png" />
        </figure>
        <WidgetContent>
          <Skeleton
            loading={launched && !inclination}
            width="59px"
            height="9px"
          >
            <WidgetValue>
              {getInclination(isFinished, launched, inclination, lastPassage)}
            </WidgetValue>
          </Skeleton>
          <Skeleton loading={false} width="32px" height="6px">
            <WidgetLabel>Inclination</WidgetLabel>
          </Skeleton>
        </WidgetContent>
      </Widget>
      <Widget
        minWidth="370px"
        hide={
          widgets.findIndex((id: string) => id === 'next-point') === -1 ||
          !launched ||
          isFinished
        }
      >
        <TrackingIcon
          alt=""
          src={getTrackingpointIcon(nextPoint, 'no-border')?.url}
        />
        <WidgetContent>
          <Skeleton loading={!nextPoint?.eta} width="59px" height="9px">
            <WidgetValue>
              <div>
                <Timer>
                  {formatTimezoneDate({
                    date: nextPoint?.eta,
                    timezone: timezone?.id,
                    format: 'H:mm',
                  })}
                </Timer>
                {formatTimezoneDate({
                  date: nextPoint?.eta,
                  timezone: timezone?.id,
                  format: 'll',
                })}
              </div>
            </WidgetValue>
          </Skeleton>
          <Skeleton loading={false} width="32px" height="6px">
            <WidgetLabel>ETA at Next Point</WidgetLabel>
          </Skeleton>
        </WidgetContent>
      </Widget>
      <Widget
        minWidth="370px"
        hide={
          widgets.findIndex((id: string) => id === 'arrival') === -1 ||
          !launched ||
          isFinished
        }
      >
        <TrackingIcon
          alt=""
          src={getTrackingpointIcon(endPoint, 'no-border')?.url}
        />
        <WidgetContent>
          <Skeleton loading={!endPoint?.eta} width="59px" height="9px">
            <WidgetValue>
              <div>
                <Timer>
                  {formatTimezoneDate({
                    date: endPoint?.eta,
                    timezone: timezone?.id,
                    format: 'H:mm',
                  })}
                </Timer>
                {formatTimezoneDate({
                  date: endPoint?.eta,
                  timezone: timezone?.id,
                  format: 'll',
                })}
              </div>
            </WidgetValue>
          </Skeleton>
          <Skeleton loading={false} width="32px" height="6px">
            <WidgetLabel>ETA at Receiver</WidgetLabel>
          </Skeleton>
        </WidgetContent>
      </Widget>
    </WidgetList>
    <Menu data-testid="w-collapse-button" isShowing={!hide} onClick={() => setHide(!hide)}>
      <svg viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1 9L5 5L1 1V9Z"
          fill="#5850ec"
          stroke="#5850ec"
          strokeWidth="2"
          strokeLinejoin="round"
        />
      </svg>
    </Menu>
  </WidgetsContainer>
) : null;

export default Widgets;
