import { useContext, useEffect, useRef, useState } from 'react';
import AccountStore from 'app/modules/account/account.context';
import SettingsStore from 'app/modules/settings/settings.context';
import Project from './modules/project/project';
import Run from './modules/run/run';
import Survey from './modules/survey/survey';
import Weather from './controls/weather/weather';
import FollowButtons from './controls/follow-buttons/follow-buttons';
import Ruler from './controls/ruler/ruler';
import Labels from './controls/labels/labels';
import MapTypeControl from './controls/map-type/map-type';
import ResetRotation from './controls/reset-rotation/reset-ration';
import ZoomButton from './controls/zoom-button/zoom-button';
import {
  changeMapOverlay,
  checkOverlayUnit,
  fitBounds,
  init,
  renderMeasureTool,
  setListeners,
} from './map.controller';
import {
  Bound,
  Props,
  WeatherOption,
} from './map.d';
import {
  MapTemplate,
  MapWrapper,
  Controls,
  ToggleControlsContainer,
  ToggleShowingMapControllers,
} from './map.style';
import Pipeline from './modules/pipeline/pipeline';
import TrackingPointMarkers from './modules/trackingpoint-marker/trackingpoint-marker';
import { HideControls, ShowControls } from './map.svgs';
import ViewButton from './controls/view-button/view-button';
import MeasureTool from '../../../../lib/measuretool-googlemaps-v3/src';
import MobileDetect from 'mobile-detect';

const MAP_ELEMENT_ID = 'GTMAP_WRAPPER';

/**
 *
 */
const renderControls = ({
  isFollowingPig,
  setIsFollowingPig,
  isFollowingLoggedUser,
  setIsFollowingLoggedUser,
  controls,
  weatherUnit,
  weatherOverlay,
  setWeatherOverlay,
  map,
  zoom,
  setZoom,
  hasRuler,
  setHasRuler,
  hasLabels,
  setHasLabels,
  measureTool,
  observerMode,
  setObserverMode,
  hidePigButton,
  hideLoggedUserButton,
}: any) => {
  // TODO TYPES
  const renderers: { [key: string]: () => void } = {
    weather: function WeatherControl() {
      return (
        <Weather
          unit={weatherUnit}
          selected={weatherOverlay}
          onSelect={setWeatherOverlay}
        />
      );
    },
    zoom: function ZoomControl() {
      return (
        <ZoomButton
          key="map-zoom-button"
          onZoomIn={() => {
            setZoom(zoom + 1);
            map.setZoom(zoom + 1);
          }}
          onZoomOut={() => {
            setZoom(zoom - 1);
            map.setZoom(zoom - 1);
          }}
        />
      );
    },
    follow: function FollowControl() {
      return (
        <FollowButtons
          key="map-follow-buttons"
          hidePigButton={hidePigButton}
          hideLoggedUserButton={hideLoggedUserButton}
          followingCurrentUserPosition={isFollowingLoggedUser}
          followingPig={isFollowingPig}
          onFollowingCurrentUserPositionToggle={() => {
            if (!isFollowingLoggedUser) map.setZoom(15);
            setIsFollowingLoggedUser(!isFollowingLoggedUser);
            setIsFollowingPig(false);
          }}
          onFollowingPigToggle={() => {
            if (!isFollowingPig) map.setZoom(25);
            setIsFollowingPig(!isFollowingPig);
            setIsFollowingLoggedUser(false);
          }}
        />
      );
    },
    measure: function MeasureToolControl() {
      return (
        <Ruler
          key="map-ruler"
          active={hasRuler}
          onClick={() => {
            if (measureTool && hasRuler) {
              measureTool.end();
              setHasRuler(false);
            }

            if (measureTool && !hasRuler) {
              measureTool.start();
              setHasRuler(true);
            }
          }}
        />
      );
    },
    labels: function LabelsControl() {
      return (
        <Labels
          key="map-labels"
          active={hasLabels}
          onClick={() => setHasLabels(!hasLabels)}
        />
      );
    },
    view: function LabelsControl() {
      return (
        <ViewButton
          observerView={observerMode}
          onClick={() => setObserverMode(!observerMode)}
        />
      );
    },
  };

  return controls.map((control: string) =>
    renderers[control] ? renderers[control]() : null
  );
};

/**
 *
 * @returns
 */
const Map = ({
  options,
  survey,
  runs,
  modules,
  controls,
  bounds,
  widgets,
  parentWeatherOverlay,
  pipeline,
  mapZoom,
  trackingpoints,
  observerMode,
  setObserverMode,
  testid,
  hidePigButton,
  isFollowingPig,
  setIsFollowingPig,
  isFollowingLoggedUser,
  setIsFollowingLoggedUser,
  loggedUserPosition,
}: Props) => {
  const accountContext = useContext(AccountStore);
  const settingsContext = useContext(SettingsStore);
  const mapRef = useRef<HTMLDivElement>();

  // initialization states
  const [initialized, setInitialized] = useState<boolean>(false);

  // map states
  const [map, setMap] = useState<google.maps.Map>();
  const [zoom, setZoom] = useState<number>(mapZoom);
  const [obounds, setOBounds] = useState<Bound[]>([]);
  const [measureTool, setMeasureTool] = useState<any>(); //TODO TYPE
  const [tilt, setTilt] = useState<number>();
  const [heading, setHeading] = useState<number>();
  const [isStreetViewMode, setIsStreetViewMode] = useState<boolean>(false);
  const [showControls, setShowControls] = useState<boolean>(true);

  // map controls states
  const [weatherOverlay, setWeatherOverlay] = useState<WeatherOption>();
  const [hasRuler, setHasRuler] = useState<boolean>(false);
  const [hasLabels, setHasLabels] = useState<boolean>(false);
  const [isShowingMapControllers, setIsShowingMapControllers] =
    useState<boolean>(false);

  const md = new MobileDetect(window.navigator.userAgent);

  // map initialization
  useEffect(() => {
    init({
      options,
      element: mapRef.current,
      setMap,
      setInitialized,
      setIsStreetViewMode,
    });
  }, []);

  // after map initialization
  useEffect(() => {
    if (map && initialized) {
      setListeners({
        setZoom,
        map,
        setTilt,
        setHeading,
      });

      renderMeasureTool({
        map,
        setMeasureTool,
        distanceUnit: settingsContext.state.distanceUnit,
      });
    }
  }, [map, initialized]);

  // change map tile overlay
  useEffect(() => {
    changeMapOverlay({ map, weatherOverlay });
  }, [weatherOverlay]);

  useEffect(() => {
    mapZoom && map?.setZoom(mapZoom);
  }, [mapZoom]);

  // check if has overlay setted and change the overlay option to match the weather unit
  useEffect(() => {
    checkOverlayUnit({
      weatherOverlay,
      weatherUnit: settingsContext.state.weatherUnit,
      setWeatherOverlay,
    });
  }, [settingsContext.state.weatherUnit]);

  // changing weather overlay by parent
  useEffect(() => {
    setWeatherOverlay(parentWeatherOverlay);
  }, [parentWeatherOverlay]);

  // always boundary change, fit the map to bound
  useEffect(() => {
    if (bounds?.length && map) {
      fitBounds({ obounds, bounds, map, setOBounds });
    }
  }, [bounds, map]);

  useEffect(() => {
    if (measureTool) {
      // measure tool update
      // eslint-disable-next-line @typescript-eslint/no-var-requires

      if (settingsContext.state.distanceUnit?.id === 'ft' || settingsContext.state.distanceUnit?.id === 'mi') {
        measureTool.setOption('unit', MeasureTool.UnitTypeId.IMPERIAL);
      }

      if (settingsContext.state.distanceUnit?.id === 'km' || settingsContext.state.distanceUnit?.id === 'm') {
        measureTool.setOption('unit', MeasureTool.UnitTypeId.METRIC);
      }
    }
  }, [settingsContext.state.distanceUnit]);
  
  return (
    <MapTemplate data-testid={testid}>
      <MapWrapper ref={mapRef} id={MAP_ELEMENT_ID} />
      <ToggleShowingMapControllers
        displayNone={isStreetViewMode}
        onClick={() => {
          setIsShowingMapControllers(!isShowingMapControllers);
          map?.setOptions({
            streetViewControl: !isShowingMapControllers,
            streetViewControlOptions: {
              position: 0,
            },
          });
        }}
      >
        <svg
          width="25"
          height="23"
          viewBox="0 0 25 23"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.6397 17.5157C13.1323 17.5143 13.6138 17.3698 14.0257 17.0999L24.0971 9.84664C24.2531 9.72552 24.3393 9.53561 24.3281 9.33855C24.332 9.15583 24.2456 8.98297 24.0971 8.87652L13.0094 0.884109C12.8988 0.81428 12.7706 0.777344 12.6398 0.777344C12.5089 0.777344 12.3808 0.814277 12.2701 0.884109L1.18205 8.87652H1.1822C1.03372 8.98298 0.947294 9.15584 0.95118 9.33855C0.939964 9.53563 1.02624 9.72552 1.1822 9.84664L11.2536 17.0999C11.6631 17.3748 12.1463 17.5199 12.6396 17.5157H12.6397ZM22.6652 9.3384L13.2867 16.0837C13.0972 16.2374 12.8607 16.3214 12.6168 16.3214C12.3727 16.3214 12.1362 16.2374 11.9469 16.0837L2.56837 9.3384L12.6397 2.13111L22.6652 9.3384Z"
            fill="black"
          />
          <path
            d="M0.951067 14.0953V14.3725C0.997271 14.4187 0.997272 14.4649 1.04348 14.4649L1.13589 14.5573L11.115 21.7183C11.5579 22.0425 12.0908 22.2201 12.6396 22.2265C13.1874 22.2143 13.7186 22.0371 14.1642 21.7183L24.097 14.6036C24.2376 14.4709 24.3204 14.2884 24.328 14.0954C24.3316 13.901 24.224 13.7217 24.0508 13.6334L22.4802 12.4784C22.3438 12.3851 22.1769 12.3474 22.0136 12.3731C21.8503 12.3988 21.7032 12.4862 21.6023 12.617C21.5221 12.7493 21.492 12.9059 21.5175 13.0586C21.5429 13.2111 21.6221 13.3496 21.7409 13.4487L22.6648 14.0955L13.4712 20.7481C13.2237 20.9092 12.9348 20.9949 12.6397 20.9949C12.3445 20.9949 12.0556 20.9092 11.8081 20.7481L2.56816 14.0955L3.49212 13.4487V13.4488C3.63103 13.3651 3.72643 13.2248 3.75305 13.0648C3.77967 12.905 3.73496 12.7414 3.63074 12.6172C3.53803 12.4895 3.39896 12.4033 3.24331 12.3775C3.08765 12.3516 2.9281 12.3878 2.79907 12.4785L1.18206 13.6335H1.13586H1.13601C1.12375 13.6335 1.11194 13.6385 1.10326 13.6471C1.09459 13.6558 1.08981 13.6675 1.08981 13.6797L0.997395 13.7721C0.997395 13.8183 0.951189 13.8645 0.951189 13.9107L0.951067 14.0953Z"
            fill="black"
          />
          <path
            d="M1.04364 13.7756H0.997432L1.08984 13.6832C1.06427 13.6705 1.03317 13.6808 1.02046 13.7063C1.00775 13.7318 1.01807 13.7629 1.04364 13.7756L1.04364 13.7756Z"
            fill="black"
          />
          <path
            d="M1.08984 14.4663C1.04364 14.4663 1.04364 14.4201 0.997432 14.3739C0.951225 14.3277 1.04363 14.4201 1.04363 14.4663H1.08984Z"
            fill="black"
          />
        </svg>
      </ToggleShowingMapControllers>

      {map &&
      initialized &&
      !isStreetViewMode &&
      (isShowingMapControllers ||
      !md.mobile()) &&
      controls.indexOf('map-type') !== -1 ? (
        <MapTypeControl
          onMapTypeChange={(id) => map.setMapTypeId(id)}
          hasStreetViewButton={
            isShowingMapControllers || options?.streetViewControl
          }
        />
      ) : null}

      {initialized && !isStreetViewMode ? (
        <Controls observerMode={!!observerMode || false}>
          <ToggleControlsContainer
            onClick={() => setShowControls(!showControls)}
          >
            {showControls ? <HideControls /> : <ShowControls />}
          </ToggleControlsContainer>
          {showControls
            ? renderControls({
                controls,
                weatherUnit: settingsContext.state.weatherUnit,
                weatherOverlay,
                setWeatherOverlay,
                map,
                zoom,
                setZoom,
                isFollowingPig,
                setIsFollowingPig,
                isFollowingLoggedUser,
                setIsFollowingLoggedUser,
                hasRuler,
                setHasRuler,
                hasLabels,
                setHasLabels,
                measureTool,
                loggedUserPosition,
                showOnlineUsers: {}, // TODO: ONLINE USERS
                hidePigButton,
                hideLoggedUserButton: !loggedUserPosition,
                setShowOnlineUsers: () => {
                  // dispatch({
                  //   type: 'TOGGLE_SHOW_ONLINE_USERS',
                  // });
                },
                observerMode,
                setObserverMode,
              })
            : null}

          {map && !isStreetViewMode && (tilt || heading) ? (
            <ResetRotation
              onClick={() => {
                setTilt(0);
                setHeading(0);

                map.setTilt(0);
                map.setHeading(0);
              }}
            />
          ) : null}
        </Controls>
      ) : null}

      {initialized && map && runs && modules.indexOf('project') !== -1 ? (
        <Project map={map} runs={runs} />
      ) : null}

      {initialized && map && pipeline && modules.indexOf('pipeline') !== -1 ? (
        <Pipeline map={map} pipeline={pipeline} />
      ) : null}

      {initialized &&
      map &&
      trackingpoints &&
      modules.indexOf('trackingpoints') !== -1 ? (
        <TrackingPointMarkers map={map} trackingpoints={trackingpoints} />
      ) : null}

      {initialized && modules.indexOf('survey') !== -1 ? (
        <Survey
          map={map}
          zoom={zoom}
          localSurvey={survey}
          hasLabels={hasLabels}
        />
      ) : null}
      
      {initialized &&
      accountContext.state.auth &&
      map &&
      modules.indexOf('run') !== -1 ? (
        <Run
          observerMode={observerMode}
          map={map}
          zoom={zoom}
          widgets={widgets}
          fitBounds={(nbounds) =>
            fitBounds({ obounds, bounds: nbounds, map, setOBounds, zoom })
          }
          hasLabels={hasLabels}
          hasRuler={hasRuler}
          measureTool={measureTool}
        />
      ) : null}
    </MapTemplate>
  );
};

Map.defaultProps = {
  mapZoom: 6,
};

export default Map;
