import { UserType } from 'app/interfaces/account.interfaces';
import {
  InspectionContext,
  InspectionDispatchType,
} from './inspection.context.d';
import { updateUser } from './handlers/inspection.user.handlers';
import { deleteTrackingPoint, updatePoint, updatePointMedia, updatePointNote, updateTPMarkerDic, updateTrigger, updateTriggerSeenTrigger } from './handlers/inspection.tp.handlers';
import { updateQube } from "./handlers/inspection.qube.handlers";
import { QubeDeviceType } from "../qube/qube.interfaces";
import { deletePassage, updatePassage } from "./handlers/inspection.passage.handlers";
import { PassageType, TimePinType, TrackingPointNoteType, TrackingPointCascadeType } from "./inspection.interfaces";
import { SurveyMediaType } from "../survey/survey.interfaces";
import { TriggerSeenType, TriggerType } from "../detection/detection.interfaces";
import { updateTimePin } from "./handlers/inspection.timepin.handlers";

/**
 * 
 * @param state
 * @param action 
 * @returns account context state
 */
export const reducer = (
  state: InspectionContext,
  action: InspectionDispatchType
): InspectionContext => {
  switch (action.type) {
    case 'SET_RUN':
      return { ...state, run: action.data as InspectionContext['run'] };
    case 'SET_VERIFIED':
      return { ...state, verified: action.data as InspectionContext['verified'] };
    case 'SET_ERROR':
      return { ...state, error: action.data as InspectionContext['error'] };
    case 'SET_LOADING':
      return { ...state, loading: action.data as InspectionContext['loading'] };
    case 'SET_PIPELINE':
      return { ...state, pipeline: action.data as InspectionContext['pipeline'] };
    case 'SET_PROJECT':
      return { ...state, project: action.data as InspectionContext['project'] };
      case 'SET_FORECAST':
        return { ...state, forecast: action.data as InspectionContext['forecast'] };
    case 'SET_TICK':
      return { ...state, tick: action.data as InspectionContext['tick'] };
    case 'SET_TRIGGER_SEEN_TRIGGERS':
      return updateTriggerSeenTrigger(state, action.data as TriggerSeenType);
    case 'SET_LAST_UPDATE':
      return { ...state, last_update: action.data as InspectionContext['last_update'] };
    case 'SET_SHOW_TIMEPINS_MODAL':
      return { ...state, show_timepins_modal: action.data as InspectionContext['show_timepins_modal'] };
    case 'SET_SHOW_CREATE_TIMEPIN_MODAL':
      return { ...state, show_create_timepin_modal: action.data as InspectionContext['show_create_timepin_modal'] };
    case 'UPDATE_USER':
      return updateUser(state, action.data as UserType);
    case 'UPDATE_QUBE':
      return updateQube(state, action.data as QubeDeviceType);
    case 'UPDATE_PASSAGE':
      return updatePassage(state, action.data as PassageType);
    case 'UPDATE_TRIGGER':
      return updateTrigger(state, action.data as TriggerType);
    case 'UPDATE_ESTIMATION':
      return { ...state, estimation: action.data as InspectionContext['estimation'] }
    case 'UPDATE_POINT':
      return updatePoint(state, action.data as TrackingPointCascadeType)
    case 'SET_GRID':
      return { ...state, grid: action.data as Slick.Grid<TrackingPointCascadeType> }
    case 'SET_CUSTOMER':
      return { ...state, customer: action.data as InspectionContext['customer'] }
    case 'UPDATE_NOTE':
      return updatePointNote(state, action.data as TrackingPointNoteType);
    case 'UPDATE_MEDIA':
      return updatePointMedia(state, action.data as SurveyMediaType);
    case 'UPDATE_TIMEPIN':
      return updateTimePin(state, action.data as TimePinType);
    case 'TOGLE_CHART_MODAL':
      return { ...state, show_chart: !state.show_chart }
    case 'SET_IS_FOLLOWING_PIG':
      return { ...state, following_pig: action.data as InspectionContext['following_pig'] };
    case 'SET_IS_FOLLOWING_LOGGED_USER':
      return { ...state, following_logged_user: action.data as InspectionContext['following_logged_user'] };
    case 'SET_LOGGED_USER_POSITION':
      return { ...state, logged_user_position: action.data as InspectionContext['logged_user_position'] };
    
    // Selections
    case 'SET_SELECTED_POINT':
      return { ...state, selected_point: action.data as InspectionContext['selected_point'] };
    case 'SET_SELECTED_TIMEPIN':
      return { ...state, selected_timepin: action.data as InspectionContext['selected_timepin'] };
    case 'SET_SELECTED_QUBE':
      return { ...state, selected_qube: action.data as InspectionContext['selected_qube'] };
    case 'SET_SELECTED_TRIGGER_POINT':
      return { ...state, selected_trigger_point: action.data as number };
    
    
    // Dictionaries
    case 'SET_TRACKINGPOINTS_DIC':
      return { ...state, trackingpoints_dic: action.data as InspectionContext['trackingpoints_dic'] };
    case 'SET_USERS_DIC':
      return { ...state, users_dic: action.data as InspectionContext['users_dic'] };
    case 'SET_TIMEPINS_DIC':
      return { ...state, timepins_dic: action.data as InspectionContext['timepins_dic'] };
    case 'SET_QUBES_DIC':
      return { ...state, qubes_dic: action.data as InspectionContext['qubes_dic'] };
    
    // Orders set
    case 'SET_TRACKINGPOINT_SET':
      return { ...state, trackingpoint_set: action.data as InspectionContext['trackingpoint_set'] };
    case 'SET_USER_SET':
      return { ...state, user_set: action.data as InspectionContext['user_set'] };
    case 'SET_TIMEPIN_SET':
      return { ...state, timepin_set: action.data as InspectionContext['timepin_set'] };
    case 'SET_QUBE_SET':
      return { ...state, qube_set: action.data as InspectionContext['qube_set'] };
    
    // Map
    case 'SET_SEGMENT':
      return { ...state, segment: action.data as InspectionContext['segment'] };
    case 'SET_EXPLANATION_MAP_TOOLTIP':
      return { ...state, segment: action.data as InspectionContext['segment'] };
    case 'SET_PIPELINE_POLY':
      return { ...state, pipeline_poly: action.data as InspectionContext['pipeline_poly'] };
    case 'SET_DRIVELINE_POLY':
      return { ...state, driveline_poly: action.data as InspectionContext['driveline_poly'] };

    // Map - Markers
    case 'SET_POINT_MARKERS':
      return { ...state, point_markers: action.data as InspectionContext['point_markers'] };
    case 'SET_LOGGED_USER_MARKER':
      return { ...state, logged_user_marker: action.data as InspectionContext['logged_user_marker'] };
    case 'SET_PIG_MARKER':
      return { ...state, pig_marker: action.data as InspectionContext['pig_marker'] };
    case 'SET_TIMEPIN_MARKERS':
      return { ...state, timepin_markers: action.data as InspectionContext['timepin_markers'] };
    case 'SET_QUBE_MARKERS':
      return { ...state, qube_markers: action.data as InspectionContext['qube_markers'] };
    case 'SET_POINT_MARKER':
      return updateTPMarkerDic(state, action.data);
      
    // DELETE
    case 'DELETE_PASSAGE':
      return deletePassage(state, action.data as PassageType);
    case 'DELETE_TRACKING_POINT':
      return deleteTrackingPoint(state, action.data as TrackingPointCascadeType);

    case 'NO_CONNECTION':
      if (state.run === null || !action.data) {
        // Do not try to reconnect
        return {
          ...state,
          no_connection_since: undefined,
          reconnection_attempt: undefined,
        };
      }
      if (state.no_connection_since) {
        return state;
      }

      return {
        ...state,
        no_connection_since: state.no_connection_since || action.data as Date,
        // This is updates in steps of 0.5. Here it's rounded up to next integer
        reconnection_attempt: Math.round((state.reconnection_attempt || 0) + 1),
      };

    case 'RECONNECTION_TRIGGER':
      return {
        ...state,
        // By adding 0.5, the reconnection will be triggered
        reconnection_attempt: (state.reconnection_attempt || 0) + 1,
      };

    case 'STOP_RECONNECTION':
      return {
        ...state,
        reconnection_attempt: undefined,
        no_connection_since: undefined,
      };

    

    default:
      throw new Error(`Unknown inspection reducer action ${action.type}`);
  }
};
