interface Props {
  percentage: number;
}

const  ProgressBar = ({ percentage }: Props) => {
  const colors = [
    '#F6382D', '#F6382D', '#F6382D', '#F6382D', '#F63F2F', '#F77A3D',
    '#F8A347', '#F9CA51', '#F9E156', '#F9E056', '#F1DE57', '#CED95A',
    '#A6D35F', '#6DCB64', '#43C469', '#2CC16B', '#0CBC6F'
  ];

  const rects = colors.map((color, index) => {
    const fill = percentage >= (index + 1) * 100 / 17 ? color : '#DCDFE2';
    return (
      <rect
        key={index}
        x={(index * 7) + 3}
        width="3"
        height="11"
        rx="1.5"
        fill={fill}
      />
    );
  });

  return (
    <svg width="120" height="11" viewBox="0 0 120 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      {rects}
    </svg>
  );
}

export default ProgressBar;