import {
  formatDistance,
  formatMps,
  formatTimezoneDate,
} from '@/lib/formatter';
import { Column } from 'app/components/table/table.interfaces';
import { TrackingPointType } from 'app/interfaces/inspection.interfaces';

export const trackingSheetColumns = ({
  timezone,
  speedUnit,
  distanceUnit,
}: any): Column[] => [
    {
      field: 'name',
      headerName: 'Name',
      width: 150,
    },
    {
      field: 'active',
      headerName: 'Hold',
      width: 60,
      sortable: true,
      valueGetter: (row: TrackingPointType) => (row.active ? 'Active' : 'Holded'),
    },
    {
      field: 'passage.tstamp',
      headerName: 'Passage time',
      description: "Format: 'HH:mm:ss.SSS' ",
      width: 110,
      valueGetter: (row: any) => {
        return row.passage?.tstamp || '';
      },
      valueFormatter: (value: any): string =>
        formatTimezoneDate({
          date: value,
          timezone: timezone?.id,
          format: 'HH:mm:ss.SSS',
        }) || '---',
    },
    {
      field: 'passageDate',
      headerName: 'Passage date',
      description: "Format: 'YYYY-MM-DD' ",
      width: 110,
      valueGetter: (row: any) => row.passage?.tstamp || '',
      valueFormatter: (data: any): string =>
        formatTimezoneDate({
          date: data,
          timezone: timezone?.id,
          format: 'YYYY-MM-DD',
        }) || '---',
    },
    {
      field: 'longitude',
      headerName: 'Longitude',
      valueGetter: (row: any) =>
        `${row.geometry?.coordinates[0].toFixed(9)}`,
      width: 210,
    },
    {
      field: 'latitude',
      headerName: 'Latitude',
      valueGetter: (row: any) =>
        `${row.geometry?.coordinates[1].toFixed(9)}`,
      width: 210,
    },
    {
      field: 'speed',
      headerName: `Speed (${speedUnit?.label})`,
      width: 100,
      align: 'right',
      valueGetter: (row: any) => row.speed || '',
      valueFormatter: (value): string =>
        formatMps({ distance: value, unit: speedUnit, returnNumber: true }) ||
        '---',
    },
    {
      field: 'distPrevious',
      headerName: `Dist. from previous (${distanceUnit?.id})`,
      width: 200,
      valueGetter: (row: TrackingPointType) => {
        const currentDistance: number = parseInt(`${row?.distance}` || '', 10);
        const prevDistance: number =
          parseInt(`${row?.prev?.distance}` || '', 10) || 0;
        return `${currentDistance - prevDistance}`;
      },
      valueFormatter: (data: any): string =>
        formatDistance({
          distance: data,
          unit: distanceUnit?.id,
          returnNumber: true,
        }) || '',
    },
    {
      field: 'elevation',
      headerName: `Elevation (${distanceUnit?.id})`,
      width: 130,
      align: 'right',
      valueFormatter: (value: any): string => {
        return formatDistance({
          distance: +value,
          unit: distanceUnit?.id,
          returnNumber: true,
        });
      },
    },
    {
      field: 'inclination',
      headerName: 'Inclination',
      width: 90,
      align: 'right',
      valueFormatter: (value: any): string =>
        value ? `${parseInt(value, 10)?.toFixed(1) || 0}°` : '',
    },
    {
      field: 'locationDescription',
      headerName: 'Location description',
      width: 200,
      valueGetter: (row: TrackingPointType) =>
        row.location_description ? row.location_description : '',
    },
    {
      field: 'deviceSN',
      headerName: 'Device SN',
      width: 200,
      valueGetter: (row: TrackingPointType) =>
        row.device_sn ? row.device_sn : '',
    },
    {
      field: 'stationingNumber',
      headerName: 'Stationing Number',
      width: 200,
      valueGetter: (row: TrackingPointType) =>
        row.station_number ? row.station_number : '',
    },
    {
      field: 'rawFootage',
      headerName: 'Raw footage',
      width: 200,
      valueGetter: (row: TrackingPointType) =>
        row.raw_footage ? row.raw_footage : '',
    },
    {
      field: 'milepost',
      headerName: 'Milepost',
      width: 200,
      valueGetter: (row: TrackingPointType) => (row.milepost ? row.milepost : ''),
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 200,
      valueGetter: (row: TrackingPointType) =>
        row.description && row.description !== '&nbsp;' ? row.description : '',
    },
    {
      field: 'depthOfCover',
      headerName: 'Depth of Cover',
      valueGetter: (row: TrackingPointType) =>
        row.depth_of_cover ? row.depth_of_cover : '',
      width: 160,
    },
    {
      field: 'alignmentSheet',
      headerName: 'Alignment Sheet',
      width: 160,
      valueGetter: (row: TrackingPointType) =>
        row.alignment_sheet ? row.alignment_sheet : '',
    },
    {
      field: 'survey_method',
      headerName: 'Survey Method',
      width: 160,
      valueGetter: (row: TrackingPointType) =>
        row.survey_method ? `${row.survey_method}` : '',
    },
    {
      field: 'datum',
      headerName: 'Datum',
      width: 160,
      valueGetter: (row: TrackingPointType) => (row.datum ? `${row.datum}` : ''),
    },
    {
      field: 'surveyed_time',
      headerName: 'Surveyed Time',
      width: 160,
      valueGetter: (row: TrackingPointType) =>
        row.surveyed_time ? `${row.surveyed_time}` : '',
    },
    {
      field: 'survey_accuracy',
      headerName: 'Survey Accuracy',
      width: 160,
      valueGetter: (row: TrackingPointType) =>
        row.survey_accuracy ? `${row.survey_accuracy}` : '',
    },
    {
      field: 'geoid_model',
      headerName: 'Geoid Model',
      width: 160,
      valueGetter: (row: TrackingPointType) =>
        row.geoid_model ? `${row.geoid_model}` : '',
    },
    {
      field: 'antenna_height',
      headerName: 'Antenna Height',
      width: 160,
      valueGetter: (row: TrackingPointType) =>
        row.antenna_height ? `${row.antenna_height}` : '',
    },
    {
      field: 'elevation_top',
      headerName: 'Elevation Cop',
      width: 160,
      valueGetter: (row: TrackingPointType) =>
        row.elevation_top ? `${row.elevation_top}` : '',
    },
    {
      field: 'ellipsoid_height',
      headerName: 'Ellipsoid Height',
      width: 160,
      valueGetter: (row: TrackingPointType) =>
        row.ellipsoid_height ? `${row.ellipsoid_height}` : '',
    },
    {
      field: 'ellipsoid_height_cop',
      headerName: 'Ellipsoid Height Cop',
      width: 160,
      valueGetter: (row: TrackingPointType) =>
        row.ellipsoid_height_cop ? `${row.ellipsoid_height_cop}` : '',
    },
    {
      field: 'tract',
      headerName: 'Tract',
      width: 160,
      valueGetter: (row: TrackingPointType) => (row.tract ? row.tract : ''),
    },
    {
      field: 'country',
      headerName: 'Country',
      valueGetter: (row: TrackingPointType) => (row.country ? row.country : ''),
      width: 160,
    },
    {
      field: 'state',
      headerName: 'State',
      valueGetter: (row: TrackingPointType) => (row.state ? row.state : ''),
      width: 160,
    },
    {
      field: 'comment',
      headerName: 'Comment',
      valueGetter: (row: TrackingPointType) => (row.comment ? row.comment : ''),
      width: 160,
    },
    {
      field: 'pressureDifferential',
      headerName: 'Pressure Differential',
      valueGetter: (row: TrackingPointType) =>
        row.pressure_differential ? row.pressure_differential : '',
      width: 160,
    },
  ];
