import { MobileInformationBtn } from './mobile-information-button.style';

interface Props {
  onClick: () => void;
}

/**
 *
 * @returns
 */
const MobileInformationButton = (props: Props) => (
  <MobileInformationBtn onClick={props.onClick}>
    <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M3.99997 12.1062C3.83326 6.93955 7.99997 7.10622 7.99997 4.43955C7.99997 1.10622 2.66664 -0.0604489 1.99997 4.77286L0 4.60614C0.833333 -1.89386 10 -1.06053 10 4.27284C10 8.10617 6 7.4395 5.83333 12.1062H3.99994L3.99997 12.1062ZM3.66667 15.9396V13.7729H5.83336V15.9396H3.66667Z" fill="#1a1f36" />
    </svg>
  </MobileInformationBtn>
);

export default MobileInformationButton;
