import { formatPipePointsToPath } from '@/lib/formatter';
import {
  PipelineType,
} from '../../../../../interfaces/inspection.interfaces';
import { getBoundsBox } from '../../../../../utils/map.utils';
import { serializeSurvey } from 'app/modules/survey/handlers/survey.survey.handlers';


export const mapSurvey = (localSurvey, setPipeline, setSurvey, setPointsDic) => {
  const serialized = serializeSurvey({}, localSurvey);

  setPipeline(serialized.pipeline);
  setSurvey(serialized.survey);
  setPointsDic(serialized.points_dic);
  return serialized;
};

/**
 *
 */
export const centerMap = ({
  pipeline,
  map,
}: {
  pipeline?: PipelineType;
  map?: google.maps.Map;
}) => {
  if (pipeline?.geometry && map) {
    const points = formatPipePointsToPath({
      project: null,
      name: null,
      length: null,
      id: null,
      start: { coordinates: [], type: '' },
      geometry: { coordinates: pipeline.geometry?.coordinates || [], type: '' },
    });

    if (points) {
      const center: any = getBoundsBox(points);
      map.fitBounds(center, 70);
    }
  }
};
