import { approveTrigger, rejectTrigger } from 'app/services/run.service';

/**
 * handle approve trigger button
 * @param param0
 */
export const onApproveTrigger = async ({
  triggerId,
  token,
}: {
  triggerId: number;
  token: string;
}) => {
  await approveTrigger(triggerId, token);
};

/**
 * handle reject trigger button
 * @param param0
 */
export const onRejectTrigger = async ({
  triggerId,
  token,
}: {
  triggerId: number;
  token: string;
}) => {
  await rejectTrigger(triggerId, token);
};
