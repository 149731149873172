import { useContext, useEffect, useMemo, ReactNode, useCallback } from 'react';
import moment from 'moment-timezone';
import InspectionStore from 'app/modules/inspection/inspection.context';
import SettingsStore from 'app/modules/settings/settings.context';
import AccountStore from 'app/modules/account/account.context';
import NotificationStore from 'app/modules/notification/notification.context';
import { AuthType } from 'app/modules/account/account.context.d';
import { SheetContainer, SkeletonWrapper } from './tracking-sheet.style';
import Table from '../slick-table/slick-table';
import Skeleton from '../skeleton/skeleton';
import { getColumns } from '../../models/sheet/sheet';
import { getEvents, getFrozenColumn, parsePointToRow, refreshAllPoints, refreshETE } from './tracking-sheet.controller';
import { getSheetColumns } from './tracking-sheet.model';
import {
  distanceFormatter,
  etaFormatter,
  eteFormatter,
  inclinationFormatter,
  inputTypeFormatter,
  passageDateFormatter,
  passageTimeFormatter,
  speedFormatter,
  triggersFormatter,
} from './tracking-sheet.formatters';
import { Bound } from '../common/map/map.d';
import { ConfirmationModalType } from '../confirmation-modal/confirmation-modal.d';

interface Props {
  id: number;
  timezone: string;
  distanceUnit: string;
  speedUnit: string;
  permissionType?: string;
  tableContentHeight: string;
  diff: number;
  setBounds: (data: Bound[]) => void;
  showConfimationModal: (data: ConfirmationModalType) => void;
  setTriggerPoint: (id?: number) => void;
}

/**
 *
 * @param
 * @returns
 */
const TrackingSheet = (props: Props) => {
  const settingsContext = useContext(SettingsStore);
  const notificationContext = useContext(NotificationStore);
  const inspectionContext = useContext(InspectionStore);
  const accountContext = useContext(AccountStore);
  const auth = accountContext.state.auth as AuthType;
  
  /**
   *
   */
  const overideParsePointToRow = useCallback((id: number, index: number) => {
    if (!props.permissionType) return null;

    return parsePointToRow(
      inspectionContext.state.run?.id || 0,
      inspectionContext.state.trackingpoints_dic[id],
      index,
      settingsContext.state,
      inspectionContext.state.is_observer,
      props.permissionType,
      inspectionContext.state.run?.launched || false,
      inspectionContext.state.passages_dic,
      inspectionContext.state.tp_to_passages_dic,
      inspectionContext.state.triggerseen_trigger_set,
      inspectionContext.state.run?.predicted_launch_speed,
    );
  }, [props.permissionType,
      settingsContext.state.timezone,
      settingsContext.state.distanceUnit,
      settingsContext.state.speedUnit,
      inspectionContext.state.is_observer,
      inspectionContext.state.run?.launched,
      inspectionContext.state.triggerseen_trigger_set,
      inspectionContext.state.trackingpoints_dic,
      inspectionContext.state.tp_to_passages_dic,
      inspectionContext.state.passages_dic
  ]);

  // memorize sheet data
  const data = useMemo(() => {
    if (inspectionContext.state.run?.trackingpoint_set) {
      return inspectionContext.state.run?.trackingpoint_set.map(overideParsePointToRow);
    }
    return [];
  }, [
    inspectionContext.state.grid,
    inspectionContext.state.trackingpoints_dic,
    inspectionContext.state.passages_dic,
    inspectionContext.state.triggers_dic,
    inspectionContext.state.triggerseen_trigger_set,
    settingsContext.state.speedUnit,
    settingsContext.state.distanceUnit,
    settingsContext.state.timezone,
  ]);

  // create skeletons to loading
  const skeletons: ReactNode[] = [];
  for (let c = 0; c <= 600; c += 1) {
    skeletons.push(<Skeleton key={c} loading height="20px" />);
  }
  
  // REFRESH THE ETE COLUMN USING TICK
  useEffect(() => {
    if (inspectionContext.state.grid) refreshETE(inspectionContext.state.grid);
  }, [inspectionContext.state.tick]);
  
  // REFRESH ALL POINT IF PERMISSION CHANGE
  useEffect(() => {
    if (inspectionContext.state.grid && props.permissionType) {
      refreshAllPoints(inspectionContext.state, settingsContext.state, props.permissionType);
    }
  }, [inspectionContext.state.grid, props.permissionType]);
  useEffect(() => {
    if (inspectionContext.state.run) {
      inspectionContext.dispatch({ type: 'SET_LOADING' })
    }
  }, [inspectionContext.state.run]);

  useEffect(() => {
    if (!inspectionContext.state.grid) return;
    if (inspectionContext.state.selected_point) {
      const point = inspectionContext.state.trackingpoints_dic[inspectionContext.state.selected_point];
      if (point?.index) {
        inspectionContext.state.grid.setSelectedRows([point.index])
        inspectionContext.state.grid.scrollRowIntoView(point.index, true)
      }
    } else {
      inspectionContext.state.grid.setSelectedRows([])
    }
  }, [inspectionContext.state.selected_point]);

  if (
    !auth ||
    inspectionContext.state.loading ||
    !inspectionContext.state.run ||
    inspectionContext.state.run.id !== props.id ||
    !inspectionContext.state.run.trackingpoint_set?.length ||
    !props.permissionType ||
    !inspectionContext.state.trackingpoints_dic[inspectionContext.state.run.trackingpoint_set[0]]
  ) {
    // render load state
    return (
      <SheetContainer>
        <SkeletonWrapper>{skeletons}</SkeletonWrapper>
      </SheetContainer>
    );
  }

  // check if can be render the run
  if (!auth || inspectionContext.state.loading || !inspectionContext.state.run || inspectionContext.state.run.id !== props.id || !inspectionContext.state.run.trackingpoint_set?.length || !props.permissionType) {
    // render load state
    return (
      <SheetContainer>
        <SkeletonWrapper>{skeletons}</SkeletonWrapper>
      </SheetContainer>
    )
  }

  /**
   *
   * @param row
   * @param nextPoint
   * @param prevPoint
   */
  const getMapBounds = (row: any, nextPoint, prevPoint) => {
    if (!prevPoint?.latitude || !nextPoint?.latitude) {
      return [
        {
          lat: row.latitude,
          lng: row.longitude,
        },
      ];
    }

    const bounds: Bound[] = [
      { lat: row.latitude, lng: row.longitude },
      {
        lat: prevPoint?.latitude,
        lng: prevPoint?.longitude,
      },
      {
        lat: nextPoint?.latitude,
        lng: nextPoint?.longitude,
      },
    ];

    return bounds;
  };

  /**
   *
   * @param oprams
   */
  const handleSelectPoint = ({ point, nextPoint, prevPoint }) => {
    inspectionContext.dispatch({ type: 'SET_SELECTED_POINT', data: point.id });
    inspectionContext.dispatch({ type: 'SET_SELECTED_QUBE', data: point.geolocks[0] });  
    const bounds = getMapBounds(point, nextPoint, prevPoint);
    props.setBounds(bounds);
  };

  const setError = ({ title, message }: {
    title: string,
    message: string
  }) => {
    notificationContext.dispatch({
      type: 'SET_TOAST',
      data: {
        type: 'error',
        title: title,
        text: message,
      },
    });
  }

  // create columns definitions to run sheet
  const columns = getColumns(
    {
      speed: settingsContext.state.speedUnit,
      distance: settingsContext.state.distanceUnit,
    },
    !inspectionContext.state.is_observer,
    getSheetColumns(!!inspectionContext.state.project?.using_linestat, !!inspectionContext.state.project?.using_qube),
    [
      ['passage_date(Passage)', 'Passage Date', 150, passageDateFormatter, ['permission_type', 'passage']],
      ['passage_time(Passage)', 'Passage Time', 150, passageTimeFormatter, ['permission_type', 'passage']],
      ['speed*', 'Speed', 100, speedFormatter],
      ['speed_delta*', 'Speed Dt.', 100, speedFormatter],
      ['eta*', 'ETA', 160, etaFormatter],
      ['ete*', 'ETE', 160, eteFormatter, ['eta', 'launched']],
      ['distance*', 'Dist. from Previous', 140, distanceFormatter],
      ['longitude*'],
      ['latitude*'],
      ['chainage(Distance)'],
      ['elevation*', 'Elevation', 140, distanceFormatter],
      ['inclination*', 'Inclination', 140, inclinationFormatter],
      ['input_type*', 'Input', 50, inputTypeFormatter, ['trigger_set']],
      ['triggers*', 'Triggers', 64, triggersFormatter, ['trigger_set', 'triggers_seen']],
    ],
    getEvents(auth.user, auth.token, handleSelectPoint, props.setTriggerPoint, setError),
  );

  return (
    <SheetContainer>
      <Table
        data={data}
        grid={inspectionContext.state.grid}
        columns={columns}
        onLoad={(grid) => {
          inspectionContext.dispatch({
            type: 'SET_GRID',
            data: grid,
          });
        }}
        options={{
          frozenColumn: getFrozenColumn(),
          editable: props.permissionType === 'editor',
        }}
      />
    </SheetContainer>
  );
};

TrackingSheet.defaultProps = {
  distanceUnit: 'ft',
  speedUnit: 'mph',
  timezone: moment?.tz?.guess(),
};

export default TrackingSheet;
