import { useEffect } from 'react';
import { PipelineType } from '../../../../../interfaces/inspection.interfaces';
import { centerMap, init } from './pipeline.controller';

interface Props {
  pipeline: PipelineType;
  map: google.maps.Map;
}

const Pipeline = ({ pipeline, map }: Props) => {

  // load modules
  useEffect(() => {
    if (pipeline) {
      init({ map, pipeline });
      centerMap({ pipeline, map });
    }
  }, [pipeline]);

  return null;
};

export default Pipeline;
