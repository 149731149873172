import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Wrapper } from './input-date.style';

interface Props {
  value: string;
  handleChange: (data: any) => void;
  hasError?: () => string;
  placeholder?: string;
  customStyle?: string;
  field?: string;
  id: string;
  format?: string;
  disabled?: boolean;
  testid?: string;
}

/**
 * Input component
 * @returns
 */
const InputDate = ({
  value,
  handleChange,
  id,
  disabled,
  field,
  hasError,
  placeholder,
  customStyle,
  format,
  testid,
}: Props) => {
  const error = hasError ? hasError() : '';
  return (
    <Wrapper error={error} customStyle={customStyle}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          data-testid={testid}
          disabled={disabled}
          id={id}
          placeholder={placeholder}
          disableToolbar
          variant="inline"
          format={format}
          margin="normal"
          inputVariant="standard"
          value={value ? new Date(`${value}`) : null}
          autoOk={true}
          onChange={() => ''}
          onAccept={(date) =>
            handleChange({
              value: date,
              oldValue: new Date(`${value}`),
              id,
              field,
            })
          }
        />
      </MuiPickersUtilsProvider>
    </Wrapper>
  );
};

InputDate.defaultProps = {
  value: '',
  id: '',
  format: 'yyyy-MM-dd',
  customStyle: '',
  disabled: false,
  placeholder: '',
  handleChange: () => '',
  hasError: () => '',
};

export default InputDate;
