import {
  formatDistance,
  formatMps,
  formatTimezoneDate,
} from '@/lib/formatter';
import { Chart, registerables } from 'chart.js';
import { columnsModel } from './chart-modal.model';
import { SettingsContext, Unit } from 'app/modules/settings/settings.context.d';
import { InspectionContext } from 'app/modules/inspection/inspection.context.d';
import { Column, TableProps } from 'app/components/table/table.interfaces';
import { TableContentInterface } from './chart-modal.d';

const getSpeedCanvas = (tableContent: TableContentInterface[], speedUnit?: Unit) => {
  const speeds = tableContent
    .filter((p) => p.speed)
    .map((p) =>
      formatMps({
          distance: p.speed,
          unit: speedUnit,
          returnNumber: true,
        })
    );
  return speeds;
};

/**
 *
 * @param points
 * @param distanceUnit
 * @returns
 */
const getElevationsCanvas = (tableContent: TableContentInterface[], distanceUnit?: Unit) =>
  tableContent.map((point) =>
    formatDistance({
        distance: point.elevation,
        unit: distanceUnit?.id || '',
        returnNumber: true,
      })
  );

/**
 *
 * @param points
 * @returns
 */
const getInclinationsCanvas = (tableContent: TableContentInterface[]) =>
tableContent.map((point) => point.inclination);

/**
 *
 * @param distanceUnit
 * @param speedUnit
 * @param canvas
 * @param setCanvas
 * @param run
 * @param selectedChart
 * @param runElevations
 * @returns
 */
export const setCanvasPlot = (
  settingsState: SettingsContext,
  canvas: Chart | undefined,
  setCanvas: (chart?: Chart) => void,
  inspectionState: InspectionContext,
  selectedChart: string,
  tableContent: TableContentInterface[]
) => {
  if (!document || !inspectionState.run?.trackingpoint_set) return;
  const element: any = document.getElementById('gt-chart');
  if (!element) return;
  const ctx = element?.getContext('2d');
  if (canvas?.destroy) {
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    canvas.destroy();
  }


  Chart.register(...registerables);

  const distancesLabels = inspectionState.run.trackingpoint_set.map((id: number) => {
    const point = inspectionState.trackingpoints_dic[id];
    return `${point?.name?.slice(0, 10)}`
  })

  const speeds = getSpeedCanvas(tableContent, settingsState.speedUnit);

  const elevations = getElevationsCanvas(tableContent, settingsState.distanceUnit);
  const inclinations = getInclinationsCanvas(tableContent);
  const datasets: any[] = [];

  if (selectedChart === 'speed') {
    datasets.push({
      label: `Speed (${settingsState.speedUnit?.label})`,
      backgroundColor: 'rgb(255, 99, 132)',
      borderColor: 'rgb(255, 99, 132)',
      data: [...speeds],
      tension: 0.1,
      tick: {},
    });
  }

  if (selectedChart === 'elevation') {
    datasets.push({
      label: `Elevations (${settingsState.distanceUnit?.label})`,
      backgroundColor: 'rgb(0, 99, 132)',
      borderColor: 'rgb(0, 99, 132)',
      data: [...elevations],
    });
  }

  if (selectedChart === 'inclination') {
    datasets.push({
      label: `Inclination (°)`,
      backgroundColor: 'rgb(127, 255, 0)',
      borderColor: 'rgb(127, 255, 0)',
      data: [...inclinations],
      animation: {
        duration: 0,
      },
    });
  }

  const myChart = new Chart(ctx, {
    type: 'line',
    data: {
      labels: [...distancesLabels],
      datasets,
    },
    options: {
      scales: {
        y: {
          beginAtZero: selectedChart === 'speed',
        },
      },
    },
  }) as Chart;

  setCanvas(myChart);
};

/**
 *
 * @param run
 * @param timezone
 * @param distanceUnit
 * @param speedUnit
 * @param setTableContent
 * @param elevations
 * @returns
 */
export const loadTableContent = (
  inspectionState: InspectionContext,
  settingsState: SettingsContext,
  setTableContent: (data: any) => void
) => {
  if (!inspectionState.run) return;
  const trackingpointSet = inspectionState.run?.trackingpoint_set;
  const trackingpointDic = inspectionState.trackingpoints_dic;
  const passageDic = inspectionState.passages_dic;

  const content = trackingpointSet?.map(
    (pId: number, i: number) => {
      const point = trackingpointDic[pId];
      const passage = passageDic[point?.passage as number];
      return ({
        id: point?.id,
        name: point?.name,
        index: i,
        formatedSpeed: formatMps({
          distance: point?.speed,
          unit: settingsState.speedUnit,
        }),
        passage: formatTimezoneDate({
          date: passage?.tstamp || '',
          format: 'll H:mm',
          timezone: settingsState.timezone?.id || '',
        }),
        distance: formatDistance({
          distance: point?.distance,
          unit: settingsState.distanceUnit?.id || '',
        }),
        formatedInclination: `${point?.inclination}°`,
        formatedElevation: formatDistance({
          distance: point?.elevation || '',
          unit: settingsState.distanceUnit?.id || '',
        }),
        location: `${point?.geometry?.coordinates[0].toFixed(
          6
        )}, ${point?.geometry?.coordinates[1].toFixed(6)}`,
        speed: point?.speed,
        elevation: point?.elevation,
        inclination: point?.inclination,
        
    })
  });

  setTableContent(content);
};

/**
 *
 * @param columnsKey
 * @param speedUnit
 * @param distanceUnit
 * @param timezone
 * @param elevations
 * @returns
 */
export const getColumns = (
  columnsKey: string,
  settingsState: SettingsContext,
): TableProps['columns'] => {
  if (
    !settingsState.speedUnit ||
    !settingsState.distanceUnit ||
    !settingsState.timezone
  ) return [];

  const storagedColumns = window.localStorage.getItem(columnsKey);
  const parsedStorageColumns = storagedColumns
    ? JSON.parse(storagedColumns)
    : null;

  const allColumns: Column[] = columnsModel();

  if (parsedStorageColumns) {
    return parsedStorageColumns.map((sc: Column) => ({
      ...allColumns.find((c: Column) => sc.field === c.field),
      ...sc,
    }));
  }

  return allColumns;
};

/**
 *
 * @param run
 * @param estimation
 * @param speedUnit
 * @returns
 */
export const getSpeedValue = (
  inspectionState: InspectionContext,
  speedUnit: Unit
): string => {
  // TODO: define estimation type
  const idsOfPoints = inspectionState.run?.trackingpoint_set || []
  const pointsDic = inspectionState.trackingpoints_dic;
  const points = idsOfPoints.filter((point) => pointsDic[point] ? pointsDic[point].passage : false);

  let currentSpeed = 0;

  if (!inspectionState.run?.launched) {
    return '-';
  }

  if (!inspectionState.run?.is_finished && inspectionState.estimation) {
    currentSpeed = inspectionState.estimation.speed || 0;
  }

  const lastPointId = points[points.length - 1];
  if (inspectionState.run?.is_finished && lastPointId) {
    currentSpeed = pointsDic[lastPointId].speed;
  }

  return formatMps({
    distance: currentSpeed,
    unit: speedUnit,
  });
};

/**
 *
 * @param run
 * @param estimation
 * @param speedUnit
 * @returns
 */
export const getInclinationValue = (
  inspectionState: InspectionContext,
) => {
  const idsOfPoints = inspectionState.run?.trackingpoint_set || []
  const pointsDic = inspectionState.trackingpoints_dic;
  const points = idsOfPoints.filter((point) => pointsDic[point] ? pointsDic[point].passage : false);

  let inclination: number | string = 0;

  if (!inspectionState.run?.launched) {
    return '-';
  }

  // TODO: Why estimation returns a number sometimes, maybe the id not serialized?
  if (
    inspectionState.run?.launched &&
    !inspectionState.run?.is_finished &&
    typeof inspectionState.estimation === 'object' &&
    inspectionState.estimation?.inclination
  ) {
    inclination = inspectionState.estimation.inclination;
    inclination = parseFloat(`${inspectionState.estimation.inclination}`);
    return `${inclination.toFixed(1)}°`;
  }

  const lastPointId = points[points.length - 1];
  if (inspectionState.run?.is_finished && lastPointId) {
    inclination = parseInt(pointsDic[lastPointId].speed)

    return inclination ? `${inclination.toFixed(1)}°` : '-';
  }

  return '-';
};

/**
 *
 * @param run
 * @param estimation
 * @param speedUnit
 * @returns
 */
export const getElevationValue = (
  inspectionState: InspectionContext,
  distanceUnit: Unit
) => {
  const idsOfPoints = inspectionState.run?.trackingpoint_set || []
  const pointsDic = inspectionState.trackingpoints_dic;
  const points = idsOfPoints.filter((point) => pointsDic[point] ? pointsDic[point].passage : false);

  if (!inspectionState.run?.launched) {
    return '-';
  }

  let elevation = 0;

  if (
    inspectionState.run?.launched &&
    !inspectionState.run?.is_finished &&
    inspectionState.estimation
  ) {
    elevation = inspectionState.estimation.elevation || 0;
  }

  const lastPointId = points[points.length - 1];
  if (inspectionState.run?.is_finished && lastPointId) {
    elevation = parseInt(pointsDic[lastPointId].elevation)
  }

  return formatDistance({
    distance: elevation,
    unit: distanceUnit.id,
  });
};
