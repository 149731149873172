import styled from 'styled-components';

export const InputComponent = styled.input<any>`
  padding: 5px 10px;
  border: 1px solid #e5e5e5;
  border-radius: 3px;
  width: 100%;

  &:active {
    padding: 5px 10px;
    border: 1px solid #5850ec;
  }
  &:focus {
    padding: 5px 10px;
    border: 1px solid #5850ec;
  }
  ${({ customStyle }: any) => customStyle}
`;
