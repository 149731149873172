import styled from 'styled-components';

export const Card = styled.div`
  scroll-snap-align: center;

  width: 100%;
  height: 150px;

  background: #ffffff;
  border-radius: 6px;

  box-shadow: 0px 6px 10px 0px #33394E33;
 
  display: grid;
  grid-template-columns: 1fr 48px;
  grid-template-rows: 1fr;

  opacity: ${({ focused }) => (focused ? 1 : 0.3)};
`;

export const CardAddButton = styled.button< { disabled?: boolean }>`
  width: 42px;
  height: 26px;
  background: #5850ec;
  border-radius: 26px;
  padding: 6px 10px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 8px;
  line-height: 10px;

  color: #ffffff;

  ${({ disabled }) => disabled ? 'display: none;' : ''}
`;

export const CardContent = styled.div`
  display: grid;
  align-content: space-between;
  padding: 12px;
`;

export const CardHeader = styled.header`
  display: flex;
  align-items: center;
  gap: 6px;

  border-bottom: 0.6px solid #dcdfe2;
  padding-bottom: 8px;
`;

export const CardMainData = styled.dl`
  display: flex;
  gap: 14px;

  margin: 0;
`;

export const CardMainDataGroup = styled.div`
  display: flex;
  gap: 4px;
`;

export const CardMainDataLabel = styled.dt`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 11px;

  margin: 0 ;
  color: #4B5066;
`;

export const CardMainDataValue = styled.dd`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 9px;
  line-height: 11px;

  margin: 0;

  color: #4B5066;
`;

export const CardMoreButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 28px;
  height: 28px;
  background-color: transparent;
  border: 0.5px solid #4B5066;

  border-radius: 50%;
`;

export const CardPassage = styled.div`
  display: grid;
  grid-template-columns: ${({ isObserver }) => isObserver ? '1fr 1fr' : 'auto 1fr 1fr'};
  gap: 7px;
`;

export const CardPassageInput = styled.div<{ invalid: boolean, isObserver: boolean }>`
  display: flex;

  border: 0.5px solid;
  background-color: ${({ invalid }) => (!invalid ? 'transparent' : 'rgba(237, 32, 36, 0.1)')};
  border-color: ${({ invalid }) => (!invalid ? '#5850ec' : 'rgba(237, 32, 36, 1)')};
  border-radius: 6px;

  padding: 3px;

  align-items: center;
  justify-content: flex-start;

  color: ${({ invalid }) => (!invalid ? '#5850ec' : 'rgba(237, 32, 36, 1)')};

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;

  ${({ isObserver }) => isObserver ? 'border: 0;' : ''}

  > svg {
    margin-right: 8px;

    > * {
      fill: ${({ invalid }) => (!invalid ? '#5850EC' : 'rgba(237, 32, 36, 1)')};
    }
  }

  > input {
    width: 100%;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;

    background-color: transparent;
    color: ${({ invalid }) => (!invalid ? '#5850ec' : 'rgba(237, 32, 36, 1)')};
  }
`;

export const CardSidebar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  background: #DCDFE2;
  
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 9px;
  line-height: 11px;
  
  color: #4B5066
`;

export const CardTrackingPointName = styled.h3`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  color: #4B5066;
  
`;

export const CardTrackingPointTypeIcon = styled.img`
  width: 22px;
  height: 22px;
`;

export const CardDeviceExplanationContainer = styled.div`
  position: relative;
`;

export const CardDeviceExplanationButton = styled.button`
  width: 22px;
  height: 22px;

  background: #dcdfe2;
  border-radius: 50%;
`;

export const CardDeviceInformation = styled.div`
  display: flex;
`;

export const CardDeviceSeeTriggersButton = styled.button<{ highlighted: boolean }>`
  background-color: ${({ highlighted }) => (highlighted ? '#5850ec' : '#dcdfe2')};
  border-radius: 30px;
  
  padding: 6px 15px;
  
  color: ${({ highlighted }) => (highlighted ? '#ffffff' : '#4b5066')};

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 9px;
  line-height: 7px;

  margin-right: 25px;
`;

export const CardDeviceInputType = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  margin-right: 11px;
  
  label {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 11px;

    color: #4b5066;
  }
`;

export const CardDeviceExplanationTooltip = styled.div`
  position: absolute;

  width: 163px; 
  bottom: calc(-100% + 60px);
  right: -11px;
  background-color: #ffffff;
  padding: 8px 16px 16px 16px;
  border-radius: 4px;

  -webkit-filter: drop-shadow(0px 1px 2px rgba(0,0,0,.2));
  filter: drop-shadow(0px 1px 2px rgba(0,0,0,.2));

  span {
    content: '';
    position: absolute;
    top: 98%;
    right: 14px;
    margin-left: -8px;
    width: 0;
    height: 0;
    border-top: 8px solid #fff;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    z-index: 2;
  }
`;

export const CardDeviceExplanationTooltipCloseButton = styled.button`
  background-color: transparent;
  float: right;
  position: relative;
  top: 8px;
`;

export const CardDeviceExplanationTooltipContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  width: 100%;

  > p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    text-align: center;

    color: #656978;
  }

  > figure {
    margin-top: -8px;
    margin-bottom: 4px;
  }
`; 
