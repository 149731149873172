import InspectionStore from 'app/modules/inspection/inspection.context';
import { TimePinType } from 'app/modules/inspection/inspection.interfaces.d';
import { useContext, useEffect, useState } from 'react';
import { changeMarker, renderMarker } from './time-pin-marker.controller';

interface Props {
  timePin: TimePinType;
  map: google.maps.Map;
  hasLabels: boolean;
}

const TimePinMarker = ({ timePin, map, hasLabels }: Props) => {
  const inspectionContext = useContext(InspectionStore);
  const [marker, setMarker] = useState<google.maps.Marker | undefined>();

  useEffect(() => {
    renderMarker(inspectionContext, timePin, map, setMarker, marker);
    return () => {
      marker?.setMap(null);
    };
  }, [])

  useEffect(() => {
    changeMarker({ marker, timePin, map, hasLabels })
  }, [timePin, hasLabels])

  return null;
};

export default TimePinMarker;