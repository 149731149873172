import { formatDistance, formatMps } from "@/lib/formatter";
import { Column } from "app/components/table/table.interfaces";
import { Timezone, Unit } from "app/store/store.interfaces";
import { Chart, registerables } from 'chart.js';
import { columnsModel } from "./mobile-widgets-modal.model";
import { SettingsContext } from "app/modules/settings/settings.context.d";
import { PassageType, RunType, TrackingPointPlainType } from "app/modules/inspection/inspection.interfaces";
import { InspectionContext } from "app/modules/inspection/inspection.context.d";


export const getWeatherText = (
  forecast: RunType['forecast'] | undefined,
  weatherUnit: SettingsContext['weatherUnit'] | undefined,
) => {
  if (forecast && weatherUnit) {
    const fc = parseInt(
      weatherUnit?.id === 'farenheit'
      ? `${forecast.feelslikeF}`
      : `${forecast.feelslikeC}`,
      10
    );
    
    const unitText = weatherUnit.id === 'farenheit' ? 'ºF' : 'ºC'
    return `${fc} ${unitText}`;
  }
  return '---'
}

export const getInclination = (
  isFinished: boolean,
  launched: boolean,
  inclination: number | undefined | null,
  lastPoint?: TrackingPointPlainType
) => {
  if (launched && isFinished && lastPoint?.inclination) {
    const data = +lastPoint.inclination;
    return `${data.toFixed(1)}º`;
  }

  if (launched && !isFinished && inclination) {
    const data = +inclination;
    return `${data.toFixed(1)}º`;
  }

  return '---';
};

const getSpeedCanvas = (points: TrackingPointPlainType[], speedUnit: Unit, predictedLaunchSpeed: number) => {
  const speeds = points
    .map((p, index: number) =>
      p.speed
        ? formatMps({
            distance: index === 0 ? predictedLaunchSpeed : p.speed,
            unit: speedUnit,
            returnNumber: true,
          })
        : 0
    );
  return speeds;
};

/**
 *
 * @param points
 * @param distanceUnit
 * @returns
 */
const getElevationsCanvas = (
  pointIds: number[],
  pointsDic: InspectionContext['trackingpoints_dic'],
  distanceUnit: Unit,
) =>
  pointIds.map((pId) =>
  pointsDic[pId].elevation
      ? formatDistance({
          distance: pointsDic[pId].elevation,
          unit: distanceUnit.id,
          returnNumber: true,
        })
      : 0
  );

/**
 *
 * @param points
 * @returns
 */
const getInclinationsCanvas = (
  points: number[],
  pointsDic: InspectionContext['trackingpoints_dic'],
) =>
  points.map((pId) => pointsDic[pId].inclination);

/**
 *
 * @param distanceUnit
 * @param speedUnit
 * @param canvas
 * @param setCanvas
 * @param run
 * @param selectedChart
 * @param runElevations
 * @returns
 */
export const setCanvasPlot = (
  settingsState: SettingsContext,
  inspectionState: InspectionContext,
  canvas: Chart | undefined,
  setCanvas: (chart?: Chart) => void,
  selectedChart: string,
) => {
  if (
      !document ||
      !inspectionState.run?.trackingpoint_set ||
      !settingsState.speedUnit ||
      !settingsState.distanceUnit
    ) return;
  if (canvas?.destroy) canvas.destroy();

  const element: any = document.getElementById('gt-chart');
  if (!element) return;
  const ctx = element?.getContext('2d');

  Chart.register(...registerables);

  const pointsWithPassage = inspectionState.run?.trackingpoint_set
    .filter((pId: number) => {
      const point = inspectionState.trackingpoints_dic[pId];
      return !!point.passage;
    })
    .map((pId: number) => {
      return inspectionState.trackingpoints_dic[pId];
    });

  let distances;
  if (selectedChart === 'speed') {
    distances = pointsWithPassage.map((point: TrackingPointPlainType) => {
      if (point.name) return `${point.name.slice(0, 10)}`;
      else return '';
    });
  } else {
    distances = inspectionState.run?.trackingpoint_set.map((pId: number) => {
      const point = inspectionState.trackingpoints_dic[pId];
      if (point.name) return `${point.name.slice(0, 10)}`;
      else return '';
    });
  }

  const speeds = getSpeedCanvas(
    pointsWithPassage,
    settingsState.speedUnit,
    inspectionState.run.predicted_launch_speed || 0
  );

  const elevations = getElevationsCanvas(
    inspectionState.run?.trackingpoint_set,
    inspectionState.trackingpoints_dic,
    settingsState.distanceUnit
  );

  const inclinations = getInclinationsCanvas(
    inspectionState.run.trackingpoint_set,
    inspectionState.trackingpoints_dic,
  );
  const datasets: any[] = [];

  if (selectedChart === 'speed') {
    datasets.push({
      label: `Speed (${settingsState.speedUnit?.label})`,
      backgroundColor: 'rgb(255, 99, 132)',
      borderColor: 'rgb(255, 99, 132)',
      data: [...speeds],
      tension: 0.1,
      tick: {},
    });
  }

  if (selectedChart === 'elevation') {
    datasets.push({
      label: `Elevations (${settingsState.distanceUnit?.label})`,
      backgroundColor: 'rgb(0, 99, 132)',
      borderColor: 'rgb(0, 99, 132)',
      data: [...elevations],
    });
  }

  if (selectedChart === 'inclination') {
    datasets.push({
      label: `Inclination (°)`,
      backgroundColor: 'rgb(127, 255, 0)',
      borderColor: 'rgb(127, 255, 0)',
      data: [...inclinations],
      animation: {
        duration: 0,
      },
    });
  }

  const myChart = new Chart(ctx, {
    type: 'line',
    data: {
      labels: [...distances],
      datasets,
    },
    options: {
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        y: {
          beginAtZero: selectedChart === 'speed',
        },
        x: {
          display: false,
        },
      },
    },
  }) as Chart;

  setCanvas(myChart);
};

const mapPointToTable = (
  point: TrackingPointPlainType,
  passage: PassageType,
  index: number,
) => ({
  id: point.id,
  name: point.name,
  index: index,
  speed: point.speed,
  passage: passage?.tstamp,
  distance: point.distance,
  inclination: `${point.inclination}°`,
  elevation: point.elevation,
  geometry: point.geometry,
})

/**
 *
 * @param run
 * @param timezone
 * @param distanceUnit
 * @param speedUnit
 * @param setTableContent
 * @param elevations
 * @returns
 */
export const loadTableContent = (
  settingsState: SettingsContext,
  inspectionState: InspectionContext,
  setTableContent: (data: any) => void
) => {
  if (!inspectionState.run?.trackingpoint_set) return;
  const pointSet = [ ...inspectionState.run.trackingpoint_set ];
  const pointDic = { ...inspectionState.trackingpoints_dic };

  const content = pointSet.map((pId: number, i: number) => {
    const passageId = inspectionState.tp_to_passages_dic[pId];
    const passage = inspectionState.passages_dic[passageId];
    return mapPointToTable(pointDic[pId], passage, i);
  });

  setTableContent(content);
};

export const getColumns = (
  columnsKey: string,
  speedUnit: Unit | undefined,
  distanceUnit: Unit | undefined,
  timezone: Timezone | undefined
): Column[] => {
  if (!speedUnit || !distanceUnit || !timezone) return [];

  const storagedColumns = /* window.localStorage.getItem(columnsKey); */ null;
  const parsedStorageColumns = storagedColumns
    ? JSON.parse(storagedColumns)
    : null;

  const allColumns: Column[] = columnsModel({
    speedUnit: speedUnit,
    timezone: timezone,
    distanceUnit: distanceUnit,
  });

  if (parsedStorageColumns) {
    return parsedStorageColumns.map((sc: Column) => ({
      ...allColumns.find((c: Column) => sc.field === c.field),
      ...sc,
    }));
  }

  return allColumns;
};
