import styled from 'styled-components';

export const QubeModalContainer = styled.div<{ geolocked: boolean }>`
  position: absolute;
  z-index: 10;
  top: 103px;
  right: ${({ geolocked }) => geolocked ? '370px' : '10px'};

  width: 284px;

  background-color: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;

  padding: 16px;
`;

export const QubeModalHeader = styled.header`
  display: flex;
  align-items: center;
  gap: 8px;

  > figure > img {
    width: 28px;
  }

  border-bottom: 1px solid #dcdfe2;

  padding-bottom: 16px;
  margin-bottom: 16px;
`;

export const QubeModalQubeTitle = styled.h2`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  color: #4b5066;
`;

export const QubeModalQubeInformations = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;;
`; 

export const QubeInfoList = styled.dl`
  display: flex;
  flex-direction: column;

  gap: 8px;
  margin: 0;
`;

export const QubeInfo = styled.div``;

export const QubeInfoLabel = styled.dt`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.2em;
  text-transform: uppercase;

  color: rgba(105, 115, 134, 0.75);

  margin-bottom: 2px;
`;
export const QubeInfoValue = styled.dd`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;

  color: #1a1f36;

  margin: 0;
`;

export const QubePropertiesList = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;

  gap: 8px;
`;
export const QubeProperty = styled.li`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const QubePropertyStatus = styled.span<{ checked: boolean }>`
  width: 16px;
  height: 16px;

  border-radius: 50%;
  background-color: ${({ checked }) => (checked ? '#3ec182' : '#767986')};
`;

export const QubePropertyLabel = styled.label`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 16px;

  color: #1a1f36;
`;
