import { useEffect, useState } from 'react';
import Breadcrumbs from '../../components/breadcrumbs/breadcrumbs';
import SearchInput from '../../components/search-input/search-input';
import Tabs from '../../components/tabs/tabs';
import { QubeType } from '../../interfaces/inspection.interfaces';
import Card from '../../components/devices/device-card/device-card';
import DeviceCarousel from '../../components/devices/device-carrousel/device-carousel';
import { QubeDict } from './devices.d';
import {
  Actions,
  Content,
  DeviceList,
  DevicesPage,
  PageHeader,
  PageTitle,
  TabsWrapper,
} from './devices.style';

interface Props {
  qubes: QubeType[];
}

/**
 *
 */
const Devices = (props: Props) => {
  const [openedDevice, setOpenedDevice] = useState<QubeType>();
  const [search, setSearch] = useState<string>('');
  const [devices, setDevices] = useState<QubeDict>({});

  const breadcrumbs = [
    {
      id: '#1',
      text: 'Devices',
      href: '/devices',
      loading: false,
    },
  ];

  const tabs = [
    {
      id: 'all',
      text: `All`,
      quantity: props.qubes.length || 0,
      href: '',
    },
  ];

  /**
   * render device card list
   */
  const renderDevices = () =>
    props.qubes.map((device, i) => {
      return device ? <Card key={i} device={device} onClick={setOpenedDevice} /> : null;
    });

  useEffect(() => {
    const nDevices = {};
    for (const q of props.qubes) {
      if (q) nDevices[q.serial] = q;
    }
    setDevices(nDevices);
  }, [props.qubes]);

  return (
    <DevicesPage>
      <Breadcrumbs
        customStyle={`
          position: sticky;
          top: 0;
          left: 0;
          width: 100%;

          @media (min-width: 1024px) {
            position: relative;
          }
        `}
        routes={breadcrumbs}
      />
      <PageHeader>
        <PageTitle>Devices (Pig Assets)</PageTitle>
        <Actions>
          <SearchInput
            customStyle={`
                max-width: 500px;
              `}
            value={search}
            placeholder="Search"
            onChange={({ target }) => setSearch(target.value)}
          />
          {/* <ViewModeButton type={viewType} onViewModeChange={setViewType} /> */}
        </Actions>
      </PageHeader>

      <TabsWrapper>
        <Tabs routes={tabs} selected={tabs[0]} />
      </TabsWrapper>

      <Content>
        <DeviceList>{renderDevices()}</DeviceList>
      </Content>

      <DeviceCarousel
        devices={devices}
        changeSelectedDevice={setOpenedDevice}
        selected={openedDevice}
        onClose={() => setOpenedDevice(undefined)}
      />
    </DevicesPage>
  );
};

export default Devices;
