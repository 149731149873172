import { useMemo, useState, useContext, useEffect } from 'react';
import {
  distanceUnitOptions,
  speedUnitOptions,
  weatherUnitOptions,
  widgetOptions,
} from './over-run-header.models';
import {
  ConnectionErrorLabel,
  ConnectionErrorLabelTimezone,
  Header,
  RunName,
  DocumentationButton,
  ObserverButton,
  downloadCustomStyle,
} from './overlay-run-header.style';
import SettingsStore from 'app/modules/settings/settings.context';
import InspectionStore from 'app/modules/inspection/inspection.context';
import { handleMeasurementUnits } from './overlay-run-header.controller';
import { WeatherOption } from 'app/components/common/map/map.d';
import { weatherImperialOptions, weatherMetricOptions } from 'app/components/common/map/map.model';
import Skeleton from 'app/components/skeleton/skeleton';
import ScheduledOnButton from '../scheduled-on-button/scheduledOnButton';
import HelpButtonLegend from 'app/components/help-button-icons-legend/helpButtonIconsLegend';
import DropdownButton from 'app/components/dropdown-button/dropdown-button';
import { formatTimezoneDate } from '@/lib/formatter';
import { exportxlxs } from '../../exports/run-xlxs-exporter';
import { exportcsv } from '../../exports/run-csv-exporter';

/**
 *
 * @param param
 * @returns
 */
const OverlayHeader = ({
  checkedTiles,
  setCheckedTiles,
  ScheduleSubmit,
  launchRunAction,
  abortRunAction,
  finishRunAction,
  widgets,
  setWidgets,
  hideScheduleButton,
  hideName,
  hideObserverViewButton,
  hideWeather,
  hideHelp,
  hidden,
  permissionType,
  hideTimezone,
  showEventsButton,
  onToggleOpenEventModal,
  id,
  setObserverMode,
  observerMode,
}: any) => {
  const [showMenu, setShowMenu] = useState<string>('');
  const [checkedColumns, setCheckedColumns] = useState<string[]>([]);
  const [columns, setColumns] = useState<{id: string, label: string, checked: boolean }[]>([]);
  const [allColumns, setAllColumns] = useState<any[]>([]);
  const [showDoaloadMethods, setShowDoaloadMethods] = useState<boolean>(false);
  const settingsContext = useContext(SettingsStore);
  const inspectionContext = useContext(InspectionStore);
  const selectedTimezone = useMemo(() => {
    return settingsContext.state.timezoneList?.find((tzone: any) => 
      settingsContext.state.timezone?.id === tzone.id);
  }, [settingsContext.state.timezone?.id, settingsContext.state.timezoneList]);
  

  useEffect(() => {
    const checkedColumnsN: string[] = []
    const defaultColumns = inspectionContext.state.grid?.getColumns() || [];
    setAllColumns(defaultColumns);
    defaultColumns.map((c) => {
      const field = c.field as unknown as string;
      field && checkedColumnsN.push(field);
    })
    setCheckedColumns(checkedColumnsN)
  }, [inspectionContext.state.grid]);
  useEffect(() => {
    setColumns(allColumns.map((option: any) => ({
          id: option.field,
          label: option.name,
          checked:
          checkedColumns?.findIndex((id: string) => id === option.field) !== -1,
      })
    ));
    inspectionContext.state.grid?.setColumns(allColumns.filter((c) => {
      return (checkedColumns?.findIndex((id: string) => id === c.field)) !== -1;
    }));
  }, [checkedColumns]);
  // Todo: No connection feature
  // useEffect(() => {
  //   if (!window.navigator.onLine) {
  //     dispatch({
  //       type: 'NO_CONNECTION',
  //       data: new Date(),
  //     });
  //   }

  //   window.addEventListener('offline', () => {
  //     dispatch({
  //       type: 'NO_CONNECTION',
  //       data: new Date(),
  //     });
  //   });

  //   window.addEventListener('online', () => {
  //     dispatch({
  //       type: 'NO_CONNECTION',
  //       data: null,
  //     });
  //   });
  //   return () => {
  //     window.removeEventListener('offline', () => '');
  //     window.removeEventListener('online', () => '');
  //   };
  // }, []);

  const weatherOptions: WeatherOption[] =
  settingsContext.state.weatherUnit?.id === 'farenheit'
      ? weatherImperialOptions
      : weatherMetricOptions;

  return (
    <Header hidden={hidden}>
      <div>
        <Skeleton
          hidden={!!hideName}
          loading={inspectionContext.state.run?.id !== id}
          width="120px"
          height="16px"
          inline
        >
          <RunName>{inspectionContext.state.run?.name}</RunName>
        </Skeleton>
        <ScheduledOnButton
          hidden={hideScheduleButton}
          permissionType={permissionType}
          abortAction={abortRunAction}
          finishAction={finishRunAction}
          speedUnit={settingsContext.state.speedUnit?.id || ''}
          submit={ScheduleSubmit}
          launchAction={launchRunAction}
        />
        <ConnectionErrorLabel
          show={
            !!inspectionContext.state.no_connection_since &&
            inspectionContext.state.run?.id === id
          }
        >
          {`Connection lost at ${formatTimezoneDate({
            date: inspectionContext.state.no_connection_since || '',
            timezone: settingsContext.state.timezone?.id,
            format: 'HH:mm',
           })}`}
          <ConnectionErrorLabelTimezone>
            {`${settingsContext.state.timezone?.label} time.`}
          </ConnectionErrorLabelTimezone>
        </ConnectionErrorLabel>
      </div>
      <div>
        <DocumentationButton
          title="Documentation"
          onClick={() => {
            window.open('/docs/', '__blank');
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            enableBackground="new 0 0 24 24"
            height="16px"
            viewBox="0 0 24 24"
            width="16px"
            fill="#ffffff"
            opacity={0.8}
          >
            <g>
              <rect fill="none" height="24" width="24" />
            </g>
            <g>
              <g />
              <g>
                <path d="M21,5c-1.11-0.35-2.33-0.5-3.5-0.5c-1.95,0-4.05,0.4-5.5,1.5c-1.45-1.1-3.55-1.5-5.5-1.5S2.45,4.9,1,6v14.65 c0,0.25,0.25,0.5,0.5,0.5c0.1,0,0.15-0.05,0.25-0.05C3.1,20.45,5.05,20,6.5,20c1.95,0,4.05,0.4,5.5,1.5c1.35-0.85,3.8-1.5,5.5-1.5 c1.65,0,3.35,0.3,4.75,1.05c0.1,0.05,0.15,0.05,0.25,0.05c0.25,0,0.5-0.25,0.5-0.5V6C22.4,5.55,21.75,5.25,21,5z M21,18.5 c-1.1-0.35-2.3-0.5-3.5-0.5c-1.7,0-4.15,0.65-5.5,1.5V8c1.35-0.85,3.8-1.5,5.5-1.5c1.2,0,2.4,0.15,3.5,0.5V18.5z" />
                <g>
                  <path d="M17.5,10.5c0.88,0,1.73,0.09,2.5,0.26V9.24C19.21,9.09,18.36,9,17.5,9c-1.7,0-3.24,0.29-4.5,0.83v1.66 C14.13,10.85,15.7,10.5,17.5,10.5z" />
                  <path d="M13,12.49v1.66c1.13-0.64,2.7-0.99,4.5-0.99c0.88,0,1.73,0.09,2.5,0.26V11.9c-0.79-0.15-1.64-0.24-2.5-0.24 C15.8,11.66,14.26,11.96,13,12.49z" />
                  <path d="M17.5,14.33c-1.7,0-3.24,0.29-4.5,0.83v1.66c1.13-0.64,2.7-0.99,4.5-0.99c0.88,0,1.73,0.09,2.5,0.26v-1.52 C19.21,14.41,18.36,14.33,17.5,14.33z" />
                </g>
              </g>
            </g>
          </svg>
        </DocumentationButton>
        <HelpButtonLegend
          title="Legend"
          hidden={hideHelp}
          show={showMenu === 'help'}
          handleShow={() => setShowMenu(showMenu === 'help' ? '' : 'help')}
        />
        <DropdownButton
          title="Download Tracking Sheet"
          customStyle={downloadCustomStyle}
          icon={
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
              <path d="M1 6.69257V11.5321C1 12.0667 1.41328 12.5 1.92308 12.5H12.0769C12.5867 12.5 13 12.0667 13 11.5321V6.69257M4.69231 6.20861L6.34729 7.94397C6.70777 8.32196 7.29223 8.32196 7.65271 7.94397L9.30769 6.20861M7 1.5L7 7.66047" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
            </svg>

          }
          type="label"
          show={showDoaloadMethods}
          handleShow={(showing: boolean) => setShowDoaloadMethods(showing)}
          groups={[[
            {id: 'csv', text: '.csv', index: 0 },
            {id: 'xlsx', text: '.xlsx', index: 1 },
          ]]}
          onClick={(option) => {
            const data = {
              csv: () => exportcsv(inspectionContext.state, settingsContext.state),
              xlsx: () => exportxlxs(inspectionContext.state, settingsContext.state),
            };
            if (data[option.id]) data[option.id]()
          }}
        />
         <DropdownButton
          text="Columns"
          type="checkbox"
          show={showMenu === 'columns'}
          handleShow={(show: boolean) => setShowMenu(show ? 'columns' : '')}
          groups={[columns]}
          onClick={(option: any) => {
            if (option.checked) {
              setCheckedColumns(checkedColumns.filter(c => c !== option.id));
            } else {
              setCheckedColumns([...checkedColumns, option.id]);
            }
          }}
        />
        <DropdownButton
          text={`${settingsContext.state.speedUnit?.label} / ${settingsContext.state.distanceUnit?.label
            } / ${settingsContext.state.weatherUnit?.label?.toLowerCase()}`}
          type="radio"
          show={showMenu === 'unit'}
          handleShow={(show: boolean) => setShowMenu(show ? 'unit' : '')}
          groupTitles={['Speed', 'Distance', 'Temperature']}
          groups={[
            speedUnitOptions.map((option) => ({
              ...option,
              selected: option.id === settingsContext.state.speedUnit?.id,
            })), // 0
            distanceUnitOptions.map((option) => ({
              ...option,
              selected: option.id === settingsContext.state.distanceUnit?.id,
            })), // 1
            weatherUnitOptions.map((option) => ({
              ...option,
              selected: option.id === settingsContext.state.weatherUnit?.id,
            })), // 2
          ]}
          onClick={(option: any, index: number) =>
            handleMeasurementUnits(option, index, settingsContext, setShowMenu)
          }
        />
        <DropdownButton
          text="weather"
          type="radio"
          hideButton={hideWeather}
          show={showMenu === 'overlay'}
          handleShow={(show: boolean) => setShowMenu(show ? 'overlay' : '')}
          groups={[
            weatherOptions
              .sort((o1, o2) => (o1.index > o2.index ? 1 : -1))
              .map((option) => ({
                ...option,
                selected: option.id === checkedTiles?.[0]?.id,
              })),
          ]}
          onClick={(option: any) => {
            if (option.selected) setCheckedTiles([]);
            else setCheckedTiles([option]);

            setShowMenu('');
          }}
        />
        <DropdownButton
          text="widgets"
          type="checkbox"
          hideButton={observerMode || !widgets}
          show={showMenu === 'widgets'}
          handleShow={(show: boolean) => setShowMenu(show ? 'widgets' : '')}
          groups={[
            widgetOptions.map((option: any) => ({
              ...option,
              checked:
                widgets?.findIndex((id: string) => id === option.id) !== -1,
            })),
          ]}
          onClick={(option: any) => {
            let newWidgets = [...widgets];
            const index = newWidgets.findIndex(
              (id: string) => id === option.id
            );

            if (index === -1) {
              newWidgets.push(option.id);
            } else {
              newWidgets.splice(index, 1);
            }

            if (option.id === 'all' && index === -1) {
              newWidgets = widgetOptions.map((opt) => opt.id);
            } else if (option.id === 'all') {
              newWidgets = [];
            }

            if (newWidgets.length !== widgetOptions.length) {
              newWidgets = newWidgets.filter((o) => o !== 'all');
            }

            if (newWidgets.length === widgetOptions.length - 1) {
              newWidgets.push('all');
            }

            setWidgets(newWidgets);
            setShowMenu('');
          }}
        />
        <DropdownButton
          show={showMenu === 'timezone'}
          text={selectedTimezone?.label}
          hideButton={hideTimezone}
          customStyle={'z-index: 35;'}
          type="radio"
          handleShow={(show: boolean) => setShowMenu(show ? 'timezone' : '')}
          groups={[
            settingsContext.state.timezoneList?.map((tzone: any) => ({
              id: tzone?.id,
              label: tzone?.label,
              selected: tzone?.id === selectedTimezone?.id,
            })),
          ]}
          onClick={(option: any) => {
            settingsContext.dispatch({
              type: 'SET_TIMEZONE_UNIT',
              data: option
            });
            setShowMenu('');
          }}
        />
        {!observerMode && showEventsButton ? (
          <DropdownButton
            text={'Events'}
            type="radio"
            show={showMenu === 'unit'}
            handleShow={onToggleOpenEventModal}
            groups={[]}
          />
        ) : null}

        <ObserverButton
          displayNone={hideObserverViewButton}
          onClick={() => {
            if (!observerMode) {
              inspectionContext.dispatch({
                type: 'SET_GRID',
                data: undefined,
              });
            }

            setObserverMode(!observerMode)
          }}
        >
          <svg
            width="18"
            height="11"
            viewBox="0 0 18 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.0552642 4.98303C0.0795333 4.93236 0.111678 4.88497 0.151688 4.84217C3.11485 1.60992 6.12242 -0.0236692 9.108 0.000259098C12.0548 0.0192812 14.9973 1.64993 17.8527 4.84679C17.891 4.88828 17.9216 4.93435 17.9449 4.98306C17.9818 5.06013 18 5.1436 18 5.22657V5.22756V5.22772C17.9998 5.30955 17.982 5.39154 17.9457 5.46762C17.9223 5.51731 17.8909 5.56405 17.8524 5.60635C14.997 8.80288 12.0546 10.4331 9.10809 10.4525C9.09055 10.4525 9.07333 10.4526 9.05578 10.4526C6.09279 10.4526 3.09722 8.82361 0.151519 5.6103C0.111508 5.56766 0.079366 5.51994 0.0550976 5.46943C0.0182015 5.39236 -3.94494e-07 5.30939 -3.94494e-07 5.22625C-3.94494e-07 5.14311 0.0182017 5.05981 0.0552616 4.9829L0.0552642 4.98303ZM9.10058 9.32749C11.6047 9.31125 14.1515 7.932 16.6762 5.2263C14.1513 2.5206 11.6051 1.14132 9.10058 1.12512C9.08549 1.12512 9.07106 1.12496 9.05598 1.12496C6.53507 1.12496 3.93868 2.50406 1.33301 5.22614C3.95425 7.96479 6.56156 9.34915 9.10089 9.32749L9.10058 9.32749ZM9.00006 2.45797C10.5267 2.45797 11.7686 3.6998 11.7686 5.22647C11.7686 6.75314 10.5267 7.99497 9.00006 7.99497C7.47339 7.99497 6.23156 6.75314 6.23156 5.22647C6.23156 3.6998 7.47339 2.45797 9.00006 2.45797ZM9.00006 6.87001C9.9064 6.87001 10.6435 6.1326 10.6435 5.2266C10.6435 4.32026 9.90606 3.58318 9.00006 3.58318C8.09406 3.58318 7.35665 4.3206 7.35665 5.2266C7.35648 6.13276 8.09376 6.87001 9.00006 6.87001Z"
              fill="white"
            />
          </svg>
          {`Go to ${!observerMode ? 'Observer' : 'Editor'} view`}
        </ObserverButton>
      </div>
    </Header>
  );
};

export default OverlayHeader;
