import { ParticipantType } from "app/modules/account/account.interfaces";
import { ProjectsContext } from "../projects.context.d";
import { UserType } from "app/interfaces/account.interfaces";

/**
 *
 * @param state
 * @param participant 
 * @returns 
 */
export const updateParticipant = (state: ProjectsContext, participant: ParticipantType) => {
  const stateCopy = { ...state };
  const participantsDic = { ...stateCopy.participants_dic }
  participantsDic[participant.id] = participant;
  stateCopy.participants_dic = participantsDic;
  return stateCopy;
};

/**
 *
 * @param state
 * @param user
 * @returns
 */
export const updateUser = (state: ProjectsContext, user: UserType) => {
  const stateCopy = { ...state };
  const usersDic = { ...stateCopy.users_dic }
  usersDic[user.id] = user;
  stateCopy.users_dic = usersDic;
  return stateCopy;
};
