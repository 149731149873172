import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  min-height: 100%;

  position: relative;
`;

export const Fieldset = styled.fieldset`
  display: grid;
  gap: 12px;

  padding-bottom: 24px;
  margin-bottom: 16px;

  &:not(:last-child) {
    border-bottom: solid 1px #dfe4ea;
  }

  @media (min-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const Figure = styled.figure`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 68px;
  height: 68px;

  border-radius: 50%;

  overflow: hidden;
  background-color: #f7f8f9;
`;

export const AvatarGroup = styled.div`
  display: flex;
  align-items: center;

  gap: 16px;
`;

export const Avatar = styled.img`
  width: 100%;
  height: 100%;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  gap: 12px;

  border-top: solid 1px #dfe4ea;
  padding-top: 24px;
`;

const slimColors = {
  red: '#f21e2b',
  default: '#161e2e',
};

const slimBorderColors = {
  red: '#f21e2b',
  default: '#def0e4',
};

export const SlimButton = styled.button<{ color?: 'red' | 'default' }>`
  border: solid 1px ${({ color }) => slimBorderColors[color || 'default']};
  color: ${({ color }) => slimColors[color || 'default']};

  background-color: transparent;
  border-radius: 6px;

  padding: 11px 12px;

  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 1em;

  cursor: pointer;
`;

export const CropWrapper = styled.div<{ cropping: boolean }>`
  display: ${({ cropping }) => (cropping ? 'block' : 'none')};
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.8);
`;

export const BackButton = styled.button`
  position: absolute;
  top: 16px;
  left: 16px;

  background-color: transparent;
  z-index: 2;

  cursor: pointer;
`;
