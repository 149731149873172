import InspectionStore from "app/modules/inspection/inspection.context";
import { EstimationType } from "app/modules/inspection/inspection.interfaces";
import { useContext, useEffect, useState } from "react";
import { changeYellowPolyline, changeGreenPolyline, renderYellowPolyline, renderGreenPolyline } from "./segment-polyline.controller";

interface Props {
  estimation: EstimationType;
  map: google.maps.Map;
}

const SegmentPolyline = ({ estimation, map }: Props) => {
  const inspectionContext = useContext(InspectionStore);
  const [green, setGreen] = useState<google.maps.Polyline | undefined>();
  const [yellow, setYellow] = useState<google.maps.Polyline | undefined>();

  useEffect(() => {
    const yellowPoly = renderYellowPolyline(inspectionContext, estimation, map, green);
    const greenPoly = renderGreenPolyline(estimation, map, yellow);
    setYellow(yellowPoly);
    setGreen(greenPoly);

    return () => {
      greenPoly?.setMap(null);
      yellowPoly?.setMap(null);
    };
  }, [])

  useEffect(() => {
    changeYellowPolyline({ polyline: yellow, estimation, map })
    changeGreenPolyline({ polyline: green, estimation, map })
  }, [estimation])

  return null;
};

export default SegmentPolyline;