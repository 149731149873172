import { useState, useContext, useEffect } from 'react';
import moment from 'moment-timezone';
import { SheetContainer } from './survey-sheet.style';
import Table from '../slick-table/slick-table';
import { getColumns } from '../../models/sheet/sheet';
import {
  getEvents,
  getMapBounds,
  refreshGrid,
  parsePoints,
} from './survey-sheet.controller';
import AccountStore from 'app/modules/account/account.context';
import SettingsStore from 'app/modules/settings/settings.context';
import SurveyStore from 'app/modules/survey/survey.context';
import { getCleanSurvey } from '../common/map/modules/survey/survey.dummy';

const SurveyTrackingSheet = ({
  setBounds,
  setGrid,
  grid,
}: any) => {
  const accountContext = useContext(AccountStore);
  const settingsContext = useContext(SettingsStore);
  const surveyContext = useContext(SurveyStore);
  const [columns, setColumns] = useState<Slick.Column<any>[]>([]);
  const [parsedPoints, setParsedPoints] = useState<any[]>([]);
  const handleSelectPoint = ({ point, nextPoint, prevPoint }) => {
    const bounds = getMapBounds(point, nextPoint, prevPoint);
    setBounds(bounds);
    surveyContext.dispatch({
      type: 'SET_SELECTED_POINT',
      data: point?.id
    });
  };

  useEffect(() => {
    if (!accountContext.state.auth?.user) return;
    const nColumns = getColumns(
      {
        speed: settingsContext.state.speedUnit,
        distance: settingsContext.state.distanceUnit,
      },
      true,
      undefined,
      undefined,
      getEvents(surveyContext.dispatch, accountContext.state.auth?.token, handleSelectPoint)
    );
    grid?.setColumns(nColumns);
    setColumns(nColumns);
  }, [accountContext.state.auth?.token, surveyContext.state.selected_point]);

  useEffect(() => {
    return () => {
      surveyContext.dispatch({
        type: 'SET_SURVEY',
        data: getCleanSurvey(),
      });
    }
  }, []);

  useEffect(() => {
    if (grid && parsedPoints.length) refreshGrid(
      parsedPoints,
      grid,
    )
  }, [
    parsedPoints
  ]);
  useEffect(() => {
    parsePoints(
      surveyContext.state,
      settingsContext.state,
      false,
      setParsedPoints
    );
  }, [
    settingsContext.state.speedUnit,
    settingsContext.state.distanceUnit,
    surveyContext.state.points_dic,
    surveyContext.state.survey?.surveypoint_set
  ]);

  return (
    <SheetContainer>
      {parsedPoints && parsedPoints.length ? (
        <Table
          grid={grid}
          columns={columns}
          data={parsedPoints}
          onLoad={setGrid}
        />
      ) : null}
    </SheetContainer>
  );
};

SurveyTrackingSheet.defaultProps = {
  distanceUnit: 'ft',
  speedUnit: 'mph',
  timezone: moment?.tz?.guess(),
};

export default SurveyTrackingSheet;
