import styled from 'styled-components';

export const Button = styled.button`
  text-align: center;
  width: 80px;
  border: 1px solid #444444;

  padding: 3px;
  font-size: 12px;
  border-radius: 3px;
  float: right;





`;
