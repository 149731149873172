import { ReactNode, useContext, useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import Map from 'app/components/common/map/map';
import { useParams } from 'react-router-dom';
import AccountStore from 'app/modules/account/account.context';
import InspectionStore from 'app/modules/inspection/inspection.context';
import NotificationStore from 'app/modules/notification/notification.context';
import SettingsStore from 'app/modules/settings/settings.context';
import { AuthType } from 'app/modules/account/account.context.d';
import { QubeType } from 'app/interfaces/inspection.interfaces';
import { TrackingPointPlainType } from 'app/modules/inspection/inspection.interfaces';
import ConfirmationLaunchModal from 'app/components/confirmation-launch-modal/confirmation-modal';
import Skeleton from 'app/components/skeleton/skeleton';
import TrackingPointMedias from 'app/components/tracking-point-medias/tracking-point-media';
import MobileObserverView from '../../components/mobile-observer-view/mobile-observer-view';
import TrackingPointCard from '../../components/tracking-point-card/tracking-point-card';
import TrackingPointCardWithDevice from '../../components/tracking-point-card-with-device/tracking-point-card-with-device';
import TrackingPointModal from '../../components/tracking-point-modal/tracking-point-modal';
import TrackingPointPassageModal from '../../components/tracking-point-passage-modal/tracking-point-passage-modal';
import MobileRunStatus from '../../components/mobile-run-status/mobile-run-status';
import MobileScheduleModal from '../../components/mobile-schedule-modal/mobile-schedule-modal';
import MobileConfigurationButton from '../../components/mobile-configuration-button/mobile-configuration-button';
import MobileUnitsModal from '../../components/mobile-units-modal/mobile-units-modal';
import MobileTimezoneModal from '../../components/mobile-timezone-modal/mobile-timezone-modal';
import MobileWidgetsButton from '../../components/mobile-widgets-button/mobile-widgets-button';
import MobileInformationButton from '../../components/mobile-information-button/mobile-information-button';
import {
  getMapBounds,
  handleHold,
  handlePassageChange,
  handlePassageDelete,
  updateIcon,
  updateMapBounds,
} from './mobile.controller';
import {
  abortRunAction,
  finishRunAction,
  launchRun,
  updateScheduledRun,
} from '../../run.controller';
import {
  RunMobileContainer,
  MapContainer,
  Content,
  CollapseButtonWrapper,
  CollapseButton,
  LaunchButton,
  TrackingPointList,
  ViewTab,
  ViewTabs,
  Views,
  View,
} from './mobile.style';
import MobileInformationModal from '../../components/mobile-information-modal/mobile-information-modal';
import MobileWidgetsModal from '../../components/mobile-widgets-modal/mobile-widgets-modal';
import ConfirmationFinishModal, { Props as ConfirmationFinishModalProps } from 'app/components/confirmation-finish-modal/confirmation-finish-modal';
import { Bound } from 'app/components/common/map/map.d';
import { uploadMedia } from '../../components/tracking-point-overlay/tracking-point-overlay.controller';

interface Props {
  observerMode: boolean;
  setObserverMode: (observerMode: boolean) => void;
  permissionType?: string;
}

/**
 *
 * @param props
 * @returns
 */
const Mobile = (props: Props) => {
  const { id }: { id: string } = useParams();
  const settingsContext = useContext(SettingsStore);
  const notificationContext = useContext(NotificationStore);
  const inspectionContext = useContext(InspectionStore);
  const accountContext = useContext(AccountStore);
  const auth = accountContext.state.auth as AuthType;
  const [fullscreen, setFullscreen] = useState<boolean>(true); // if the map is fullcreen and the list is collapsed
  const [launchConfirmation, setLaunchConfirmation] = useState<any>();
  const [launchLoading, setLaunchLoading] = useState<boolean>(false);
  const [loadded, setLoadded] = useState<boolean>(false);
  const [finishConfirmation, setFinishConfirmation] = useState<ConfirmationFinishModalProps>({});
  const [selectedTrackingPoint, setSelectedTrackingPoint] =
    useState<TrackingPointPlainType>(); // tracking point selected to add new passage
  const [selectionTstamp, setSelectionTstamp] = useState<number>(); // tstamp of was selected a tracking point to add new passage
  const [isShowGallery, setIsShowGallery] = useState<boolean>(false);
  const [isOpenedScheduleModal, setIsOpenedScheduleModal] =
    useState<boolean>(false);
  const [isOpenedUnitsModal, setIsOpenedUnitsModal] = useState<boolean>(false);
  const [isOpenedTriggers, setIsOpenedTriggers] = useState<boolean>(false);
  const [isOpenedTimezoneModal, setIsOpenedTimezoneModal] =
    useState<boolean>(false);
  const [isOpenedInformationModal, setIsOpenedInformationModal] =
    useState<boolean>(false);
  const [isOpenedWidgetsModal, setIsOpenedWidgetsModal] =
    useState<boolean>(false);
  const [selectedGalleryMedia, setSelectedGalleryMedia] = useState<number>();
  const [activeTab, setActiveTab] = useState<'editor' | 'observer'>('editor');
  const [focusedCard, setFocusedCard] = useState<number>(0);
  const [bounds, setBounds] = useState<Bound[]>();
  const [qube, setQube] = useState<QubeType>();
  const [zoom, setZoom] = useState<number>(6);
  const listRef = useRef<HTMLElement>(null);

  /**
   *
   */
  const scrollToCard = (point: TrackingPointPlainType, delay = 0) => {
    // this set timeout prevent that the query selector wait list card render
    setTimeout(() => {
      // scroll list to focused card
      const item = document.querySelector(`#tp-list-id-${point?.id}`);
      if (item) item.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, delay);
  };

  useEffect(() => {
    inspectionContext.dispatch({
      type: 'SET_IS_FOLLOWING_PIG',
      data: true,
    })
  }, []);

  useEffect(() => {
    if (selectedTrackingPoint) {
      const tpoint = inspectionContext.state.trackingpoints_dic[selectedTrackingPoint.id];
      setSelectedTrackingPoint(tpoint);
    }
  }, [inspectionContext.state.run]);

  useEffect(() => {
    if (inspectionContext.state.run?.launched) {
      setFullscreen(false);
      setLaunchLoading(false);
    }
  }, [inspectionContext.state.run?.launched]);

  useEffect(() => {
    const point = inspectionContext.state.trackingpoints_dic[`${inspectionContext.state.run?.last_point_with_passage}`];
    const nextPoint = inspectionContext.state.trackingpoints_dic[point?.next_point_actived];
    const dicLength = Object.keys(inspectionContext.state.trackingpoints_dic).length;
    const setLength = inspectionContext.state.run?.trackingpoint_set?.length;
    
    if (nextPoint && inspectionContext.state.run?.launched && setLength && dicLength === setLength && !loadded) {
      setFocusedCard(nextPoint.index || 0);

      // scroll list to focused card
      scrollToCard(nextPoint, 1);
      
      const animationDuration = 1000;
      setTimeout(() => {
        if (!inspectionContext.state.following_pig) {
          updateMapBounds(getMapBounds(nextPoint), setBounds);
          setZoom(16);
        }

        setLoadded(true);
      }, animationDuration);
    }
  }, [inspectionContext.state.trackingpoints_dic]);

  useEffect(() => {
    const points = inspectionContext.state.trackingpoints_dic;
    if (selectedTrackingPoint && points[selectedTrackingPoint?.id]) {
      setSelectedTrackingPoint(points[`${selectedTrackingPoint?.id}`]);
    }
  }, [inspectionContext.state.trackingpoints_dic[`${selectedTrackingPoint?.id}`]]);

  useEffect(() => {
    if (inspectionContext.state.run?.last_point_with_passage) {
      const points = inspectionContext.state.trackingpoint_set;
      const pointsDic = inspectionContext.state.trackingpoints_dic;
      const passagePointId = inspectionContext.state.run?.last_point_with_passage;
      const passagePoint = pointsDic[passagePointId];

      let focusedCardPoint: TrackingPointPlainType | undefined = undefined;
      if (passagePoint?.index) {
        const focusedCardPointIndex = points[passagePoint.index + 1];
        focusedCardPoint = pointsDic[focusedCardPointIndex];
      }

      setTimeout(() => {
        const focused = focusedCardPoint?.index || passagePoint?.index;
        if (focused && focusedCardPoint) {
          setFocusedCard(focused);
          scrollToCard(focusedCardPoint);
        }
      }, 5000);
    }
  }, [inspectionContext.state.run?.last_point_with_passage]);

  useEffect(() => {
    if (inspectionContext.state.selected_point) {
      const selected = inspectionContext.state.selected_point;
      const point = inspectionContext.state.trackingpoints_dic[selected]
      
      setFocusedCard(point.index || 0);
      scrollToCard(point);
    }
  }, [inspectionContext.state.selected_point]);

  useEffect(() => {
    if (props.observerMode) setActiveTab('observer');
  }, [props.observerMode]);

  /**
   *
   * @returns
   */
  const renderTrackingPoints = () => {
    if (!inspectionContext.state.run?.trackingpoint_set) {
      // create skeletons to loading
      const skeletons: ReactNode[] = [];
      for (let skeletonIndex = 0; skeletonIndex <= 10; skeletonIndex += 1) {
        skeletons.push(<Skeleton key={skeletonIndex} loading height="115px" />);
      }

      return skeletons;
    }

    const tpoints = inspectionContext.state.run?.trackingpoint_set || [];
    const usingDevice = inspectionContext.state.project?.using_linestat || inspectionContext.state.project?.using_qube;
    const Card = usingDevice ? TrackingPointCardWithDevice : TrackingPointCard;

    return tpoints.map((tpoint, index) => inspectionContext.state.trackingpoints_dic[tpoint] ? (
      <Card
        trackingpoint={inspectionContext.state.trackingpoints_dic[tpoint]}
        permissionType={props.permissionType}
        key={tpoint}
        focused={index === focusedCard}
        onAddClick={() => {
          const point = inspectionContext.state.trackingpoints_dic[tpoint];
          setSelectionTstamp(Date.now());
          setSelectedTrackingPoint(point);
          inspectionContext.dispatch({
            type: 'SET_IS_FOLLOWING_PIG',
            data: false,
          })
        }}
        onMoreClick={(triggers?: boolean) => {
          const point = inspectionContext.state.trackingpoints_dic[tpoint];
          setIsOpenedTriggers(!!triggers);
          setSelectedTrackingPoint(point);

          if (point.geolocks && inspectionContext.state.run?.qube_set) {
            const qube = inspectionContext.state.qubes_dic[point?.geolocks[0]];
            if (qube) setQube(qube as QubeType);
          }
        }}
        onPassageDelete={() => {
          inspectionContext.dispatch({
            type: 'SET_IS_FOLLOWING_PIG',
            data: false,
          });

          const point = inspectionContext.state.trackingpoints_dic[tpoint];
          if (auth) { handlePassageDelete(point, auth.token); }
        }}
        onPassageChange={(tstamp) => {
          inspectionContext.dispatch({
            type: 'SET_IS_FOLLOWING_PIG',
            data: false,
          });
          
          const point = inspectionContext.state.trackingpoints_dic[tpoint];
          if (inspectionContext.state.run) {
            handlePassageChange(tstamp, inspectionContext.state.run, point, auth.token);
          }
        }}
      />
    ) : (
      <Skeleton key={index} loading height="115px" />
    ));
  };

  return (
    <RunMobileContainer fullscreen={fullscreen}>
      <MapContainer>
        <MobileRunStatus
          permissionType={props.permissionType}
          onOpenScheduleModal={() => inspectionContext.state.run?.launched ? '' : setIsOpenedScheduleModal(true)}
          onAbort={() => {
            return setFinishConfirmation({
              hide: false,
              title: 'Are you sure?',
              text: 'The Run will be reset and all passage information will be lost.',
              onConfirm: () =>
                abortRunAction(id, auth, setFinishConfirmation, notificationContext.dispatch),
              onCancel: () => setFinishConfirmation({}),
            });
          }}
          onFinish={() => {
            return inspectionContext.state.run?.is_finished
              ? () => ''
              : setFinishConfirmation({
                  hide: false,
                  title: 'Are you sure?',
                  text: "The Run isn't finished yet. - Passage data will be saved to this point and Run wi ll be flagged as Complete.",
                  onConfirm: () =>
                    finishRunAction(
                      id,
                      auth?.token || '',
                      setFinishConfirmation,
                      notificationContext.dispatch
                    ),
                  onCancel: () => setFinishConfirmation({}),
                });
          }}
        />

        {inspectionContext.state.run ? (
          <>
            <MobileConfigurationButton
              onOpenUnitsModal={() => setIsOpenedUnitsModal(true)}
              onOpenTimezoneModal={() => setIsOpenedTimezoneModal(true)}
              onOpenDocumentation={() => {}}
            />

            <MobileInformationButton
              onClick={() => setIsOpenedInformationModal(true)}
            />

            <MobileWidgetsButton
              onClick={() => setIsOpenedWidgetsModal(!isOpenedWidgetsModal)}
            />
          </>
        ) : null}

        {props.permissionType &&
        inspectionContext.state.run?.id === parseInt(id, 10) ? (
          <Map
            mapZoom={zoom}
            observerMode={props.observerMode}
            setObserverMode={props.setObserverMode}
            modules={['run']}
            controls={['labels', 'follow', 'zoom', 'map-type']}
            bounds={bounds}
            onMarkerClick={({ point }) => {
              inspectionContext.dispatch({
                type: 'SET_SELECTED_POINT',
                data: point.id,
              });
            }}
            isFollowingPig={inspectionContext.state.following_pig}
            isFollowingLoggedUser={inspectionContext.state.following_logged_user}
            setIsFollowingPig={(data: boolean) => inspectionContext.dispatch({
              type: 'SET_IS_FOLLOWING_PIG',
              data,
            })}
            setIsFollowingLoggedUser={(data: boolean) => inspectionContext.dispatch({
              type: 'SET_IS_FOLLOWING_LOGGED_USER',
              data,
            })}
          />
        ) : null}
      </MapContainer>

      <Content collapsed={fullscreen}>
        {inspectionContext.state.run && !inspectionContext.state.run.launched && props.permissionType === 'editor'? (
          <LaunchButton
            loading={launchLoading}
            disabled={launchLoading}
            onClick={() => {
              if (launchLoading) return;

              setLaunchConfirmation({
                hide: false,
                title: 'Are you sure?',
                text:
                  `The Run simulation will be started, and 1 credit will be spent. ` +
                  `You currently have ${inspectionContext.state.run?.customer_balance || 0} credits. ` +
                  `You will have one hour to abort the run and get the credit back.`,
                onConfirm: (data: any) => {
                  if (!inspectionContext.state.trackingpoint_set) return;
                  setLaunchLoading(true);
                  launchRun(
                    id,
                    auth.token || '',
                    notificationContext.dispatch,
                    setLaunchConfirmation,
                    data.launchDate,
                    data.launchSpeed,
                    data.isTest,
                  );
                  
                  const pointid = inspectionContext.state.trackingpoint_set[0];
                  inspectionContext.dispatch({
                    type: 'SET_SELECTED_POINT',
                    data: inspectionContext.state.trackingpoints_dic[pointid].id,
                  });
                  setFullscreen(false);
                },
                onCancel: () => setLaunchConfirmation({}),
              });
            }}
          >
            {!launchLoading ? 'Launch' : 'Launching'}
          </LaunchButton>
        ) : null}

        <CollapseButtonWrapper collapsed={fullscreen}>
          <CollapseButton
            collapsed={fullscreen}
            onClick={() => setFullscreen(!fullscreen)}
          >
            <svg
              width="19"
              height="11"
              viewBox="0 0 19 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.5787 0.997244C17.3578 0.996964 17.146 1.0853 16.9902 1.24262L9.58723 8.69089L2.18468 1.24262C1.97438 1.03103 1.66793 0.948446 1.38056 1.02584C1.09333 1.10324 0.868955 1.32899 0.792036 1.61797C0.71511 1.90696 0.797193 2.21545 1.00749 2.42702L8.99871 10.4676C9.15773 10.6189 9.36829 10.7031 9.58724 10.7031C9.80619 10.7031 10.0167 10.6189 10.1759 10.4676L18.1671 2.42702L18.167 2.42716C18.3231 2.26998 18.4107 2.05701 18.4107 1.83489C18.4107 1.61277 18.3231 1.3998 18.167 1.24276C18.0109 1.08572 17.7992 0.997381 17.5785 0.997381L17.5787 0.997244Z"
                fill="white"
              />
            </svg>
          </CollapseButton>
        </CollapseButtonWrapper>

        <ViewTabs collapsed={fullscreen}>
          <ViewTab
            active={activeTab === 'editor'}
            onClick={() => setActiveTab('editor')}
          >
            Editor view
          </ViewTab>
          <ViewTab
            active={activeTab === 'observer'}
            onClick={() => setActiveTab('observer')}
          >
            Observer view
          </ViewTab>
        </ViewTabs>

        <Views collapsed={fullscreen} >
          <View view={activeTab}>
            <TrackingPointList
              ref={listRef}
              id="GT_TP_LIST"
              data-testid="gt-test-list"
              onScroll={(e) => {
                if (loadded) {
                  inspectionContext.dispatch({
                    type: 'SET_IS_FOLLOWING_PIG',
                    data: false,
                  });
                  
                  inspectionContext.dispatch({
                    type: 'SET_IS_FOLLOWING_LOGGED_USER',
                    data: false,
                  });
                }

                const updateFocusedCard = _.debounce((event) => {
                  if (inspectionContext.state.run && inspectionContext.state.trackingpoint_set) {
                    const usingDevice = inspectionContext.state.project?.using_linestat || inspectionContext.state.project?.using_qube;
                    const cardHeight = usingDevice ? 151 : 115;
                    const padding = 16;

                    // index = round number of pixel scrolled - padding top + padding bottom / height of card + card gap
                    let index = Math.round((event.target.scrollTop + 2 * padding) / (cardHeight + padding));

                    if (event.target.scrollTop + 2 * padding + event.target.clientHeight >= event.target.scrollHeight) {
                      index = inspectionContext.state.trackingpoint_set.length - 1;
                    }

                    setFocusedCard(index);

                    // update map bounds and zoom
                    const focusedCardPointKey = inspectionContext.state.trackingpoint_set[index];
                    const focusedCardPoint = inspectionContext.state.trackingpoints_dic[focusedCardPointKey];
                    if (focusedCardPoint) {
                      updateMapBounds(getMapBounds(focusedCardPoint), setBounds);
                      setZoom(16);
                    }
                  }
                }, 500);
                e.persist();
                updateFocusedCard(e);
              }}
            >
              {renderTrackingPoints()}
            </TrackingPointList>
          </View>
          <View view={activeTab}>
            <MobileObserverView />
          </View>
        </Views>
      </Content>
      {
        launchConfirmation ? (
          <ConfirmationLaunchModal
            {...launchConfirmation}
            speedUnit={settingsContext.state.speedUnit}
            initialLaunchSpeed={inspectionContext.state.run?.predicted_launch_speed}
            updateSpeed={(speed: string, cb: any) => cb()}
          />
        ) : null
      }
      <ConfirmationFinishModal
        {...finishConfirmation}
      />

      {selectedTrackingPoint && selectionTstamp ? (
        <TrackingPointPassageModal
          trackingpoint={selectedTrackingPoint}
          now={selectionTstamp}
          onConfirm={(tstamp) => {
            if (auth && inspectionContext.state.run && inspectionContext.state.trackingpoint_set) {
              handlePassageChange(
                tstamp,
                inspectionContext.state.run,
                selectedTrackingPoint,
                auth.token,
              );
            }

            setSelectedTrackingPoint(undefined);
            setSelectionTstamp(undefined);
          }}
          onCancel={() => {
            setSelectedTrackingPoint(undefined);
            setSelectionTstamp(undefined);
          }}
        />
      ) : null}

      {selectedTrackingPoint && isShowGallery && auth ? (
        <TrackingPointMedias
          show
          onClose={() => {
            setIsShowGallery(false);
          }}
          permissionType={props.permissionType as 'observer' | 'editor'}
          selectedMediaId={selectedGalleryMedia}
          token={auth.token}
          medias={selectedTrackingPoint.media_set || []}
        />
      ) : null}

      {selectedTrackingPoint && !selectionTstamp && !isShowGallery ? (
        <TrackingPointModal
          permissionType={props.permissionType}
          isOpenedTriggers={isOpenedTriggers}
          qube={qube}
          trackingpoint={selectedTrackingPoint}
          onAddPassageClick={() => {
            setSelectionTstamp(Date.now());
          }}
          onHold={() => {
            if (auth) {
              handleHold(selectedTrackingPoint, auth.token, notificationContext.dispatch);
            }
          }}
          onPassageChange={(tstamp) => {
            if (auth && inspectionContext.state.run && inspectionContext.state.trackingpoint_set) {
              handlePassageChange(
                tstamp,
                inspectionContext.state.run,
                selectedTrackingPoint,
                auth.token,
              );
            }
          }}
          onIconChange={({ value }: { value: any }) => updateIcon({
            id: selectedTrackingPoint?.id,
            value,
            token: auth?.token,
            dispatch: notificationContext.dispatch,
          })}
          onClose={() => {
            setSelectedTrackingPoint(undefined);
            setSelectionTstamp(undefined);
            setIsOpenedTriggers(false);
          }}
          onShowGallery={() => {
            setIsShowGallery(true);
          }}
          onShowGalleryMedia={(index) => {
            setIsShowGallery(true);
            setSelectedGalleryMedia(index);
          }}
          onAddGalleryMedia={(event) => {
            if (event.target.files && auth) {
              uploadMedia(
                selectedTrackingPoint,
                event.target.files,
                auth.token,
                inspectionContext.state.run,
                notificationContext.dispatch,
                inspectionContext.dispatch,
                () => {}
              );
            }
          }}
        />
      ) : null}

      {isOpenedScheduleModal ? (
        <MobileScheduleModal
          opened={isOpenedScheduleModal}
          onClose={() => setIsOpenedScheduleModal(false)}
          onApply={(date, time, speed) => {
            updateScheduledRun(
              date,
              time,
              speed,
              inspectionContext.state.run?.id,
              auth?.token,
              notificationContext.dispatch,
              settingsContext.state.timezone,
              settingsContext.state.speedUnit
            );
          }}
        />
      ) : null}

      {isOpenedUnitsModal &&
      settingsContext.state.distanceUnit &&
      settingsContext.state.speedUnit &&
      settingsContext.state.weatherUnit ? (
        <MobileUnitsModal
          onClose={() => setIsOpenedUnitsModal(false)}
          onSpeedChange={(data) => {
            settingsContext.dispatch({
              type: 'SET_SPEED_UNIT',
              data,
            });
          }}
          onDistanceChange={(data) => {
            settingsContext.dispatch({
              type: 'SET_DISTANCE_UNIT',
              data,
            });
          }}
          onWeatherChange={(data) => {
            settingsContext.dispatch({
              type: 'SET_WEATHER_UNIT',
              data,
            });
          }}
          distance={settingsContext.state.distanceUnit}
          weather={settingsContext.state.weatherUnit}
          speed={settingsContext.state.speedUnit}
        />
      ) : null}

      {isOpenedTimezoneModal &&settingsContext.state.timezone ? (
        <MobileTimezoneModal
          onClose={() => setIsOpenedTimezoneModal(false)}
          onTimezoneChange={(data) => {
            settingsContext.dispatch({
              type: 'SET_TIMEZONE_UNIT',
              data,
            });
          }}
          timezone={settingsContext.state.timezone}
        />
      ) : null}

      {isOpenedInformationModal ? (
        <MobileInformationModal
          onClose={() => setIsOpenedInformationModal(false)}
        />
      ) : null}

      {isOpenedWidgetsModal ? (
        <MobileWidgetsModal
          onClose={() => setIsOpenedWidgetsModal(!isOpenedWidgetsModal)}
        />
      ) : null}
    </RunMobileContainer>
  );
};

export default Mobile;
