import { Wrapper, Email, ContentList, Option } from './sidebar.style';

export type ActiveTypes = 'my-account' | 'units-and-timezone';

interface SidebarProps {
  email: string;
  active: ActiveTypes;
  onActiveChange: (active: ActiveTypes) => void;
}

interface SidebarOptionType {
  id: string;
  text: string;
  icon: JSX.Element;
}

const options: SidebarOptionType[] = [
  {
    id: 'my-account',
    text: 'My account',
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33C4.62 15.49 4 13.82 4 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z" />
      </svg>
    ),
  },
  {
    id: 'security',
    text: 'Security',
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
        <path d="M11 44q-1.25 0-2.125-.875T8 41V19.3q0-1.25.875-2.125T11 16.3h3.5v-4.8q0-3.95 2.775-6.725Q20.05 2 24 2q3.95 0 6.725 2.775Q33.5 7.55 33.5 11.5v4.8H37q1.25 0 2.125.875T40 19.3V41q0 1.25-.875 2.125T37 44Zm0-3h26V19.3H11V41Zm13-7q1.6 0 2.725-1.1t1.125-2.65q0-1.5-1.125-2.725T24 26.3q-1.6 0-2.725 1.225T20.15 30.25q0 1.55 1.125 2.65Q22.4 34 24 34Zm-6.5-17.7h13v-4.8q0-2.7-1.9-4.6Q26.7 5 24 5q-2.7 0-4.6 1.9-1.9 1.9-1.9 4.6ZM11 41V19.3 41Z" />
      </svg>
    ),
  },
  {
    id: 'units-and-timezone',
    text: 'Units and timezone',
    icon: (
      <svg viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect
          x="13"
          y="0.661133"
          width="8"
          height="17"
          rx="2"
          transform="rotate(45 13 0.661133)"
          stroke="#8E939E"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.1719 3.4895L12.2932 5.61082"
          stroke="#8E939E"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.05078 5.61084L10.1721 7.73216"
          stroke="#8E939E"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.92969 7.73218L8.05101 9.8535"
          stroke="#8E939E"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.80664 9.85352L5.92796 11.9748"
          stroke="#8E939E"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
  },
];

const renderContentOptions = (
  active: ActiveTypes,
  onActiveChange: (active: ActiveTypes) => void
) =>
  options.map((option) => (
    <Option
      key={option.id}
      active={active === (option.id as ActiveTypes)}
      onClick={() => onActiveChange(option.id as ActiveTypes)}
    >
      {option.icon}
      {option.text}
    </Option>
  ));

const Sidebar = ({ email, active, onActiveChange }: SidebarProps) => (
  <Wrapper>
    <Email>{email}</Email>
    <ContentList>{renderContentOptions(active, onActiveChange)}</ContentList>
  </Wrapper>
);

export default Sidebar;
