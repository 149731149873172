import { Cluster, Renderer } from '@googlemaps/markerclusterer';

export class IconRenderer implements Renderer {
  objectIsIcon(
    obj: string | google.maps.Icon | google.maps.Symbol | null
  ): obj is google.maps.Icon {
    if (obj === null) return false;
    if (typeof obj === 'string') return false;

    return 'url' in obj;
  }

  public render(
    { count, position, markers }: Cluster,
  ): google.maps.Marker {
    // change color
    let color = '#000';
    let hasActiveRun = false;
    markers?.forEach((m: google.maps.Marker) => {
      const icon: any = m?.getIcon();
      if (this.objectIsIcon(icon)) {
        if (`${icon?.url}` === '/assets/icons/red-run-marker.svg') {
          color = '#ff0000';
          hasActiveRun = true;
        } else if (
          `${icon?.url}` === '/assets/icons/run-marker.svg' &&
          !hasActiveRun
        ) {
          color = '#0000ff';
        }
      }
    });

    // create svg url with fill color
    const svg = window.btoa(`
      <svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
      <circle cx="120" cy="120" opacity=".6" r="70" />
      <circle cx="120" cy="120" opacity=".3" r="90" />
      <circle cx="120" cy="120" opacity=".2" r="110" />
      <circle cx="120" cy="120" opacity=".1" r="130" />
      </svg>`);

    // create marker using svg icon
    return new google.maps.Marker({
      position,
      icon: {
        url: `data:image/svg+xml;base64,${svg}`,
        scaledSize: new google.maps.Size(45, 45),
      },
      label: {
        text: String(count),
        color: 'rgba(255,255,255,0.9)',
        fontSize: '12px',
      },
      // adjust zIndex to be above other markers
      zIndex: 1000 + count,
    });
  }
}
