import { getTrackingpointIcon } from "app/utils/map.utils";
import { iconsArr } from "app/components/icon-selector/icons.model";
import { SurveyProviderValue } from "app/modules/survey/survey.context.d";
import { SurveyPointType } from "app/modules/survey/survey.interfaces";


/**
 *
 * @param param0
 */
export const changeTrackingPointMarker = ({
  marker,
  point,
  map,
  hasLabels,
}: {
  map: google.maps.Map;
  marker?: google.maps.Marker;
  point: SurveyPointType,
  hasLabels: boolean,
}) => {
  if (!point || !marker) return;
  const updatedIcon = getTrackingpointIcon(point, undefined, map.getZoom());

  const infos = [point.name];
  if (updatedIcon.legend)
    infos.push(updatedIcon.legend);


  marker.setIcon(updatedIcon);
  marker.setTitle(infos.join(' - '));

  if (hasLabels) {
    marker.setLabel({
      color: '#ffffff',
      fontWeight: 'bold',
      text: point.name as string,
      fontSize: '10px',
      className: 'marker-label',
    });
  } else {
    marker.setLabel(null);
  }
};

/**
 *  renderTrackingpoints
 * @param param0
 */
export const renderMarker = (
  surveyContext: SurveyProviderValue,
  point: SurveyPointType,
  map: google.maps.Map,
  setMarker: (m: google.maps.Marker) => void,
  oldmarker?: google.maps.Marker,
) => {
  if (oldmarker) {
    oldmarker.setMap(null);
  }
  if (!point) return;


  const icon: any = getTrackingpointIcon(point); // TODO TYPE
  const iconFromList = iconsArr.find((i: any) => i.id === point?.type); // TODO TYPE

  const marker = new window.google.maps.Marker({
    map,
    icon,
    title: `${point.name} - ${icon?.legend} - ${iconFromList?.label || 'AGM'}`,
  });
  
  if (point?.geometry) {
    marker.setPosition({
      lng: point.geometry.coordinates[0],
      lat: point.geometry.coordinates[1],
    })
  }

  marker.setZIndex(4);

  marker.addListener('click', () => {
    surveyContext.dispatch({
      type: 'SET_SELECTED_POINT',
      data: point.id,
    });
  });

  setMarker(marker);
};