import { InspectionContext } from 'app/modules/inspection/inspection.context.d';
import { objectDeepClone } from '../../../../../../../utils/map.utils';

/**
 * 
 * @param points 
 * @param pointsDic 
 * @param passagesDic 
 * @returns 
 */
export const getLastPassage = (
  points: number[],
  pointsDic: InspectionContext['trackingpoints_dic'],
  passagesDic: InspectionContext['passages_dic']
) => {
  const indexedPoints = objectDeepClone(
    points?.map((id: any, index: number) => ({ ...pointsDic[id], index }))
  );

  const filtered = indexedPoints?.filter((point) => {
    const passage = passagesDic[point.passage];
    return passage?.tstamp
  });

  const lastPassagePoint = objectDeepClone(filtered.slice(-1)[0]);
  // TODO move this to backend
  let i = lastPassagePoint.index + 1;
  while (indexedPoints[i] && !indexedPoints[i].active) {
    i++;
  }
  const nextPassagePoint = indexedPoints[i] || indexedPoints[1];
  const endPoint = indexedPoints.slice(-1)[0];

  let point;

  if (!lastPassagePoint?.index || !!lastPassagePoint?.passage) {
    point = points[0];
  } else {
    point = points[lastPassagePoint?.index + 1] || points[lastPassagePoint?.index];
  }
  return {
    lastPassagePoint,
    nextPassagePoint,
    point,
    endPoint,
  };
};
