import { formatDistance } from '../../../lib/formatter';
import { getTrackingPointIconSrc } from '../../utils/map.utils';
import { FormatterReturn } from './sheet.d';

/**
 * function to formatter the latitude and the longitude
 * @param row
 * @param cell
 * @param value
 * @returns string
 */
export const coordsFormatter = (row: unknown, cell: number, value: string) => {
  const addClasses = 'gt-input number right';
  // TODO: check why it is receving value as NaN?
  return { text: parseFloat(value).toFixed(8), addClasses };
};

/**
 * function to formatter the latitude and the longitude
 * @param row
 * @param cell
 * @param value
 * @returns string
 */
export const activeFormatter = (
  row: unknown,
  cell: number,
  value: string,
  defs: unknown,
  context: any
) => {
  const holdText = `
    <span class="${context.is_observer ? 'hold-circle disabled' : 'hold-circle'}"></span>
  `;

  const unholText = `
    <span class="${context.is_observer ? 'unhold-circle disabled' : 'unhold-circle'}"></span>
  `;

  const activeState = context.active ? holdText : unholText;

  return context.is_device_trigger ? `<span class="centered-hold-column">-</span>` : activeState;
};

/**
 * function to formatter the AGM type from ref to icon
 * @param row
 * @param cell
 * @param value
 * @param defs
 * @param context
 * @returns
 */
export const typeFormatter = (
  row: unknown,
  cell: number,
  value: string,
  defs: unknown,
  context: any // TODO: found de context type
): string => {
  const icon = getTrackingPointIconSrc(context, 'no-border');
  return `
    <img class="gt-tp-icon" src="${icon.url}" alt="" />
  `;
};

/**
 * function to formatter the text columns
 * @param row
 * @param cell
 * @param value
 * @returns
 */
export const defaultFormatter = (row: unknown, cell: number, value: string) => {
  const addClasses = 'gt-input';
  return { text: value, addClasses };
};

/**
 * function to formatter the number columns
 * @param row
 * @param cell
 * @param value
 * @returns
 */
export const numberFormatter = (
  row: unknown,
  cell: number,
  value: string,
  defs: any,
  context: any,
  float = false
): FormatterReturn => {
  const addClasses = 'gt-input number right';
  // TODO: check why it is receving value as NaN?
  
  const number = float ? parseFloat(value) : parseInt(value);
  return { text: isNaN(number) ? '' : number.toString(), addClasses };
};

/**
 * function to formatter the distance columns
 * @param row
 * @param cell
 * @param value
 * @returns
 */
export const distanceFormatter = (
  row: number,
  cell: number,
  value: string,
  defs: any,
  context: any
) => {
  const addClasses = 'gt-input number';
  const formatted = formatDistance({
    distance: parseFloat(`${value}`),
    unit: context.distance_unit?.id,
  });
  // TODO: check why it is receving value as NaN?
  return { text: formatted, addClasses };
};
