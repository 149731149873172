import moment from 'moment-timezone';
import VMasker from 'vanilla-masker';
import { deleteEvent, updateEvent } from '../../services/run.service';
import { InspectionProviderValue } from 'app/modules/inspection/inspection.context.d';
import { SettingsContext } from 'app/modules/settings/settings.context.d';
import { AccountContext } from 'app/modules/account/account.context.d';

export const saveEvent = async (
  event: any,
  inspectionContext: InspectionProviderValue,
  settingsState: SettingsContext,
  accountState: AccountContext,
  setIsLoading: (data: boolean) => void,
  setError: (data: any) => void
) => {
  setIsLoading(true);
  if (!event.date) {
    setIsLoading(false);
    setError({ field: 'date', error: 'Date field is required' });
    return;
  }
  if (!event.time) {
    setIsLoading(false);
    setError({ field: 'time', error: 'Time field is required' });
    return;
  }
  if (!event.name) {
    setIsLoading(false);
    setError({ field: 'name', error: 'Name field is required' });
    return;
  }

  const momentDate = moment();
  const date = VMasker.toPattern(`${event.date}`, '9999-99-99');
  const time = VMasker.toPattern(`${event.time}`, '99:99:99.999');

  const year = +date.slice(0, 4);
  const month = +date.slice(5, 7) - 1;
  const day = +date.slice(8, 10);
  const h = +time.slice(0, 2);
  const m = +time.slice(3, 5);
  const s = +time.slice(6, 8);
  const mm = +time.slice(9);

  momentDate.tz(settingsState.timezone?.id || '');
  momentDate.set('date', day);
  momentDate.set('month', month);
  momentDate.set('year', year);
  momentDate.set('hour', h);
  momentDate.set('minute', m);
  momentDate.set('second', s);
  momentDate.set('millisecond', mm);

  const isoDate = momentDate.toISOString();

  const eventTime = momentDate.toDate().getTime();
  const launchRunTime = new Date(`${inspectionContext.state.run?.launch_time}`).getTime();
  if (launchRunTime > eventTime) {
    setError({
      field: 'time',
      error: 'Event date and time must be after run launch',
    });
    setIsLoading(false);
    return;
  }
  if (momentDate.toDate().toString() === 'Invalid Date') {
    setError({
      field: 'time',
      error: 'Invalid time',
    });
    setIsLoading(false);
    return;
  }

  try {
    await updateEvent(
      event.id,
      {
        tstamp: isoDate,
        title: event.name,
      },
      accountState.auth?.token || ''
    );

    inspectionContext.dispatch({
      type: 'SET_SELECTED_TIMEPIN',
      data: undefined,
    })
    setIsLoading(false);
  } catch (err) {
    setIsLoading(false);
    console.log([err]);
  }
};

export const onDeleteEvent = async (
  eventId: any,
  accountState: AccountContext,
  dispatch: InspectionProviderValue['dispatch'],
  setIsLoading: (data: boolean) => void,
  clear: () => void
) => {
  setIsLoading(true);
  try {
    await deleteEvent(eventId, accountState.auth?.token || '');

    dispatch({
      type: 'SET_SELECTED_TIMEPIN',
      data: undefined,
    });
    setIsLoading(false);
    clear();
  } catch (err) {
    setIsLoading(false);
    console.log([err]);
  }
};
