import { Route, BrowserRouter as Router } from 'react-router-dom';

// css global imports
import '../assets/css/reset.css';

// components
import PrivateWrapper from 'app/modules/account/private-wrapper';

// modules
import AccountModule from './modules/account/account.module';
import InspectionModule from './modules/inspection/inspection.module';
import InvitationModule from './modules/invitation/invitation.module';
import ProjectsModule from './modules/projects/projects.module';
import SurveyModule from './modules/survey/survey.module';

// providers
import { AccountProvider } from './modules/account/account.context';
import { NotificationProvider } from './modules/notification/notification.context';
import { SettingsProvider } from './modules/settings/settings.context';

/**
 * init provider and routing the application
 * @returns React.Component
 */
export const App = () => (
  <Router>
    <SettingsProvider>
      <NotificationProvider>
        <AccountProvider>
          <Route path="/" component={AccountModule} />
          <Route path="/invitations" component={InvitationModule} />

          <PrivateWrapper>
            <Route path="/runs/:id" component={InspectionModule} />
            <Route path="/" component={ProjectsModule} />
            <Route path="/surveys" component={SurveyModule} />
          </PrivateWrapper>
        </AccountProvider>
      </NotificationProvider>
    </SettingsProvider>
  </Router>
);

export default App;
