import { matchPath } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import ReactGA from "react-ga4";
import App from './app/app';
import { StrictMode } from 'react';

const routes = [
  {path: '/runs/:id/report'},
  {path: '/runs/:id'},
  {path: '/forgot-password/:token'},
  {path: '/forgot-password'},
  {path: '/register'},
  {path: '/calendar'},
  {path: '/people'},
  {path: '/projects/:id'},
  {path: '/projects'},
  {path: '/surveys/:id'},
  {path: '/surveys'},
  {path: '/runs/:id'},
  {path: '/invitations'},
  {path: '/'},
]

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  release: import.meta.env.VITE_SENTRY_RELEASE,
  environment: import.meta.env.VITE_DEFAULT_DOMAIN,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(
        history,
        routes,
        matchPath
      ),
    })
  ],
  tracesSampleRate: 1.0,
  enableTracing: true,
});

ReactGA.initialize(`${import.meta.env.VITE_GA_MEASUREMENT_ID}`);

console.log(`release: ${import.meta.env.VITE_SENTRY_RELEASE} - sha: ${import.meta.env.VITE_SHA}`)

const doc = document.getElementById('root');

if (doc) {
  const root = createRoot(doc);
  root.render(
    <StrictMode>
      <App />
    </StrictMode>
  );
}
