import { useContext, useEffect, useState } from 'react';
import Button from '../button/button';
import {
  ModalWrapper,
  Modal,
  ButtonWrapper,
  ConfirmationTitle,
  ConfirmationFooter,
  buttonCustomStyle,
  CloseButtonContainer,
  EventExplanation,
  Main,
  Header,
  CardWrapper,
  CardsListWrapper,
  CardLabel,
  CardButton,
} from './event-list-overlay.style';
import InspectionStore from 'app/modules/inspection/inspection.context';
import { TimePinType } from 'app/modules/inspection/inspection.interfaces';

const EventListOverlay = () => {
  const inspectionContext = useContext(InspectionStore);
  const [events, setEvents] = useState<(TimePinType | null)[]>([]);

  useEffect(() => {
    if (inspectionContext.state.selected_point) {
      inspectionContext.dispatch({
        type: 'SET_SHOW_TIMEPINS_MODAL',
        data: false,
      });
    }
  }, [inspectionContext.state.selected_point]);

  useEffect(() => {
    const keys = Object.keys(inspectionContext.state.timepins_dic);
    const newEvents = keys.map((key: string) => inspectionContext.state.timepins_dic[key])

    setEvents(newEvents);
  }, [inspectionContext.state.timepins_dic]);
  
  if (!inspectionContext.state.show_timepins_modal) return null;
  
  return (
    <ModalWrapper>
      <Modal>
        <CloseButtonContainer>
          <svg
            onClick={() => inspectionContext.dispatch({
              type: 'SET_SHOW_TIMEPINS_MODAL',
              data: false,
            })}
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="14.2427"
              y="4.34375"
              width="2"
              height="14"
              rx="1"
              transform="rotate(45 14.2427 4.34375)"
              fill="#000"
            />
            <rect
              x="15.6567"
              y="14.2422"
              width="2"
              height="14"
              rx="1"
              transform="rotate(135 15.6567 14.2422)"
              fill="#000"
            />
          </svg>
        </CloseButtonContainer>
        <Header>
          <ConfirmationTitle>Events</ConfirmationTitle>
          <EventExplanation>
            Add a date and time between the launch and finish time (or ETA at
            receiver if it&apos;s an ongoing run). A pin will drop and show
            where the pig was/will be at that time.
          </EventExplanation>
        </Header>
        <Main>
          <ConfirmationTitle>{`On going events (${events.length})`}</ConfirmationTitle>
          <CardsListWrapper>
            {events.map((event: any) => (
              <CardWrapper key={event.id}>
                <CardLabel>{event.title}</CardLabel>
                <CardButton onClick={() => {
                  inspectionContext.dispatch({
                    type: 'SET_SELECTED_TIMEPIN',
                    data: event.id,
                  });
                  inspectionContext.dispatch({
                    type: 'SET_SHOW_TIMEPINS_MODAL',
                    data: false,
                  });
                }}
                >Edit</CardButton>
              </CardWrapper>
            ))}
          </CardsListWrapper>
          <ConfirmationFooter>
            <ButtonWrapper>
              <Button
                customStyle={buttonCustomStyle}
                handleClick={() => {
                  inspectionContext.dispatch({
                    type: 'SET_SHOW_TIMEPINS_MODAL',
                    data: false,
                  });
                  inspectionContext.dispatch({
                    type: 'SET_SHOW_CREATE_TIMEPIN_MODAL',
                    data: true,
                  });
                }}
                value="New event"
              />
            </ButtonWrapper>
          </ConfirmationFooter>
        </Main>
      </Modal>
    </ModalWrapper>
  );
};

EventListOverlay.defaultProps = {
  hide: true,
  title: 'What is Lorem Ipsum?',
  text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
  confirmationText: 'Confirm',
  cancelationText: 'Cancel',
  icon: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#ffffff">
      <path d="M12 5.99L19.53 19H4.47L12 5.99M2.74 18c-.77 1.33.19 3 1.73 3h15.06c1.54 0 2.5-1.67 1.73-3L13.73 4.99c-.77-1.33-2.69-1.33-3.46 0L2.74 18zM11 11v2c0 .55.45 1 1 1s1-.45 1-1v-2c0-.55-.45-1-1-1s-1 .45-1 1zm0 5h2v2h-2z" />
    </svg>
  ),
};

export default EventListOverlay;
