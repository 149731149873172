import styled from 'styled-components';

export const MobileInformationModalContent = styled.div`
  width: 100%;

  background-color: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;

  max-height: 100%;
`;

export const MobileInformationModalContentCloseButton = styled.button`
  background-color: transparent;
`;

export const MobileInformationModalContentHeader = styled.header`
  padding: 14px 17px 14px 17px;
  border-bottom: 0.7px solid rgba(130, 132, 143, 0.7);

  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 9px;
`;

export const MobileInformationModalContentTitle = styled.h2`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;

  color: #494c5d;
`;

export const MobileInformationModalInformations = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: start;
  gap: 20px;

  padding: 14px 17px 14px 17px;
`;

export const MobileInformationModalInformation = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 8px;
`;

export const MobileInformationModalInformationLabel = styled.label`
  display: inline-block;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;

  color: #494c5d;

  margin-bottom: 8px;
`;

export const MobileInformationModalInformationDescription = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 200;
  font-size: 13px;
  line-height: 16px;

  color: #494c5d;
`;

export const MobileInformationModalInformationFigure = styled.figure``;

export const MobileInformationModalInformationTitle = styled.h3`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */


  color: #494c5d;
`;

export const MobileInformationModalMapPointInformations = styled.div`
  display: grid;
  gap: 16px;
`;

export const MobileInformationModalPipelinePointsInformations = styled.div`
  display: grid;
  gap: 16px;
`;

export const MobileInformationModalWrapper = styled.div`
  position: fixed;
  top: 40px;
  left: 0;
  z-index: 20;

  display: flex;
  align-items: center;

  width: 100%;
  height: calc(100vh - 40px);
  background-color: rgba(112, 122, 140, 0.5);

  padding: 30px;
`;

// PIPELINES FIGURES

export const MobileIngormationFigureGrayPipeline = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  width: 20px;
  height: 20px;

  border-radius: 50%;
  background-color: #d9d9d9;

  &:after {
    content:  "";
    display: block;

    width: 100%;
    height: 4px;

    background-color: #b8b8b8;

    transform: rotate(45deg);
  }
`;

export const MobileIngormationFigureGreenPipeline = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  width: 20px;
  height: 20px;

  border-radius: 50%;
  background-color: #d9d9d9;

  &:after {
    content:  "";
    display: block;

    width: 100%;
    height: 4px;

    background-color: #9acd32;

    transform: rotate(45deg);
  }
`;

export const MobileIngormationFigureRedHighlight = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  width: 20px;
  height: 20px;

  border-radius: 50%;
  background-color: rgba(254, 0, 0, 0.4);

  &:after {
    content:  "";
    display: block;

    width: 100%;
    height: 4px;

    background-color: rgba(254, 0, 0, 0.75);

    transform: rotate(45deg);
  }
`;

export const MobileIngormationFigureDriveline = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  width: 20px;
  height: 20px;

  border-radius: 50%;
  background-color: #d9d9d9;

  &:after {
    content:  "";
    display: block;

    width: 100%;
    height: 2px;

    background-color: #3333c8;

    transform: rotate(45deg);
  }
`;
