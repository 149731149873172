import styled from 'styled-components';

export const HelpButton = styled.button<{ hidden?: boolean }>`
  width: 30px;
  height: 30px;

  background: rgba(62, 66, 85, 0.65);
  backdrop-filter: blur(10px);

  border-radius: 100%;
  color: #fff;
  font-size: 17px;

  box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;

  cursor: pointer;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;

  label {
    cursor: pointer;
  }

  ${({ hidden }) => (hidden ? 'display: none;' : '')}
`;

export const HelpContainer = styled.div<{ show: boolean }>`
  position: absolute;
  width: 550px;
  top: 100%;
  padding: 8px 0;
  right: 50%;

  background: rgba(62, 66, 85, 0.9);
  backdrop-filter: blur(10px);

  color: #fff;
  font-size: 17px;

  box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;

  z-index: 10;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  ${({ show }) => (show ? '' : 'display: none;')}
`;

export const HelpTitle = styled.label`
  width: 100%;
  text-align: left;
  font-weight: bold;
  padding: 10px 20px;
`;
export const HelpList = styled.ul`
  width: 100%;
`;
export const HelpCard = styled.li`
  display: grid;
  grid-template-columns: 50px 1fr;
  padding: 10px;
  border-top: 1px solid rgba(255, 255, 255, 0.7);
  justify-content: space-between;
`;
export const HelpCardImage = styled.img`
  width: 35px;
  height: 35px;
`;

export const HelpTextWrapper = styled.div`
  width: 100%;
`;
export const HelpCardTitle = styled.label`
  font-weight: bold;
  display: block;
  width: 100%;
  text-align: left;
  font-size: 15px;
`;
export const HelpCardText = styled.label`
  display: block;
  width: 100%;
  text-align: left;
  margin-top: 5px;
  font-size: 13px;
`;

export const PipelinePiece = styled.div<{ color: string }>`
  width: 10px;
  height: 35px;
  background-color: ${({ color }) => color || '#9acd32'};
  border: 1px solid #444444;
  transform: rotate(142deg);
  margin-left: 10px;
`;

export const PipelineHightLightedPiece = styled.div`
  width: 10px;
  height: 35px;
  background-color: rgba(254, 0, 0, 0.75);
  /* border: 1px solid #444444; */
  box-shadow: 10px 10px 119px 50px rgba(254, 0, 0, 0.85);
`;

export const PipelineHightLightWrapper = styled.div`
  width: 35px;
  height: 35px;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

export const DrivelineWrapper = styled.div`
  width: 35px;
  height: 35px;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  border: 1px solid #8a8aff;
  justify-content: center;
  overflow: hidden;
`;

export const DrivelineedPiece = styled.div`
  width: 6px;
  height: 35px;
  background-color: #3333c8;
  /* border: 1px solid #3333c8; */
  transform: rotate(142deg);
  box-shadow: 10px 10px 119px 50px #fff;
`;

export const SeparatorWrapper = styled.div`
  padding: 10px;
`;
