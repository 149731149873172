import { useEffect } from 'react';
import { RunType } from '../../../../../interfaces/inspection.interfaces';
import { init } from './trackingpoint-marker.controller';

interface Props {
  trackingpoints: RunType['trackingpoint_set'];
  map: google.maps.Map;
}

const TrackingPointMarkers = ({ trackingpoints, map }: Props) => {

  // load modules
  useEffect(() => {
    if (trackingpoints) {
      init({ map, trackingpoints });
    }
  }, [trackingpoints]);

  return null;
};

export default TrackingPointMarkers;
