import styled from 'styled-components';

export const TabList = styled.ul`
  position: relative;
  display: flex;
  gap: 16px;

  z-index: 2;
`;

export const TabItem = styled.li<{ selected: boolean }>`
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 13px;
  padding-bottom: 13px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 13px;

  box-shadow: ${({ selected }) =>
    selected ? '0px 4px 0px 0px #5850ec' : 'none'};

  > a,
  > span {
    color: ${({ selected }) => (selected ? '#5850ec' : '#99a2ad')};
  }
`;

export const Quantity = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 13px;

  color: #414141;
`;
