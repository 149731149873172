import {
  useState,
  useEffect,
  useContext,
  useCallback,
  ChangeEvent,
} from 'react';
import Cropper from 'react-easy-crop';
import InputGroup from '../../../../input-group/inputGroup';
import Input from '../../../../input/input';
import Button from '../../../../button/button';
import PhoneInput from '../../../../phone-input/phone-input';
import {
  Form,
  Fieldset,
  Figure,
  Avatar,
  AvatarGroup,
  ActionsWrapper,
  SlimButton,
  CropWrapper,
  BackButton,
} from './my-account.style';
import { submit } from './my-account.controller';
import { countries } from '../../../../phone-input/phone-input.model';
import AccountStore from 'app/modules/account/account.context';
import NotificationStore from 'app/modules/notification/notification.context';

/**
 * 
 * @returns 
 */
const MyAccount = () => {
  const accountContext = useContext(AccountStore);
  const notificationContext = useContext(NotificationStore);
  const [avatar, setAvatar] = useState<string | null>(null);
  const [newAvatar, setNewAvatar] = useState<any>(null);
  const [newAvatarExt, setNewAvatarExt] = useState<string>('');
  const [newAvatarName, setNewAvatarName] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [surName, setSurName] = useState<string>('');
  const [countryCode, setCountryCode] = useState<string>('US');
  const [countryCallingCode, setCountryCallingCode] = useState<string>('1');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [cropping, setCropping] = useState<string | null>(null);
  const [crop, setCrop] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState<number>(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<any>(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPxls) => {
    setCroppedAreaPixels(croppedAreaPxls);
  }, []);

  const reload = (user: any) => {
    if (!user) return;

    setAvatar(user.avatar);
    setFirstName(user.first_name);
    setSurName(user.last_name);
    setPhoneNumber(user.phone_number || '');

    const country = countries.find(
      (country) => country.countryCallingCode === user.country_code
    );

    if (country) {
      setCountryCallingCode(country.countryCallingCode);
      setCountryCode(country.countryCode);
    }
  };

  useEffect(() => {
    if (accountContext.state.auth?.user) reload(accountContext.state.auth.user);
  }, [accountContext.state.auth]);

  useEffect(() => {
    if (accountContext.state.auth?.user && accountContext.state.opened_profile_modal) reload(accountContext.state.auth.user);
  }, [accountContext.state.opened_profile_modal]);

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <div>
        <CropWrapper cropping={!!cropping}>
          <BackButton onClick={() => setCropping(null)}>
            <svg
              width="18"
              height="18"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="4.94971"
                width="2"
                height="7"
                rx="1"
                transform="rotate(45 4.94971 0)"
                fill="#ffffff"
              />
              <rect
                x="6.36377"
                y="8.48438"
                width="2"
                height="7"
                rx="1"
                transform="rotate(135 6.36377 8.48438)"
                fill="#ffffff"
              />
            </svg>
          </BackButton>
          <Cropper
            image={cropping || ''}
            crop={crop}
            zoom={zoom}
            aspect={4 / 4}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
          <Button
            value="Crop profile image"
            customStyle={`
              width: auto;
              border: none;

              position: absolute;
              bottom: 16px;
              right: 16px;

              font-size: 13px;
              padding: 8px 12px;

              transiton-duration: 200ms;

              &:focus {
                padding: 8px 12px;
                border: none;
              }
            `}
            handleClick={() => {
              if (!cropping) return;

              const image = document.createElement('img');
              const canvas = document.createElement('canvas');
              const ctx = canvas.getContext('2d');

              image.onload = () => {
                canvas.width = 100;
                canvas.height = 100;

                ctx?.drawImage(
                  image,
                  croppedAreaPixels.x,
                  croppedAreaPixels.y,
                  croppedAreaPixels.width,
                  croppedAreaPixels.height,
                  0,
                  0,
                  100,
                  100
                );

                setNewAvatar(canvas.toDataURL());
                setCropping(null);
              };

              image.src = cropping;
            }}
          />
        </CropWrapper>
        <Fieldset>
          <AvatarGroup>
            <Figure>
              {newAvatar || avatar ? (
                <Avatar src={newAvatar || avatar} alt="" />
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24px"
                  viewBox="0 0 24 24"
                  width="24px"
                  fill="#99A2AD"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M12 12.25c1.24 0 2.25-1.01 2.25-2.25S13.24 7.75 12 7.75 9.75 8.76 9.75 10s1.01 2.25 2.25 2.25zm4.5 4c0-1.5-3-2.25-4.5-2.25s-4.5.75-4.5 2.25V17h9v-.75zM19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14z" />
                </svg>
              )}
            </Figure>
            <Button
              type="button"
              customStyle={`
                width: auto;
                padding: 12px 16px;

                &:focus {
                  padding: 12px 16px;
                }
              `}
              value="Upload New Picture"
              handleClick={() => {
                const fileSelector = document.createElement('input');
                fileSelector.setAttribute('type', 'file');
                fileSelector.click();
                fileSelector.onchange = () => {
                  if (!fileSelector.files?.length) return;

                  const reader = new FileReader();
                  reader.onload = (e: any) => {
                    setCropping(e.target.result);
                    document
                      .querySelector('#profile-content-wrapper')
                      ?.scrollTo(0, 0);
                  };

                  setNewAvatarExt(fileSelector.files[0].type.split('/')[1]);
                  setNewAvatarName(fileSelector.files[0].name.split('.')[0]);
                  reader.readAsDataURL(fileSelector.files[0]);
                };
              }}
            />
          </AvatarGroup>
        </Fieldset>
        <Fieldset>
          <InputGroup label="Name">
            <Input
              customStyle={`height: 40px;`}
              value={firstName}
              handleChange={(e: any) => {
                setFirstName(e.target.value);
              }}
            />
          </InputGroup>
          <InputGroup label="Surname">
            <Input
              customStyle={`height: 40px;`}
              value={surName}
              handleChange={(e: any) => {
                setSurName(e.target.value);
              }}
            />
          </InputGroup>
          <InputGroup
            label="Phone Number (Optional)"
            description="example: +15556450274"
          >
            <PhoneInput
              phone={phoneNumber}
              country={countryCode}
              onCountryChange={(code, country) => {
                setCountryCode(country);
                setCountryCallingCode(code);
              }}
              onPhoneChange={(e: ChangeEvent<HTMLInputElement>) => {
                const regexp = /^([0-9])*$/;
                if (regexp.test(e.target.value)) {
                  setPhoneNumber(e.target.value);
                }
              }}
            />
          </InputGroup>
        </Fieldset>
      </div>
      <ActionsWrapper>
        <Button
          handleClick={() => {
            if (!accountContext.state.auth?.user?.id) return;

            const user: any = {};

            if (firstName !== accountContext.state.auth?.user.first_name) {
              user.first_name = firstName;
            }

            if (surName !== accountContext.state.auth?.user.first_name) {
              user.last_name = surName;
            }

            const phone = `+${countryCallingCode}${phoneNumber}`;
            if (phone !== accountContext.state.auth?.user.phone) {
              user.phone_number = phoneNumber;
              user.country_code = countryCallingCode;
            }

            if (newAvatar) {
              const parsedAvatar: any = {};
              parsedAvatar.dataUrl = newAvatar;
              parsedAvatar.ext = newAvatarExt;
              parsedAvatar.name = newAvatarName;

              user.avatar = parsedAvatar;
            }

            submit(
              accountContext.state.auth?.user.id || 0,
              user,
              accountContext.state.auth,
              () => null,
              accountContext.dispatch,
              notificationContext.dispatch
            );
          }}
          customStyle={`
            width: auto;
            padding: 12px 16px;

            &:focus {
              padding: 12px 16px;
            }
          `}
          value="Update"
        />
        <SlimButton
          color="default"
          type="button"
          onClick={() => {
            accountContext.dispatch({
              type: 'CLOSE_PROFILE_MODAL',
            });
          }}
        >
          Cancel
        </SlimButton>
      </ActionsWrapper>
    </Form>
  );
};

export default MyAccount;
