import { Loader } from '@googlemaps/js-api-loader';
import { Unit } from '../../../store/store.interfaces';
import { getBoundsBox } from '../../../utils/map.utils';
import { WeatherOption } from './map.d';
import { weatherImperialOptions, weatherMetricOptions } from './map.model';
import MeasureTool from '../../../../lib/measuretool-googlemaps-v3/src';

// google map settings
const GOOGLE_MAP_API_KEY = 'AIzaSyBMLOqCBfQby-SpbQ__otKm7pg46qG6j8s';
const GOOGLE_MAP_ID = '90f559dd3d1ba78f';
const DEFAULT_GOOGLE_MAP_OPTIONS = {
  mapId: GOOGLE_MAP_ID,
  center: { lat: 0, lng: 0 },
  zoom: 6,
  disableDefaultUI: true,
  streetViewControl: false,
  mapTypeControl: false,
  zoomControl: false,
};

// aeris api settings
const AERIS_ID = import.meta.env.VITE_AERIS_ID;
const AERIS_SECRET = import.meta.env.VITE_AERIS_SECRET;

/**
 *
 */
export const init = async ({
  options,
  element,
  setMap,
  setInitialized,
  setIsStreetViewMode,
}: {
  options: google.maps.MapOptions;
  element: HTMLDivElement | undefined;
  setMap: (map: google.maps.Map) => void;
  setInitialized: (initialized: boolean) => void;
  setIsStreetViewMode: (isStreetViewMode: boolean) => void;
}) => {
  if (!element) return;

  const loader = new Loader({
    apiKey: GOOGLE_MAP_API_KEY,
    libraries: ['geometry'],
    version: 'beta',
  });
  
  await loader.load();

  const google = window.google;
  const googleMapOptions = { ...DEFAULT_GOOGLE_MAP_OPTIONS, ...options };
  const map = new google.maps.Map(element, googleMapOptions);

  const thePanorama = map.getStreetView();

  google.maps.event.addListener(thePanorama, 'visible_changed', () => {
    setIsStreetViewMode(thePanorama.getVisible());
  });

  setMap(map);
  setInitialized(true);
};

/**
 *
 * @param param0
 */
export const setListeners = ({
  map,
  setZoom,
  setTilt,
  setHeading,
}: {
  map: google.maps.Map;
  setZoom: (zoom: number) => void;
  setTilt: (tilt?: number) => void;
  setHeading: (heading?: number) => void;
}) => {
  const google = window.google;
  google.maps.event.addListener(map, 'zoom_changed', () => {
    const zoom = map.getZoom();
    setZoom(zoom || 0);
  });


  google.maps.event.addListener(map, 'tilt_changed', () => {
    setTilt(map.getTilt());
  });

  google.maps.event.addListener(map, 'tilt_changed', () => {
    setHeading(map.getHeading());
  });
};

/**
 *
 * @param map
 */
export const changeMapOverlay = ({
  map,
  weatherOverlay,
}: {
  weatherOverlay?: WeatherOption;
  map?: google.maps.Map;
}) => {
  if (map) map.overlayMapTypes.clear();
  if (weatherOverlay) {
    const tile = new google.maps.ImageMapType({
      getTileUrl: (coord: any, zoom: any) => {
        return `https://maps.aerisapi.com/${AERIS_ID}_${AERIS_SECRET}/${weatherOverlay.id}/${zoom}/${coord.x}/${coord.y}/current.png`;
      },
      tileSize: new google.maps.Size(256, 256),
    });

    if (map) {
      map.overlayMapTypes.insertAt(0, tile);
    }
  }
};

/**
 *
 * @param map
 */
export const renderMeasureTool = ({
  map,
  setMeasureTool,
  distanceUnit,
}: {
  map?: google.maps.Map;
  setMeasureTool: (measureTool: unknown) => void;
  distanceUnit: any; // TODO TYPE
}) => {
  // load measure tool
  // eslint-disable-next-line @typescript-eslint/no-var-requires

  let unit = MeasureTool.UnitTypeId.METRIC;

  if (distanceUnit?.id === 'ft' || distanceUnit?.id === 'mi') {
    unit = MeasureTool.UnitTypeId.IMPERIAL;
  }

  const measureTool = new MeasureTool(map, {
    contextMenu: false,
    unit,
  });

  setMeasureTool(measureTool);
};

/**
 *
 */
export const checkOverlayUnit = ({
  weatherOverlay,
  weatherUnit,
  setWeatherOverlay,
}: {
  weatherOverlay?: WeatherOption;
  weatherUnit: Unit | undefined;
  setWeatherOverlay: (weatherOverlay?: WeatherOption) => void;
}) => {
  let options: WeatherOption[] = [];

  // check if it is imperial unit
  if (weatherOverlay && weatherUnit?.id === 'farenheit') {
    options = weatherImperialOptions;
  }

  // check if it is metric unit
  if (weatherOverlay && weatherUnit?.id !== 'farenheit') {
    options = weatherMetricOptions;
  }

  const newWeatherOverlay = options.find(
    ({ index }) => index === weatherOverlay?.index
  );

  setWeatherOverlay(newWeatherOverlay);
};

/**
 *
 */
export const fitBounds = ({
  bounds,
  obounds,
  setOBounds,
  map,
  zoom,
}: {
  bounds: any;
  obounds: any;
  setOBounds: (bounds: any) => void;
  map: google.maps.Map;
  zoom?: number;
}) => {
  const nOBounds = [...obounds];
  const nBounds = bounds;
  setOBounds(bounds);

  if (nBounds.length > 1) {
    const array: any = [...bounds].filter((o) => o.lat);
    const mapData = getBoundsBox(array);
    map.fitBounds(mapData, 100);

    if (array.length === 3) map.setZoom(13);
    else zoom && map.setZoom(zoom);
    return;
  }

  map.setZoom(zoom || 18);
  const destination = new google.maps.LatLng(bounds[0].lat, bounds[0].lng);
  
  const animatedPanTo = (map: google.maps.Map, destination: google.maps.LatLng, duration: number) => {
    const start = nOBounds[0] || map.getCenter() as any;
    const startTimestamp = performance.now();

    const animate = () => {
      const elapsed = performance.now() - startTimestamp;
      const progress = Math.min(elapsed / duration, 1);
      const easing = (t: number) => t * (2 - t);
      const interpolatedLatLng = google.maps.geometry.spherical.interpolate(start, destination, easing(progress));
      map.panTo(interpolatedLatLng);

      if (progress < 1) {
        requestAnimationFrame(animate);
      }
    }

    animate();
  }

  const panDuration = 500;
  animatedPanTo(map, destination, panDuration);
};
