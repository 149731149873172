import { Slick } from './slickgrid';
import { SlickTableProps } from './slick-table.d';
import { TrackingPointType } from 'app/interfaces/inspection.interfaces';

const STORAGE_COLUMN_KEY = Math.random().toString();

/**
 *
 * @param columns
 * @param dispatch
 */
export const init = (
  columns: any[],
  data: unknown[],
  option: any,
  onLoad?: (grid: any) => void,
) => {
  const columnsReference = {};
  columns.forEach((c) => {
    columnsReference[c.id] = c;
  });

  // get on local storage potentials configuration saved, to keep
  // user configuration
  const stringColumns = window.localStorage.getItem(STORAGE_COLUMN_KEY);
  let gridColumns: any[];

  // check if there is saved columns configuration
  // ?? this configuration is restricted to the browser
  // if another user is logged should get configuration
  // of preveouly user. would we save this configuration
  // with a user reference
  if (stringColumns) {
    gridColumns = JSON.parse(stringColumns);
    gridColumns = gridColumns.filter((c) => columnsReference[c.id]);
    gridColumns = gridColumns.map((c) => ({
      ...columnsReference[c.id],
      originalWidth: c.originalWidth,
    }));
  } else {
    gridColumns = [...columns];
  }

  // get element to render the slickgrid
  const gridElement = document.querySelector('#grid') as HTMLElement;
  if (!gridElement) throw 'There is not element wrapper to grid';

  // init slickgrid
  const grid = new Slick.Grid(
    gridElement,
    data as Slick.SlickData[],
    gridColumns,
    option,
  );

  grid.onDblClick.subscribe((e: any, args: any) => {
    const column: any = grid && grid.getColumns && grid.getColumns()[args.cell];
    column?.onCellDblClick?.(e, args);
  });

  grid.onClick.subscribe((e: any, args: any) => {
    const column: any = grid.getColumns && grid.getColumns()[args.cell];
    column?.onCellClick?.(e, args);
  });

  grid.onCellChange.subscribe((e: any, args: any) => {
    const column: any = grid.getColumns && grid.getColumns()[args.cell];
    column?.onCellChange?.(e, args);
  });

  grid.onColumnsReordered.subscribe(() => {
    window.localStorage.setItem(
      STORAGE_COLUMN_KEY,
      JSON.stringify(grid.getColumns())
    );
  });

  grid.onColumnsResized.subscribe(() => {
    window.localStorage.setItem(
      STORAGE_COLUMN_KEY,
      JSON.stringify(grid.getColumns())
    );
  });

  const pluginOptions = {
    readOnlyMode : false,
    includeHeaderWhenCopying : false,
  };


  // register copy pasta plugin
  grid.setSelectionModel(new Slick.CellSelectionModel());
  grid.registerPlugin(new Slick.CellExternalCopyManager(pluginOptions));

  // set resize lister
  // TODO: clear listener on unmount, this should be spending client memory
  window.addEventListener('resize', () => {
    grid.resizeCanvas();
  });

  grid.render();

  // dristribute grid to parents, if necessary
  if (onLoad) onLoad(grid);
};


export const refreshTableLines = (grid: any, props: SlickTableProps) => {
  if (grid) {
    grid.setData(props.data);
    grid.invalidate()
    
    // Add a css class to all qube lines
    const lineIndex: number[] = props.data.filter((d: any) => d?.is_device_trigger)
      .map((l: any) => (l?.index - 1) as number);

    const modifiedCells = {};
    lineIndex.forEach((line: number) => {
      const tpList = props.data as TrackingPointType[];
      const isDisabled = tpList[line].disabled;
      if (!modifiedCells[line]) {
        modifiedCells[line] = {};
      }
      const gridC = grid.getColumns();
      gridC.forEach((c) => {
        modifiedCells[line][c.id] = `${isDisabled ? 'disabled-point' : 'qube-point'}`;
      });
    });
    grid.setCellCssStyles('modified', modifiedCells);
  }
}
