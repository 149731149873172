import {
  SurveyFileSetType,
  SurveyPlainType,
  SurveyPointType,
  SurveyType
} from 'app/modules/survey/survey.interfaces.d';
import { SurveyContext } from '../survey.context.d';


const mapSurvey = (data: SurveyType, fileSet: number[], pointSet: number[]): SurveyPlainType => ({
  created: data.created, // "2023-07-18T12:03:44.359157Z"
  customer: data.customer?.id, // {company: {…}, id: 1, subscribed: false, name: 'CDI', template: null, …}
  geoid_model: data.geoid_model, //""
  gps_datum: data.gps_datum,
  gps_equipment: data.gps_equipment,
  gps_survey_accuracy: data.gps_survey_accuracy,
  id: data.id,
  name: data.name,
  owner: data.owner,
  pipeline: data.pipeline.id,
  speed_up_at_tees: data.speed_up_at_tees,
  survey_by: data.survey_by?.id || null,
  survey_company: data.survey_company,
  surveyor: data.surveyor,
  surveyor_phone: data.surveyor_phone,
  updated_at: data.updated_at,
  surveyfile_set: fileSet,
  surveypoint_set: pointSet,
})


/**
 * 
 * @param state 
 * @param point 
 * @returns 
 */
export const serializeSurvey = (state: SurveyContext, survey: SurveyType): SurveyContext => {
  const stateCopy = { ...state };
  const pointSet: number[] = [];
  const pointDic = {};
  const surveyFileSet: number[] = [];
  const surveyFileDic = {};
  
  survey.surveyfile_set.forEach((file: SurveyFileSetType) => {
    surveyFileSet.push(file.id);
    surveyFileDic[file.id] = file;
  });

  survey.surveypoint_set.forEach((point: SurveyPointType) => {
    pointSet.push(point.id);
    pointDic[point.id] = point;
  });

  const mappedSurvey = mapSurvey(survey, surveyFileSet, pointSet);

  stateCopy.points_dic = pointDic;
  stateCopy.files_dic = surveyFileDic;
  stateCopy.survey = mappedSurvey;
  stateCopy.pipeline = survey.pipeline;


  return stateCopy as SurveyContext;
}
