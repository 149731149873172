import { useMemo } from 'react';
import Radio from 'app/components/radio/radio';
import { timezoneListModel } from 'app/models/timezones';
import { Timezone } from 'app/store/store.interfaces';
import {
  MobileTimezoneModalContent,
  MobileTimezoneModalContentCloseButton,
  MobileTimezoneModalContentCurrentWrapper,
  MobileTimezoneModalContentCurrentTimezone,
  MobileTimezoneModalContentCurrentTimezoneTitle,
  MobileTimezoneModalContentHeader,
  MobileTimezoneModalContentTimezoneList,
  MobileTimezoneModalContentTimezoneListItem,
  MobileTimezoneModalContentTitle,
  MobileTimezoneModalWrapper,
} from './mobile-timezone-modal.style';

interface Props {
  onClose: () => void;
  onTimezoneChange: (timezone: Timezone) => void;
  timezone: Timezone;
}

/**
 *
 * @param props
 * @returns
 */
const MobileTimezoneModal = (props: Props) => {
  const timezone = useMemo(() => timezoneListModel.find(({ location }) => props.timezone.id === location), [props.timezone]);

  /**
   *
   */
  const renderTimezones = () => timezoneListModel.map((tz) => (
    <MobileTimezoneModalContentTimezoneListItem
      key={tz.location}
      onClick={() => props.onTimezoneChange({ ...tz, id: tz.location, label: tz.name })}
    >
      <Radio text={tz.name} selected={props.timezone.id === tz.location} />
    </MobileTimezoneModalContentTimezoneListItem>
  ));

  return (
    <MobileTimezoneModalWrapper>
      <MobileTimezoneModalContent>
        <MobileTimezoneModalContentHeader>
          <MobileTimezoneModalContentTitle>
            Timezone
          </MobileTimezoneModalContentTitle>

          <MobileTimezoneModalContentCloseButton onClick={props.onClose}>
            <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="16.2637" y="4.94922" width="2" height="16" transform="rotate(45 16.2637 4.94922)" fill="#99A2AD"/>
              <rect x="17.6777" y="16.2637" width="2" height="16" transform="rotate(135 17.6777 16.2637)" fill="#99A2AD"/>
            </svg>
          </MobileTimezoneModalContentCloseButton>
        </MobileTimezoneModalContentHeader>

        <MobileTimezoneModalContentCurrentWrapper>
          <MobileTimezoneModalContentCurrentTimezoneTitle>Current timezone</MobileTimezoneModalContentCurrentTimezoneTitle>
          <MobileTimezoneModalContentCurrentTimezone>{timezone?.name || '-'}</MobileTimezoneModalContentCurrentTimezone>
        </MobileTimezoneModalContentCurrentWrapper>

        <MobileTimezoneModalContentTimezoneList>
          {renderTimezones()}
        </MobileTimezoneModalContentTimezoneList>
      </MobileTimezoneModalContent>
    </MobileTimezoneModalWrapper>
  );
};

export default MobileTimezoneModal;

