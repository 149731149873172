import { PipelineType } from "app/interfaces/inspection.interfaces";
import { DrivelinePolysType } from "../survey/survey.d";
import { formatPipePointsToPath } from "@/lib/formatter";
import { getBoundsBox } from "app/utils/map.utils";
import { InspectionContext, InspectionProviderValue } from "app/modules/inspection/inspection.context.d";
import { AccountProviderValue, AuthType } from "app/modules/account/account.context.d";

/**
 *
 * @param param0
 */
export const watchCurrentPosition = ({
  marker,
  dispatch,
}: {
  marker: google.maps.Marker | undefined;
  dispatch: InspectionProviderValue['dispatch'];
}) => {
  return marker ? navigator.geolocation.watchPosition(
    (position) => {
      marker.setPosition({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });

      dispatch({
        type: 'SET_LOGGED_USER_POSITION',
        data: {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        }
      });
    },
    () => '',
    { enableHighAccuracy: true, maximumAge: 0, timeout: 10000 }
  ) : undefined;
};

/**
 * renderPipeline
 * @returns
 */
export const renderPipeline = ({
  inspectionContext,
  map,
}: {
  map?: google.maps.Map;
  inspectionContext: InspectionProviderValue;
}) => {
  const pipelinePoly = inspectionContext.state.pipeline_poly;
  const pipeline = inspectionContext.state.pipeline;

  if (pipelinePoly?.inner) pipelinePoly.inner.setMap(null);
  if (pipelinePoly?.outer) pipelinePoly.outer.setMap(null);
  if (!pipeline?.geometry?.coordinates) return;

  const points = formatPipePointsToPath({
    project: null,
    name: null,
    length: null,
    id: null,
    start: { coordinates: [], type: '' },
    geometry: { coordinates: pipeline.geometry.coordinates, type: '' },
  });

  const innerPipe = new window.google.maps.Polyline({
    path: points,
    geodesic: true,
    strokeColor: '#f1f1f1',
    strokeOpacity: 0.45,
    strokeWeight: 4,
    zIndex: 1,
    map,
  });

  const outerPipe = new window.google.maps.Polyline({
    path: points,
    geodesic: true,
    strokeColor: '#6e7881',
    strokeOpacity: 1,
    strokeWeight: 8,
    zIndex: 0,
    map,
  });

  inspectionContext.dispatch({
    type: 'SET_PIPELINE_POLY',
    data: { outer: outerPipe, inner: innerPipe }

  })
};

/**
 * renderDriveline
 * @param param0
 */
export const renderDriveline = ({
  inspectionContext,
  map,
}: {
  inspectionContext: InspectionProviderValue;
  map: google.maps.Map;
}) => {
  const driveline = inspectionContext.state.pipeline?.driveline;

  if (!driveline?.coordinates) return;
  inspectionContext.state.driveline_poly?.forEach((polyline) => {
    polyline.setMap(null);
  });

  const polylines: DrivelinePolysType = [];

  driveline.coordinates.forEach((path: any) => {
    const points = path.map((p: number[]) => ({ lng: p[0], lat: p[1] }));
    const polyline = new google.maps.Polyline({
      path: points,
      geodesic: true,
      strokeColor: '#4e4ee9',
      strokeOpacity: 1,
      strokeWeight: 2,
      zIndex: 1,
      map,
    });

    polyline.setMap(map);
    polylines.push(polyline);
  });

  inspectionContext.dispatch({
    type: 'SET_DRIVELINE_POLY',
    data: polylines,
  });
};

/**
 *  
 * @param param0
 */
export const setLoggedUserMarker = (
  accountContext: AccountProviderValue,
  inspectionContext: InspectionProviderValue,
  map,
) => {
  if (inspectionContext.state.point_markers) {
    Object.keys(inspectionContext.state.point_markers).forEach((key) => {
      inspectionContext.state.point_markers[key].setMap(null);
    });
  }
  const user = accountContext.state.auth?.user as AuthType['user'];

  const avatar = 
      user.avatar && user.avatar[0] === '/'
        ? `${user.avatar}`
        : user.avatar;
  
  const customerLogo = inspectionContext.state.project?.customer?.company?.logo;
  // get customer avatar
  const customerAvatar =
    customerLogo && customerLogo[0] === '/' ? `${customerLogo}` : customerLogo;

  // define avatar to be used
  const url = avatar || customerAvatar || '/assets/icons/gt-logo.svg';

  const icon = {
    url,
    scaledSize: new google.maps.Size(25, 25), // scaled size
    origin: new google.maps.Point(0, 0), // origin
    anchor: new google.maps.Point(12.5, 12.5), // anchor
  };

  const marker = new window.google.maps.Marker({
    map,
    icon,
    title: 'You',
    opacity: 1,
    zIndex: 10,
  });
    
  marker.setZIndex(4);

  inspectionContext.dispatch({
    type: 'SET_LOGGED_USER_MARKER',
    data: marker,
  })
};


/**
 *
 */
export const centerMap = ({
  pipeline,
  map,
}: {
  pipeline?: PipelineType;
  map?: google.maps.Map;
}) => {
  if (pipeline?.geometry && map) {
    const points = formatPipePointsToPath({
      project: null,
      name: null,
      length: null,
      id: null,
      start: { coordinates: [], type: '' },
      geometry: { coordinates: pipeline.geometry.coordinates, type: '' },
    });
    if (points) {
      const center: any = getBoundsBox(points);
      map.fitBounds(center, 70);
    }

  }
};

/**
 *
 */
export const getLogoOrientation = (project: InspectionContext['project']) => {
  const subscribed = project?.customer?.subscribed;
  const logo = project?.customer?.company?.logo;
  
  return (subscribed && logo) ? logo : 'PORTRAIT';
};

/**
 *
 */
export const getLogoImage = (project: InspectionContext['project']) => {
  const subscribed = project?.customer?.subscribed;
  const logo = project?.customer?.company?.logo;  

  return (subscribed && logo) ? logo : '/assets/icons/gt-logo.svg';
};
