import { useContext } from 'react';
import { AGMTitle, CloseButton, Content, DeviceSerialNumber, Header, Modal, ModalWrapper, TriggersAmount } from './satelite-passages-modal.style';
import SatellitePassages from '../satellite-passages/satellite-passages';
import { AuthType } from 'app/modules/account/account.context.d';
import AccountStore from 'app/modules/account/account.context';
import InspectionStore from 'app/modules/inspection/inspection.context';
import SettingsStore from 'app/modules/settings/settings.context';

/**
 *
 * @returns
 */
const SatelitePassagesModal = () => {
  const accountContext = useContext(AccountStore);
  const auth = accountContext.state.auth as AuthType;
  const inspectionContext = useContext(InspectionStore);
  const settingsContext = useContext(SettingsStore);
  const tpointDic = inspectionContext.state.trackingpoints_dic;
  const tpoint = tpointDic[inspectionContext.state.selected_trigger_point as number];

  /**
   * close satellite modal
   */
  const onClose = () => {
    inspectionContext.dispatch({
      type: 'SET_SELECTED_TRIGGER_POINT',
      data: undefined,
    });
  };

  return (
    <ModalWrapper open onClick={onClose}>
      <Modal onClick={(e) => e.stopPropagation()}>
        <Header>
          <div>
            <AGMTitle>{tpoint.name}</AGMTitle>
            <DeviceSerialNumber>
              DEVICE SERIAL NUMBER: {tpoint.device_sn || '-'}
            </DeviceSerialNumber>
          </div>
          <TriggersAmount>
            {tpoint?.trigger_set?.length}{' '}
            {(tpoint?.trigger_set?.length || 0) > 1 ? 'triggers' : 'trigger'} total
          </TriggersAmount>
          <CloseButton onClick={onClose}>
            <svg
              width="23"
              height="23"
              viewBox="0 0 23 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="16.2637"
                y="4.94922"
                width="2"
                height="16"
                transform="rotate(45 16.2637 4.94922)"
                fill="#99a2ad"
              />
              <rect
                x="17.6777"
                y="16.2637"
                width="2"
                height="16"
                transform="rotate(135 17.6777 16.2637)"
                fill="#99a2ad"
              />
            </svg>
          </CloseButton>
        </Header>
        <Content>
          <SatellitePassages
            onClose={onClose}
            agm={tpoint}
            timezone={settingsContext.state.timezone}
            authToken={auth.token}
            isObserver={inspectionContext.state.is_observer}
          />
        </Content>
      </Modal>
    </ModalWrapper>
  );
};

export default SatelitePassagesModal;
