import { useEffect, useState } from 'react';

const SOCKET_URL = import.meta.env.VITE_SOCKET_URL;

/* For performance purposes, each item in payload may be an url,
  and it needs to be substituted by the returned by that url.
  This way we can make use of caching for the snapshot.
  processNext will do this asynchronously, while preserving processing
  order.
*/

export type OperationType = {
  operation?: string,
  instance_type?: string,
  data?: unknown,
  _instance_type?: string,
  _deleted?: boolean,
};

const connect = (
  channel,
  id,
  setWebSocket,
  handleOnMessage,
  onConnected?: (ws: WebSocket) => void,
  onError?: () => void,
) => {
  const onClose = (onError ? onError : () => '') as () => void;
  try {
    if (SOCKET_URL && channel && id) {
        const ws = new WebSocket(`${SOCKET_URL}/${channel}/${id}/`);
        setWebSocket(ws);
        ws.onmessage = handleOnMessage;
        ws.onclose = onClose;
        if (onConnected) {
          ws.onopen = () => onConnected(ws);
        }
        return ws;
      }
  } catch (err) {
    console.log('error socket')
  }
}


/**
 *
 * @param channel
 * @param id
 * @param token
 */
export const useWebSocket = (
  channel: string,
  id: string,
  onMessage?: (payload: unknown[], operation?: string) => void,
  onTransactionChange?: (transaction: string) => void,
  onConnected?: (ws: WebSocket) => void,
  onError?: () => void,
  reconnect?: boolean,
): WebSocket | undefined => {
  const [wSocket, setWebSocket] = useState<WebSocket>();
  const handleOnMessage = (payload: MessageEvent) => {
    const data = JSON.parse(payload.data) as unknown[];
    onMessage && onMessage(data);
    const lastObject = data[data.length - 1] as { _tstamp: string };
    if (onTransactionChange && lastObject?._tstamp) onTransactionChange(lastObject._tstamp);
  };

  useEffect(() => {
    const ws = connect(channel, id, setWebSocket, handleOnMessage, onConnected, onError)

    return () => {
      if (ws) ws.close();
    };
  }, []);

  useEffect(() => {
    if (wSocket) {
      connect(channel, id, setWebSocket, handleOnMessage, onConnected, onError)
    }
  }, [reconnect]);

  return wSocket;
};
