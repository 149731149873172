import { InspectionContext } from '../inspection.context.d';
import { QubeDeviceType } from 'app/modules/qube/qube.interfaces';

/**
 * 
 * @param state 
 * @param qube 
 * @returns 
 */
export const updateQube = (state: InspectionContext, qube: QubeDeviceType) => {
  const stateCopy = { ...state };

  if (!state.qubes_dic[qube.serial]) {
    stateCopy.qube_set.push(qube.serial);
  }
  stateCopy.qubes_dic[qube.serial] = qube;
  return stateCopy;
};

