import { InspectionProviderValue } from "app/modules/inspection/inspection.context.d";
import { EstimationType, RunType } from "app/modules/inspection/inspection.interfaces";


/**
 *
 * @param param0
 */
export const changeTrackingPointMarker = ({
  marker,
  estimation,
  map,
  run,
}: {
  map: google.maps.Map;
  marker?: google.maps.Marker;
  estimation: EstimationType;
  run?: RunType;
}) => {
  if (!estimation.location || !marker) return;

  if (run?.is_finished || !run?.launched) {
    marker.setMap(null);
  }
  
  if (run?.launched && !run?.is_finished) {
    marker.setMap(map);
  }
  
  marker.setPosition({
    lat: estimation.location.coordinates[1],
    lng: estimation.location.coordinates[0],
  })
};

/**
 *  renderTrackingpoints
 * @param param0
 */
export const renderMarker = (
  inspectionContext: InspectionProviderValue,
  estimation: EstimationType,
  map: google.maps.Map,
  oldmarker?: google.maps.Marker,
) => {
  if (oldmarker) {
    oldmarker.setMap(null);
  }

  const icon = {
    url: '/assets/icons/tracking-points/pig.svg',
    scaledSize: new window.google.maps.Size(70, 70), // scaled size
    origin: new window.google.maps.Point(0, 0), // origin
    anchor: new window.google.maps.Point(35, 35), // anchor
  };

  const marker = new window.google.maps.Marker({
    icon,
  });

  if (estimation.location?.coordinates) {
    marker.setPosition({
      lat: estimation.location?.coordinates[1],
      lng: estimation.location?.coordinates[0],
    })
  }
  marker.setMap(map)
  marker.setZIndex(3);

  // Open the InfoWindow on mouseover:
  google.maps.event.addListener(marker, 'mouseover', (e: any) => {
    inspectionContext.dispatch({
      type: 'SET_EXPLANATION_MAP_TOOLTIP',
      data: {
        type: 'PIG',
        position: e.latLng,
      }
    })
  });

  google.maps.event.addListener(marker, 'mouseout', () => {
    inspectionContext.dispatch({
      type: 'SET_EXPLANATION_MAP_TOOLTIP',
      data: undefined
    });
  });

  return marker;
};