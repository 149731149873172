import { Button } from './view-button.style';

const ViewButton = ({
  onClick,
  observerView,
}: {
  onClick: () => void;
  observerView: boolean;
}) => (
  <Button
    active={observerView}
    title={observerView ? 'Go to Editor view' : 'Go to Observer view'}
    onClick={onClick}
  >
    {observerView ? (
      <svg viewBox="0 0 18 11" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0.0552642 4.98303C0.0795333 4.93236 0.111678 4.88497 0.151688 4.84217C3.11485 1.60992 6.12242 -0.0236692 9.108 0.000259098C12.0548 0.0192812 14.9973 1.64993 17.8527 4.84679C17.891 4.88828 17.9216 4.93435 17.9449 4.98306C17.9818 5.06013 18 5.1436 18 5.22657V5.22756V5.22772C17.9998 5.30955 17.982 5.39154 17.9457 5.46762C17.9223 5.51731 17.8909 5.56405 17.8524 5.60635C14.997 8.80288 12.0546 10.4331 9.10809 10.4525C9.09055 10.4525 9.07333 10.4526 9.05578 10.4526C6.09279 10.4526 3.09722 8.82361 0.151519 5.6103C0.111508 5.56766 0.079366 5.51994 0.0550976 5.46943C0.0182015 5.39236 -3.94494e-07 5.30939 -3.94494e-07 5.22625C-3.94494e-07 5.14311 0.0182017 5.05981 0.0552616 4.9829L0.0552642 4.98303ZM9.10058 9.32749C11.6047 9.31125 14.1515 7.932 16.6762 5.2263C14.1513 2.5206 11.6051 1.14132 9.10058 1.12512C9.08549 1.12512 9.07106 1.12496 9.05598 1.12496C6.53507 1.12496 3.93868 2.50406 1.33301 5.22614C3.95425 7.96479 6.56156 9.34915 9.10089 9.32749L9.10058 9.32749ZM9.00006 2.45797C10.5267 2.45797 11.7686 3.6998 11.7686 5.22647C11.7686 6.75314 10.5267 7.99497 9.00006 7.99497C7.47339 7.99497 6.23156 6.75314 6.23156 5.22647C6.23156 3.6998 7.47339 2.45797 9.00006 2.45797ZM9.00006 6.87001C9.9064 6.87001 10.6435 6.1326 10.6435 5.2266C10.6435 4.32026 9.90606 3.58318 9.00006 3.58318C8.09406 3.58318 7.35665 4.3206 7.35665 5.2266C7.35648 6.13276 8.09376 6.87001 9.00006 6.87001Z"
          fill="000000"
        />
      </svg>
    ) : (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
        <path d="M4 48v-6.05h40V48Zm4.1-11.35V30l18.65-18.65L33.4 18 14.75 36.65Zm3-3h2.25L29.1 17.9l-2.25-2.25L11.1 31.4ZM35.6 15.8l-6.65-6.65 4.2-4.2q.55-.65 1.25-.675.7-.025 1.4.675l3.9 3.9q.65.65.65 1.375T39.8 11.6ZM11.1 33.65Z" />
      </svg>
    )}
  </Button>
);

export default ViewButton;
