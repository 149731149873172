import styled from 'styled-components';

export const Header = styled.header<{ hidden: boolean }>`
  display: none;
  position: relative;

  z-index: 2;
  background: rgba(62, 66, 85, 0.65);
  backdrop-filter: blur(10px);

  gap: 16px;

  width: 100%;

  align-items: center;
  justify-content: space-between;

  height: auto;
  padding: 10px 15px;

  will-change: transform, height;
  transition-property: transform, height;
  transition-duration: 0ms;

  > div {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  > button {
    bottom: -42px;
  }

  @media (min-width: 1024px) {
    display: flex;
    ${({ hidden }) => (hidden ? 'display: none;' : '')}
  }
  ${({ hidden }) => (hidden ? 'display: none;' : '')}
`;

export const RunName = styled.h1`
  display: inline-block;

  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;

  max-width: 350px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  color: #ffffff;
`;

export const ConnectionErrorLabel = styled.label<{ show: boolean }>`
  display: inline-block;

  font-family: Inter;
  font-style: normal;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;

  color: #ffffff;
  background-color: #dc143c;

  padding: 5px 10px;
  border-radius: 8px;
  ${({ show }) => (show ? '' : 'display: none;')}
`;
export const ConnectionErrorLabelTimezone = styled.label`
  font-size: 10px;
  padding-left: 5px;
  font-weight: normal;
`;

export const DocumentationButton = styled.button`
  width: 30px;
  height: 30px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: rgba(62, 66, 85, 0.65);
  backdrop-filter: blur(10px);

  border-radius: 100%;
  color: #fff;
  font-size: 17px;
  line-height: 1em;

  box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;

  cursor: pointer;

  > svg {
    position: relative;
    top: -1px;
  }
`;
export const DownloadButton = styled.button`
  width: 30px;
  height: 30px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: rgba(62, 66, 85, 0.65);
  backdrop-filter: blur(10px);

  border-radius: 100%;
  color: #fff;
  font-size: 17px;
  line-height: 1em;

  box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;

  cursor: pointer;

  > svg {
    position: relative;
    top: -1px;
  }
`;

export const ObserverButton = styled.button<{ displayNone: boolean }>`
  background: #5850ec;
  border-radius: 6px;

  color: #fff;
  padding: 7px 12px;

  display: flex;
  align-items: center;
  cursor: pointer;
  > svg {
    margin-right: 5px;
  }
  ${({ displayNone }) => (displayNone ? 'display: none;' : '')}
`;

export const downloadCustomStyle = `
button {
  border-radius: 100%;
  padding: 8px;
  svg { width: 12px; margin: 0; }
}
>ul {
  top: calc(100% - 15px);
  right: 15px;
  width: 60px;

  >li {
    padding: 0;

    >ul {
      padding: 0;
      gap: 3px;
      >li {
        padding: 8px;
        cursor: pointer;
        label {
          cursor: pointer;
        }
      }
      >li:hover {
        background-color: #f1f1f1
      }
    }
  }
}
`