import {  RunType } from 'app/interfaces/inspection.interfaces';
import { verifyToken } from 'app/services/auth.service';
import { checkIsProjectAdministrator, deleteProject, loadProjectRuns } from 'app/services/projects.service';
import { deleteRun } from 'app/services/run.service';
import { ProjectsProviderValue } from '../projects.context.d';

/**
 * 
 * @param websocket 
 * @param token 
 * @param transaction 
 */
export const handleOnOpen = (websocket: WebSocket, token: string, transaction: string) => {
  // start server connection, request the snapshot
  websocket.send(`${token} ${transaction}`);
};


/**
 * this manage changes received by socket messages
 */
export const handleOnMessage = (
  payload: unknown[],
  dispatch: ProjectsProviderValue['dispatch'],
) => {
  for (const data of payload) {
    const { _instance_type } = data as {  _instance_type: string };

    if (_instance_type === 'project.serializers.ProjectSerializer') {
      dispatch({
        type: 'SET_PROJECT',
        data,
      });
    }

    if (_instance_type === 'project.serializers.ParticipantSerializer') {
      dispatch({
        type: 'UPDATE_PARTICIPANT',
        data,
      });
    }

    if (/ParticipantSerializer/.test(_instance_type)) {
      // debugger;
    }
    
    if (_instance_type === 'account.serializers.UserSerializer') {
      dispatch({
        type: 'UPDATE_USER',
        data,
      });
    }
    
    if (_instance_type === 'qube.serializers.QubeDeviceSerializer') {
      dispatch({
        type: 'UPDATE_QUBE',
        data,
      });
    }
  }
};

/**
 * 
 * @param id 
 * @param token 
 * @param setIsAdministrator 
 * @param setUnauthorized 
 */
export const checkPermissions = async (
  id: string,
  token: string,
  setIsAdministrator: (isAdministrator: boolean) => void,
  setUnauthorized: (unauthorized: boolean) => void,
) => {
  try {
    const checkAdminResponse = await checkIsProjectAdministrator(id, token);
    setIsAdministrator(checkAdminResponse.data);
  } catch (err: any) {
    // TOOD: err inteface
    if (err.response?.status === 403) setUnauthorized(true);
  }
};

/**
 * 
 * @param id 
 * @param token 
 * @returns 
 */
export const fetchProjectRuns = async (id: string, token: string, setRuns: (runs: RunType[]) => void) => {
  const response = await loadProjectRuns(parseInt(id), token);
  setRuns(response.data);
};

/**
 * 
 * @param param
 */
export const onDeleteRun = async ({
  setLoading,
  setRuns,
  runs,
  run,
  auth,
}) => {
  setLoading(true);

  await deleteRun(run.id, auth.token);
  const filteredRuns = runs.filter((r: any) => r.id !== run.id);
  setRuns(filteredRuns);
  setLoading(false);
};

/**
 *
 * @param params init params
 * @param params.setLoading toggle loading on component state
 * @param params.setProject save project on component state
 * @param params.setProject save the runs on component state
 */
export const onDeleteProject = async ({
  setLoading,
  id,
  auth,
  history,
  accountDispatch,
}) => {
  setLoading(true);

  await deleteProject(`${id}`, auth.token);
  history.push('/projects');
  setLoading(false);

  const authResponse = await verifyToken(auth.token);
  accountDispatch({
    type: 'LOGIN',
    data: { token: auth.token, user: authResponse.data.user },
  });
};
