import {
  DrivelineedPiece,
  DrivelineWrapper,
  HelpButton,
  HelpCard,
  HelpCardImage,
  HelpCardText,
  HelpCardTitle,
  HelpContainer,
  HelpList,
  HelpTextWrapper,
  HelpTitle,
  PipelineHightLightedPiece,
  PipelineHightLightWrapper,
  PipelinePiece,
  SeparatorWrapper,
} from './helpButtonIconsLegend.style';

/**
 *
 * @param param0
 * @returns
 */
const HelpButtonLegend = ({ show, handleShow, hidden, title }: any) => {
  return (
    <HelpButton hidden={hidden} onClick={handleShow} title={title}>
      <label>?</label>
      <HelpContainer show={show}>
        <SeparatorWrapper>
          <HelpTitle>Map Points</HelpTitle>
          <HelpList>
            <HelpCard>
              <HelpCardImage src="https://cdi-gt-medias.s3.us-east-2.amazonaws.com/default/agm.svg" />
              <HelpTextWrapper>
                <HelpCardTitle>Blue points: Default</HelpCardTitle>
                <HelpCardText>
                  The tool will arrive and Hold here, awaiting a time input.
                </HelpCardText>
              </HelpTextWrapper>
            </HelpCard>
            <HelpCard>
              <HelpCardImage src="https://cdi-gt-medias.s3.us-east-2.amazonaws.com/yellow/agm.svg" />
              <HelpTextWrapper>
                <HelpCardTitle>
                  {'Yellow points: Awaiting tool arrival'}
                </HelpCardTitle>
                <HelpCardText>
                  The point is presently Holding for a time input.
                </HelpCardText>
              </HelpTextWrapper>
            </HelpCard>
            <HelpCard>
              <HelpCardImage src="https://cdi-gt-medias.s3.us-east-2.amazonaws.com/gray/agm.svg" />
              <HelpTextWrapper>
                <HelpCardTitle>Grey points: Do not hold</HelpCardTitle>
                <HelpCardText>
                  Will not Hold for a time input (often a preset AGM), or was
                  skipped due to downstream point having been tracked.
                </HelpCardText>
              </HelpTextWrapper>
            </HelpCard>
          </HelpList>
        </SeparatorWrapper>
        <SeparatorWrapper>
          <HelpTitle>Pipeline Routes</HelpTitle>
          <HelpList>
            <HelpCard>
              <PipelinePiece color="#b8b8b8" />
              <HelpTextWrapper>
                <HelpCardTitle>Gray: Default</HelpCardTitle>
                <HelpCardText>
                  Pipeline&apos;s standard route across the map.
                </HelpCardText>
              </HelpTextWrapper>
            </HelpCard>
            <HelpCard>
              <PipelinePiece color="#9acd32" />
              <HelpTextWrapper>
                <HelpCardTitle>Green: Successfully tracked</HelpCardTitle>
                <HelpCardText>
                  Pipeline behind the tool that has been successfully tracked.
                </HelpCardText>
              </HelpTextWrapper>
            </HelpCard>
            <HelpCard>
              <PipelineHightLightWrapper>
                <PipelineHightLightedPiece />
              </PipelineHightLightWrapper>
              <HelpTextWrapper>
                <HelpCardTitle>
                  Red highlight: Tool&apos;s positional margin of error
                </HelpCardTitle>
                <HelpCardText>
                  A region that represents the earliest likely arrival of the
                  tool and the latest likely arrival of the tool (i.e. the
                  tool&apos;s positional standard deviation).
                </HelpCardText>
              </HelpTextWrapper>
            </HelpCard>
            <HelpCard>
              <DrivelineWrapper>
                <DrivelineedPiece />
              </DrivelineWrapper>
              <HelpTextWrapper>
                <HelpCardTitle>Blue line: Vehicle driveline</HelpCardTitle>
                <HelpCardText>
                  Vehicle route taken to move between tracking points.
                </HelpCardText>
              </HelpTextWrapper>
            </HelpCard>
          </HelpList>
        </SeparatorWrapper>
      </HelpContainer>
    </HelpButton>
  );
};

HelpButtonLegend.defaultProps = {
  type: 'label',
  groupTitles: [],
};

export default HelpButtonLegend;
