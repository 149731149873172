import { useMemo, useState } from 'react';
import { getTrackingpointIcon } from '../../utils/map.utils';
import Input from '../search-input/search-input';
import { iconsArr } from './icons.model';
import {
  FlyToButton,
  Selector,
  SelectorItem,
  SelectorItemLabel,
  Wrapper,
  ListWrapper,
  IconChooserLabel,
  Background,
  ListHeader,
  ListFixedTop,
  SvgTag,
  IconImage,
} from './iconSelector.style';

const IconSelector = ({
  handleChange,
  row,
  showIconSelector,
  setShowIconSelector,
  permissionType,
  border,
  fill,
  customLabelStyle,
  iconSize,
}: any) => {
  const [searchText, setSearchText] = useState<string>('');
  const icons = useMemo(() => {
    const copyArr = [...iconsArr];
    const reg = RegExp(searchText, 'gi');
    return copyArr.filter((icon) => reg.test(icon.label) || reg.test(icon.id));
  }, [searchText]);
  const isActive =
    showIconSelector === row?.id && typeof showIconSelector !== 'undefined';

  return (
    <Wrapper>
      <Background
        oppened={isActive}
        onClick={() => setShowIconSelector(false)}
      />
      <FlyToButton
        openedList={isActive}
        disabled={permissionType !== 'editor'}
        onClick={() => {
          if (permissionType === 'editor') {
            setShowIconSelector(isActive ? '' : row?.id);
          }
        }}
      >
        <IconImage
          alt={row.name}
          src={
            getTrackingpointIcon(row, !border ? 'no-border' : undefined)?.url
          }
          size={iconSize}
        />
        <svg
          width="9"
          height="7"
          viewBox="0 0 8 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.86824 5.48057C4.48435 6.15239 3.51565 6.15239 3.13176 5.48057L0.854936 1.49614C0.47399 0.829482 0.955357 3.37812e-07 1.72318 4.04937e-07L6.27682 8.03029e-07C7.04464 8.70154e-07 7.52601 0.829483 7.14506 1.49614L4.86824 5.48057Z"
            fill={fill || '#FFF'}
          />
        </svg>
      </FlyToButton>
      <ListWrapper oppened={isActive && permissionType === 'editor'}>
        <ListFixedTop>
          <ListHeader>
            <IconChooserLabel>Icon chooser</IconChooserLabel>
            <SvgTag
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => setShowIconSelector(false)}
            >
              <rect
                x="8.53564"
                y="0.0507812"
                width="2"
                height="12"
                rx="1"
                transform="rotate(45 8.53564 0.0507812)"
                fill="#161E2E"
              />
              <rect
                x="9.94971"
                y="8.53516"
                width="2"
                height="12"
                rx="1"
                transform="rotate(135 9.94971 8.53516)"
                fill="#161E2E"
              />
            </SvgTag>
          </ListHeader>
          <div style={{ paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px' }}>
            <Input
              value={searchText}
              onChange={({ target }) => setSearchText(target.value)}
              placeholder="Search"
              customStyle={`
                  padding: 0 5px;
                  height: 26px;
                  width: 100%;

                  >input {
                    font-size: 10px;
                  }
                `}
            />
          </div>
        </ListFixedTop>
        <Selector>
          {icons
            .sort((a: any, b: any) => {
              if (a.label > b.label) return 1;
              else return -1;
            })
            .map((icon: any) => (
              <SelectorItem
                key={icon.id}
                onClick={() => {
                  setShowIconSelector(false);
                  handleChange({
                    value: icon.id,
                    row,
                  });
                }}
              >
                {icon.icon}
                <SelectorItemLabel customLabelStyle={customLabelStyle}>{icon.label}</SelectorItemLabel>
              </SelectorItem>
            ))}
        </Selector>
      </ListWrapper>
    </Wrapper>
  );
};

export default IconSelector;
