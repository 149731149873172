import { useContext, useEffect, useState } from 'react';
import { extractNumbers, formatMps, formatSpeedToMps, formatTimezoneDate } from '@/lib/formatter';
import Button from '../button/button';
import InputGroup from '../input-group/inputGroup';
import Input from '../input/input';
import LinkButton from '../link-button/link-button';
import {
  ModalWrapper,
  Modal,
  ModalFigure,
  ButtonWrapper,
  ConfirmationTitle,
  ConfirmationText,
  ConfirmationFooter,
  TimeWrapper,
} from './confirmation-modal.style';
import Radio from '../radio/radio';
import InputDate from '../input-date/input-date';
import InputMask from 'react-input-mask';
import SettingsStore from 'app/modules/settings/settings.context';
import PassageEditor from '../slick-table/editors/PassageEditor';

const ConfirmationModal = ({
  icon,
  hide,
  title,
  text,
  confirmationText,
  cancelationText,
  onConfirm,
  onCancel,
  speedUnit,
  initialLaunchSpeed,
  updateSpeed,
}: any) => {
  const settingsContext = useContext(SettingsStore)
  const [launchSpeed, setLaunchSpeed] = useState<string>(initialLaunchSpeed);
  const [isTest, setIsTest] = useState<boolean>(false);
  const [launchDate, setLaunchDate] = useState<string>();
  const [launchTime, setLaunchTime] = useState<string>();

  useEffect(() => {
    const now = new Date();
    const time = formatTimezoneDate({
      date: now,
      timezone: settingsContext.state.timezone?.id || 'utc',
      format: 'HH:mm:ss.SSS',
    });
    const date = formatTimezoneDate({
      date: now,
      timezone: settingsContext.state.timezone?.id || 'utc',
      format: 'YYYY/MM/DD',
    });
    setLaunchDate(date);
    setLaunchTime(time);
    return () => {
      setLaunchDate(undefined); 
      setLaunchTime(undefined);
    }
  }, []);

  useEffect(() => {
    setLaunchSpeed(
      formatMps({
        distance: initialLaunchSpeed,
        unit: speedUnit,
        returnNumber: true,
      })
    );
  }, [speedUnit, initialLaunchSpeed]);

  return (
    <ModalWrapper hide={hide}>
      <Modal>
        <ModalFigure>{icon}</ModalFigure>
        <ConfirmationTitle>{title}</ConfirmationTitle>
        <ConfirmationText>{text}</ConfirmationText>
        <Radio
          customStyle="margin: 15px 0;"
          selected={isTest}
          text="Launch as test"
          onClick={() => setIsTest(!isTest)}
        />
        <ConfirmationFooter>
        <InputGroup
            label={`Launch Time ${settingsContext.state.timezone?.label}:`}
            error=""
            description='YYYY-MM-DD &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  HH:mm:ss.SSS'
            customStyle={`
              padding: 0;
              visibility: hidden;
              width: 2px;
              height: 2px;
              label {
                margin-bottom: 8px;
                padding-right: 8px;
                padding-bottom: 0px;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 500;
                font-size: 13px;
                line-height: 16px;
              }
              > div {

  
                > input {
                  width: 100px;
                  display: inline-block;
                  padding: 4px 8px;
                  border: 1px solid #e5e5e5;
                  border-radius: 4px;
  
                  &:active {
                    padding: 5px 10px;
                    border: 1px solid #5850ec;
                  }
                  
                  &:focus {
                    padding: 5px 10px;
                    border: 1px solid #5850ec;
                  }
                }
              }
            `}
          >
            <TimeWrapper>
              <InputDate
                placeholder="YYYY/MM/DD"
                customStyle={`
                  width: 100px;
                  display: inline-block;
                  padding-left: 4px;
                  height: 25px;
                  padding-right: 4px;
                `}
                testid="create-timepin-date-field"
                handleChange={({ value }) => {
                  setLaunchDate(
                    formatTimezoneDate({
                      date: value,
                      timezone: settingsContext.state?.timezone?.id || '',
                      format: 'YYYY/MM/DD',
                    })
                  )
                }}
                value={launchDate}
                id="confirmation-launch-modal-date"
              />
              <InputMask
                id="confirmation-launch-modal-time"
                maskChar=""
                mask="99:99:99.9999"
                placeholder='HH:mm:ss.SSS'
                value={launchTime}
                onChange={({ target }) => setLaunchTime(extractNumbers(target.value, true))}
              />
            </TimeWrapper>
          </InputGroup>
          <InputGroup
            label={`Predicted launch Speed (${speedUnit?.id})`}
            error=""
            customStyle={`
              padding: 0;

              label {
                font-size: 13px;
              }
            `}
          >
            <Input
              customStyle={`
                width: 60px;

                padding-left: 4px;
                padding-right: 4px;
              `}
              value={launchSpeed}
              handleChange={(event) => setLaunchSpeed(event.target.value)}
            />
          </InputGroup>
          <ButtonWrapper>
            <Button
              disabled={!launchSpeed}
              handleClick={() => {
                const passageEditor = new PassageEditor({});
                const date = passageEditor.serializeValue('passage_date', launchDate, '', settingsContext.state.timezone?.id)
                const time = passageEditor.serializeValue('passage_time', launchTime, date, settingsContext.state.timezone?.id)
                updateSpeed(launchSpeed, () => {
                  onConfirm({
                    launchSpeed: +formatSpeedToMps({
                      distance: launchSpeed,
                      unit: speedUnit?.id,
                    }),
                    isTest,
                    launchDate: new Date(time),
                  });
                })
              }}
              value={confirmationText}
            />
            <LinkButton handleClick={onCancel}>{cancelationText}</LinkButton>
          </ButtonWrapper>
        </ConfirmationFooter>
      </Modal>
    </ModalWrapper>
  );
};

ConfirmationModal.defaultProps = {
  hide: true,
  title: 'What is Lorem Ipsum?',
  text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
  confirmationText: 'Confirm',
  cancelationText: 'Cancel',
  icon: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#ffffff">
      <path d="M12 5.99L19.53 19H4.47L12 5.99M2.74 18c-.77 1.33.19 3 1.73 3h15.06c1.54 0 2.5-1.67 1.73-3L13.73 4.99c-.77-1.33-2.69-1.33-3.46 0L2.74 18zM11 11v2c0 .55.45 1 1 1s1-.45 1-1v-2c0-.55-.45-1-1-1s-1 .45-1 1zm0 5h2v2h-2z" />
    </svg>
  ),
};

export default ConfirmationModal;
