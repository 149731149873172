import { InspectionProviderValue } from 'app/modules/inspection/inspection.context.d';
import { TimePinType } from 'app/modules/inspection/inspection.interfaces.d';

/**
 *
 * @param tp
 * @param state
 * @param setSelectedTimePin
 * @param map
 * @returns
 */
const getTimepinMarker = (
  tp: TimePinType,
  map: google.maps.Map,
  inspectionDispatch: InspectionProviderValue['dispatch'],
) => {
  const icon: any = {
    url: 'https://cdi-gt-medias.s3.us-east-2.amazonaws.com/map-marker.svg',
    scaledSize: new google.maps.Size(36, 36), // scaled size
    origin: new google.maps.Point(0, 0), // origin
    anchor: new google.maps.Point(18, 36), // anchor
    legend: tp.title,
  };

  const marker = new google.maps.Marker({
    map,
    icon,
    title: tp.title,
  });

  if (tp.location) {
    marker.setPosition({
      lat: tp.location.coordinates[1],
      lng: tp.location.coordinates[0],
    });
  }

  marker.setZIndex(8);

  marker.addListener('click', () => inspectionDispatch({
    type: 'SET_SELECTED_TIMEPIN',
    data: tp.id,
  }));
  return marker;
};


/**
 *
 * @param param0
 */
export const changeMarker = ({
  marker,
  timePin,
  hasLabels,
}: {
  map: google.maps.Map;
  timePin: TimePinType;
  hasLabels: boolean;
  marker?: google.maps.Marker;
}) => {
  if (!timePin || !marker) return;

  if (timePin.location) {
    marker.setPosition({
      lat: timePin.location.coordinates[1],
      lng: timePin.location.coordinates[0],
    });
  }

  marker.setTitle(timePin.title);

  if (hasLabels) {
    marker.setLabel({
      color: '#ffffff',
      fontWeight: 'bold',
      text: timePin.title,
      fontSize: '10px',
      className: 'marker-label',
    });
  } else {
    marker.setLabel(null);
  }
};

/**
 *  renderTrackingpoints
 * @param param0
 */
export const renderMarker = (
  inspectionContext: InspectionProviderValue,
  timePin: TimePinType,
  map: google.maps.Map,
  setMarker: (m: google.maps.Marker) => void,
  oldMarker?: google.maps.Marker,
) => {
  if (oldMarker) {
    oldMarker.setMap(null);
  }


  const marker = getTimepinMarker(
    timePin,
    map,
    inspectionContext.dispatch,
  );

  setMarker(marker);
};