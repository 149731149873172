import { useState } from 'react';
import DropdownButton from '../../../../dropdown-button/dropdown-button';
import { WeatherOption } from '../../map.d';
import { weatherImperialOptions, weatherMetricOptions } from '../../map.model';
import { weatherDropDownCustomStyle } from './weather.style';

interface Props {
  unit: string;
  selected: WeatherOption;
  onSelect: (selected?: WeatherOption) => void;
}

const Weather = ({ unit, selected, onSelect }: Props) => {
  const [show, setShow] = useState<boolean>(false);
  const options =
    unit === 'farenheit' ? weatherImperialOptions : weatherMetricOptions;

  return (
    <DropdownButton
      customStyle={weatherDropDownCustomStyle}
      iconFillColor="black"
      text="weather"
      type="radio"
      show={show}
      handleShow={(showing: boolean) => setShow(showing)}
      groups={[
        options
          .sort((o1, o2) => (o1.index > o2.index ? 1 : -1))
          .map((option) => ({
            ...option,
            selected: option.id === selected?.id,
          })),
      ]}
      onClick={(option: WeatherOption) => {
        if (!option.selected) onSelect(option);
        else onSelect();

        setShow(false);
      }}
    />
  );
};

export default Weather;
