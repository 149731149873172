import moment from 'moment-timezone';
import { timezoneListModel } from '../../models/timezones';
import { SettingsProviderValue } from './settings.context.d';

const SETTINGS_KEY = 'GT@run/settings';

/**
 * 
 */
const getDefaultTimezone = () => {
  const date = new Date();
  const nearOffset = date.getTimezoneOffset();
  const myTimezoneList = timezoneListModel.map((t) => ({
    label: t.name,
    id: t.location,
    offset: t.offset,
  }));

  const currentTimezone = myTimezoneList.find(
    (t: any) => t.id === moment.tz.guess()
  );

  const nearTimezone = myTimezoneList.find(({ offset }) => offset === (nearOffset * -1));

  return currentTimezone || nearTimezone;
};

const DEFAULT_UNIT_SETTINGS = {
  speedUnit: {
    id: 'mps',
    label: 'm/s',
  },
  distanceUnit: {
    id: 'ft',
    label: 'ft',
  },
  wheatherUnit: {
    id: 'farenheit',
    label: '°F',
  },
  timezone: getDefaultTimezone(),
};


/**
 * Todo: Doc this function
 * @param 
 */
export const setSettingsFromLocalStore = (dispatch: SettingsProviderValue['dispatch']) => {
  const myTimezoneList = timezoneListModel.map((t) => ({
    label: t.name,
    id: t.location,
    offset: t.offset,
  }));

  dispatch({
    type: 'SET_TIMEZONE_LIST',
    data: myTimezoneList,
  });

  const settings = window.localStorage.getItem(SETTINGS_KEY) || '{}';
  const parsedSettings = JSON.parse(settings);
  
  dispatch({
    type: 'SET_SPEED_UNIT',
    data: parsedSettings.speedUnit || DEFAULT_UNIT_SETTINGS.speedUnit,
  });

  dispatch({
    type: 'SET_DISTANCE_UNIT',
    data: parsedSettings.distanceUnit || DEFAULT_UNIT_SETTINGS.distanceUnit,
  });

  dispatch({
    type: 'SET_WEATHER_UNIT',
    data: parsedSettings.weatherUnit || DEFAULT_UNIT_SETTINGS.wheatherUnit,
  });

  dispatch({
    type: 'SET_TIMEZONE_UNIT',
    data: parsedSettings.timezone || DEFAULT_UNIT_SETTINGS.timezone,
  });
};

/**
 * 
 */
export const updateLocalStoreSettings = (key, data) => {
  const settings = window.localStorage.getItem(SETTINGS_KEY);
  if (settings) {
    const parsedSettings = JSON.parse(settings);
    parsedSettings[key] = data;
    const stringfiedSettings = JSON.stringify(parsedSettings)
    window.localStorage.setItem(SETTINGS_KEY, stringfiedSettings);
  } else {
    const defaultSettingsCopy = { ...DEFAULT_UNIT_SETTINGS };
    defaultSettingsCopy[key] = data; 
    const stringfiedSettings = JSON.stringify(defaultSettingsCopy)
    window.localStorage.setItem(SETTINGS_KEY, stringfiedSettings);
  }
};
