export const ShowControls = () => (
  <svg style={{ top: '1px', left: '0.5px', opacity: 0.7 }} width="18px" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.7705 0.205882C11.6159 0.205685 11.4676 0.267519 11.3585 0.377644L6.17649 5.59144L0.994702 0.377645C0.847494 0.229536 0.632983 0.171725 0.43182 0.225903C0.230762 0.280081 0.0736973 0.438106 0.019854 0.640393C-0.0339942 0.842683 0.0234635 1.05863 0.170673 1.20672L5.76453 6.83511C5.87584 6.94101 6.02323 7 6.1765 7C6.32976 7 6.47715 6.94101 6.58856 6.83511L12.1824 1.20672L12.1823 1.20682C12.2916 1.09679 12.3529 0.947715 12.3529 0.79223C12.3529 0.636746 12.2916 0.48767 12.1823 0.37774C12.0731 0.267811 11.9249 0.205976 11.7704 0.205976L11.7705 0.205882Z" fill="#1A1F36"/>
  </svg>
);

export const HideControls = () => (
  <svg style={{ opacity: 0.7 }}  width="24px" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="13.4443" y="4.56445" width="1.57012" height="12.5609" transform="rotate(45 13.4443 4.56445)" fill="#1A1F36"/>
    <rect x="14.5547" y="13.4492" width="1.57012" height="12.5609" transform="rotate(135 14.5547 13.4492)" fill="#1A1F36"/>
  </svg>
);
