import { useContext, useEffect, useState } from 'react';
import {
  renderPipeline,
  renderDriveline,
  watchCurrentPosition,
  setLoggedUserMarker,
  centerMap,
  getLogoOrientation,
  getLogoImage,
} from './run.controller';
import InspectionStore from 'app/modules/inspection/inspection.context';
import MobileDetect from 'mobile-detect';
import AccountStore from 'app/modules/account/account.context';
import UpdateEventOverlay from 'app/components/update-event-overlay/update-event-modal';
import CreateEventOverlay from 'app/components/create-event-overlay/create-event-modal';
import EventListOverlay from 'app/components/event-list-overlay/event-list-overlay';
import Widgets from './components/widgets/widgets';
import { GtLogo } from './run.style';
import SettingsStore from 'app/modules/settings/settings.context';
import TPMarker from './components/tp-marker/tp-marker';
import ChartModal from 'app/modules/inspection/run/components/chart-modal/chart-modal';
import TimePinMarker from './components/time-pin-marker/time-pin-marker';
import PigMarker from './components/pig-marker/pig-marker';
import SegmentPolyline from './components/segment-polyline/segment-polyline';
import QubeMarker from './components/qube-marker/qube-marker';

/**
 * 
 * @param props
 * @returns 
 */
const Run = ({
  map,
  widgets,
  fitBounds,
  zoom,
  hasLabels,
  hasRuler,
  measureTool,
  observerMode,
}: any) => {
  const accountContext = useContext(AccountStore);
  const inspectionContext = useContext(InspectionStore);
  const settingsContext = useContext(SettingsStore);
  const [hideWidgets, setHideWidgets] = useState<boolean>(true);

  // load module
  useEffect(() => {
    //  TODO: Pressure
    // renderFocusPin({
    //   run,
    //   map,
    //   dispatch,
    //   state,
    // });
    
    setLoggedUserMarker(accountContext, inspectionContext, map);
    const md = new MobileDetect(window.navigator.userAgent);
    if (!md.mobile()) {
      setHideWidgets(false);
    }
  }, []);

  // Render pipeline 
  useEffect(() => {
    // check if exist pipeline
    if (inspectionContext.state.pipeline) {
      renderPipeline({
        inspectionContext,
        map,
      });
    }
  }, [inspectionContext.state.pipeline]);
  
  // Render driveline
  useEffect(() => {
    // check if exist driveline
    if (inspectionContext.state.pipeline?.driveline && map) {
      renderDriveline({
        inspectionContext,
        map,
      });
    }
  }, [inspectionContext.state.pipeline?.driveline]);
  
  // Update logged user position on map
  useEffect(() => {
    const watch =  watchCurrentPosition({
      marker: inspectionContext.state.logged_user_marker,
      dispatch: inspectionContext.dispatch,
    });

    return () => {
      navigator.geolocation.clearWatch(watch || 0);
    };
  }, [inspectionContext.state.logged_user_marker]);

  // Update logged user position on map
  useEffect(() => {
    const marker = inspectionContext.state.logged_user_marker;
    const position = inspectionContext.state.logged_user_position;
    const followingLoggedUserPosition = inspectionContext.state.following_logged_user
    if (marker && position) marker.setPosition(position);
    if (marker && position && followingLoggedUserPosition) fitBounds([position]);
  }, [
    inspectionContext.state.logged_user_position,
    inspectionContext.state.following_logged_user
  ]);

  // After laod pipeline, center map
  useEffect(() => {
    centerMap({
      pipeline: inspectionContext.state?.pipeline,
      map,
    });
  }, [inspectionContext.state.pipeline_poly]);

  // set follow pig position listener
  useEffect(() => {
    const isFollowingPig = inspectionContext.state.following_pig;
    const estimation = inspectionContext.state.estimation;
    if (isFollowingPig && estimation?.location) {
      fitBounds([{
        lat: estimation.location?.coordinates[1],
        lng: estimation.location?.coordinates[0],
      }]);
    }
  }, [inspectionContext.state.following_pig, inspectionContext.state.estimation]);
  
  const nextPointId = inspectionContext.state.estimation?.next_point;
  const endPointId = inspectionContext.state.run?.trackingpoint_set?.slice(-1)[0];
  const nextPoint = nextPointId ? inspectionContext.state.trackingpoints_dic[nextPointId] : undefined;
  const endPoint = endPointId ? inspectionContext.state.trackingpoints_dic[endPointId] : undefined;

  return (
    <div>
      {
        observerMode ? null : (
          <Widgets
            hide={hideWidgets}
            displayNone={inspectionContext.state.is_observer}
            setHide={setHideWidgets}
            elevation={inspectionContext.state.estimation?.elevation}
            inclination={inspectionContext.state.estimation?.inclination}
            finalEta={inspectionContext.state.estimation?.final_eta}
            endPoint={endPoint}
            nextPoint={nextPoint}
            speed={inspectionContext.state.estimation?.speed}
            lastPassage={inspectionContext.state.estimation}
            launched={inspectionContext.state.run?.launched}
            weatherForecast={inspectionContext.state.forecast}
            weatherUnit={settingsContext.state.weatherUnit}
            isFinished={inspectionContext.state.run?.is_finished}
            distanceUnit={settingsContext.state.distanceUnit}
            speedUnit={settingsContext.state.speedUnit}
            timezone={settingsContext.state.timezone}
            widgets={widgets}
    
            selectedTrackingPoint={inspectionContext.state.selected_point}
            openChartModal={() =>
              inspectionContext.dispatch({
                type: 'TOGLE_CHART_MODAL',
              })
            }
          />
        )
      }
      
      {
        inspectionContext.state.run?.trackingpoint_set?.map((tId, index) => {
          const point = inspectionContext.state.trackingpoints_dic[tId]
          return point ? (
            <TPMarker
              hasLabels={hasLabels}
              hasRuler={hasRuler}
              measureTool={measureTool}
              zoom={zoom}
              key={`${tId}-${index}`}
              point={point}
              map={map}
            />
          ) : null;
        })
      }

      {
        inspectionContext.state.timepin_set.map((tId, index) => {
          const timePin = inspectionContext.state.timepins_dic[tId]
          return timePin ? (
            <TimePinMarker
              key={`${tId}-${index}`}
              timePin={timePin}
              map={map}
              hasLabels={hasLabels}
            />
          ) : null;
        })
      }
      
      {
        map &&
        inspectionContext.state.project?.using_qube &&
        inspectionContext.state.qube_set.map((qubeId, index) => {
          const qube = inspectionContext.state.qubes_dic[qubeId]
          return qube ? (
            <QubeMarker
              key={`${qubeId}-${index}`}
              qube={qube}
              map={map}
            />
          ) : null;
        })
      }

      {
        inspectionContext.state.estimation ? (
          <PigMarker
            estimation={inspectionContext.state.estimation}
            map={map}
          />
        ) : null
      }

      {
        inspectionContext.state.estimation ? (
          <SegmentPolyline
            estimation={inspectionContext.state.estimation}
            map={map}
          />
        ) : null
      }

      <GtLogo
        observerView={!!inspectionContext.state.is_observer}
        orientation={getLogoOrientation(inspectionContext.state.project)}
        src={getLogoImage(inspectionContext.state.project)}
      />
      
      <UpdateEventOverlay />
      <CreateEventOverlay />
      <EventListOverlay />
      
      {
        inspectionContext.state.show_chart ? (
          <ChartModal />
        ) : null
      }
    </div>
  );
};

export default Run;
