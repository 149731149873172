import { useState } from "react";
import {
  HelpActionTitle,
  HelpActionWrapper,
  HelpDescription,
  HelpSubtitle
} from "./confidence-level.style";
import ProgressBar from "../progress-bar/progress-bar";

interface Props {
  confidenceLevel?: number;
}

const  ConfidenceLevel = ({
  confidenceLevel,
}: Props) => {
  const [suportModal, setSuportModal] = useState<{
    x: number;
    y: number;
  } | undefined>(undefined);

  if (
    confidenceLevel === undefined ||
    confidenceLevel === null

  ) return null;

  return (
    <>
      <ProgressBar percentage={confidenceLevel} />
        <svg
          onClick={(e) => {
            if (!suportModal) {
              setSuportModal({
                x: e.pageX,
                y: e.pageY,
              });
            } else {
              setSuportModal(undefined);
            }
          }}
          width="15"
          height="15"
          viewBox="0 0 15 15"
          fill="none"
        >
          <circle cx="7.5" cy="7.5" r="7.1" stroke="#767986" strokeWidth="0.8" />
          <path
            d="M7.20867 8.90051V8.86531C7.21258 8.49175 7.2517 8.19447 7.32602 7.97347C7.40034 7.75247 7.50595 7.57351 7.64286 7.43661C7.77976 7.2997 7.94405 7.17355 8.13571 7.05816C8.25111 6.98775 8.35476 6.90463 8.44668 6.100.53861 6.71101 8.61097 6.59855 8.66378 6.47143C8.71854 6.3443 8.74592 6.20349 8.74592 6.04898C8.74592 5.85731 8.70094 5.69107 8.61097 5.55025C8.521 5.40944 8.40072 5.30089 8.25013 5.22462C8.09953 5.14834 7.93231 5.1102 7.74847 5.1102C7.5881 5.1102 7.43359 5.14345 7.28495 5.20995C7.13631 5.27645 7.01212 5.38108 6.91237 5.52385C6.81263 5.66662 6.75493 5.8534 6.73929 6.08418H6C6.01565 5.7517 6.1017 5.46713 6.25816 5.23048C6.41658 4.99384 6.62487 4.81293 6.88304 4.68775C7.14315 4.56258 7.43163 4.5 7.74847 4.5C8.09269 4.5 8.39192 4.56845 8.64617 4.70536C8.90238 4.84226 9.09991 5.03002 9.23878 5.26862C9.37959 5.50723 9.45 5.77908 9.45 6.08418C9.45 6.29932 9.41675 6.49392 9.35025 6.66798C9.28571 6.84205 9.19184 6.99753 9.06862 7.13444C8.94736 7.27134 8.80068 7.3926 8.62857 7.49821C8.45646 7.60578 8.31858 7.71922 8.21492 7.83852C8.11127 7.95587 8.03597 8.09571 7.98903 8.25804C7.94209 8.42037 7.91667 8.62279 7.91275 8.86531V8.90051H7.20867ZM7.58418 10.6372C7.43946 10.6372 7.31526 10.5854 7.21161 10.4818C7.10795 10.3781 7.05612 10.2539 7.05612 10.1092C7.05612 9.96445 7.10795 9.84026 7.21161 9.73661C7.31526 9.63295 7.43946 9.58112 7.58418 9.58112C7.72891 9.58112 7.8531 9.63295 7.95676 9.73661C8.06042 9.84026 8.11224 9.96445 8.11224 10.1092C8.11224 10.205 8.0878 10.293 8.0389 10.3732C7.99196 10.4534 7.9284 10.5179 7.84821 10.5668C7.76998 10.6138 7.68197 10.6372 7.58418 10.6372Z"
            fill="#4B5066"
          />
        </svg>
        {<HelpActionWrapper
          displayNone={!suportModal}
          top={suportModal?.y}
          left={suportModal?.x}
        >
          <svg
            className="cloose-button"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            onClick={() => setSuportModal(undefined)}
          >
            <rect x="9.57031" y="2.91406" width="1.17688" height="9.41507" transform="rotate(45 9.57031 2.91406)" fill="#99A2AD"/>
            <rect x="10.4023" y="9.57031" width="1.17688" height="9.41507" transform="rotate(135 10.4023 9.57031)" fill="#99A2AD"/>
          </svg>
          <HelpActionTitle>
            Confidence Level &nbsp; <b>{`${confidenceLevel}%`}</b>
          </HelpActionTitle>
          <HelpSubtitle>What is confidence level?</HelpSubtitle>
          <HelpDescription>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque imperdiet eleifend eros, vel blandit massa hendrerit vitae. Nulla vel ligula non enim consectetur dictum. Vivamus auctor consequat mi, vitae malesuada velit lobortis vitae. Nam molestie maximus diam, sit amet elementum enim eleifend sed. Maecenas tincidunt viverra massa, a congue ipsum aliquet quis. Vivamus sit amet nisi ultricies, imperdiet massa vel, tristique risus.</HelpDescription>
        </HelpActionWrapper>}
    </>
  );
}

export default ConfidenceLevel;