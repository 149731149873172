import MobileDetect from 'mobile-detect';
import ReactGA from "react-ga4";
import {
  createPassage,
  deletePassage,
  launch,
  markTriggerAsSeen,
  toggleActiveTrackingPoint,
  updatePassage,
  updateTrackingPoint
} from "app/services/run.service";
import { sheetEvents } from "./tracking-sheet.model";
import { UserType } from 'app/interfaces/account.interfaces';
import { InspectionContext } from 'app/modules/inspection/inspection.context.d';
import { SettingsContext } from 'app/modules/settings/settings.context.d';
import { TrackingPointPlainType } from 'app/modules/inspection/inspection.interfaces';

/**
 *
 * @returns
 */
const handleCommonTextChange = async (args: any, token: string) => { // TODO: type event args
  const data = {};
  const point = args.grid.getData()[args.row];
  const column = args.column || args.grid.getColumns()[args.cell]
  data[column.field] = point[column.field];
  await updateTrackingPoint(point.id, data, token);
};

/**
 *
 * @param token
 * @returns
 */
export const flyTo = async (args: any, handleSelectPoint: (data: any) => void) => {
  // TODO: type event args
  const point = args.grid.getData()[args.row];
  const nextPoint = args.grid.getData()[args.row + 1];
  const prevPoint = args.grid.getData()[args.row - 1];
  handleSelectPoint({ point, nextPoint, prevPoint });
};

/**
 *
 * @param args
 * @param token
 */
const handlePassageTimeChange = async (
  args: any,
  token: string,
  setError: (data: { title: string, message: string }) => void,
) => {
  const point = args.grid.getData()[args.row];
  const trackingPointId = point.id;
  const runId = point.run_id;
  const passage = point.passage;
  const distance = point.distance;
  const speed = point.speed;
  const isoDate = point.passage_time;

  const data = {
    run: runId,
    distance: distance,
    tracking_point: trackingPointId,
    tstamp: isoDate,
    id: passage?.id,
    speed: undefined
  };

  if (distance === '0') data.speed = speed;

  if (!point.launched) {
    if (point.index === 1) {
      try {
        const launchData = {
          id: runId,
          tstamp: data.tstamp,
          speed: point.predicted_launch_speed,
          as_test: false,
        };
    
        await launch(runId, launchData, token);
      } catch (err: any) {
        if (err.response.data.type) {
          return setError({ title: 'Error', message: 'Sorry, error to launch run, try again later or contact us.' });
        }
      }
    } else {
      setError({ title: 'Error', message: 'Sorry, to register a passage, launch the run first.' });
    }

    return;
  }

  if (isoDate && passage) {
    try{
      await updatePassage(passage.id, data, token);
    } catch {
      setError({ title: 'Error', message: 'Error to update passage. Please, try again later.' });
    }
  }

  if (isoDate && !passage) {
    try{
      await createPassage(data, token);
    } catch {
      setError({ title: 'Error', message: 'Error to create passage. Please, try again later.' });
    }

    ReactGA.event({
      category: "Run",
      action: "Create Passage",
      label: "time",
    });
  }

  if (!isoDate && passage) {
    try {
      await deletePassage(passage.id, token)
    } catch {
      setError({ title: 'Error', message: 'Error to delete passage. Please, try again later' });
    }
  }
};

/**
 *
 * @param args
 * @param token
 */
const handlePassageDateChange = async (
  args: any,
  token: string,
  setError: (data: { title: string, message: string }) => void,
) => {
  const point = args.grid.getData()[args.row];
  const trackingPointId = point.id;
  const runId = point.run_id;
  const passage = point.passage;
  const distance = point.distance;
  const speed = point.speed;
  const isoDate = point.passage_date;

  const data = {
    run: runId,
    distance: distance,
    tracking_point: trackingPointId,
    tstamp: isoDate,
    id: passage?.id,
    speed: undefined
  };

  if (distance === '0') data.speed = speed;

  if (isoDate && passage) {
    try {
      await updatePassage(passage.id, data, token);
    } catch {
      setError({ title: 'Error', message: 'Error to update passage. Please, try again later' });
    }
  }

  if (isoDate && !passage) {
    try {
      await createPassage(data, token);
    } catch {
      setError({ title: 'Error', message: 'Error to create passage. Please, try again later' });
    }

    ReactGA.event({
      category: "Run",
      action: "Create Passage",
      label: "date",
    });
  }

  if (!isoDate && passage) {
    try {
      await deletePassage(passage.id, token)
    } catch {
      setError({ title: 'Error', message: 'Error to delete passage. Please, try again later' });
    }
  }
};

/**
 *
 * @param args
 * @param token
 */
const handleTriggersClick = (args: any, token: string, user: UserType, setTriggerPoint: any) => {
  const point = args.grid.getData()[args.row];
  if (!point?.trigger_set.length || point.is_observer) return;

  setTriggerPoint(point.id);
  const triggers = point?.trigger_set.filter(
    ({ id }: any) => !point?.triggers_seen?.[id]?.[user.id as number]
  );

  triggers.forEach((id) => {
    markTriggerAsSeen(id, token);
  });
}

/**
 *
 * @returns
 */
const toggleActive = async (
  args: any,
  token: string,
  setError: (data: { title: string, message: string }) => void,
) => {
  const point = args.grid.getData()[args.row];

  try {
    await toggleActiveTrackingPoint(
      `${point.id}`,
      {
        id: point.id,
        active: !point.active,
      },
      token
    );
  } catch {
    setError({ title: 'Error', message: 'Error to delete passage. Please, try again later' });
  }
};

/**
 *
 */
export const getEvents = (
  user: UserType,
  token: string,
  handleSelectPoint: (data: any) => void,
  setTriggerPoint: (id?: number) => void,
  setError: (data: { title: string, message: string }) => void,
) => {
  const changeHandles = {
    handleCommonTextChange,
    handlePassageTimeChange,
    handlePassageDateChange,
  };

  const clickHandles = {
    handleTriggersClick: (args: any) => {
      handleTriggersClick(args, token, user, setTriggerPoint);
    },
    flyTo: (args) => flyTo(args, handleSelectPoint),
    toggleActive,
  };

  const doubleClickHandles = {};
  const events = {};

  Object.keys(sheetEvents).forEach((key) => {
    const event: {
      onChange?: (e: any, args: any) => void;
      onClick?: (e: any, args: any) => void;
      onDoubleClick?: (e: any, args: any) => void;
    } = {};
    if (sheetEvents[key].onChange) {
      event.onChange = (e: any, args: any) =>
        changeHandles[sheetEvents[key].onChange](args, token, setError);
    }

    if (sheetEvents[key].onClick) {
      event.onClick = (e: any, args: any) =>
        clickHandles[sheetEvents[key].onClick](args, token, setError);
    }

    if (sheetEvents[key].onDoubleClick) {
      event.onDoubleClick = (e: any, args: any) => doubleClickHandles[sheetEvents[key].onDoubleClick](args, token);
    }

    events[key] = event;
  });

  return events;
};

/**
 *
 * @param grid
 * @param index
 * @param data
 */
export const reloadPoint = (index: number, data: any, grid: any) => {
  const columns = grid.getColumns();
  const gridData = grid.getData();
  const rowData = gridData[index];
  gridData[index] = data;

  for (const column of columns) {
    if (!data || rowData && rowData[column.field] !== data[column.field]) {
      const col = grid.getColumnIndex(column.field);
      grid.updateCell(index, col);
    }

    if (column.affected_by) {
      for (const affected of column.affected_by) {
        if (rowData && rowData[affected] !== data[affected]) {
          const col = grid.getColumnIndex(column.field);
          grid.updateCell(index, col);
        }
      }
    }
  }
};

/**
 * parse tracking points to slickgrid row data
 */
export const parsePointToRow = (
  runId: number,
  point: TrackingPointPlainType,
  index: number,
  settingsState: SettingsContext,
  isObserver: boolean,
  permissionType: string,
  launched: boolean,
  passages: InspectionContext['passages_dic'],
  tpToPassage: InspectionContext['tp_to_passages_dic'],
  triggersSeenTrigger?: number[],
  predictedLaunchSpeed?: number,
) => {
  if (!point) return null;
  const passageId = tpToPassage[point.id];
  const passage = passageId && point.passage ? passages[passageId] : undefined;
  return {
    id: point.id,
    index: index + 1,
    name: point.name,
    is_device_trigger: point.is_device_trigger,
    geolocks: point.geolocks,
    type: point.type,
    color: point.color,
    active: point.active,
    chainage: point.chainage,
    passage_time: passage?.tstamp,
    passage_date: passage?.tstamp,
    longitude: point.geometry?.coordinates[0],
    latitude: point.geometry?.coordinates[1],
    speed: point.speed,
    speed_delta: point.speed_delta,
    distance: point.distance,
    elevation: point.elevation,
    inclination: point.inclination,
    eta: point.eta,
    is_next: point.is_next,
    milepost: point.milepost,
    description: point.description,
    comment: point.comment,
    depth_of_cover: point.depth_of_cover,
    alignment_sheet: point.alignment_sheet,
    speed_offset: point.speed_offset,
    device_sn: point.device_sn,
    county: point.county,
    state: point.state,
    country: point.country,
    tract: point.tract,
    pressure_differential: point.pressure_differential,
    location_description: point.location_description,
    antenna_height: point.antenna_height,
    datum: point.datum,
    elevation_top: point.elevation_top,
    ellipsoid_height: point.ellipsoid_height,
    ellipsoid_height_cop: point.ellipsoid_height_cop,
    geoid_model: point.geoid_model,
    survey_accuracy: point.survey_accuracy,
    survey_method: point.survey_method,
    passage,
    trigger_set: point.trigger_set,
    triggers_seen_triggers: triggersSeenTrigger,
    disabled: point.disabled,
    timezone: settingsState.timezone?.id,
    distance_unit: settingsState.distanceUnit,
    speed_unit: settingsState.speedUnit,
    is_observer: isObserver,
    run_id: runId,
    permission_type: permissionType,
    predicted_launch_speed: predictedLaunchSpeed,
    launched,
    passage_type: point.passage_type
  };
};

/**
 *
 * @returns
 */
export const getFrozenColumn = () => {
  const md = new MobileDetect(window.navigator.userAgent);
  if (md.mobile()) {
    return 0;
  }

  return 3;
};

/**
 * refresh every ETE columns
 * @param grid
 */
export const refreshETE = (grid: any) => {
  // probably always there is slickGrid, but is a necessary interface validation
  if (!grid) return;

  const col = grid.getColumnIndex('ete');
  for (let i = 0; i < grid.getData().length; i++) {
    // ETE column to update
    grid.updateCell(i, col);
  }
};


/**
 *
 * @param state
 * @returns
 */
export const refreshAllPoints = (
  inspectionContext: InspectionContext,
  settingsContext: SettingsContext,
  permissionType: string
) => {
  if (!inspectionContext.run || !inspectionContext.grid) return;

  /**
   *
   */
  const overideParsePointToRow = (
    point: number,
    index: number,
  ) => {
    return parsePointToRow(
      inspectionContext.run?.id || 0,
      inspectionContext.trackingpoints_dic[point],
      index,
      settingsContext,
      inspectionContext.is_observer,
      permissionType,
      !!inspectionContext.run?.launched,
      inspectionContext.passages_dic,
      inspectionContext.tp_to_passages_dic,
      inspectionContext.triggerseen_trigger_set,
      inspectionContext.run?.predicted_launch_speed || undefined,
    );
  };

  // map tracking points to slickgrid row data
  const data = inspectionContext.run?.trackingpoint_set?.map(overideParsePointToRow) || [];
  for (const row of data) {
    const index = data.indexOf(row);
    const gridData = inspectionContext.grid.getData();
    gridData[index] = row;

    inspectionContext.grid.invalidateRow(index);
    inspectionContext.grid.updateRow(index);
    inspectionContext.grid.render();
  }
};

