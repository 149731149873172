import { Route, useHistory, useParams } from "react-router-dom";
import InspectionStore from "./inspection.context";
import Run from "./run/run.page";
import Report from "./report/report";
import { ReactElement, useContext, useEffect, useReducer, useState } from "react";
import { AuthType } from "../account/account.context.d";
import AccountStore from "../account/account.context";
import NotificationStore from "../notification/notification.context";
import { InspectionContext, InspectionDispatchType } from "./inspection.context.d";
import { reducer } from "./inspection.reducer";
import { useWebSocket } from "app/utils/websocket.utils";
import { handleOnMessage, handleOnOpen } from "./inspection.controller";
import Toast from "app/components/toast/toast";
import ForbiddenError from "./forbidden-error/forbidden-error";

const initialInspectionState: InspectionContext = {
  customer: undefined,
  loading: false,
  error: undefined,
  verified: false,
  is_observer: false,
  show_timepins_modal: false,
  following_pig: false,
  following_logged_user: false,
  show_create_timepin_modal: false,
  show_chart: false,
  grid: undefined,
  no_connection_since: undefined,
  reconnection_attempt: undefined,
  run: undefined,
  pipeline: undefined,
  driveline: undefined,
  last_update: '',
  balance: 0,
  project: undefined,
  forecast: undefined,
  last_passage: undefined,
  logged_user_position: undefined,
  
  // Selections
  selected_point: undefined,
  selected_timepin: undefined,
  selected_qube: undefined,
  selected_trigger_point: undefined,
  
  estimation: undefined,
  
  // Dictionaries
  trackingpoints_dic: {},
  users_dic: {},
  timepins_dic: {},
  qubes_dic: {},
  medias_dic: {},
  notes_dic: {},
  triggers_dic: {},
  passages_dic: {},
  tp_to_notes_dic: {},
  tp_to_medias_dic: {},
  tp_to_passages_dic: {},
  tp_to_triggers_dic: {},
  
  // sets
  trackingpoint_set: [],
  triggerseen_trigger_set: [],
  media_set: [],
  note_set: [],
  user_set: [],
  timepin_set: [],
  qube_set: [],
  passage_set: [],
  
  // Map
  point_markers: {},
  qube_markers: {},
  timepin_markers: {},
  logged_user_marker: undefined,
  segment: undefined,
  pipeline_poly: undefined,
  driveline_poly: undefined,
  explanation_map_tooltip: undefined,
  pig_marker: undefined,

  tick: 0,
}

interface Props {
  value?: unknown;
  children: ReactElement;
}

/**
 * 
 * @param WrappedComponent 
 * @param initialState 
 * @returns 
 */
export const  InspectionModule = ({ value, children }: Props) => {
  const { id }: { id: string } = useParams();
  const accountContext = useContext(AccountStore);
  const history = useHistory();
  const notificationContext = useContext(NotificationStore);
  const [timeInterval, setTimeInterval] = useState<NodeJS.Timeout>();
  const [reconnect, setReconnect] = useState(false);
  const auth = accountContext.state.auth as AuthType;
  // Define state and dispatch method for this Context
  const reducerData = useReducer(reducer, { ...initialInspectionState, ...value as InspectionContext });
  const state: InspectionContext = reducerData[0];
  const dispatch: (data: InspectionDispatchType) => void = reducerData[1];

  const onConnected = (websocket: WebSocket) => {
    clearInterval(timeInterval)
    dispatch({
      type: 'STOP_RECONNECTION',
    });
    handleOnOpen(websocket, auth.token, state.last_update)
  };
  const ws = useWebSocket(
    'run',
    id,
    (payload) => handleOnMessage(payload, dispatch, notificationContext.dispatch, history, id),
    (last_update) => dispatch({ type: 'SET_LAST_UPDATE', data: last_update }),
    onConnected,
    () => {
      dispatch({ type: 'NO_CONNECTION', data: new Date() });
    },
    reconnect,
  );

  useEffect(() => {
    return () => {
      ws?.close()
    }
  }, []);

  useEffect(() => {
    if (state.reconnection_attempt && !state.error) {
      setReconnect(!(reconnect));
    }
  }, [state.reconnection_attempt]);

  useEffect(() => {
    if (state.no_connection_since) {
      const ti = setInterval(() => {
        dispatch({
          type: 'RECONNECTION_TRIGGER',
        })
      }, 5000);

      if (!state.no_connection_since && ti) {
        clearInterval(timeInterval)
      }
      
      if (ti) setTimeInterval(ti);
      
      return () => {
        if (timeInterval) clearInterval(timeInterval);
      }
    } else {
      clearInterval(timeInterval)
    }
  }, [state.no_connection_since]);


  useEffect(() => {
    if (!auth) history.push('/');
  }, [auth]);

  // props comes afterwards so the can override the default ones.
  return (
    <InspectionStore.Provider value={{ state, dispatch }}>
      <Route exact path="/runs/:id" component={Run} />
      <Route exact path="/runs/:id/forbidden" component={ForbiddenError} />
      <Route exact path="/runs/:id/report" component={Report} />
      {children}
      <Toast />
    </InspectionStore.Provider>
  );
};
export default InspectionModule