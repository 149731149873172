import InspectionStore from "app/modules/inspection/inspection.context";
import { useContext, useEffect, useState } from "react";
import { QubeDeviceType } from "app/modules/qube/qube.interfaces";
import { getQubeIcon, renderQubeDevice } from "./qube-marker.controller";

interface Props {
  qube: QubeDeviceType;
  map: google.maps.Map;
}

/**
 * 
 * @param props 
 * @returns 
 */
const QubeMarker = (props: Props) => {
  const inspectionContext = useContext(InspectionStore);
  const [marker, setMarker] = useState<google.maps.Marker>();

  useEffect(() => {
    renderQubeDevice(
      props.qube,
      props.map,
      setMarker,
      inspectionContext.dispatch,
    );  
  }, []);

  useEffect(() => {
    const coords = props.qube.position?.coordinates;
    if (coords) {
      marker?.setPosition({
        lng: coords[0],
        lat: coords[1],
      });
    }
    if (!props.qube.geolocked) {
      marker?.setMap(props.map);
    } else {
      marker?.setMap(null);
    }

    marker?.setIcon(getQubeIcon(props.qube.armed))
  }, [props.qube.position, props.qube.geolocked])

  return null;
};

export default QubeMarker;