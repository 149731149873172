import { ButtonComponent } from './button.style';

interface Props {
  handleClick(e?: any): void;
  value?: string;
  disabled?: boolean;
  customStyle: string;
  type?: 'button' | 'submit';
  testid?: string;
  name?: string;
  id?: string;
}

/**
 * Button component
 * @returns
 */
const Button = ({
  value,
  handleClick,
  type,
  customStyle,
  disabled,
  testid,
  id,
  name,
}: Props) => {
  return (
    <ButtonComponent
      customStyle={customStyle}
      disabled={disabled}
      type={type}
      name={name}
      id={id}
      onClick={disabled ? () => '' : handleClick}
      data-testid={testid}
    >
      {value}
    </ButtonComponent>
  );
};

Button.defaultProps = {
  customStyle: '',
  value: null,
  disabled: false,
  type: 'button',
  handleClick: () => '',
};

export default Button;
