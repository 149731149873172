import styled from 'styled-components';

export const DevicesPage = styled.div``;

export const PageHeader = styled.header`
  padding: 16px;

  display: grid;
  grid-template-columns: 1fr auto;
  align-items: end;
`;

export const PageTitle = styled.h1`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 25px;

  color: #161e2e;
`;

export const Actions = styled.div`
  display: none;

  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: auto 85px auto;
    justify-items: center;

    gap: 6px;
  }
`;

export const Content = styled.main`
  padding: 16px;
`;

export const TabsWrapper = styled.div`
  padding-left: 16px;
  padding-right: 16px;
`;

export const DeviceList = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 194px;
  gap: 8px;

  @media (min-width: 1024px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;
