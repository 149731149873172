export const links = [
  {
    id: 'projects',
    href: '/projects',
    iconType: 'projects',
    text: 'Projects',
    mobile: true,
  },
  {
    id: 'calendar',
    href: '/calendar',
    iconType: 'calendar',
    text: 'Calendar',
    mobile: true,
  },
  {
    id: 'people',
    href: '/people',
    iconType: 'people',
    text: 'People',
    mobile: false,
  },
  {
    id: 'surveys',
    href: '/surveys',
    iconType: 'surveys',
    text: 'Surveys',
    external: false,
    mobile: true,
  },
  {
    id: 'docs',
    href: '/docs/',
    iconType: 'documentation',
    text: 'Documentation',
    external: true,
    mobile: true,
  },
];
