import { Breadcrumb } from 'app/components/breadcrumbs/breadcrumbs';
import { InspectionContext } from '../inspection.context.d';
import {
  abortRun,
  checkIsAllowedMakeEditionsOnProject,
  finishRun,
  launch,
  updateRun
} from 'app/services/run.service';
import { NotificationDispatchType } from 'app/modules/notification/notification.context.d';
import { formatSpeedToMps } from '@/lib/formatter';
import moment from 'moment-timezone';
import { Timezone, Unit } from 'app/modules/settings/settings.context.d';

/**
 * @param run
 * @returns
 */
export const getBreadcrumbs = (context: InspectionContext): Breadcrumb[] => [
  {
    id: '#1',
    text: 'Projects',
    href: `/projects`,
    loading: false,
  },
  {
    id: '#2',
    text: context?.project?.name || '',
    href: `/projects/${context?.project?.id}`,
    loading: !context?.project,
  },
  {
    id: '#3',
    text: context?.run?.name || '',
    href: `/runs/${context?.run?.id}`,
    loading: !context?.run,
  },
];

/**
 * check on api if logged user is allowed to make editions or not
 * @param projectId project id to check
 * @param token logged auth token
 * @param setPermissionType it is set on run context the user permission type, editor or observer
 */
export const checkIsAllowedMakeEditions = async (
  projectId: string,
  token: string,
  setPermissionType: (permissionType: string) => void,
) => {
  const response = await checkIsAllowedMakeEditionsOnProject(projectId, token);
  setPermissionType(response.data ? 'editor' : 'observer');
};



/**
 * @param id
 * @param run
 * @param token
 * @param dispatch
 * @param setConfirmation
 * @param tstamp
 * @param launchSpeed
 * @param isTest
 * @returns
 */
export const launchRun = async (
  id: string,
  token: string,
  notificationDispath: (toast: NotificationDispatchType) => void,
  setConfirmation: (data: any) => void,
  tstamp: Date,
  launchSpeed: number,
  isTest: boolean,
) => {
  try {
    setConfirmation({});
    notificationDispath({
      type: 'SET_TOAST',
      data: {
        title: 'Loading . . . ',
        type: 'loading',
      },
    });

    const launchData = {
      id,
      tstamp: tstamp.toISOString(),
      speed: launchSpeed,
      as_test: isTest,
    };

    await launch(id, launchData, token);
  } catch (err: any) {
    if (err.response.data.type) {
      return notificationDispath({
        type: 'SET_TOAST',
        data: {
          type: 'error',
          title: err.response.data.title,
          text: err.response.data.message,
        },
      });
    }

    notificationDispath({
      type: 'SET_TOAST',
      data: {
        type: 'error',
        title: 'Error',
        text: 'Unexpected error, try again later.',
      },
    });
  }
};

/**
 * @param id
 * @param auth
 * @param setConfirmation
 * @param dispatch
 * @returns
 */
export const abortRunAction = async (
  id: string,
  auth: any,
  setConfirmation: (confirmation: any) => void,
  notificationDispath: (toast: NotificationDispatchType) => void,
) => {
  try {
    notificationDispath({
      type: 'SET_TOAST',
      data: {
        title: 'Loading . . . ',
        type: 'loading',
      },
    });

    setConfirmation({});
    await abortRun(id, {}, auth.token);
  } catch (err: any) {
    if (err.response.data.type) {
      return notificationDispath({
        type: 'SET_TOAST',
        data: {
          type: 'error',
          title: err.response.data.title,
          text: err.response.data.message || 'Error to abort run',
        },
      });
    }
    notificationDispath({
      type: 'SET_TOAST',
      data: {
        type: 'error',
        title: 'Error',
        text: 'Unexpected error, try again later.',
      },
    });
  }
};

/**
 *
 * @param id
 * @param token
 * @param setConfirmation
 * @param dispatch
 */
export const finishRunAction = async (
  id: string,
  token: string,
  setConfirmation: (confirmation: any) => void,
  notificationDispath: (toast: NotificationDispatchType) => void,
) => {
  try {
    notificationDispath({
      type: 'SET_TOAST',
      data: {
        title: 'Loading . . . ',
        type: 'loading',
      },
    });
    setConfirmation({});
    await finishRun(id, {}, token);
  } catch (err) {
    notificationDispath({
      type: 'SET_TOAST',
      data: {
        type: 'error',
        title: 'Error',
        text: 'Error to finish run',
      },
    });
  }
};

/**
 *
 * @param data
 * @param dispatch
 * @param timezone
 * @returns
 */
export const updateScheduledRun = async (
  date: string,
  time: string,
  speed: string,
  runId: number | undefined,
  token: string | undefined,
  notificationDispath: (toast: NotificationDispatchType) => void,
  timezone: Timezone | undefined,
  speedUnit?: Unit | undefined,
  hidePicker?: (hide: boolean) => void,
) => {
  if (!timezone?.id) return;
  if (!speedUnit?.id) return;
  if (!token) return;
  if (!runId) return;

  const startDate = moment(date).tz(timezone.id);
  const cleanTime = time.replace(/:/g, '').replace('.', '');

  const h = +cleanTime.slice(0, 2);
  const m = +cleanTime.slice(2, 4);
  const s = +cleanTime.slice(4, 6);
  const mm = +cleanTime.slice(6, );
  const isValid = !(h > 23 || m > 59 || s > 59 || mm > 999);

  if (!isValid) {
    return notificationDispath({
      type: 'SET_TOAST',
      data: {
        type: 'error',
        title: 'Error',
        text: 'Invalid date time',
      },
    });
  }

  startDate.hour(h);
  startDate.minute(m);
  startDate.second(s);
  startDate.millisecond(mm);

  const dataToApi = {
    id: runId,
    scheduled_launch_at: startDate.toISOString(),
    scheduled_launch_time: startDate.toISOString(),
    predicted_launch_speed: formatSpeedToMps({
      distance: +speed,
      unit: speedUnit.id,
    }),
  };
  try {
    await updateRun(runId.toString(), dataToApi, token);
    if (hidePicker) hidePicker(false);
  } catch (err: any) {
    if (err.response?.data?.type) {
      return notificationDispath({
        type: 'SET_TOAST',
        data: {
          type: 'error',
          title: err.response?.data?.title,
          text: err.response?.data?.message,
        },
      });
    }
    notificationDispath({
      type: 'SET_TOAST',
      data: {
        type: 'error',
        title: 'Error',
        text: 'Unexpected error, try again later.',
      },
    });
  }
};
