// WEATHER OPTIONS

const weatherDefaultOptions = [
  {
    id: 'radar-global',
    label: 'Radar',
    index: 1,
  },
  {
    id: 'lightning-strikes-5m-icons',
    label: 'Lightning',
    index: 2,
  },
  {
    id: 'radar-global,lightning-strikes-5m-icons',
    label: 'Radar and Lightning',
    index: 3,
  },
  {
    id: 'flat-dk,water-flat,satellite,admin-cities-dk',
    label: 'Cloud Cover (Satellite)',
    index: 4,
  },
  {
    id: 'flat-dk,water-flat,alerts,counties,admin-cities-dk',
    label: 'Alerts (USA)',
    index: 5,
  },
  {
    id: 'humidity,water-flat,admin-cities-dk',
    label: 'Humidity',
    index: 7,
  },
];

export const weatherMetricOptions = [
  ...weatherDefaultOptions,
  {
    id: 'flat-dk,temperatures,admin-cities-dk,temperatures-text-metric',
    label: 'Current Temperatures',
    index: 6,
  },
];

export const weatherImperialOptions = [
  ...weatherDefaultOptions,
  {
    id: 'flat-dk,temperatures,admin-cities-dk,temperatures-text',
    label: 'Current Temperatures',
    index: 6,
  },
];

// CONTROLS OPTIONS
