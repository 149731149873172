import { useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { saveRun } from './editRunModal.controller';
import {
  ModalWrapper,
  Modal,
  Header,
  CloseIcon,
  Container,
} from './editRunModal.style';
import FirstStep from './steps/first-step/firstStep';
import AccountStore from 'app/modules/account/account.context';
import SettingsStore from 'app/modules/settings/settings.context';
import NotificationStore from 'app/modules/notification/notification.context';

const EditRunModal = ({ hide, onClose, run, onUpdated }: any) => {
  const { id } = useParams<{ id: string }>();
  const notificationContext = useContext(NotificationStore);
  const accountContext = useContext(AccountStore);
  const settingsContext = useContext(SettingsStore);
  const history = useHistory();
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  return (
    <ModalWrapper onClick={(e: any) => e.stopPropagation()} hide={hide}>
      <Modal>
        <Header>
          <CloseIcon onClick={() => onClose()}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="14.2427"
                y="4.34375"
                width="2"
                height="14"
                rx="1"
                transform="rotate(45 14.2427 4.34375)"
                fill="#000"
              />
              <rect
                x="15.6567"
                y="14.2422"
                width="2"
                height="14"
                rx="1"
                transform="rotate(135 15.6567 14.2422)"
                fill="#000"
              />
            </svg>
          </CloseIcon>
        </Header>
        <Container>
          <FirstStep
            onSubmit={(updatedRun: any, cleanFields: any) =>
              saveRun({
                updatedRun,
                auth: accountContext.state.auth,
                history,
                setErrors,
                timezone: settingsContext.state.timezone,
                speedUnit: settingsContext.state.speedUnit,
                projectId: id,
                setIsLoading,
                cleanFields,
                run,
                onUpdated,
                onClose,
                dispatch: notificationContext.dispatch,
              })
            }
            errors={errors}
            isLoading={isLoading}
            run={run}
          />
        </Container>
      </Modal>
    </ModalWrapper>
  );
};

EditRunModal.defaultProps = {};

export default EditRunModal;
