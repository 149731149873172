import Avatar from '../../components/avatar/avatar';
import { User } from '../../store/store.interfaces';
import {
  AddPartipantButton,
  AddictionalNumber,
  List,
} from './participant-list.style';

interface Props {
  participants?: any[];
  onAddParticipant?: () => void;
  withAdd?: boolean;
  limit: number;
  onClickAvatar?: (p: User) => void;
  onSurplusClick?: () => void;
}

const renderAvatars = (
  participants: any[],
  onClickAvatar?: (p: User) => void
) =>
  [...participants]
    .reverse()
    .map((p: User, i: number) => (
      <Avatar
        key={`${p.id}-${i}`}
        id={`${p.id}`}
        src={p.avatar}
        firstName={p.first_name}
        lastName={p.last_name}
        email={p.email}
        size={32}
        withPointer={!!onClickAvatar}
        onClick={() => (onClickAvatar ? onClickAvatar(p) : null)}
      />
    ));

const ParticipantList = ({
  participants,
  onAddParticipant,
  withAdd,
  limit,
  onClickAvatar,
  onSurplusClick,
}: Props) => {
  return participants ? (
    <List reverse={false}>
      {renderAvatars(participants.slice(0, limit), onClickAvatar)}
      {participants.length - limit > 0 ? (
        <AddictionalNumber onClick={onSurplusClick}>
          +{participants.length - limit}
        </AddictionalNumber>
      ) : null}
      {withAdd ? (
        <AddPartipantButton onClick={onAddParticipant}>+</AddPartipantButton>
      ) : null}
    </List>
  ) : null;
};

ParticipantList.defaultProps = {
  limit: 4,
};

export default ParticipantList;
