import { useEffect, useState } from 'react';
import { ControlList, ControlItem, Control } from './map-type.style';

interface ControlType {
  id: string;
  name: string;
}

const renderTypes = (
  controlTypes: ControlType[],
  onMapTypeChange: (mapType: string) => void
) =>
  controlTypes.map(({ id, name }) => (
    <ControlItem key={id}>
      <Control onClick={() => onMapTypeChange(id)}>{name}</Control>
    </ControlItem>
  ));

interface Props {
  onMapTypeChange: (mapType: string) => void;
  hasStreetViewButton: boolean;
}

const MapTypeControl = ({ onMapTypeChange, hasStreetViewButton }: Props) => {
  const [types, setTypes] = useState<ControlType[]>([]);
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const google = window.google;

    const controlTypes = [
      {
        name: 'Map',
        id: google.maps.MapTypeId.ROADMAP,
      },
      {
        name: 'Satellite',
        id: google.maps.MapTypeId.HYBRID,
      },
      {
        name: 'Terrain',
        id: google.maps.MapTypeId.TERRAIN,
      },
    ];

    setTypes(controlTypes);
  }, []);

  return (
    <ControlList hasStreetViewButton={hasStreetViewButton}>
      {renderTypes(types, onMapTypeChange)}
    </ControlList>
  );
};

export default MapTypeControl;
