import { QubeType } from "app/interfaces/inspection.interfaces";
import {
  QubeCardContainer,
  QubeCardHeader,
  QubeCardQubeTitle,
  QubeCardQubeInformations,
  QubeInfo,
  QubeInfoLabel,
  QubeInfoList,
  QubeInfoValue,
  QubePropertiesList,
  QubeProperty,
  QubePropertyStatus,
  QubePropertyLabel,
} from './qube-card-mobile.style';
import Battery from "app/components/devices/battery/battery";

interface Props {
  qube: QubeType;
}

/**
 * format qube uptime
 * @returns
 */
const getUptime = (secs: number) : string => {
  if (!secs) return '-';

  const fixZero = (n: number) => (n < 10 ? `0${n.toFixed(0)}` : n.toFixed(0));

  const minutes = Math.floor(secs / 60);
  const secondsUpTo = secs % 60;
  const hours = Math.floor(minutes / 60);
  const minutesUpTo = minutes % 60;

  return `${fixZero(hours)}:${fixZero(minutesUpTo)}:${fixZero(secondsUpTo)}`;
};

/**
 *
 * @param props
 * @returns
 */
const QubeCardMobile = (props: Props) => {
  return (
    <QubeCardContainer>
      <QubeCardHeader>
        <figure>
          <img src={props.qube.armed ? '/assets/icons/qube/armed-qube.svg' : '/assets/icons/qube/qube.svg'} alt="" />
        </figure>
        <QubeCardQubeTitle>Qube {props.qube.serial}</QubeCardQubeTitle>
      </QubeCardHeader>
      <QubeCardQubeInformations>
      <QubePropertiesList>
        <QubeProperty>
          <QubePropertyStatus checked={props.qube.online} />
          <QubePropertyLabel>Online</QubePropertyLabel>
        </QubeProperty>
        <QubeProperty>
          <QubePropertyStatus checked={props.qube.armed} />
          <QubePropertyLabel>{props.qube.armed ? 'Armed' : 'Disarmed'}</QubePropertyLabel>
        </QubeProperty>
        <QubeProperty>
          <QubePropertyStatus checked={props.qube.geolocked} />
          <QubePropertyLabel>GeoLocked</QubePropertyLabel>
        </QubeProperty>
      </QubePropertiesList>

      <QubeInfoList>
        <QubeInfo>
          <QubeInfoLabel>Uptime</QubeInfoLabel>
          <QubeInfoValue>{getUptime(props.qube.uptime_secs)}</QubeInfoValue>
        </QubeInfo>
        <QubeInfo>
          <QubeInfoLabel>Battery</QubeInfoLabel>
          <QubeInfoValue>
            <Battery
              id={props.qube.serial}
              level={props.qube.battery_level}
            />
          </QubeInfoValue>
        </QubeInfo>
      </QubeInfoList>
      </QubeCardQubeInformations>
    </QubeCardContainer>
  );
};

export default QubeCardMobile;
