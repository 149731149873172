import { SettingsProviderValue } from "app/modules/settings/settings.context.d";

export const handleMeasurementUnits = (
  option: unknown,
  index: number,
  settingsContext: SettingsProviderValue,
  setShowMenu: (data: string) => void,
) => {
  const handles = [
     //0 = setSpeedUnit
    (data: unknown) => settingsContext.dispatch({
        type: 'SET_SPEED_UNIT',
        data,
    }),
    // 1 = setDistanceUnit
    (data: unknown) => settingsContext.dispatch({
      type: 'SET_DISTANCE_UNIT',
      data,
    }),
    // 2 = setWeatherUnit
    (data: unknown) => settingsContext.dispatch({
      type: 'SET_WEATHER_UNIT',
      data,
    }),
  ];
  handles[index](option);
  setShowMenu('')
}