import { InspectionProviderValue } from "app/modules/inspection/inspection.context.d";
import { QubeDeviceType } from "app/modules/qube/qube.interfaces";

/**
 *
 * @param armed
 */
export const getQubeIcon = (armed: boolean) => {
  const icon: google.maps.Icon = {
    url: armed ? '/assets/icons/qube/armed-qube.svg' : '/assets/icons/qube/qube.svg',
    scaledSize: armed ? new google.maps.Size(20, 20) : new google.maps.Size(22, 22), // scaled size
    origin: new google.maps.Point(0, 0), // origin
    anchor: armed ? new google.maps.Point(10, 10) : new google.maps.Point(11, 11), // scaled size
  };

  return icon;
};

/**
 *
 *
 */
export const renderQubeDevice = (
  device: QubeDeviceType,
  map: google.maps.Map,
  setMarker: (d: google.maps.Marker) => void,
  dispatch: InspectionProviderValue['dispatch'],
) => {
  const marker = new google.maps.Marker({
    icon: getQubeIcon(!!device.armed),
    zIndex: 10,
  });

  if (device.position?.coordinates) {
    marker.setPosition({
      lng: device.position?.coordinates[0],
      lat: device.position?.coordinates[1],
    });
  }

  if (device.position && !device.geolocked) {
    marker.setMap(map);
  }

  marker.addListener('click', () => {
    dispatch({
      type: 'SET_SELECTED_QUBE',
      data: device.serial
    });
  });

  setMarker(marker)
};