import styled from 'styled-components';

export const LinkButtonComponent = styled.button<any>`
  padding: 5px 10px;
  border: 1px solid transparent;
  border-radius: 6px;
  background-color: transparent;
  color: #5850ec;

  font-weight: 500;
  font-size: 0.93em;
  line-height: 1.12em;

  &:active {
    padding: 5px 10px;
    border: 1px solid transparent;
  }

  &:focus {
    padding: 5px 10px;
    border: 1px solid transparent;
  }

  cursor: pointer;

  ${({ customStyle }: any) => customStyle}
`;
