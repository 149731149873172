import { SettingsContext } from "app/modules/settings/settings.context.d";
import { InspectionContext } from "../../inspection.context.d";
import { formatTimezoneDate } from "@/lib/formatter";
import { convertCSVPoint } from "./exporters.formatter";

/**
 * @param 
 * @returns 
 */
export const exportcsv = (
  inspectionState: InspectionContext,
  settingsState: SettingsContext
) => {
  const columns = inspectionState.grid?.getColumns().filter((c) => {
    if (c.field === 'ete') return false;
    else if (c.field === 'input_type') return false;
    else if (c.field === 'triggers') return false;
    else {
      return true;
    }
  }) as string[];
  const columnFields = columns.map((column: any) => column.field);
  const columnNames = columns.map((column: any) => column.name);
  const pointList = inspectionState.run?.trackingpoint_set?.map((p) => {
    const point = inspectionState.trackingpoints_dic[p];
    return convertCSVPoint(point, columnFields, inspectionState, settingsState)
  }) as string[][];
  
  const csv = [columnNames].concat(pointList).map(row =>
    row
    .map(String)  // convert every value to String
    .map(v => v.replaceAll('"', '""'))  // escape double quotes
    .map(v => `"${v}"`)  // quote it
    .join(',')  // comma-separated
  ).join('\r\n');  // rows starting on new lines

  // Create a blob
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);

  // Create a link to download it
  const pom = document.createElement('a');
  pom.href = url;
  const assingAt = formatTimezoneDate({
    date: new Date(),
    timezone: settingsState.timezone?.id || 'UTC',
    format: 'YYYY-MM-DD_-_HH:mm:ss'
  }).replace('-', 'Y').replace('-','M').replace('_', 'D_').replace(':', 'H').replace(':', 'M') + 'S';
  
  const timezoneStr = settingsState.timezone?.label?.replace(/ /g, '_');
  const filenameStr = `${inspectionState.run?.name}_-_${assingAt}_-_${timezoneStr}`;
  pom.setAttribute('download', filenameStr);
  pom.click();
}
