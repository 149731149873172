import { useContext, useEffect, useState } from 'react';
import {
  centerMap, mapSurvey,
} from './survey.controller';
import {
  Props,
} from './survey.d';
import SurveyStore from 'app/modules/survey/survey.context';
import SPMarker from './components/sp-marker/sp-marker';
import Pipeline from './components/pipeline/pipeline';
import { SurveyContext } from 'app/modules/survey/survey.context.d';
import Driveline from './components/driveline/driveline';

const Survey = ({
  map,
  zoom,
  hasLabels,
  localSurvey,
}: Props) => {
  const surveyContext = useContext(SurveyStore);
  const [pipeline, setPipeline] = useState<SurveyContext['pipeline']>()
  const [survey, setSurvey] = useState<SurveyContext['survey']>()
  const [pointsDic, setPointsDic] = useState<SurveyContext['points_dic']>({})

  // Center map
  useEffect(() => {
    if (pipeline) {
      centerMap({
        pipeline: pipeline,
        map,
      });
    }
  }, [pipeline]);

  // update pipeline from survey store
  useEffect(() => {
    if (surveyContext.state?.pipeline) {
      setPipeline(surveyContext.state?.pipeline);
    }
  }, [surveyContext.state?.pipeline]);
  
  // update survey points from survey store
  useEffect(() => {
    if (surveyContext.state?.points_dic) {
      setPointsDic(surveyContext.state?.points_dic);
    }
  }, [surveyContext.state?.points_dic]);
  
  // update survey from survey store
  useEffect(() => {
    if (surveyContext.state?.survey) {
      setSurvey(surveyContext.state?.survey);
    }
  }, [surveyContext.state?.survey]);

  // update everything from local survey
  useEffect(() => {
    if (localSurvey) {
      mapSurvey(
        localSurvey,
        setPipeline,
        setSurvey,
        setPointsDic,
      );
    }
  }, [localSurvey]);

  return (
    <>
      { map && zoom &&
        survey?.surveypoint_set?.map((tId, index) => {
          const point = pointsDic[tId]
          return (
            <SPMarker
              hasLabels={hasLabels}
              zoom={zoom}
              key={`${tId}-${index}`}
              point={point}
              map={map}
            />
          );
        })
      }
      { map &&
        pipeline ? (
          <Pipeline
            pipeline={pipeline}
            map={map}
          />
        ) : null
      }
      { map &&
        pipeline?.driveline ? (
          <Driveline
            driveline={pipeline.driveline}
            map={map}
          />
        ) : null
      }

    </>
  );
};

export default Survey;
