import { useContext, useEffect, useMemo, useState } from 'react';
import IMask from 'imask';
import { formatMps, formatTimezoneDate } from '@/lib/formatter';
import InputDate from 'app/components/input-date/input-date';
import { serializePassageTime } from '../../devices/mobile/mobile.controller';
import { getTrackingPointIconSrc } from 'app/utils/map.utils';
import {
  Card,
  CardAddButton,
  CardContent,
  CardDeviceExplanationButton,
  CardDeviceExplanationContainer,
  CardDeviceExplanationTooltip,
  CardDeviceExplanationTooltipCloseButton,
  CardDeviceExplanationTooltipContent,
  CardDeviceInformation,
  CardDeviceInputType,
  CardDeviceSeeTriggersButton,
  CardHeader,
  CardMainData,
  CardMainDataGroup,
  CardMainDataLabel,
  CardMainDataValue,
  CardMoreButton,
  CardPassage,
  CardPassageInput,
  CardSidebar,
  CardTrackingPointName,
  CardTrackingPointTypeIcon,
} from './tracking-point-card-with-device.style';
import { markTriggersAsSeen } from './tracking-point-card-with-device.controller';
import SettingsStore from 'app/modules/settings/settings.context';
import InspectionStore from 'app/modules/inspection/inspection.context';
import AccountStore from 'app/modules/account/account.context';
import { AuthType } from 'app/modules/account/account.context.d';
import { PassageType, TrackingPointPlainType } from 'app/modules/inspection/inspection.interfaces';

interface Props {
  trackingpoint: TrackingPointPlainType;
  focused: boolean;
  onAddClick: () => void;
  permissionType: string | undefined;
  onMoreClick: (triggers?: boolean) => void;
  onPassageDelete: () => void;
  onPassageChange: (tstamp: number) => void;
}

/**
 * 
 * @param type
 * @returns
 */
const getInputTypeFigure = (passage?: PassageType, proportion=1) => {
  if (passage?.trigger) {
    return (
      <svg width={17 * proportion} height={19 * proportion} viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.69389 9.52888L3.41933 4.25432C2.34386 6.67413 1.16085 12.4817 5.03253 16.3533C8.90422 20.225 14.7118 19.042 17.1316 17.9666L8.69389 9.52888ZM8.69389 9.52888C9.35558 8.84616 11.0016 7.80337 12.2922 9.09393C13.5827 10.3845 12.7283 11.9501 11.9217 12.7567M10.679 5.06093C12.6588 4.88978 16.5599 5.77941 16.3252 10.7071M10.679 1.02792C13.6365 0.75905 19.7129 2.31848 20.3582 10.7071" stroke="#93A3BC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M4.22641 15.5469L1 21.9997H11.4858V19.5799" stroke="#93A3BC" strokeWidth="1.5" strokeLinejoin="round"/>
      </svg>
    );
  }

  if (passage && !passage.trigger) {
    return (
      <svg width={17 * proportion} height={17 * proportion} viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 4.42773L2.28572 10.142L2.28572 14.7135H6.85714L12.5714 8.99916M8 4.42773L11.4286 0.999163L16 5.57059L12.5714 8.99916M8 4.42773L12.5714 8.99916" stroke="#93A3BC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8 4.42773L12.5714 8.99916" stroke="#93A3BC" strokeWidth="1.5"/>
      </svg>
    );
  }

  return '-';
};

/**
 * 
 * @param type
 * @returns
 */
const getInputTypeText = (passage?: PassageType | null) => {
  if (passage?.trigger) {
    return 'Satellite: this passage was added by a trigger sent by a device on the field';
  }

  if (passage && !passage.trigger) {
    return 'Manual passage: this passage was manually added by one of the editors of this run';
  }

  return '-';
};

/**
 *
 */
const TrackingPointCardWithDevice = (props: Props) => {
  const settingsContext = useContext(SettingsStore);
  const inspectionContext = useContext(InspectionStore);
  const accountContext = useContext(AccountStore);
  const auth = accountContext.state.auth as AuthType;
  const tpoint = useMemo(() => props.trackingpoint, [props.trackingpoint]);
  const passage = useMemo(() => {
    if (!props.trackingpoint.passage) return undefined;
    return inspectionContext.state.passages_dic[props.trackingpoint.passage];
  }, [props.trackingpoint.passage, inspectionContext.state.passages_dic]);
  const icon = useMemo(() => getTrackingPointIconSrc(props.trackingpoint), [props.trackingpoint.color]);
  const [passageTimeValue, setPassageTimeValue] = useState<string>('');
  const [passageDateValue, setPassageDateValue] = useState<Date>();
  const [isOpenExpanation, setIsOpenExplanation] = useState<boolean>(false);


  const eta = useMemo(() => {
    if (!props.trackingpoint.eta) return '-';
    return formatTimezoneDate({
      date: props.trackingpoint.eta,
      timezone: settingsContext.state.timezone?.id || 'utc',
      format: 'MM/DD HH:mm:ss',
    });
  }, [props.trackingpoint.eta, settingsContext.state.timezone]);

  const speed = useMemo(() => {
    if (!props.trackingpoint.speed) return '-';
    return formatMps({
      distance: props.trackingpoint.speed,
      unit: settingsContext.state.speedUnit,
    });
  }, [props.trackingpoint.speed, settingsContext.state.speedUnit]);
  
  const nTriggers = useMemo(() => {
    if (!props.trackingpoint.trigger_set) return [];
    return props.trackingpoint.trigger_set?.filter((trigger) => {
      return !inspectionContext.state.triggerseen_trigger_set.includes(trigger);
    });
  }, [props.trackingpoint.trigger_set, inspectionContext.state.triggerseen_trigger_set]);


  useEffect(() => {
    if (passage?.tstamp) {
      const time = formatTimezoneDate({
        date: passage.tstamp,
        timezone: settingsContext.state.timezone?.id || 'utc',
        format: 'HH:mm:ss.SSS',
      });

      setPassageTimeValue(time);
      setPassageDateValue(new Date(passage.tstamp));
    }

    if (!passage?.tstamp) {
      setPassageTimeValue('');
      setPassageDateValue(undefined);
    }
  }, [settingsContext.state.timezone, passage?.tstamp]);

  const mask = new IMask.MaskedPattern({
    mask: 'HH:MM:SS.MMM',
    blocks: {
      HH: { mask: /^[0-9]{1,2}$/ },
      MM: { mask: /^[0-9]{1,2}$/ },
      SS: { mask: /^[0-9]{1,2}$/ },
      MMM: { mask: /^[0-9]{1,3}$/ },
    },
  });

  return (
    <Card id={`tp-list-id-${tpoint.id}`} data-testid={tpoint.name} focused={props.focused}>
      <CardContent>
        <CardHeader>
          <CardTrackingPointTypeIcon src={icon.url} alt="" />
          <CardTrackingPointName>{tpoint.name}</CardTrackingPointName>
        </CardHeader>

        <CardPassage isObserver={props.permissionType !== 'editor'}>
          <CardAddButton disabled={props.permissionType !== 'editor'} onClick={props.onAddClick}>Add</CardAddButton>
          <CardPassageInput isObserver={props.permissionType !== 'editor'} invalid={passage && !passage.valid}>
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 0C2.24288 0 0 2.24301 0 5C0 7.75698 2.24302 10 5 10C7.75698 10 10 7.75698 10 5C10 2.24301 7.75698 0 5 0ZM5 9.3752C2.58757 9.3752 0.625029 7.41261 0.625029 5.00023C0.625029 2.58785 2.58762 0.625262 5 0.625262C7.41238 0.625262 9.37497 2.58785 9.37497 5.00023C9.37497 7.41261 7.41238 9.3752 5 9.3752ZM6.99848 7.37779C7.0956 7.52037 7.05888 7.71486 6.91622 7.81198C6.86238 7.8486 6.80107 7.86618 6.74058 7.86618C6.64064 7.86618 6.54252 7.81844 6.48203 7.72962L4.74172 5.1761C4.70637 5.12426 4.68752 5.06295 4.68752 5.00009V1.65128C4.68752 1.47874 4.82745 1.33882 4.99998 1.33882C5.17252 1.33882 5.31245 1.47875 5.31245 1.65128V4.90377L6.99848 7.37779Z" fill="#5850EC"></path></svg>
            <input
              type="tel"
              placeholder={props.permissionType !== 'editor' ? '---' : ''}
              value={mask.resolve(passageTimeValue)}
              disabled={props.permissionType !== 'editor'}
              onChange={(e) => setPassageTimeValue(e.target.value)}
              onBlur={() => {
                if (settingsContext.state.timezone?.id && passageTimeValue && !passage?.tstamp) {
                  const serializedPassage = serializePassageTime(passageTimeValue, settingsContext.state.timezone.id)
                  props.onPassageChange(serializedPassage);
                }
                if (settingsContext.state.timezone?.id && passageTimeValue && passage?.tstamp) {
                  const serializedPassage = serializePassageTime(passageTimeValue, settingsContext.state.timezone.id, passage.tstamp)
                  const pTstamp = new Date(passage.tstamp);
                  if (serializedPassage !== pTstamp.valueOf()) {
                    props.onPassageChange(serializedPassage);
                  }
                }
                if (settingsContext.state.timezone?.id && !passageTimeValue && props.trackingpoint.passage) {
                  props.onPassageDelete();
                }
              }}
            />
          </CardPassageInput>
          <CardPassageInput isObserver={props.permissionType !== 'editor'} invalid={tpoint.passage && !passage?.valid}>
            <svg width="11" height="11" viewBox="0 0 11 11" fill="#5850EC">
              <path fillRule="evenodd" clipRule="evenodd" d="M9.6666 1.08698H9.10046V0H7.96819V1.08698H3.43909V0H2.30681V1.08698H1.74067C1.11792 1.08698 0.608398 1.57613 0.608398 2.17397V9.78286C0.608398 10.3807 1.11792 10.8698 1.74067 10.8698H9.6666C10.2894 10.8698 10.7989 10.3807 10.7989 9.78286V2.17397C10.7989 1.57613 10.2894 1.08698 9.6666 1.08698ZM9.6666 9.78286H1.74067V3.80444H9.6666V9.78286Z" fill="#5850EC" />
            </svg>

            <InputDate
              placeholder={props.permissionType !== 'editor' ? '---' : ''}
              disabled={props.permissionType !== 'editor'}
              value={passageDateValue?.toISOString()}
              handleChange={(e) => {
                setPassageDateValue(e.value)
                props.onPassageChange(e.value.valueOf())
              }}
              format="MMM dd, yyyy"
              customStyle={`
                border: none;
                padding: 0;

                input {
                  color: ${passage && !passage.valid ? 'rgba(237, 32, 36, 1)' : '#5850ec'} !important;

                  font-family: 'Inter';
                  font-style: normal;
                  font-weight: 700;
                  font-size: 10px !important;
                  line-height: 12px;
                }
              `}
            />
          </CardPassageInput>
        </CardPassage>

        <CardMainData>
          <CardMainDataGroup>
            <CardMainDataLabel>Speed:</CardMainDataLabel>
            <CardMainDataValue>{speed}</CardMainDataValue>
          </CardMainDataGroup>

          <CardMainDataGroup>
            <CardMainDataLabel>ETA:</CardMainDataLabel>
            <CardMainDataValue>{eta}</CardMainDataValue>
          </CardMainDataGroup>
        </CardMainData>

        <CardDeviceInformation>
          <CardDeviceSeeTriggersButton
            onClick={() => {
              props.onMoreClick(true)
              if (nTriggers.length && auth?.user.id) {
                markTriggersAsSeen(
                  auth.token,
                  nTriggers,
                );
              }
            }}
            highlighted={nTriggers.length}
          >
            Triggers ({tpoint.trigger_set?.length || 0})
          </CardDeviceSeeTriggersButton>
          <CardDeviceInputType>
            <label>Input:</label>
            <figure>
              {getInputTypeFigure(passage)}
            </figure>
          </CardDeviceInputType>

          {
            tpoint.passage ? (
              <CardDeviceExplanationContainer>
                <CardDeviceExplanationButton
                  onClick={() => setIsOpenExplanation(!isOpenExpanation)}
                >
                  <svg width="5" height="9" viewBox="0 0 5 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.13096 6.27417V6.22958C2.13592 5.75641 2.18546 5.37986 2.2796 5.09992C2.37374 4.81999 2.50752 4.59331 2.68093 4.4199C2.85434 4.24648 3.06244 4.0867 3.30521 3.94054C3.45138 3.85135 3.58267 3.74607 3.69911 3.62468C3.81554 3.50081 3.9072 3.35836 3.97409 3.19734C4.04346 3.03631 4.07814 2.85794 4.07814 2.66224C4.07814 2.41946 4.02116 2.20889 3.9072 2.03052C3.79325 1.85215 3.64089 1.71466 3.45014 1.61804C3.25938 1.52143 3.04757 1.47312 2.8147 1.47312C2.61156 1.47312 2.41585 1.51524 2.22758 1.59946C2.0393 1.68369 1.88199 1.81623 1.75565 1.99707C1.6293 2.17792 1.55622 2.4145 1.5364 2.70683H0.599976C0.619794 2.28568 0.728796 1.92523 0.926982 1.62548C1.12765 1.32572 1.39148 1.09657 1.71849 0.938018C2.04797 0.77947 2.41338 0.700195 2.8147 0.700195C3.25071 0.700195 3.62974 0.786902 3.9518 0.960314C4.27632 1.13373 4.52653 1.37155 4.70242 1.67378C4.88079 1.97602 4.96998 2.32037 4.96998 2.70683C4.96998 2.97933 4.92786 3.22583 4.84363 3.44631C4.76188 3.66679 4.64297 3.86374 4.4869 4.03715C4.3333 4.21056 4.1475 4.36416 3.9295 4.49793C3.71149 4.63419 3.53684 4.77787 3.40555 4.92899C3.27425 5.07763 3.17887 5.25476 3.11941 5.46037C3.05996 5.66599 3.02775 5.92239 3.0228 6.22958V6.27417H2.13096ZM2.60661 8.47404C2.42329 8.47404 2.26598 8.40839 2.13468 8.27709C2.00338 8.14579 1.93773 7.98848 1.93773 7.80516C1.93773 7.62184 2.00338 7.46453 2.13468 7.33323C2.26598 7.20193 2.42329 7.13628 2.60661 7.13628C2.78993 7.13628 2.94724 7.20193 3.07854 7.33323C3.20984 7.46453 3.27549 7.62184 3.27549 7.80516C3.27549 7.92655 3.24452 8.03803 3.18259 8.1396C3.12313 8.24117 3.04262 8.32292 2.94105 8.38485C2.84195 8.44431 2.73047 8.47404 2.60661 8.47404Z" fill="#4b5066"/>
                  </svg>
                </CardDeviceExplanationButton>

                {
                  isOpenExpanation ? (
                    <CardDeviceExplanationTooltip>
                      <CardDeviceExplanationTooltipCloseButton onClick={() => setIsOpenExplanation(false  )}>
                        <svg width="27" height="25.2" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect x="10.5693" y="2.91309" width="1.17688" height="9.41507" transform="rotate(45 10.5693 2.91309)" fill="#99A2AD"/>
                          <rect x="11.4014" y="9.56982" width="1.17688" height="9.41507" transform="rotate(135 11.4014 9.56982)" fill="#99A2AD"/>
                        </svg>
                      </CardDeviceExplanationTooltipCloseButton>
                      <CardDeviceExplanationTooltipContent>
                        <figure>
                          {getInputTypeFigure(passage, 1.2)}
                        </figure>
                        <p>
                          {getInputTypeText(passage)}
                        </p>
                      </CardDeviceExplanationTooltipContent>
                      <span />
                    </CardDeviceExplanationTooltip>
                  ) : null
                }

              </CardDeviceExplanationContainer>
            ) : null
          }
        </CardDeviceInformation>
      </CardContent>

      <CardSidebar>
        <CardMoreButton onClick={() => props.onMoreClick()}>
          <svg width="16" height="4" viewBox="0 0 16 4" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="2" cy="2" r="2" fill="#4B5066" />
            <circle cx="8" cy="2" r="2" fill="#4B5066" />
            <circle cx="14" cy="2" r="2" fill="#4B5066" />
          </svg>
        </CardMoreButton>
        <label>more</label>
      </CardSidebar>
    </Card>
  );
};

export default TrackingPointCardWithDevice;
