import moment from 'moment-timezone';
import { formatMps, formatTimezoneDate } from '@/lib/formatter';

export const initComponent = ({
  setSpeed,
  run,
  settingsContext,
  setInitialTime,
  setInitialDate,
}) => {
  if (run?.scheduled_launch_at) {
    const launch_at = moment.utc(run?.scheduled_launch_at)

    setInitialTime(
      formatTimezoneDate({
        date: launch_at.toDate(),
        timezone: settingsContext.state.timezone?.id,
        format: 'HH:mm:ss.SSS',
      })
    );

    setInitialDate(
      formatTimezoneDate({
        date: launch_at.toDate(),
        timezone: settingsContext.state.timezone?.id,
        format: 'YYYY/MM/DD',
      })
    );
  }

  if (run?.initial_speed) {
    const speed = formatMps({
      distance: run.initial_speed,
      unit: settingsContext.state.speedUnit,
      returnNumber: true,
    });

    setSpeed(speed);
  }
};

export const getStatusLabel = (inspectionState): string => {
  if (inspectionState.run?.status === 'EARLY_FINISHED') return 'FINISHED EARLY';
  else return `${inspectionState.run?.status}`;
};