import styled from 'styled-components';

export const Content = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;
`;

export const PassagesSection = styled.section`
  display: grid;
  grid-template-columns: 1fr;

  gap: 8px;

  & + & {
    margin-top: 22px;
  }
`;

export const GraditentWrapper = styled.div`
  position: relative;
  width: 100px;
  display: flex;
  gap: 4px;

  align-items: center;
`;


export const SectionTitle = styled.h3`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.2em;
  text-transform: uppercase;

  color: rgba(105, 115, 134, 0.75);

  margin-bottom: 9px;
`;

export const Card = styled.article`
  display: grid;
  grid-template-columns: 1fr 130px auto;
  align-items: center;

  padding: 4px 10px;

  background-color: #f5f5f8;
  border-radius: 3px;
`;

export const CardPassage = styled.div``;

export const CardPassageTimeWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
`;

export const CardPassageTime = styled.time`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;

  color: #161e2e;

  @media (min-width: 1024px) {
    font-size: 15px;
  }
`;

export const CardPassageMark = styled.span`
  display: flex;
  align-items: center;
  gap: 2px;

  color: #f6382d;
  font-size: 9px;

  @media (min-width: 1024px) {
    font-size: 12px;
  }
`;

export const CardActions = styled.div`
  display: flex;
  gap: 8px;

  @media (min-width: 1024px) {
    gap: 13px;
  }
`;

export const ApproveButton = styled.button`
  width: 80px;
  height: 27px;
  border-radius: 30px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;

  color: #5850ec;

  cursor: pointer;
`;

export const RejectButton = styled.button`
  width: 80px;
  height: 27px;
  border-radius: 30px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  background-color: #5850ec;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;

  color: #ffffff;

  cursor: pointer;
`;

export const Empty = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;

  color: rgba(26, 31, 54, 0.65);
`;

export const EmptyWrapper = styled.div`
  text-align: center;
  padding: 16px;
`;
