export const units = {
  speed: [
    { id: 'mps', label: 'm/s' },
    { id: 'kmh', label: 'km/h' },
    { id: 'mph', label: 'mph' },
  ],
  distance: [
    { id: 'm', label: 'm' },
    { id: 'km', label: 'km' },
    { id: 'ft', label: 'ft' },
    { id: 'mi', label: 'mi' },
  ],
  weather: [
    { id: 'farenheit', label: 'ºF' },
    { id: 'celsius', label: 'ºC' },
  ],
};
