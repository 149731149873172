/*************************************************************************
 * Generated by Django React Framework on 2023-09-29 18:32 (UTC)         *
 *                                                                       *
 * Based on all StateChannel.as_asgi() calls in backend.asgi.application *
 *                                                                       *
 * This is expected to match project/channels.py                         *
 *                                                                       *
 *                         DO NOT EDIT THIS FILE                         *
 *                                                                       *
 * To rebuild it, run:                                                   *
 *                                                                       *
 *     ./manage.py makefrontend [project]                                *
 *                                                                       *
 *************************************************************************/
import { StateChannel } from 'lib/django-react';

const SOCKET_URL = import.meta.env.VITE_SOCKET_URL.replace(/ws$/, '');

import { ProjectType } from './project.interfaces.d';


export class ProjectChannel extends StateChannel<ProjectType> {

  anchor = 'project.serializers.ProjectSerializer';
  endpoint: string = 'ws/project/{project_id}/';

  project_id: number;

  baseURL: string = SOCKET_URL;

  constructor(project_id: number, token: string) {
    super(token);
    this.project_id = project_id;
  }

  model = {
    "project.serializers.ProjectSerializer": {
      "customer": "customer.serializers.CustomerSerializer",
      "creator": "account.serializers.UserSerializer",
      "final_customer": "project.serializers.FinalCustomerSerializer",
      "participant_set": "account.serializers.ParticipantSerializer",
      "qube_set": "qube.serializers.QubeDeviceSerializer"
    },
    "customer.serializers.CustomerSerializer": {
      "company": "account.serializers.CompanySerializer"
    },
    "account.serializers.CompanySerializer": {},
    "project.serializers.FinalCustomerSerializer": {},
    "account.serializers.ParticipantSerializer": {
      "project": "account.serializers.ProjectLocalSerializer",
      "user": "account.serializers.UserSerializer"
    },
    "account.serializers.ProjectLocalSerializer": {},
    "account.serializers.UserSerializer": {},
    "qube.serializers.QubeDeviceSerializer": {}
  };
}