import { useContext, useEffect, useState } from 'react';
import AccountStore from 'app/modules/account/account.context';
import { Container } from './run.page.style';
import InspectionStore from '../inspection.context';
import { AuthType } from 'app/modules/account/account.context.d';
import { checkIsAllowedMakeEditions, getBreadcrumbs } from './run.controller';
import Breadcrumbs from 'app/components/breadcrumbs/breadcrumbs';
import Desktop from './devices/desktop/desktop';
import Mobile from './devices/mobile/mobile';
import MobileDetect from 'mobile-detect';


/**
 *
 * @returns
 */
const RunPage = () => {
  const accountContext = useContext(AccountStore);
  const auth = accountContext.state.auth as AuthType;
  const inspectionContext = useContext(InspectionStore);
  const [permissionType, setPermissionType] = useState<string>('');
  const [observerMode, setObserverMode]= useState<boolean>(false);
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    const md = new MobileDetect(window.navigator.userAgent);
    setIsMobile(!!md.mobile());
  }, []);

  useEffect(() => {
    if (inspectionContext.state.run?.project) {
      checkIsAllowedMakeEditions(
        inspectionContext.state.run?.project?.toString(),
        auth?.token,
        setPermissionType,
      );
    }
  }, [inspectionContext.state.run?.project])

  useEffect(() => {
    if (permissionType) {
      const obj: { [key:string]: boolean } = {
        observer: true,
        editor: false,
      };
      setObserverMode(obj[permissionType]);
    }
  }, [permissionType])

  useEffect(() => {
    const time = 1000 - (new Date().getTime() % 1000);
    setTimeout(() => {
      inspectionContext.dispatch({
        type: 'SET_TICK',
        data: (inspectionContext.state.tick + 1) % 60,
      });
    }, time);
  }, [inspectionContext.state.tick]);

  return (
    <Container>
      <Breadcrumbs withoutAvatar routes={getBreadcrumbs(inspectionContext.state)} />
      {
        isMobile ? (
          <Mobile
            observerMode={observerMode}
            setObserverMode={setObserverMode}
            permissionType={permissionType}
          />
        ) : (
          <Desktop
            observerMode={observerMode}
            setObserverMode={setObserverMode}
            passageAGM={undefined} // Set triggers modal
            permissionType={permissionType}
          />
        )
      }
    </Container>
  );
};

export default RunPage;
