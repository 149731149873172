import styled from 'styled-components';

export const MobileTimezoneModalContent = styled.div`
  width: 100%;

  background-color: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;

  max-height: 100%;

  display: grid;
  grid-template-rows: auto auto 1fr;
`;

export const MobileTimezoneModalContentCloseButton = styled.button`
  background-color: transparent;
`;

export const MobileTimezoneModalContentCurrentWrapper = styled.div`
  padding: 14px 17px 14px 17px;
  border-bottom: 0.7px solid rgba(130, 132, 143, 0.7);
`;

export const MobileTimezoneModalContentCurrentTimezone = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;

  color: #494c5d;
`;

export const MobileTimezoneModalContentCurrentTimezoneTitle = styled.label`
  display: inline-block;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */


  color: #494c5d;
  margin-bottom: 10px;
`;

export const MobileTimezoneModalContentHeader = styled.header`
  padding: 14px 17px 14px 17px;
  border-bottom: 0.7px solid rgba(130, 132, 143, 0.7);

  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 9px;
`;

export const MobileTimezoneModalContentTimezoneList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;

  padding: 14px 17px 24px 17px;

  overflow: auto;
`;

export const MobileTimezoneModalContentTimezoneListItem = styled.li``;

export const MobileTimezoneModalContentTitle = styled.h2`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;

  color: #494c5d;
`;

export const MobileTimezoneModalWrapper = styled.div`
  position: fixed;
  top: 40px;
  left: 0;
  z-index: 20;

  display: flex;
  align-items: center;

  width: 100%;
  height: calc(100vh - 40px);
  background-color: rgba(112, 122, 140, 0.5);

  padding: 30px;
`;
