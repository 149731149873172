import { UserType } from 'app/interfaces/account.interfaces';
import { InspectionContext } from '../inspection.context.d';

export const updateUser = (state: InspectionContext, user: UserType): InspectionContext => {
  if (!user) return state;
  const stateCopy = { ...state };

  if (!state.users_dic[user.id]) {
    stateCopy.user_set.push(user.id);
  }
  stateCopy.users_dic[user.id] = user;
  return stateCopy;
}