import { ProgressBarContainer, Bar } from './progress-bar.style';

/**
 *
 * @param param0
 * @returns
 */
const ProgressBar = ({ currentPercentage }: { currentPercentage: number }) => (
  <ProgressBarContainer>
    <Bar width={currentPercentage} />
  </ProgressBarContainer>
);

ProgressBar.defaultProps = {
  currentPercentage: 0,
};

export default ProgressBar;
