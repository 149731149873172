import {
  SwitchButtonLabel,
  SwitchButtonWrapper,
  SwitchButtonSwitch,
  SwitchButtonSwitchBall,
} from './switch-button.style';

interface Props {
  label?: string;
  handleChange: () => void;
  disabled?: boolean;
  customLabelStyle?: string;
  on: boolean;
}

/**
 * 
 * @param props
 * @returns 
 */
const SwitchButton = (props: Props) => (
  <SwitchButtonWrapper>
    {
      props.label ? (
        <SwitchButtonLabel customLabelStyle={props.customLabelStyle}>
          {props.label}
        </SwitchButtonLabel>
      ) : null
    }
    
    <SwitchButtonSwitch disabled={props.disabled} onClick={props.disabled ? null : props.handleChange} on={props.on}>
      <SwitchButtonSwitchBall />
    </SwitchButtonSwitch>
  </SwitchButtonWrapper>
);

export default SwitchButton;
