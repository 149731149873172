import { useContext, useEffect, useState } from 'react';
import { Route, Switch, useHistory, useParams } from 'react-router-dom';
import { RunType } from 'app/interfaces/inspection.interfaces';
import AccountStore from 'app/modules/account/account.context';
import { AuthType } from 'app/modules/account/account.context.d';
import Breadcrumbs, { Breadcrumb } from 'app/components/breadcrumbs/breadcrumbs';
import ForbiddenError from './components/forbidden-error/forbidden-error';
import { fetchProjectRuns, checkPermissions, onDeleteProject } from './project.controller';
import {
  CreatedBy, DescriptionLabel, DescriptionText, DescriptionWrapper,
  MenuButton, MenuListItem, MenuListWrapper, ProjectContainer, ProjectContent,
  ProjectHeader, ProjectTitle, SkeletonTextWrapper, TabWrapper,
} from './project.page.style';
import Skeleton from 'app/components/skeleton/skeleton';
import ParticipantList from 'app/components/participant-list/participant-list';
import Tabs from 'app/components/tabs/tabs';
import People from './components/people/people';
import Devices from 'app/pages/devices/devices';
import InviteModal from 'app/components/invitation-modal/invitation-modal';
import EditProjectModal from 'app/components/edit-project-modal/editProjectModal';
import ConfirmationModal from 'app/components/confirmation-modal/confirmation-modal';
import CreateRunModal from 'app/components/create-run-modal/createRunModal';
import Runs from './components/runs/runs';
import NavigationContainer from 'app/components/navigation-container/navigation-container';
import ProjectsStore from '../projects.context';
import Toast from 'app/components/toast/toast';
import { ProjectChannel } from 'app/modules/project/project.channels';
import { useChannelState } from 'lib/django-react/hook';
import { ParticipantType } from 'app/modules/account/account.interfaces';

const DEFAULT_BREACRUMBS = [
  {
    id: '#2',
    text: 'Projects',
    href: `/projects`,
    loading: false,
  },
];

/**
 *
 * @returns
 */
const ProjectPage = () => {
  // router
  const { id }: { id: string } = useParams();
  const history = useHistory();

  // contexts
  const accountContext = useContext(AccountStore);
  const projectsContext = useContext(ProjectsStore);
  const auth = accountContext.state.auth as AuthType;

  // Project Channel
  const channel = new ProjectChannel(parseInt(id), auth.token);
  const project = useChannelState(channel);

  // states
  const [runs, setRuns] = useState<RunType[]>([]);
  const [unauthorized, setUnauthorized] = useState<boolean>(false);
  const [isAdministrator, setIsAdministrator] = useState<boolean>(false);
  const [confirmationModal, setConfirmationModal] = useState<any>({});
  const [inviting, setInviting] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<string>('runs');
  const [editModal, setEditModal] = useState<boolean>(false);
  const [showCreateRun, setShowCreateRun] = useState<boolean>(false);

  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([
    {
      id: '#2',
      text: 'Projects',
      href: `/projects`,
      loading: false,
    },
    {
      id: '#1',
      text: '',
      href: `/projects/${id}`,
      loading: true,
    },
  ]);

  // inital fetchs
  const loadData = () => {
    checkPermissions(id, auth.token, setIsAdministrator, setUnauthorized);
    fetchProjectRuns(id, auth.token, setRuns);
  };

  useEffect(() => {
    loadData();

    return () => {
      projectsContext.dispatch({
        type: 'RESET_PROJECT_PAGE'
      });
    };
  }, []);

  useEffect(() => {
    const { pathname } = history.location;
    const selected = pathname.split('/')[3] || 'runs';
    setSelectedTab(selected);
  }, [history.location]);


  useEffect(() => {
    if (!loading && !project) {
      setBreadcrumbs([...DEFAULT_BREACRUMBS]);
    }

    if (!loading && project) {
      const newBreadcrumbs = [...DEFAULT_BREACRUMBS];
      newBreadcrumbs.push({
        id: '#1',
        text: project.name || '',
        href: `/projects/${id}`,
        loading,
      });
      setBreadcrumbs([...newBreadcrumbs]);
    }
  }, [project?.id, loading]);

  // define tab routes
  const routes = [
    {
      id: 'runs',
      text: `Runs`,
      href: `/projects/${id}`,
      quantity: runs.length || 0,
    },
    {
      id: 'people',
      text: 'People',
      href: `/projects/${id}/people`,
      quantity: project?.participant_set?.length || 0,
    },
  ];

  if (unauthorized) {
    return <ForbiddenError breadcrumbs={breadcrumbs} />
  }

  return (
    <NavigationContainer>
      <ProjectContainer>
        <Breadcrumbs
          customStyle={`
            position: sticky;
            top: 0;
            left: 0;
            width: 100%;

            @media (min-width: 1024px) {
              position: relative;
            }
          `}
          routes={breadcrumbs}
        />
        <ProjectHeader>
          <div>
            <Skeleton loading={loading || !project} width="300px" height="36px">
              <ProjectTitle className="project-page-title">
                {project?.name}
              </ProjectTitle>
            </Skeleton>
            <Skeleton loading={loading || !project} width="450px">
              <CreatedBy className="project-page-created-by">
                Created by {project?.creator ? project.creator.email : 'jason@pigging.com'}
              </CreatedBy>
            </Skeleton>
          </div>
          <div>
            <Skeleton loading={!project?.participant_set}>
              <ParticipantList
                participants={project?.participant_set
                    .map((participant: ParticipantType) => participant?.user)
                    .filter((user) => !!user)}
                withAdd={isAdministrator}
                onAddParticipant={() => setInviting(true)}
              />
            </Skeleton>
          </div>
          <MenuButton
            id="project-page-menu"
            hide={!isAdministrator}
            onClick={() => setShowMenu(!showMenu)}
          >
            <svg
              width="16"
              height="4"
              viewBox="0 0 16 4"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="2" cy="2" r="2" fill="#8E949F" />
              <circle cx="8" cy="2" r="2" fill="#8E949F" />
              <circle cx="14" cy="2" r="2" fill="#8E949F" />
            </svg>
            <MenuListWrapper hide={!showMenu}>
              <MenuListItem
                id="project-page-menu-new-run"
                onClick={() => setShowCreateRun(!showCreateRun)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="21px"
                  viewBox="0 0 24 24"
                  width="21px"
                  fill="#6b7280"
                >
                  <path d="M0 0h24v24H0V0z" fill="none" />
                  <path d="M13 7h-2v4H7v2h4v4h2v-4h4v-2h-4V7zm-1-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
                </svg>
                New Run
              </MenuListItem>
              <MenuListItem id="project-page-menu-edit" onClick={() => setEditModal(true)}>
                <svg
                  width="18px"
                  height="18px"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 4L2 9L2 13H6L11 8M7 4L10 1L14 5L11 8M7 4L11 8"
                    stroke="#8C929D"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path d="M7 4L11 8" stroke="#8C929D" strokeWidth="2" />
                </svg>
                Edit
              </MenuListItem>
              <MenuListItem
                id="project-page-menu-delete"
                onClick={() => {
                  setConfirmationModal({
                    hide: false,
                    title: 'Are you sure?',
                    text: 'This project will be deleted permanently.',
                    onConfirm: () => {
                      onDeleteProject({
                        setLoading,
                        id: project?.id,
                        auth,
                        history,
                        accountDispatch: accountContext.dispatch,
                      });
                    },
                    onCancel: () => setConfirmationModal({}),
                  });
                }}
              >
                <svg
                  width="16px"
                  height="16px"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.6667 2.33333H17M15.6667 2.33333V14.3333C15.6667 15.8061 14.4728 17 13 17H5C3.52724 17 2.33333 15.8061 2.33333 14.3333V2.33333M15.6667 2.33333H11.6667M1 2.33333H2.33333M2.33333 2.33333H6.33333M11.6667 2.33333C11.6667 1.59695 11.0697 1 10.3333 1H7.66667C6.93029 1 6.33333 1.59695 6.33333 2.33333M11.6667 2.33333H6.33333M9 11.6667V7.66667"
                    stroke="#8C929D"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Delete
              </MenuListItem>
            </MenuListWrapper>
          </MenuButton>
        </ProjectHeader>
        <DescriptionWrapper>
          <DescriptionLabel>Description</DescriptionLabel>
          <SkeletonTextWrapper>
            <Skeleton loading={loading} width="100%" />
            <Skeleton loading={loading} width="90%" />
          </SkeletonTextWrapper>
          {!loading ? (
            <DescriptionText className="project-page-description">
              {project?.description || 'No description'}
            </DescriptionText>
          ) : null}
        </DescriptionWrapper>
        <TabWrapper>
          <Tabs
            routes={routes}
            selected={
              routes.find((route) => route.id === selectedTab) || routes[0]
            }
          />
        </TabWrapper>
        <ProjectContent>
          <Switch>
            <Route exact path="/projects/:id">
              <Runs
                runs={runs}
                loading={loading}
                auth={auth}
                refreshFunc={loadData}
                setLoading={setLoading}
                setRuns={setRuns}
                usingLineStat={project?.using_linestat || false}
                usingQube={project?.using_qube || false}
              />
            </Route>
            <Route exact path="/projects/:id/people">
              {project && auth ? (
                <People
                  isAdministrator={isAdministrator}
                  project={project}
                  token={auth.token}
                  onPermissionChanged={() => {
                    // TODO: unknow
                  }}
                />
              ) : null}
            </Route>
            <Route exact path="/projects/:id/devices">
              {
                project && auth ? (
                  <Devices qubes={project.qube_set} />
                ) : (<></>)
              }
            </Route>
          </Switch>
        </ProjectContent>

        <InviteModal
          auth={auth}
          opened={inviting}
          onClose={() => {
            setInviting(false);
          }}
          links={project?.shareable_links || {}}
        />

        <EditProjectModal
          hide={!editModal}
          closeAction={() => setEditModal(false)}
          project={project}
          cb={loadData}
        />

        <ConfirmationModal {...confirmationModal} />

        {showCreateRun ? (
          <CreateRunModal
            onClose={() => setShowCreateRun(false)}
            hide={false}
            onUpdated={loadData}
            projectId={id}
            customerId={project?.customer.id}
            auth={auth}
          />
        ) : null}
      </ProjectContainer>
      <Toast />
    </NavigationContainer>
  );
};

export default ProjectPage;
