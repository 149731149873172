import { InspectionContext } from '../inspection.context.d';
import { TrackingPointNoteType, TrackingPointPlainType } from '../inspection.interfaces.d';
import { SurveyMediaPlainType } from 'app/modules/survey/survey.interfaces';
import { TriggerSeenType, TriggerType } from 'app/modules/detection/detection.interfaces';

/**
 * 
 * @param state 
 * @param point 
 * @returns 
 */
export const deleteTrackingPoint = (state: InspectionContext, point: { id: number }): InspectionContext => {
  if (!point.id) return state;
  const stateCopy = { ...state };
  const tpSet = [...state.trackingpoint_set];
  const tpDic = { ...state.trackingpoints_dic };

  const index = tpSet.indexOf(point.id);
  const nTpSet = tpSet.splice(index, 1);

  delete tpDic[point.id];

  stateCopy.trackingpoint_set = nTpSet;
  stateCopy.trackingpoints_dic = tpDic;

  return stateCopy;
}
/**
 * 
 * @param state 
 * @param point 
 * @returns 
 */
export const updateTPMarkerDic = (state: InspectionContext, payload: unknown) => {
  const data = payload as { marker: google.maps.Marker, pointId }
  const stateCopy = { ...state };
  const markers = { ...state.point_markers };

  markers[data.pointId] = data.marker;
  stateCopy.point_markers = markers;
  return stateCopy;
}

/**
 * 
 * @param state 
 * @param point 
 * @returns 
 */
export const updatePoint = (state: InspectionContext, point: TrackingPointPlainType) => {
  const stateCopy = { ...state };
  const dicCopy = { ...stateCopy.trackingpoints_dic };

  if (!state.trackingpoints_dic[point.id]) {
    const list = [...stateCopy.trackingpoint_set];
    list.push(point.id);
    stateCopy.trackingpoint_set = list;
  }

  dicCopy[point.id] = point;
  stateCopy.trackingpoints_dic = dicCopy;
  return stateCopy;
}

/**
 * 
 * @param state 
 * @param note 
 * @returns 
 */
export const updatePointNote = (state: InspectionContext, note: TrackingPointNoteType) => {
  const stateCopy = { ...state };
  const notesDic = { ...stateCopy.notes_dic };
  
  if (!state.notes_dic[note.id]) {
    const noteSet = [ ...stateCopy.note_set];
    noteSet.push(note.id);
    stateCopy.note_set = noteSet;
  }
  
  notesDic[note.id] = note;
  
  if (note.tracking_point) {
    const tpToNotesDic = { ...stateCopy.tp_to_notes_dic };
    if (!tpToNotesDic[note.tracking_point]) tpToNotesDic[note.tracking_point] = []
    if (!tpToNotesDic[note.tracking_point].includes(note.id)) tpToNotesDic[note.tracking_point].push(note.id);
    stateCopy.tp_to_notes_dic = tpToNotesDic;
  }
  
  stateCopy.notes_dic = notesDic;

  return stateCopy;
}

/**
 * 
 * @param state 
 * @param media 
 * @returns 
 */
export const updatePointMedia = (state: InspectionContext, media: SurveyMediaPlainType) => {
  const stateCopy = { ...state };
  const mediasDic = { ...state.medias_dic };
  const mediaSet = [...state.media_set];
  const tpToMediasDic = { ...state.tp_to_medias_dic };

  if (!state.medias_dic[media.id]) {
    mediaSet.push(media.id);
  }
  mediasDic[media.id] = media;

  media.trackingpoint_set?.forEach((tpId) => {
    if (!tpToMediasDic[tpId]) {
      tpToMediasDic[tpId] = [];
    }
    if (!tpToMediasDic[tpId].includes(media.id)) {
      tpToMediasDic[tpId].push(media.id)
    }
  });

  stateCopy.medias_dic = mediasDic;
  stateCopy.media_set = mediaSet;
  stateCopy.tp_to_medias_dic = tpToMediasDic;

  return stateCopy;
};

/**
 * 
 * @param state 
 * @param trigger 
 */
export const updateTrigger = (state: InspectionContext, trigger: TriggerType) => {
  const stateCopy = { ...state };
  const triggerDic = { ...state.triggers_dic };
  const tpointId = trigger.tracking_point as number;
  const tpointTriggerDic = { ...state.tp_to_triggers_dic };

  triggerDic[trigger.id] = trigger;

  const tpointTriggersSet = tpointTriggerDic[tpointId] ? [...tpointTriggerDic[tpointId]] : [];
  if (!tpointTriggersSet.includes(trigger.id)) {
    tpointTriggersSet.push(trigger.id);
    tpointTriggerDic[tpointId] = tpointTriggersSet;
  }
  
  stateCopy.triggers_dic = triggerDic;
  stateCopy.tp_to_triggers_dic = tpointTriggerDic;
  return stateCopy;
};

/**
 * 
 * @param state 
 * @param triggerSeen
 */
export const updateTriggerSeenTrigger = (state: InspectionContext, triggerSeen: TriggerSeenType) => {
  const stateCopy = { ...state };
  const triggerId = triggerSeen.trigger as number;
  const triggerSet = [...stateCopy.triggerseen_trigger_set];

  if (!triggerSet.includes(triggerId)) {
    triggerSet.push(triggerId);
  }

  stateCopy.triggerseen_trigger_set = triggerSet;
  return stateCopy;
};
