import styled from 'styled-components';

export const WidgetsOverlayWrapper = styled.div`
  position: absolute;
  z-index: 1;
  right: 0;
  top: 0;

  height: 100%;

  display: none;

  @media (min-width: 1024px) {
    display: block;
  }
`;

export const WidgetsOverlayInfo = styled.div`
  background: linear-gradient(
    180deg,
    rgba(105, 115, 134, 0.75) 0%,
    rgba(105, 115, 134, 0) 100%
  );

  overflow-y: hidden;
  width: 260px;
  height: 100%;
  padding: 18px 20px;
`;

export const WidgetsHeader = styled.header`
  position: relative;
  margin-bottom: 20px;

  font-family: 'Inter';
  font-style: normal;

  color: #ffffff;

  > label {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
  }

  > h2 {
    font-weight: 700;
    font-size: 22px;
    line-height: 27px;
  }
`;

export const WidgetsContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3% 12px;
`;

export const Widget = styled.div<{ large?: boolean }>`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1px 1fr;
  align-content: center;
  gap: 8px;

  background: #ffffff;
  box-shadow: 0px 6px 10px rgba(51, 57, 78, 0.2);
  border-radius: 8px;

  padding: 9px 12px;

  > div > svg {
    width: 17px;
    height: 17px;

    margin-bottom: 2px;
  }

  > div > label {
    display: block;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;

    color: #5850ec;
  }

  > span {
    display: inline-block;
    width: 100%;

    background-color: #dcdfe2;
  }

  &:nth-child(1) {
    grid-column: 1 / 2;
  }

  &:nth-child(2) {
    grid-column: 2 / 3;
  }

  &:nth-child(3) {
    grid-column: 1 / 3;
  }

  &:nth-child(4) {
    grid-column: 1 / 3;
  }

  &:nth-child(5) {
    grid-column: 1 / 3;
  }
`;

export const WidgetValue = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;

  color: #4b5066;
`;

export const WidgetPoint = styled.div`
  margin-bottom: 5px;
`;

export const WidgetPointImage = styled.img`
  width: 18px;
  margin-right: 8px;

  vertical-align: middle;
`;

export const WidgetPointName = styled.label`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 11px;

  color: #9ca4ac;
`;
