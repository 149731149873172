import { getTrackingpointIcon } from '../../../../../utils/map.utils';
import { RunType } from '../../../../../interfaces/inspection.interfaces';
import { iconsArr } from '../../../../icon-selector/icons.model';
import { MarkerDictionary } from '../survey/survey.d';

/**
 *
 */
export const init = ({
  map,
  trackingpoints,
}: {
  map: google.maps.Map;
  trackingpoints: RunType['trackingpoint_set'];
}) => {
  renderPoints({ map, trackingpoints });
};

/**
 *
 * @param param0
 */
const renderPoints = ({
  map,
  trackingpoints,
}: {
  map: google.maps.Map;
  trackingpoints: RunType['trackingpoint_set'];
}) => {
  const newMarkers: MarkerDictionary = {};

  trackingpoints.forEach((p: any) => {
    const icon: any = getTrackingpointIcon(p); // TODO TYPE
    const iconFromList = iconsArr.find((i: any) => i.id === p.type); // TODO TYPE

    const marker = new window.google.maps.Marker({
      position: {
        lng: p.geometry.coordinates[0],
        lat: p.geometry.coordinates[1],
      },
      map,
      icon,
      title: `${p.name} - ${icon?.legend} - ${iconFromList?.label || 'AGM'}`,
    });

    marker.setZIndex(4);

    marker.addListener('click', () => '{}');

    newMarkers[p.id] = marker;
  });
};
