import Desktop from './components/desktop/desktop';

/**
 * render onverlay box that appers when you click to some tracking point
 * on the tracking sheet
 * @param parms properties past to the component
 * @returns react component
 */
const WidgetsOverlay = () => {
  return (
    <Desktop key="desktop" />
  );
};

export default WidgetsOverlay;
