import {
  formatMps,
  formatDistance,
  formatTimezoneDate,
} from '@/lib/formatter';
import { Column } from 'app/components/table/table.interfaces';

export const columnsModel = ({
  speedUnit,
  timezone,
  distanceUnit,
}: any): Column[] => [
    { field: 'index', headerName: '#', width: 25 },
    { field: 'name', headerName: 'Point Name' },
    {
      field: 'speed',
      headerName: 'Speed',
      valueGetter: (row?: any) => {
        return row?.speed || '';
      },
      valueFormatter: (value: any) =>
        formatMps({ distance: value, unit: speedUnit }),
      width: 100,
    },
    {
      field: 'passage',
      headerName: 'Passage',
      width: 150,
      valueGetter: (row?: any) => {
        return row?.passage || '';
      },
      valueFormatter: (value: any): string => {
        return formatTimezoneDate({
          date: value,
          timezone: timezone?.id,
          format: 'MM/DD HH:mm:ss',
        });
      },
    },
    {
      field: 'distance',
      headerName: 'Distance',
      width: 120,
      valueGetter: (row?: any) => {
        const currentDistance: number = parseInt(row?.distance || '', 10);
        const prevDistance: number = parseInt(row?.prev?.distance || '', 10);
        return `${currentDistance} - ${prevDistance}`;
      },
      valueFormatter: (value: any): string =>
        formatDistance({
          distance: value,
          unit: distanceUnit?.id,
        }),
    },
    {
      field: 'inclination',
      headerName: 'Inclination',
      width: 120,
      valueGetter: (row?: any) => {
        return row.inclination;
      },
    },
    {
      field: 'elevation',
      headerName: 'Elevation',
      width: 120,
      valueGetter: (row?: any) => {
        return row.elevation;
      },
      valueFormatter: (value: any): string =>
        formatDistance({
          distance: value,
          unit: distanceUnit.id,
        }) || '0',
    },
    {
      field: 'location',
      headerName: 'Location',
      valueGetter: (row?: any) => {
        return `${row.geometry?.coordinates[0].toFixed(
          6
        )}, ${row.geometry?.coordinates[1].toFixed(6)}`;
      },
    },
  ];
