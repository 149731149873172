import { InspectionContext } from "../inspection.context.d";
import { TimePinType } from "../inspection.interfaces";

/**
 * 
 * @param state 
 * @param triggerSeen
 */
export const updateTimePin = (state: InspectionContext, timepin: TimePinType) => {
  const stateCopy = { ...state };
  const timepinDic = { ...state.timepins_dic };
  const timepinSet = [...state.timepin_set];

  if (!timepinDic[timepin.id]) {
    timepinSet.push(timepin.id);
  }
  timepinDic[timepin.id] = timepin


  stateCopy.timepins_dic = timepinDic;
  stateCopy.timepin_set = timepinSet;

  return stateCopy;
};

