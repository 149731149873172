import { useContext, useState } from 'react';
import { ContextInterface } from '../../../store/store.interfaces';
import Store from '../../../store/store';

import {
  setArmed,
} from '../device-common.controller';

import {
  Button,
} from './arm-disarm-button.style';

interface Props {
  armed: boolean;
  mqttId: string;
  style?: React.CSSProperties;
}

export const ArmDisarmButton = (
  props: Props
) => {
  const { state } = useContext<ContextInterface>(Store);
  const [waiting, setWaiting] = useState<boolean>(false);
  const [lastArmed, setLastArmed] = useState<boolean>(false);

  if (waiting && props.armed === lastArmed) {
    setWaiting(false);
  }

  const style = Object.assign(props.style || {},
                              {
                                cursor: waiting ? 'default' : 'pointer',
                              });

  if (props.armed) {
    style.backgroundColor = '#e40f0f';
    style.color = '#ffffff';
  }

  return (
    <Button
      onClick={() => {
        if (waiting || !state.auth?.token) return;
        setLastArmed(!props.armed);
        setWaiting(true);
        setArmed(props.mqttId,
                 !props.armed,
                 state.auth.token,
                );
      }}
      style={style}
    >
      {waiting ? '...' : (props.armed ? 'Disarm' : 'Arm')}
    </Button>
  );
};
