import { formatSpeedToMps } from "@/lib/formatter";
import { NotificationProviderValue } from "app/modules/notification/notification.context.d";
import { abortRun, finishRun, launch, updateRun } from "app/services/run.service";
import { Timezone, Unit } from "app/store/store.interfaces";
import moment from "moment-timezone";

/**
 * @param id
 * @param run
 * @param token
 * @param dispatch
 * @param setConfirmation
 * @param tstamp
 * @param launchSpeed
 * @param isTest
 * @returns
 */
export const launchRun = async (
  id: string | undefined,
  token: string,
  tstamp: Date,
  launchSpeed: number,
  isTest: boolean,
  setShowConfirmationLaunch: (shoConfirmationLaunch: boolean) => void, 
  notificationDispatch: NotificationProviderValue['dispatch'],
) => {
  setShowConfirmationLaunch(false);
  try {
    if (!id) return;
    notificationDispatch({
      type: 'SET_TOAST',
      data: {
        title: 'Loading . . . ',
        type: 'loading',
      },
    });

    const launchData = {
      id,
      tstamp: tstamp.toISOString(),
      speed: launchSpeed,
      as_test: isTest,
    };

    await launch(id, launchData, token);
  } catch (err: any) {
    if (err.response.data.type) {
      return notificationDispatch({
        type: 'SET_TOAST',
        data: {
          type: 'error',
          title: err.response.data.title,
          text: err.response.data.message,
        },
      });
    }

    notificationDispatch({
      type: 'SET_TOAST',
      data: {
        type: 'error',
        title: 'Error',
        text: 'Unexpected error, try again later.',
      },
    });
  }
};

/**
 * @param id
 * @param auth
 * @param setConfirmation
 * @param dispatch
 * @returns
 */
export const abortRunAction = async (
  id: string,
  token: string,
  setConfirmation: (confirmation: any) => void,
  notificationDispatch: NotificationProviderValue['dispatch'],
) => {
  if (!token) return;
  try {
    notificationDispatch({
      type: 'SET_TOAST',
      data: {
        title: 'Loading . . . ',
        type: 'loading',
      },
    });

    setConfirmation({});
    await abortRun(id, {}, token);
  } catch (err: any) {
    if (err.response.data.type) {
      return notificationDispatch({
        type: 'SET_TOAST',
        data: {
          type: 'error',
          title: err.response.data.title,
          text: err.response.data.message || 'Error to abort run',
        },
      });
    }
    notificationDispatch({
      type: 'SET_TOAST',
      data: {
        type: 'error',
        title: 'Error',
        text: 'Unexpected error, try again later.',
      },
    });
  }
};

/**
 *
 * @param id
 * @param token
 * @param setConfirmation
 * @param dispatch
 */
export const finishRunAction = async (
  id: string,
  token: string,
  setConfirmation: (confirmation: any) => void,
  notificationDispatch: NotificationProviderValue['dispatch'],
  ) => {
  try {
    notificationDispatch({
      type: 'SET_TOAST',
      data: {
        title: 'Loading . . . ',
        type: 'loading',
      },
    });
    setConfirmation({});
    await finishRun(id, {}, token);
  } catch (err) {
    notificationDispatch({
      type: 'SET_TOAST',
      data: {
        type: 'error',
        title: 'Error',
        text: 'Error to finish run',
      },
    });
  }
};

/**
 *
 * @param deltaY
 * @param setHeight
 */
export const handleDragEnd = (
  deltaY: number,
  setHeight: (h: number) => void
) => {
  const h = document.querySelector('#GT_SHEET')?.clientHeight || 0;
  setHeight(h - deltaY);
};

/**
 *
 * @param deltaY
 * @param container 
 * @param grid 
 * @returns 
 */
export const handleDrag = (deltaY: number, container, grid: any) => { // TODO: GRID Type
  const h = document.querySelector('#GT_SHEET')?.clientHeight || 0;
  
  if (h - deltaY < 24 || container.clientHeight - h + deltaY < 150) {
    return;
  }

  container.style.gridTemplateRows = `
                auto 1fr ${h - deltaY}px
              `;

  grid?.resizeCanvas();
};


/**
 *
 * @param data
 * @param dispatch
 * @param timezone
 * @returns
 */
export const updateScheduledRun = async (
  date: string,
  time: string,
  speed: string,
  runId: number | undefined,
  token: string | undefined,
  timezone: Timezone | undefined,
  notificationDispatch: NotificationProviderValue['dispatch'],
  speedUnit?: Unit | undefined,
  hidePicker?: (hide: boolean) => void,
) => {
  if (!timezone?.id) return;
  if (!speedUnit?.id) return;
  if (!token) return;
  if (!runId) return;

  const startDate = moment(date).tz(timezone.id);
  const cleanTime = time.replace(/:/g, '').replace('.', '');

  const h = +cleanTime.slice(0, 2);
  const m = +cleanTime.slice(2, 4);
  const s = +cleanTime.slice(4, 6);
  const mm = +cleanTime.slice(6, );
  const isValid = !(h > 23 || m > 59 || s > 59 || mm > 999);

  if (!isValid) {
    return notificationDispatch({
      type: 'SET_TOAST',
      data: {
        type: 'error',
        title: 'Error',
        text: 'Invalid date time',
      },
    });
  }

  startDate.hour(h);
  startDate.minute(m);
  startDate.second(s);
  startDate.millisecond(mm);

  const dataToApi = {
    id: runId,
    scheduled_launch_at: startDate.toISOString(),
    scheduled_launch_time: startDate.toISOString(),
    predicted_launch_speed: formatSpeedToMps({
      distance: +speed,
      unit: speedUnit.id,
    }),
  };
  try {
    await updateRun(runId.toString(), dataToApi, token);
    if (hidePicker) hidePicker(false);
  } catch (err: any) {
    if (err.response?.data?.type) {
      return notificationDispatch({
        type: 'SET_TOAST',
        data: {
          type: 'error',
          title: err.response?.data?.title,
          text: err.response?.data?.message,
        },
      });
    }
    notificationDispatch({
      type: 'SET_TOAST',
      data: {
        type: 'error',
        title: 'Error',
        text: 'Unexpected error, try again later.',
      },
    });
  }
};


/**
 *
 * @param speed
 * @param auth
 * @param id
 * @param speedUnit
 * @param dispatch
 * @param cb
 * @returns
 */
export const updateLaunchSpeed = async (
  speed: string,
  auth: any,
  id: any,
  speedUnit: any,
  notificationDispatch: NotificationProviderValue['dispatch'],
  cb: () => void
) => {
  const dataToApi = {
    id,
    predicted_launch_speed: formatSpeedToMps({
      distance: +speed,
      unit: speedUnit.id,
    }),
  };
  try {
    await updateRun(id, dataToApi, auth.token);
  } catch (err: any) {
    if (err.response?.data?.type) {
      return notificationDispatch({
        type: 'SET_TOAST',
        data: {
          type: 'error',
          title: err.response?.data?.title,
          text: err.response?.data?.message,
        },
      });
    }
    notificationDispatch({
      type: 'SET_TOAST',
      data: {
        type: 'error',
        title: 'Error',
        text: 'Unexpected error, try again later.',
      },
    });
  }
  cb();
};

