import { useContext, useEffect, useMemo, useState } from "react";
import { getTrackingPointIconSrc } from "app/utils/map.utils";
import { formatTimezoneDate } from "@/lib/formatter";
import { TrackingPointPlainType } from "app/modules/inspection/inspection.interfaces";
import {
  TrackingPointPassageCancelButton,
  TrackingPointPassageConfirmButton,
  TrackingPointPassageContent,
  TrackingPointPassageFigure,
  TrackingPointPassageInputs,
  TrackingPointPassageInput,
  TrackingPointPassageMassage,
  TrackingPointPassageMassageTitle,
  TrackingPointPassageMassageWrapper,
  TrackingPointPassageTrackingPointName,
  TrackingPointPassageTrackingPointTypeIcon,
  TrackingPointPassageWrapper,
} from './tracking-point-passage-modal.style';
import InputDate from "app/components/input-date/input-date";
import { serializePassageTime } from "../../devices/mobile/mobile.controller";
import SettingsStore from "app/modules/settings/settings.context";

interface Props {
  trackingpoint: TrackingPointPlainType;
  now: number;
  onCancel: () => void;
  onConfirm: (tstamp: number) => void;
}

/**
 *
 * @param props
 * @returns
 */
const TrackingPointPassageModal = (props: Props) => {
  const settingsContext = useContext(SettingsStore);
  const [passageTime, setPassageTime] = useState<string>('');
  const [passageDate, setPassageDate] = useState<Date>();
  const tpoint = useMemo(() => props.trackingpoint, [props.trackingpoint.id]);
  const icon = useMemo(() => getTrackingPointIconSrc(props.trackingpoint), [props.trackingpoint.id]);

  useEffect(() => {
    const passageTime = formatTimezoneDate({
      date: props.now,
      timezone: settingsContext.state.timezone?.id || 'utc',
      format: 'HH:mm:ss.SSS',
    });

    setPassageTime(passageTime);
    setPassageDate(new Date(props.now));
  }, []);

  // stop propation to on cancel from wrapper to childrens
  const stopPropagation = (e) => e.stopPropagation();

  return (
    <TrackingPointPassageWrapper onClick={props.onCancel}>
      <TrackingPointPassageContent onClick={stopPropagation}>
        <TrackingPointPassageFigure>
          <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_101_498)">
              <path d="M13.9997 2.33301C9.48467 2.33301 5.83301 5.98467 5.83301 10.4997C5.83301 16.6247 13.9997 25.6663 13.9997 25.6663C13.9997 25.6663 22.1663 16.6247 22.1663 10.4997C22.1663 5.98467 18.5147 2.33301 13.9997 2.33301ZM8.16634 10.4997C8.16634 7.27968 10.7797 4.66634 13.9997 4.66634C17.2197 4.66634 19.833 7.27968 19.833 10.4997C19.833 13.8597 16.473 18.888 13.9997 22.0263C11.573 18.9113 8.16634 13.8247 8.16634 10.4997Z" fill="white"/>
              <path d="M13.9997 13.4163C15.6105 13.4163 16.9163 12.1105 16.9163 10.4997C16.9163 8.88884 15.6105 7.58301 13.9997 7.58301C12.3888 7.58301 11.083 8.88884 11.083 10.4997C11.083 12.1105 12.3888 13.4163 13.9997 13.4163Z" fill="white"/>
            </g>
            <defs>
              <clipPath id="clip0_101_498">
                <rect width="28" height="28" fill="white"/>
              </clipPath>
            </defs>
          </svg>
        </TrackingPointPassageFigure>

        <TrackingPointPassageTrackingPointName>
          <TrackingPointPassageTrackingPointTypeIcon src={icon.url} alt="" />
          {tpoint.name}
        </TrackingPointPassageTrackingPointName>

        <TrackingPointPassageInputs>
          <TrackingPointPassageInput>
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5 0C2.24288 0 0 2.24301 0 5C0 7.75698 2.24302 10 5 10C7.75698 10 10 7.75698 10 5C10 2.24301 7.75698 0 5 0ZM5 9.3752C2.58757 9.3752 0.625029 7.41261 0.625029 5.00023C0.625029 2.58785 2.58762 0.625262 5 0.625262C7.41238 0.625262 9.37497 2.58785 9.37497 5.00023C9.37497 7.41261 7.41238 9.3752 5 9.3752ZM6.99848 7.37779C7.0956 7.52037 7.05888 7.71486 6.91622 7.81198C6.86238 7.8486 6.80107 7.86618 6.74058 7.86618C6.64064 7.86618 6.54252 7.81844 6.48203 7.72962L4.74172 5.1761C4.70637 5.12426 4.68752 5.06295 4.68752 5.00009V1.65128C4.68752 1.47874 4.82745 1.33882 4.99998 1.33882C5.17252 1.33882 5.31245 1.47875 5.31245 1.65128V4.90377L6.99848 7.37779Z" fill="#5850EC"/>
            </svg>

            <input
              type="tel"
              value={passageTime}
              onChange={(e) => setPassageTime(e.target.value)}
            />
          </TrackingPointPassageInput>
          <TrackingPointPassageInput>
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2.61911 0C2.22749 0 1.90479 0.330572 1.90479 0.731744V1.2195H0.714322C0.322701 1.2195 0 1.55007 0 1.95125V9.26826C0 9.66943 0.322701 10 0.714322 10H9.28568C9.6773 10 10 9.66943 10 9.26826V1.95125C10 1.55007 9.6773 1.2195 9.28568 1.2195H8.09521V0.731744C8.09521 0.330572 7.77251 0 7.38089 0C6.98927 0 6.66657 0.330572 6.66657 0.731744V1.2195H3.33326V0.731744C3.33326 0.330572 3.01056 0 2.61894 0H2.61911ZM2.61911 0.487757C2.75513 0.487757 2.85718 0.592303 2.85718 0.731636V2.19502C2.85718 2.33435 2.75513 2.43889 2.61911 2.43889C2.4831 2.43889 2.38104 2.33435 2.38104 2.19502V0.731636C2.38104 0.592299 2.4831 0.487757 2.61911 0.487757ZM7.38097 0.487757C7.51699 0.487757 7.61905 0.592303 7.61905 0.731636V2.19502C7.61905 2.33435 7.51699 2.43889 7.38097 2.43889C7.24496 2.43889 7.1429 2.33435 7.1429 2.19502V0.731636C7.1429 0.592299 7.24496 0.487757 7.38097 0.487757ZM0.714365 1.70726H1.90483V2.19502C1.90483 2.59619 2.22753 2.92676 2.61915 2.92676C3.01077 2.92676 3.33348 2.59619 3.33348 2.19502V1.70726H6.66678V2.19502C6.66678 2.59619 6.98948 2.92676 7.3811 2.92676C7.77272 2.92676 8.09542 2.59619 8.09542 2.19502V1.70726H9.28589C9.42191 1.70726 9.52396 1.8118 9.52396 1.95114V3.41452H0.47642V1.95114C0.47642 1.8118 0.578477 1.70726 0.714492 1.70726H0.714365ZM0.476293 3.90236H9.52384V9.26817C9.52384 9.40751 9.42178 9.51205 9.28576 9.51205H0.714407C0.578388 9.51205 0.476335 9.4075 0.476335 9.26817V3.90236H0.476293Z" fill="#5850EC"/>
            </svg>

            <InputDate
              value={passageDate?.toISOString()}
              handleChange={(e) => {
                setPassageDate(e.value)
              }}
              format="MMM dd, yyyy"
              customStyle={`
                border: none;
                padding: 0;

                input {
                  color: #5850ec !important;

                  font-family: 'Inter';
                  font-style: normal;
                  font-weight: 700;
                  font-size: 10px !important;
                  line-height: 12px;
                }
              `}
            />
          </TrackingPointPassageInput>
        </TrackingPointPassageInputs>

        <TrackingPointPassageMassageWrapper>
          <TrackingPointPassageMassageTitle>
            {`You're about to add a new time stamp`}
          </TrackingPointPassageMassageTitle>
          <TrackingPointPassageMassage>
            The time you added will be saved on this tracking point, and this passage time will affect the pig position and speed simulations
          </TrackingPointPassageMassage>
        </TrackingPointPassageMassageWrapper>
      </TrackingPointPassageContent>

      <TrackingPointPassageCancelButton
        type="button"
        onClick={(e) => {
          e.stopPropagation();
          props.onCancel();
        }}
        >
        Cancel
      </TrackingPointPassageCancelButton>
      <TrackingPointPassageConfirmButton
        type="button"
        onClick={(e) => {
          e.stopPropagation();
          if (settingsContext.state.timezone?.id && passageDate) {
            const ISOString = passageDate?.toISOString() || new Date(props.now).toISOString()
            const passage = serializePassageTime(passageTime, settingsContext.state.timezone.id, ISOString)
            props.onConfirm(passage);
          }
        }}
      >
        Confirm
      </TrackingPointPassageConfirmButton>
    </TrackingPointPassageWrapper>
  );
};

export default TrackingPointPassageModal;
