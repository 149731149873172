import { useContext, useEffect, useMemo, useState } from 'react';
import { formatDistance } from '@/lib/formatter';
import { Carousel } from 'react-responsive-carousel';
import {
  CloseIcon,
  Content,
  GroupLabel,
  Header,
  Picture,
  PicturesWrapper,
  PointOverlay,
  addMediaButtonStyle,
  ActionsWrapper,
  PictureConteinar,
  Icon,
  TrackingPointFloatCloseButton,
  TrackingPointMedias,
  ArrowPrev,
  ArrowButton,
  ArrowNext,
  EmptyMediaWrapper,
  EmptyMediaMainText,
  GroupWrapper,
  Group,
  GroupList,
  GroupItemLabel,
  GroupItemInput,
  GroupItem,
  PointTitle,
  MediasWrapper,
  IconSelectorWrapper,
  PictureSketchLabel,
  SlimButton,
} from './point-form.style';
import IconSelector from '../icon-selector/iconSelector';
import ButtonComponent from '../default-button/default-button';
import { extractNumbers } from '@/lib/formatter';
import {
  deleteMedia,
  onDeleteSurveyPoint,
  updateIcon,
  updateMediaCaption,
  updateSiteSketch,
} from './point-form.controller';
import MediaCarrouselItem from '../media-carrousel-item/media-carrousel-item';
import ConfirmationModal from '../confirmation-modal/confirmation-modal';
import SurveyStore from 'app/modules/survey/survey.context';
import AccountStore from 'app/modules/account/account.context';
import NotificationStore from 'app/modules/notification/notification.context';
import SettingsStore from 'app/modules/settings/settings.context';
import { SurveyPointType } from 'app/modules/survey/survey.interfaces';

export interface FileChanged {
  fileType: string;
  fileName: string;
  fileExt: string;
  url: string;
  media?: string;
  is_sketch?: boolean;
  id?: string;
  file: File;
}

const onFileChange = (setValue: (data: FileChanged) => void) => {
  const fileSelector = document.createElement('input');
  fileSelector.setAttribute('type', 'file');
  fileSelector.setAttribute('accept', 'image/*');
  fileSelector.click();
  fileSelector.onchange = () => {
    if (!fileSelector.files?.length) return;

    const fileType = fileSelector.files[0].type.split('/')[1];
    const fileName = fileSelector.files[0].name.split('.')[0];
    const fileExt = fileSelector.files[0].name.split('.').reverse()[0];
    const file = fileSelector.files[0];
    const url = URL.createObjectURL(fileSelector.files[0]);

    const fileToReturn = {
      fileType,
      fileName,
      fileExt,
      file,
      url,
    };
    setValue(fileToReturn);
  };
};

const PointForm = ({
  grid,
  isLoading,
  onStepChange,
  reload,
}: any) => {
  const surveyContext = useContext(SurveyStore);
  const accountContext = useContext(AccountStore);
  const notificationContext = useContext(NotificationStore);
  const settingsContext = useContext(SettingsStore);
  const point = useMemo<SurveyPointType | undefined>(() => {
    if (surveyContext.state.selected_point) {
      return surveyContext.state.points_dic[surveyContext.state.selected_point];
    } else return undefined;
  }, [surveyContext.state.selected_point, surveyContext.state.points_dic]);
  const [name, setName] = useState(point?.name);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] =
    useState<boolean>(false);
  const [speedOffset, setSpeedOffset] = useState(point?.speed_offset);
  const [longitude, setLongitude] = useState(point?.geometry.coordinates[0]);
  const [latitude, setLatitude] = useState(point?.geometry.coordinates[1]);
  const [description, setDescription] = useState(point?.description);
  const [antennaHeight, setAntennaHeight] = useState(
    point?.antenna_height || ''
  );
  const [chainage, setChainage] = useState(point?.chainage || '');
  const [datum, setDatum] = useState(point?.datum || '');
  const [elevationTop, setElevationTop] = useState(point?.elevation_top || '');
  const [ellipsoidHeight, setEllipsoidHeight] = useState(
    point?.ellipsoid_height || ''
  );
  const [ellipsoidHeightCop, setEllipsoidHeightCop] = useState(
    point?.ellipsoid_height_cop || ''
  );
  const [geoidModel, setGeoidModel] = useState(point?.geoid_model || '');
  const [surveyAccuracy, setSurveyAccuracy] = useState(
    point?.survey_accuracy || ''
  );
  const [surveyMethod, setSurveyMethod] = useState(point?.survey_method || '');
  const [surveyor, setSurveyor] = useState(point?.surveyor || '');

  const [comment, setComment] = useState(point?.comment || '');
  const [depthOfCover, setDepthOfCover] = useState(point?.depth_of_cover || '');
  const [alignmentSheet, setAlignmentSheet] = useState(
    point?.alignment_sheet || ''
  );
  const [deviceSn, setDeviceSn] = useState(point?.device_sn || '');
  const [county, setCounty] = useState(point?.county || '');
  const [country, setCountry] = useState(point?.country || '');
  const [locState, setLocState] = useState(point?.state || '');
  const [tract, setTract] = useState(point?.tract || '');
  const [pressureDifferential, setPressureDifferential] = useState(
    point?.pressure_differential || ''
  );
  const [locationDescription, setLocationDescription] = useState(
    point?.location_description || ''
  );
  const [fileChange, setFileChange] = useState<FileChanged | null>(null);
  const [medias, setMedias] = useState<FileChanged[]>([]);
  const [captionsToNewMedia, setCaptionsToNewMedia] = useState<any>({});
  const [selectedMedia, setSelectedMedia] = useState(0);
  const [allowArrowsNavigation, setAllowArrowsNavigation] =
    useState<boolean>(true);
  const [gallery, setGallery] = useState<boolean>(false);
  const [newSiteSketchs, setNewSiteSketchs] = useState<any>({});
  const [showIconSelector, setShowIconSelector] = useState<boolean>(false);
  const [htCorrectionTop, setHtCorrectionTop] = useState<string>(
    point?.ht_correction_top || ''
  );
  const [heightComment, setHeightComment] = useState<string>(
    point?.height_comment || ''
  );
  const [surveyNotes, setSurveyNotes] = useState<string>(
    point?.survey_notes || ''
  );
  const [surveyDate, setSurveyDate] = useState<string>(point?.survey_date || '');
  const [surveyTechnician, setSurveyTechnician] = useState<string>(
    point?.survey_technician || ''
  );
  const [referenceTechnician, setReferenceTechnician] = useState<string>(
    point?.reference_technician || ''
  );
  const [cellCoverage, setCellCoverage] = useState<string>(
    point?.cell_coverage || ''
  );
  const [siteAccess, setSiteAccess] = useState<string>(point?.site_access || '');
  const [referenceDate, setReferenceDate] = useState<string>(
    point?.reference_date || ''
  );
  const [referenceTime, setReferenceTime] = useState<string>(
    point?.reference_time || ''
  );
  const [fieldNotes, setFieldNotes] = useState<string>(point?.field_notes || '');
  const [milepost, setMilepost] = useState<string>(point?.field_notes || '');
  const [landOwner, setLandOwner] = useState<string>(point?.land_owner || '');
  const [ownerPhone, setOwnerPhone] = useState<string>(point?.owner_phone || '');

  useEffect(() => {
    if (fileChange) {
      const mediasCopy = medias;
      mediasCopy.push(fileChange);
      setMedias(mediasCopy);
      setFileChange(null);
    }
  }, [fileChange]);

  useEffect(() => {
    setName(point?.name || '');
    setSpeedOffset(point?.speed_offset || 0);
    setLongitude(point?.geometry.coordinates[0] || '');
    setLatitude(point?.geometry.coordinates[1] || '');
    setDescription(point?.description || '');
    setAntennaHeight(point?.antenna_height || '');

    const chainage = formatDistance({
      unit: settingsContext.state.distanceUnit?.id || 'ft',
      distance: point?.chainage,
      returnNumber: true,
    });

    setChainage(chainage || '');
    setDatum(point?.datum || '');
    setElevationTop(point?.elevation_top || '');
    setEllipsoidHeight(point?.ellipsoid_height || '');
    setEllipsoidHeightCop(point?.ellipsoid_height_cop || '');
    setGeoidModel(point?.geoid_model || '');
    setSurveyAccuracy(point?.survey_accuracy || '');
    setSurveyMethod(point?.survey_method || '');
    setSurveyor(point?.surveyor || '');
    setComment(point?.comment || '' || '');
    setDepthOfCover(point?.depth_of_cover || '');
    setAlignmentSheet(point?.alignment_sheet || '');
    setDeviceSn(point?.device_sn || '');
    setCounty(point?.county || '');
    setCountry(point?.country || '');
    setLocState(point?.state || '');
    setTract(point?.tract || '');
    setPressureDifferential(point?.pressure_differential || '');
    setLocationDescription(point?.location_description || '');
    setMedias(point?.media_set || []);
    setHtCorrectionTop(point?.ht_correction_top || '');
    setHeightComment(point?.height_comment || '');
    setSurveyNotes(point?.survey_notes || '');
    setSurveyDate(point?.survey_date || '');
    setSurveyTechnician(point?.survey_technician || '');
    setReferenceTechnician(point?.reference_technician || '');
    setCellCoverage(point?.cell_coverage || '');
    setSiteAccess(point?.site_access || '');
    setReferenceDate(point?.reference_date || '');
    setReferenceTime(point?.reference_time || '');
    setFieldNotes(point?.field_notes || '');
    setMilepost(point?.milepost || '');
    setOwnerPhone(point?.owner_phone || '');
    setLandOwner(point?.land_owner || '');
  }, [point]);
  
//closeAction
  return (
    <PointOverlay>
      <Header>
        <div>
          <CloseIcon onClick={() => surveyContext.dispatch({ type: 'SET_SELECTED_POINT' })}> 
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="14.2427"
                y="4.34375"
                width="2"
                height="14"
                rx="1"
                transform="rotate(45 14.2427 4.34375)"
                fill="#000"
              />
              <rect
                x="15.6567"
                y="14.2422"
                width="2"
                height="14"
                rx="1"
                transform="rotate(135 15.6567 14.2422)"
                fill="#000"
              />
            </svg>
          </CloseIcon>
          <PointTitle>{point?.name}</PointTitle>
        </div>
        <SlimButton onClick={() => setOpenDeleteConfirmation(true)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="16px"
            width="16px"
            viewBox="0 0 48 48"
            stroke="#161e2e"
          >
            <path d="M13.05 42q-1.2 0-2.1-.9-.9-.9-.9-2.1V10.5H9.5q-.65 0-1.075-.425Q8 9.65 8 9q0-.65.425-1.075Q8.85 7.5 9.5 7.5h7.9q0-.65.425-1.075Q18.25 6 18.9 6h10.2q.65 0 1.075.425.425.425.425 1.075h7.9q.65 0 1.075.425Q40 8.35 40 9q0 .65-.425 1.075-.425.425-1.075.425h-.55V39q0 1.2-.9 2.1-.9.9-2.1.9Zm0-31.5V39h21.9V10.5Zm5.3 22.7q0 .65.425 1.075.425.425 1.075.425.65 0 1.075-.425.425-.425.425-1.075V16.25q0-.65-.425-1.075-.425-.425-1.075-.425-.65 0-1.075.425-.425.425-.425 1.075Zm8.3 0q0 .65.425 1.075.425.425 1.075.425.65 0 1.075-.425.425-.425.425-1.075V16.25q0-.65-.425-1.075-.425-.425-1.075-.425-.65 0-1.075.425-.425.425-.425 1.075Zm-13.6-22.7V39 10.5Z" />
          </svg>
          Delete
        </SlimButton>
      </Header>
      <IconSelectorWrapper>
        <IconSelector
          showIconSelector={showIconSelector}
          setShowIconSelector={setShowIconSelector}
          row={point}
          fill="#000"
          border={true}
          permissionType={'editor'}
          handleChange={({ value }: { value: any }) => {
            updateIcon({
              id: point?.id,
              value,
              token: accountContext.state.auth?.token || '',
              dispatch: notificationContext.dispatch,
              refresh: reload,
            });
          }}
        />
      </IconSelectorWrapper>
      <TrackingPointMedias show={gallery}>
        <TrackingPointFloatCloseButton onClick={() => setGallery(false)}>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="14.2427"
              y="4.34375"
              width="2"
              height="14"
              rx="1"
              transform="rotate(45 14.2427 4.34375)"
              fill="white"
            />
            <rect
              x="15.6567"
              y="14.2422"
              width="2"
              height="14"
              rx="1"
              transform="rotate(135 15.6567 14.2422)"
              fill="white"
            />
          </svg>
        </TrackingPointFloatCloseButton>
        {medias.length ? (
          <Carousel
            showIndicators={false}
            selectedItem={selectedMedia}
            showThumbs={false}
            showStatus={false}
            useKeyboardArrows={allowArrowsNavigation}
            renderArrowPrev={(clickHandler, hasPrev) => (
              <ArrowPrev hide={!hasPrev}>
                <ArrowButton
                  onClick={() => {
                    if (selectedMedia > 0) setSelectedMedia(selectedMedia - 1);
                    clickHandler();
                  }}
                >
                  <svg
                    width="17"
                    height="27"
                    viewBox="0 0 17 27"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.65"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M16.1546 26.3467C17.0257 25.4756 17.0257 24.0632 16.1546 23.1921L6.6915 13.729L16.1553 4.2652C17.0264 3.39409 17.0264 1.98174 16.1553 1.11063C15.2842 0.239515 13.8718 0.239517 13.0007 1.11063L3.53693 10.5744L3.53634 10.5738L0.381763 13.7284L13.0001 26.3467C13.8712 27.2178 15.2835 27.2178 16.1546 26.3467Z"
                      fill="white"
                    />
                  </svg>
                </ArrowButton>
              </ArrowPrev>
            )}
            renderArrowNext={(clickHandler, hasNext) => (
              <ArrowNext hide={!hasNext}>
                <ArrowButton
                  onClick={() => {
                    if (selectedMedia < medias.length - 1)
                      setSelectedMedia(selectedMedia + 1);

                    clickHandler();
                  }}
                >
                  <svg
                    width="17"
                    height="27"
                    viewBox="0 0 17 27"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.65"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M16.1546 26.3467C17.0257 25.4756 17.0257 24.0632 16.1546 23.1921L6.6915 13.729L16.1553 4.2652C17.0264 3.39409 17.0264 1.98174 16.1553 1.11063C15.2842 0.239515 13.8718 0.239517 13.0007 1.11063L3.53693 10.5744L3.53634 10.5738L0.381763 13.7284L13.0001 26.3467C13.8712 27.2178 15.2835 27.2178 16.1546 26.3467Z"
                      fill="white"
                    />
                  </svg>
                </ArrowButton>
              </ArrowNext>
            )}
          >
            {[...medias].map((media: any) => (
              <MediaCarrouselItem
                onDelete={() => {
                  if (media.id) {
                    deleteMedia(medias, media, setMedias, accountContext.state.auth);
                  } else {
                    const mediaIndex = medias.findIndex(
                      (m) => m.url === media.url
                    );

                    const newMedias = { ...medias };
                    newMedias.splice(mediaIndex, 1);
                    setMedias(newMedias);

                    if (newSiteSketchs[media.url]) {
                      const siteSketchs = [...newSiteSketchs];
                      delete siteSketchs[media.url];
                      setNewSiteSketchs(siteSketchs);
                    }

                    if (captionsToNewMedia[media.url]) {
                      const captions = { ...captionsToNewMedia };
                      delete captions[media.url];
                      setCaptionsToNewMedia(captions);
                    }
                  }
                }}
                onToggleSiteSketch={() => {
                  if (media.id) {
                    updateSiteSketch(medias, media, setMedias, accountContext.state.auth);
                  } else {
                    const siteSketchs = { ...newSiteSketchs };

                    if (siteSketchs[media.url]) delete siteSketchs[media.url];
                    else siteSketchs[media.url] = true;

                    setNewSiteSketchs(siteSketchs);
                  }
                }}
                onEditCaption={(caption: string) => {
                  if (media.id) {
                    updateMediaCaption(
                      medias,
                      media,
                      setMedias,
                      accountContext.state.auth,
                      caption
                    );
                  } else {
                    const newCaptions = { ...captionsToNewMedia };
                    newCaptions[media.url] = caption;
                    setCaptionsToNewMedia(newCaptions);
                  }
                }}
                setAllowArrowNavigation={setAllowArrowsNavigation}
                caption={media.caption || captionsToNewMedia[media.url] || ''}
                media={media.media || media.url}
                mediaId={media.id}
                isSiteSketch={
                  media.is_sketch || newSiteSketchs[media.url] || false
                }
                permissionType={'editor'}
                key={media.id}
              />
            ))}
          </Carousel>
        ) : (
          <EmptyMediaWrapper>
            <EmptyMediaMainText>
              This tracking point doesn’t have any media yet...
            </EmptyMediaMainText>
          </EmptyMediaWrapper>
        )}
      </TrackingPointMedias>
      <Content>
        <GroupWrapper>
          <Group>
            <GroupLabel>AGM Information</GroupLabel>
            <GroupList>
              <GroupItem>
                <GroupItemLabel>AGM Name:</GroupItemLabel>
                <GroupItemInput
                  value={name}
                  onChange={({ target }) => setName(target.value)}
                />
              </GroupItem>

              <GroupItem>
                <GroupItemLabel>Location Description:</GroupItemLabel>
                <GroupItemInput
                  value={locationDescription}
                  onChange={({ target }) =>
                    setLocationDescription(target.value)
                  }
                />
              </GroupItem>
              <GroupItem>
                <GroupItemLabel>Chainage:</GroupItemLabel>
                <GroupItemInput
                  value={chainage}
                  type="tel"
                  onChange={({ target }) =>
                    setChainage(extractNumbers(target.value))
                  }
                />
              </GroupItem>
              <GroupItem>
                <GroupItemLabel>Description:</GroupItemLabel>
                <GroupItemInput
                  value={description}
                  onChange={({ target }) => setDescription(target.value)}
                />
              </GroupItem>

              <GroupItem>
                <GroupItemLabel>Comment:</GroupItemLabel>
                <GroupItemInput
                  value={comment}
                  onChange={({ target }) => setComment(target.value)}
                />
              </GroupItem>

              <GroupItem>
                <GroupItemLabel>County:</GroupItemLabel>
                <GroupItemInput
                  value={county}
                  onChange={({ target }) => setCounty(target.value)}
                />
              </GroupItem>
              <GroupItem>
                <GroupItemLabel>State:</GroupItemLabel>
                <GroupItemInput
                  value={locState}
                  onChange={({ target }) => setLocState(target.value)}
                />
              </GroupItem>
              <GroupItem>
                <GroupItemLabel>Country:</GroupItemLabel>
                <GroupItemInput
                  value={country}
                  onChange={({ target }) => setCountry(target.value)}
                />
              </GroupItem>
              <GroupItem>
                <GroupItemLabel>Milepost:</GroupItemLabel>
                <GroupItemInput
                  value={milepost}
                  onChange={({ target }) => setMilepost(target.value)}
                />
              </GroupItem>
              <GroupItem>
                <GroupItemLabel>Land Owner:</GroupItemLabel>
                <GroupItemInput
                  value={landOwner}
                  onChange={({ target }) => setLandOwner(target.value)}
                />
              </GroupItem>
              <GroupItem>
                <GroupItemLabel>Owner Phone:</GroupItemLabel>
                <GroupItemInput
                  value={ownerPhone}
                  onChange={({ target }) => setOwnerPhone(target.value)}
                />
              </GroupItem>
            </GroupList>
          </Group>

          <Group>
            <GroupLabel>Reference Information</GroupLabel>
            <GroupList>
              <GroupItem>
                <GroupItemLabel>Depth Of Cover:</GroupItemLabel>
                <GroupItemInput
                  value={depthOfCover}
                  onChange={({ target }) => setDepthOfCover(target.value)}
                />
              </GroupItem>
              <GroupItem>
                <GroupItemLabel>Reference Technician:</GroupItemLabel>
                <GroupItemInput
                  value={referenceTechnician}
                  onChange={({ target }) =>
                    setReferenceTechnician(target.value)
                  }
                />
              </GroupItem>
              <GroupItem>
                <GroupItemLabel>Cell Coverage:</GroupItemLabel>
                <GroupItemInput
                  value={cellCoverage}
                  onChange={({ target }) => setCellCoverage(target.value)}
                />
              </GroupItem>
              <GroupItem>
                <GroupItemLabel>Site Access:</GroupItemLabel>
                <GroupItemInput
                  value={siteAccess}
                  onChange={({ target }) => setSiteAccess(target.value)}
                />
              </GroupItem>
              <GroupItem>
                <GroupItemLabel>Reference Date:</GroupItemLabel>
                <GroupItemInput
                  value={referenceDate}
                  onChange={({ target }) => setReferenceDate(target.value)}
                />
              </GroupItem>
              <GroupItem>
                <GroupItemLabel>Reference Time:</GroupItemLabel>
                <GroupItemInput
                  value={referenceTime}
                  onChange={({ target }) => setReferenceTime(target.value)}
                />
              </GroupItem>
              <GroupItem>
                <GroupItemLabel>Field Notes:</GroupItemLabel>
                <GroupItemInput
                  value={fieldNotes}
                  onChange={({ target }) => setFieldNotes(target.value)}
                />
              </GroupItem>
            </GroupList>
          </Group>

          <Group>
            <GroupLabel>Survey Information</GroupLabel>
            <GroupList columns={3}>
              <GroupItem>
                <GroupItemLabel>Speed Offset:</GroupItemLabel>
                <GroupItemInput
                  value={speedOffset}
                  type="tel"
                  onChange={({ target }) =>
                    setSpeedOffset(+extractNumbers(target.value))
                  }
                />
              </GroupItem>

              <GroupItem>
                <GroupItemLabel>Datum:</GroupItemLabel>
                <GroupItemInput
                  value={datum}
                  onChange={({ target }) => setDatum(target.value)}
                />
              </GroupItem>

              <GroupItem>
                <GroupItemLabel>Elevation (TOP):</GroupItemLabel>
                <GroupItemInput
                  value={elevationTop}
                  type="tel"
                  onChange={({ target }) =>
                    setElevationTop(extractNumbers(target.value))
                  }
                />
              </GroupItem>

              <GroupItem>
                <GroupItemLabel>Ht. Correction (TOP):</GroupItemLabel>
                <GroupItemInput
                  value={htCorrectionTop}
                  type="tel"
                  onChange={({ target }) => setHtCorrectionTop(target.value)}
                />
              </GroupItem>

              <GroupItem>
                <GroupItemLabel>Height Comment:</GroupItemLabel>
                <GroupItemInput
                  value={heightComment}
                  type="tel"
                  onChange={({ target }) => setHeightComment(target.value)}
                />
              </GroupItem>

              <GroupItem>
                <GroupItemLabel>Survey Notes:</GroupItemLabel>
                <GroupItemInput
                  value={surveyNotes}
                  type="tel"
                  onChange={({ target }) => setSurveyNotes(target.value)}
                />
              </GroupItem>

              <GroupItem>
                <GroupItemLabel>Survey Technician:</GroupItemLabel>
                <GroupItemInput
                  value={surveyTechnician}
                  type="tel"
                  onChange={({ target }) => setSurveyTechnician(target.value)}
                />
              </GroupItem>

              <GroupItem>
                <GroupItemLabel>Survey Date:</GroupItemLabel>
                <GroupItemInput
                  value={surveyDate}
                  type="tel"
                  onChange={({ target }) => setSurveyDate(target.value)}
                />
              </GroupItem>

              <GroupItem>
                <GroupItemLabel>Survey Accuracy:</GroupItemLabel>
                <GroupItemInput
                  value={surveyAccuracy}
                  onChange={({ target }) => setSurveyAccuracy(target.value)}
                />
              </GroupItem>

              <GroupItem>
                <GroupItemLabel>Surveyor:</GroupItemLabel>
                <GroupItemInput
                  value={surveyor}
                  onChange={({ target }) => setSurveyor(target.value)}
                />
              </GroupItem>

              <GroupItem>
                <GroupItemLabel>Geoid Model:</GroupItemLabel>
                <GroupItemInput
                  value={geoidModel}
                  onChange={({ target }) => setGeoidModel(target.value)}
                />
              </GroupItem>

              <GroupItem>
                <GroupItemLabel>Antenna Height:</GroupItemLabel>
                <GroupItemInput
                  value={antennaHeight}
                  type="tel"
                  onChange={({ target }) =>
                    setAntennaHeight(extractNumbers(target.value))
                  }
                />
              </GroupItem>

              <GroupItem>
                <GroupItemLabel>Ellipsoid Height:</GroupItemLabel>
                <GroupItemInput
                  value={ellipsoidHeight}
                  type="tel"
                  onChange={({ target }) =>
                    setEllipsoidHeight(extractNumbers(target.value))
                  }
                />
              </GroupItem>

              <GroupItem>
                <GroupItemLabel>Ellipsoid Height (COP):</GroupItemLabel>
                <GroupItemInput
                  value={ellipsoidHeightCop}
                  type="tel"
                  onChange={({ target }) =>
                    setEllipsoidHeightCop(extractNumbers(target.value))
                  }
                />
              </GroupItem>

              <GroupItem>
                <GroupItemLabel>Alignment Sheet:</GroupItemLabel>
                <GroupItemInput
                  value={alignmentSheet}
                  onChange={({ target }) => setAlignmentSheet(target.value)}
                />
              </GroupItem>

              <GroupItem>
                <GroupItemLabel>Device Sn:</GroupItemLabel>
                <GroupItemInput
                  value={deviceSn}
                  onChange={({ target }) => setDeviceSn(target.value)}
                />
              </GroupItem>

              <GroupItem>
                <GroupItemLabel>Tract:</GroupItemLabel>
                <GroupItemInput
                  value={tract}
                  onChange={({ target }) => setTract(target.value)}
                />
              </GroupItem>

              <GroupItem>
                <GroupItemLabel>Pressure Differential:</GroupItemLabel>
                <GroupItemInput
                  value={pressureDifferential}
                  onChange={({ target }) =>
                    setPressureDifferential(target.value)
                  }
                />
              </GroupItem>

              <GroupItem>
                <GroupItemLabel>Latitude:</GroupItemLabel>
                <GroupItemInput
                  disabled={true}
                  value={latitude}
                  onChange={({ target }) => setLatitude(target.value)}
                />
              </GroupItem>

              <GroupItem>
                <GroupItemLabel>Longitude:</GroupItemLabel>
                <GroupItemInput
                  disabled={true}
                  value={longitude}
                  onChange={({ target }) => setLongitude(target.value)}
                />
              </GroupItem>
            </GroupList>
          </Group>
        </GroupWrapper>

        <MediasWrapper>
          <GroupLabel>Media</GroupLabel>

          <PicturesWrapper>
            {medias.map((m: FileChanged, i: number) => (
              <PictureConteinar key={i} title="Remove media">
                <Picture
                  src={m.media || m.url}
                  onClick={() => {
                    setGallery(true);
                    setSelectedMedia(i);
                  }}
                />
                <Icon
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => deleteMedia(medias, m, setMedias, accountContext.state.auth)}
                >
                  <rect
                    x="14.2427"
                    y="4.34375"
                    width="2"
                    height="14"
                    rx="1"
                    transform="rotate(45 14.2427 4.34375)"
                    fill="#000"
                  />
                  <rect
                    x="15.6567"
                    y="14.2422"
                    width="2"
                    height="14"
                    rx="1"
                    transform="rotate(135 15.6567 14.2422)"
                    fill="#000"
                  />
                </Icon>
                {m?.is_sketch ? (
                  <PictureSketchLabel>SITE SKETCH</PictureSketchLabel>
                ) : null}
              </PictureConteinar>
            ))}

            <ButtonComponent
              customStyle={addMediaButtonStyle}
              onClick={() => onFileChange(setFileChange)}
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 9.16667V12.6667C15 13.9553 13.9553 15 12.6667 15H3.33333C2.04467 15 1 13.9553 1 12.6667V9.16667M8 10.3333V1M4.5 4.5L8 1M8 1L11.5 4.5"
                  stroke="#ffffff"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              Add media
            </ButtonComponent>
          </PicturesWrapper>
        </MediasWrapper>
      </Content>
      <ActionsWrapper>
        <ButtonComponent
          disabled={isLoading}
          customStyle={`
            width: 180px;
            z-index:1;
            background-color: transparent;
            color: #5850ec;

            padding-left: 8px;
            padding-right: 8px;

            display: flex;
            align-items: center;
          `}
          onClick={() =>
            onStepChange(
              {
                id: point?.id,
                index: point?.index,
                name,
                speedOffset,
                longitude,
                latitude,
                description,
                geoidModel,
                surveyAccuracy,
                surveyMethod,
                surveyor,
                ellipsoidHeightCop,
                antennaHeight,
                chainage,
                datum,
                elevationTop,
                ellipsoidHeight,
                fileChange,
                medias,
                comment,
                depthOfCover,
                alignmentSheet,
                deviceSn,
                county,
                country,
                state: locState,
                tract,
                pressureDifferential,
                locationDescription,
                captionsToNewMedia,
                newSiteSketchs,
              },
              -1
            )
          }
        >
          {isLoading ? (
            'Loading . . .'
          ) : (
            <>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 0 24 24"
                width="24px"
                fill="#5850ec"
              >
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z" />
              </svg>
              Save and Previous
            </>
          )}
        </ButtonComponent>
        <ButtonComponent
          disabled={isLoading}
          customStyle={`
            width: 180px;
            z-index:1;
            background-color: transparent;
            color: #5850ec;

            padding-left: 8px;
            padding-right: 8px;

            display: flex;
            align-items: center;
            justify-content: center;
          `}
          onClick={() =>
            onStepChange(
              {
                id: point?.id,
                name,
                speedOffset,
                longitude,
                latitude,
                description,
                geoidModel,
                surveyAccuracy,
                surveyMethod,
                surveyor,
                ellipsoidHeightCop,
                antennaHeight,
                chainage,
                datum,
                elevationTop,
                ellipsoidHeight,
                fileChange,
                medias,
                comment,
                depthOfCover,
                alignmentSheet,
                deviceSn,
                county,
                country,
                state: locState,
                tract,
                pressureDifferential,
                locationDescription,
                captionsToNewMedia,
                newSiteSketchs,
                land_owner: landOwner,
                owner_phone: ownerPhone,
                ht_correction_top: htCorrectionTop,
                height_comment: heightComment,
                survey_notes: surveyNotes,
                survey_date: surveyDate,
                survey_technician: surveyTechnician,
                reference_technician: referenceTechnician,
                cell_coverage: cellCoverage,
                site_access: siteAccess,
                reference_date: referenceDate,
                reference_time: referenceTime,
                field_notes: fieldNotes,
                milepost,
              },
              1
            )
          }
        >
          {isLoading ? (
            'Loading . . .'
          ) : (
            <>
              Save and Next
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 0 24 24"
                width="24px"
                fill="#5850ec"
              >
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z" />
              </svg>
            </>
          )}
        </ButtonComponent>
      </ActionsWrapper>

      <ConfirmationModal
        hide={!openDeleteConfirmation}
        title="Are you sure?"
        confirmationText="Delete"
        text="The tracking point will be deleted permanently."
        onConfirm={() => {
          setOpenDeleteConfirmation(false);
          surveyContext.dispatch({ type: 'SET_SELECTED_POINT' })
          if (accountContext.state.auth?.token && point) {
            onDeleteSurveyPoint(point.id, accountContext.state.auth?.token || '', () => {
              const data = grid.getData();
              data.splice(point.index, 1);
              data.slice(point.index).forEach(row =>  row.index--);
              grid.invalidate();
              surveyContext.dispatch({
                type: 'DELETE_POINT',
                data: point
              })
            });
          }
        }}
        onCancel={() => setOpenDeleteConfirmation(false)}
      />
    </PointOverlay>
  );
};

export default PointForm;
