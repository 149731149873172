import { updateTrackingPoint } from "app/services/run.service";
import { AxiosResponse } from "axios";
import {
  createTrackingPointMedia,
  createTrackingPointNote,
  deleteTrackingPointMedia,
  deleteTrackingPointNote,
  updateTrackingPointMediaCaption,
  updateTrackingPointNote,
  toggleTrackingPointMediaSiteSketch
} from "./tracking-point-overlay.service";
import { NotificationDispatchType } from "app/modules/notification/notification.context.d";
import { InspectionDispatchType } from "app/modules/inspection/inspection.context.d";
import { TrackingPointPlainType } from "app/modules/inspection/inspection.interfaces";


/**
 * 
 * @param mediaId 
 * @param token 
 * @param setConfirmationModal 
 */
export const deleteMedia = async (
  mediaId: string,
  token: string,
  setConfirmationModal: (data: any) => void
) => {
  await deleteTrackingPointMedia(mediaId, token);
  setConfirmationModal({});
};

/**
 * 
 * @param caption 
 * @param mediaId 
 * @param token 
 */
export const updateMediaCaption = async (
  caption: string,
  mediaId: string,
  token: string
) => {
  await updateTrackingPointMediaCaption(caption, mediaId, token);
};

/**
 * 
 * @param isSiteSketch 
 * @param mediaId 
 * @param token 
 */
export const updateSiteSketch = async (
  isSiteSketch: boolean,
  mediaId: string,
  token: string
) => {
  await toggleTrackingPointMediaSiteSketch(isSiteSketch, mediaId, token);
};

/**
 * 
 * @param selectedPoint 
 * @param files 
 * @param token 
 * @param run 
 * @param dispatch 
 * @param setMediaLoading 
 * @returns 
 */
export const uploadMedia = async (
  selectedPoint: TrackingPointPlainType | undefined,
  files: FileList | File[],
  token: string,
  run: any,
  notificationDispatch: (data: NotificationDispatchType) => void,
  inspectionDispatch: (data: InspectionDispatchType) => void,
  setMediaLoading: (mediaLoading: boolean) => void
) => {
  setMediaLoading(true);
  if (!selectedPoint?.id) return;

  const saveMediaPromises: Promise<AxiosResponse>[] = [];
  
  for (const file of files) {
    const promise = createTrackingPointMedia(
      `${selectedPoint.id}`,
      file,
      token
    );

    saveMediaPromises.push(promise);
  }

  const responses = await Promise.all(saveMediaPromises);
  const newRun = { ...run };
  if (selectedPoint.index || selectedPoint.index === 0) {
    const newTrackingPoints = [...newRun.trackingpoint_set];

    newTrackingPoints[selectedPoint.index] = {
      ...newTrackingPoints[selectedPoint.index],
      media_set: [
        ...(newTrackingPoints[selectedPoint.index]?.media_set || []),
        ...responses.map((response) => response.data),
      ],
    };
    newRun.trackingpoint_set = newTrackingPoints;

    notificationDispatch({
      type: 'SET_TOAST',
      data: {
        text: 'A media was added in a tracking point',
        type: 'success',
      },
    });

    inspectionDispatch({
      type: 'SET_SELECTED_POINT',
      data: selectedPoint.id,
    });
  }

  inspectionDispatch({
    type: 'SET_RUN',
    data: newRun,
  });

  setMediaLoading(false);
};

/**
 * request note criation to service and control loading/error
 * state of component
 */
export const createNote = async (
  text: string,
  trackingPointId: string,
  token: string,
  setLoading: (loading: boolean) => void,
  setNnote: (note: undefined) => void
) => {
  setLoading(true);

  await createTrackingPointNote(
    { text, tracking_point_id: trackingPointId },
    token
  );

  setLoading(false);
  setNnote(undefined);
};

/**
 * 
 * @param id
 * @param token
 * @param trackingPointId
 * @param notes
 * @param setLoading
 * @param run
 * @param dispatch
 * @param setConfirmationModal
 */
export const deleteNote = async (
  id: string,
  token: string,
  notes: any[],
  setLoading: (loading: boolean) => void,
  dispatch: (data: InspectionDispatchType) => void,
  setConfirmationModal: (data: any) => void
) => {
  setLoading(true);

  const newNotes = [...notes];
  const noteIndex = notes.findIndex((note: any) => note.id === id);
  newNotes.splice(noteIndex, 1);

  // dispatch({
  //   type: 'SET_RUN',
  //   data: newRun,
  // });

  setLoading(false);

  try {
    await deleteTrackingPointNote(id, token);
    setConfirmationModal({});
  } catch (err) {
    console.log([err]);
  }
};

/**
 *
 * @param id
 * @param text
 * @param token
 * @param trackingPointId
 * @param notes
 * @param setLoading
 * @param run
 * @param dispatch
 */
export const updateNote = async (
  id: string,
  text: string,
  token: string,
  setLoading: (loading: boolean) => void,
) => {
  setLoading(true);

  await updateTrackingPointNote(id, { text }, token);

  setLoading(false);
};

/**
 * 
 * @param
 * @returns 
 */
export const toggleActive = async ({
  value,
  id,
  token,
  dispatch,
}: {
  value?: boolean;
  id?: number | null;
  token?: string;
  dispatch: (data: NotificationDispatchType) => void;
}) => {
  if (!id || !token) return;
  dispatch({
    type: 'SET_TOAST',
    data: {
      title: 'Loading . . . ',
      type: 'loading',
    },
  });
  try {
    const updatePointData: any = {
      id: id,
      active: value,
    };
    await updateTrackingPoint(`${id}`, updatePointData, token);
  } catch (err) {
    dispatch({
      type: 'SET_TOAST',
      data: {
        type: 'error',
        title: 'Error',
        text: `Error to update tracking point`,
      },
    });
  }
};

/**
 *
 * @returns
 */
export const handleMetadataChange = async (trackingPointId: number, data: { [key: string]: string }, token: string) => {
  await updateTrackingPoint(trackingPointId, data, token);
};


/**
 *
 * @returns
 */
export const updateIcon = async ({ value, id, token, dispatch }: any) => {
  dispatch({
    type: 'SET_TOAST',
    data: {
      title: 'Loading . . . ',
      type: 'loading',
    },
  });

  try {
    const updatePointData: any = {
      id: id,
      type: value,
    };
    await updateTrackingPoint(id, updatePointData, token);
  } catch (err) {
    dispatch({
      type: 'SET_TOAST',
      data: {
        type: 'error',
        title: 'Error',
        text: `Error to update tracking point`,
      },
    });
  }
};
