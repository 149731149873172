import styled from 'styled-components';

/**
 * @param light
 * @returns
 */
const getCardBackground = (light = false) => {
  if (light) return '#ffffff';
  return '#f0f2f3';
};

export const DeviceCard = styled.div<{ light?: boolean }>`
  display: grid;
  grid-template-rows: auto 1fr;
  background-color: ${({ light }) => getCardBackground(light)};
  box-shadow: 0px 4px 10px rgba(37, 43, 64, 0.05);
  border-radius: 6px;

`;
export const DeviceCardHeader = styled.header`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 12px;
  align-items: center;

  padding: 16px;
  border-bottom: 1px solid #dbe1e3;
`;
export const DeviceCardContent = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 16px;
  padding: 16px;
`;

export const DeviceInfoList = styled.dl`
  display: flex;
  flex-direction: column;

  gap: 8px;
  margin: 0;
`;
export const DeviceInfo = styled.div``;
export const DeviceInfoLabel = styled.dt`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.2em;
  text-transform: uppercase;

  color: rgba(105, 115, 134, 0.75);

  margin-bottom: 2px;
`;
export const DeviceInfoValue = styled.dd`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;

  color: #1a1f36;

  margin: 0;
`;

export const DevicePropertiesList = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;

  gap: 8px;
`;
export const DeviceProperty = styled.li`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const DevicePropertyStatus = styled.span<{ checked: boolean }>`
  width: 16px;
  height: 16px;

  border-radius: 50%;
  background-color: ${({ checked }) => (!checked ? '#d4d4d4' : '#e40f0f')};
`;

export const DevicePropertyLabel = styled.label`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;

  color: #1a1f36;
`;

export const DeviceTitleWrapper = styled.div``;
export const DeviceTitle = styled.h3`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.4em;
  text-transform: uppercase;
  cursor: pointer;
  color: #1a1f36;
  margin-top: -15px;
`;

export const DeviceStatus = styled.span<{ color: string }>`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;

  color: ${({ color }) => color};
`;
export const DeviceStatusFigure = styled.figure`
  width: 44px;
  height: 44px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;
  background-color: #d4d4d4;
`;
