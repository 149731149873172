/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import 'jquery';
import 'jquery-ui/dist/jquery-ui.min.js';

// slickgrid dependecies
import 'slickgrid/slick.interactions';
import 'slickgrid/slick.core';
import 'slickgrid/slick.editors';
import 'slickgrid/slick.formatters';
import 'slickgrid/slick.grid';

// slickgrid plugins
import 'slickgrid/plugins/slick.autotooltips';
import 'slickgrid/plugins/slick.cellrangedecorator';
import 'slickgrid/plugins/slick.cellrangeselector';
import 'slickgrid/plugins/slick.cellexternalcopymanager-old';
import 'slickgrid/plugins/slick.checkboxselectcolumn';
import 'slickgrid/plugins/slick.cellselectionmodel';

// slickgrid controls
import 'slickgrid/controls/slick.columnpicker';
import 'slickgrid/controls/slick.gridmenu';

// slickgrid styles
import 'slickgrid/slick.grid.css';
import 'slickgrid/controls/slick.gridmenu.css';
import 'slickgrid/controls/slick.columnpicker.css';

// @ts-ignore
export const RenamedSlick = Slick;
