import styled from 'styled-components';

export const Container = styled.div<{ customStyle?: string }>`
  padding: 12px 0;
  ${({ customStyle }) => customStyle}
`;

export const Label = styled.label.attrs({
  className: 'input-group-label',
})<any>`
  font-weight: 500;
  font-size: 0.93em;
  line-height: 1em;
  padding-bottom: 10px;
  display: block;

  color: #4b5066;
`;

export const Error = styled.label.attrs({
  className: 'input-group-error',
})<any>`
  font-weight: 500;
  font-size: 0.8em;
  line-height: 0.93em;
  color: #dd0001;

  margin-top: 4px;
`;

export const Description = styled.p`
  font-weight: 500;
  font-size: 0.75em;
  line-height: 1.3em;

  color: #4b5066;
  margin-top: 8px;
  opacity: 0.7;
`;
