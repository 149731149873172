import { useContext, useMemo, useState } from 'react';
import { formatTimezoneDate } from '@/lib/formatter';
import SettingsStore from 'app/modules/settings/settings.context';
import InspectionStore from 'app/modules/inspection/inspection.context';
import {
  RunStatusDropdownWrapper,
  RunStatusDropdownButton,
  RunStatusDropdownItem,
  RunStatusTimeButton,
  RunStatusWrapper,
  RunStatusDropdown,
} from './mobile-run-status.style';

interface Props {
  onAbort: () => void;
  onFinish: () => void;
  onOpenScheduleModal: () => void;
  permissionType: string | undefined;
}

/**
 *
 * @returns
 */
const MobileRunStatus = (props: Props) => {
  const settingsContext = useContext(SettingsStore);
  const inspectionContext = useContext(InspectionStore);
  const [isOpenedDropdown, setIsOpenedDropdown] = useState<boolean>(false);
  const launchPoint = useMemo(() => {
    if (!inspectionContext.state.trackingpoint_set) return null;
    const launchId = inspectionContext.state.trackingpoint_set[0];
    return inspectionContext.state.trackingpoints_dic[launchId];
  }, [inspectionContext.state.run?.id, inspectionContext.state.trackingpoints_dic, inspectionContext.state.trackingpoint_set]);

  const tense = {
    WAITING: 'SCHEDULED ON',
    ACTIVE: 'LAUNCHED ON',
    EARLY_FINISHED: 'FINISHED ON',
    FINISHED: 'FINISHED ON',
  };
  
  const status = {
    WAITING: 'WAITING',
    ACTIVE: 'ACTIVE',
    EARLY_FINISHED: 'FINISHED EARLY',
    FINISHED: 'FINISHED',
  };

  if (!inspectionContext.state.run || !launchPoint) return null;

  const canBeAbort = inspectionContext.state.estimation
    ? inspectionContext.state.estimation.can_be_aborted
    : true;

  /**
   *
   */
  const handleAbort = () => {
    setIsOpenedDropdown(false);
    props.onAbort();
  };

  /**
   *
   */
  const handleFinish = () => {
    setIsOpenedDropdown(false);
    props.onFinish();
  };

  /**
   *
   */
  const handleOpenScheduleModal = () => {
    setIsOpenedDropdown(false);
    props.onOpenScheduleModal();
  };

  const launchPassage = launchPoint.passage ?
    inspectionContext.state.passages_dic[launchPoint.passage] :
    undefined;
  const launchDate = launchPassage?.tstamp || inspectionContext.state.run.scheduled_launch_at;

  return (
    <RunStatusWrapper>
      <RunStatusTimeButton onClick={props.permissionType !== 'editor' ? undefined : handleOpenScheduleModal}>
        {tense[inspectionContext.state.run.status as string]}
        &nbsp;
        {formatTimezoneDate({
          date: launchDate,
          timezone: settingsContext.state.timezone?.id || 'utc',
          format: 'MMM DD, YYYY',
        })}
      </RunStatusTimeButton>

      {
        inspectionContext.state.run.launched && !inspectionContext.state.run.is_finished ? (
          <RunStatusDropdownWrapper>
            <RunStatusDropdownButton
              onClick={() => props.permissionType !== 'editor' 
                ? undefined :
                setIsOpenedDropdown(!isOpenedDropdown)
              }
            >
              {status[inspectionContext.state.run.status as string]}
            </RunStatusDropdownButton>
            <RunStatusDropdown hide={!isOpenedDropdown}>
              {
                canBeAbort ? (
                  <RunStatusDropdownItem onClick={handleAbort}>Abort Run</RunStatusDropdownItem>
                ) : null
              }
              <RunStatusDropdownItem onClick={handleFinish}>Finish Run</RunStatusDropdownItem>
            </RunStatusDropdown>
          </RunStatusDropdownWrapper>
        ) : null
      }
    </RunStatusWrapper>
  );
};

export default MobileRunStatus;
