import { useContext, useEffect, useMemo, useState, useCallback } from 'react';
import Interact from 'interactjs';
import { useDropzone } from 'react-dropzone';
import { formatTimezoneDate, formatTimezoneDateFromNow } from '@/lib/formatter';
import { Auth, Timezone, Unit } from 'app/store/store.interfaces';
import SwitchButton from 'app/components/common/base/swtich-button/switch-button';
import DesktopDataEditor from '../desktop-data-editor/desktop-data-editor';
import NoteCard from './components/note-card/note-card';
import IconSelector from 'app/components/icon-selector/iconSelector';
import ConfirmationModal from 'app/components/confirmation-modal/confirmation-modal';
import { metadata } from './tracking-point-overlay.model';
import { createNote, deleteNote, handleMetadataChange, toggleActive, updateIcon, updateNote, uploadMedia } from './tracking-point-overlay.controller';
import {
  TrackingPointOverlayCloseButton,
  TrackingPointOverlayContainer,
  TrackingPointOverlayHeader,
  TrackingPointOverlayHeaderPointName,
  TrackingPointOverlayHeaderPointNameContainer,
  TrackingPointOverlayHeaderPointType,
  TrackingPointOverlayMetaDataTableWrapper,
  TrackingPointOverlayMetaDataTable,
  TrackingPointOverlayPassageInformation,
  TrackingPointOverlayPassageInformationPassage,
  TrackingPointOverlayPassageDataGroup,
  TrackingPointOverlayPassageDataLabel,
  TrackingPointOverlayPassageDataValue,
  TrackingPointOverlayPassageDate,
  TrackingPointOverlayPassageTime,
  TrackingPointOverlayPresetContainer,
  TrackingPointOverlaySeeMoreButton,
  TrackingPointOverlaySeeMoreButtonWrapper,
  TrackingPointOverlayMedia,
  TrackingPointOverlayMediaContent,
  TrackingPointOverlayMediaContentDropzone,
  TrackingPointOverlayMediaFooter,
  TrackingPointOverlayMediaFooterMediaGalleryButton,
  TrackingPointOverlayMediaHeader,
  TrackingPointOverlayNotes,
  TrackingPointOverlayNotesContent,
  TrackingPointOverlayNotesFooter,
  TrackingPointOverlayNotesFooterButton,
  TrackingPointOverlayNotesHeader,
  TrackingPointOverlayNoteList,
} from './tracking-point-overlay.style';
import { TrackingPointModalMediaFigure, TrackingPointModalMediaImage } from '../tracking-point-modal/tracking-point-modal.style';
import SettingsStore from 'app/modules/settings/settings.context';
import NotificationStore from 'app/modules/notification/notification.context';
import InspectionStore from 'app/modules/inspection/inspection.context';
import AccountStore from 'app/modules/account/account.context';
import { AuthType } from 'app/modules/account/account.context.d';
import { InspectionContext, InspectionProviderValue } from 'app/modules/inspection/inspection.context.d';
import { TrackingPointPlainType } from 'app/modules/inspection/inspection.interfaces';

interface Props {
  permissionType: string;
  onOpenGallery: (index?: number) => void;
}

/**
 * 
 * @param event 
 */
const dragMoveListener = (event)  => {
  const target = event.target
  // keep the dragged position in the data-x/data-y attributes
  const x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx
  const y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy

  // translate the element
  target.style.transform = 'translate(' + x + 'px, ' + y + 'px)'

  // update the posiion attributes
  target.setAttribute('data-x', x)
  target.setAttribute('data-y', y)
};

/**
 * render rows of tracking point metadata
 * @returns
 */
const renderMetadata = (
  tpoint: TrackingPointPlainType,
  distanceUnit: Unit | undefined,
  speedUnit: Unit | undefined,
  timezone: Timezone | undefined,
  length: number,
  auth: Auth,
  isObserver: boolean,
) => Object.keys(metadata).slice(0, length).map((key) => {
  const units = {
    distance: distanceUnit,
    timezone: timezone,
    speed: speedUnit,
  };

  const { label, formatter, readonly } = metadata[key];
  let text = tpoint[key];

  if (formatter) {
    text = formatter(tpoint, units)
  }

  return (
    <tr key={key}>
      <td>{label}</td>
      <td style={{ width: '65%' }}>
        {readonly ?
          text :
          <DesktopDataEditor
            value={text}
            isObserver={isObserver}
            onChange={(value) => {
              const data = {};
              data[key] = value;
              handleMetadataChange(tpoint.id, data, auth.token);
            }}
          />
        }
    </td>
  </tr>
  );
});

/**
   * render the first three media of tracking point
   * @returns
   */
const renderMedia = (
  tpoint: TrackingPointPlainType,
  mediasDic: InspectionContext['medias_dic'],
  onShowGalleryMedia: (id: number) => void
) => tpoint.media_set?.slice(0, 3).map((mediaId, index) => {
  const media = mediasDic[mediaId];
  return (
    <TrackingPointModalMediaFigure key={`${mediaId}${index}`} onClick={() => { onShowGalleryMedia(index) }}>
      <TrackingPointModalMediaImage src={media?.media} alt={media?.caption} />
    </TrackingPointModalMediaFigure>
  );
});

/**
 * map context notes to card component
 * @param notes note list
 * @returns list of note cards
 */
const renderNotes = (
  notes: number[],
  inspectionContext: InspectionProviderValue,
  onDeleteNote: (
    id: string,
    token: string,
    notes: any[],
    setLoading: (loading: boolean) => void,
  ) => void,
  onEditNote: (
    id: string,
    text: string,
    token: string,
    setLoading: (loading: boolean) => void,
  ) => void,
  token: string,
  permissionType: string
) =>
    notes
    .map((noteId) => {
      if (!inspectionContext.state.selected_point) return;
      const note = inspectionContext.state.notes_dic[noteId];

      return (
        <NoteCard
          note={{
            id: note.id.toString(),
            created_at: note.created_at,
            text: note.text || '',
          }}
          key={note.id}
          allowEdition={permissionType === 'editor'}
          onDelete={(setLoading: (loading: boolean) => void) =>
            onDeleteNote(
              note.id.toString(),
              token,
              notes,
              setLoading,
            )
          }
          onEdit={(text: string, setLoading: (loading: boolean) => void) =>
            onEditNote(
              note.id.toString(),
              text,
              token,
              setLoading,
            )
          }
        />
      )
    });

/**
 * this component should render floating the run
 * in the right side, showing all informations about
 * the tracking point selected on the sheet or map
 */
const TrackingPointOverlay = (props: Props) => {
  const settingsContext = useContext(SettingsStore);
  const notificationContext = useContext(NotificationStore);
  const inspectionContext = useContext(InspectionStore);
  const accountContext = useContext(AccountStore);
  const auth = accountContext.state.auth as AuthType;
  const [showIconSelector, setShowIconSelector] = useState<string>();
  const [confirmationModal, setConfirmationModal] = useState<any>();
  const [nnote, setNnote] = useState<{ text: string; id: string }>() // new note 
  const [isSeeMore, setIsSeeMore] = useState<boolean>(false);
  const tpoint = useMemo(() => {
    const pointId = inspectionContext.state.selected_point;
    if (pointId) {
      const point = inspectionContext.state.trackingpoints_dic[pointId];
      return point;
    }
    return undefined;
  }, [
    inspectionContext.state.selected_point,
    inspectionContext.state.selected_point ? inspectionContext.state.trackingpoints_dic[inspectionContext.state.selected_point] : undefined
  ]);
  const passage = useMemo(() => {
    if (!tpoint?.passage) return undefined;
    return inspectionContext.state.passages_dic[tpoint.passage];
  }, [tpoint?.passage, inspectionContext.state.passages_dic]);

  // useCallback define to upload media 
  const onDrop = useCallback((acceptedFiles) => {
    if (!auth) return null;

    uploadMedia(
      tpoint,
      acceptedFiles,
      auth.token,
      inspectionContext.state.run,
      notificationContext.dispatch,
      inspectionContext.dispatch,
      () => ''
    );
  }, [tpoint?.id, auth]);
  const { getRootProps, isDragActive, open } = useDropzone({ onDrop, noClick: true })
  // memorize memorize max height
  const maxHeight = useMemo(() => {
    return window.innerHeight - 113;
  }, []);

  // memorize passage time
  const passageTime = useMemo(() => {
    if (!passage?.tstamp) return '';
    const time = formatTimezoneDate({
      date: passage.tstamp,
      timezone: settingsContext.state.timezone?.id || 'utc',
      format: 'HH:mm:ss.SSS',
    });
    return time;
    // memorize passage date
  }, [tpoint?.passage, settingsContext.state.timezone]);
  const passageDate = useMemo(() => {
    if (!passage?.tstamp) return '';
    return formatTimezoneDate({
      date: passage.tstamp,
      timezone: settingsContext.state.timezone?.id || 'utc',
      format: 'MMM DD, YYYY',
    });
  }, [tpoint, settingsContext.state.timezone]);
  // memorize passage eta
  const eta = useMemo(() => {
    if (!tpoint?.eta) return '-';
    return formatTimezoneDate({
      date: tpoint?.eta,
      timezone: settingsContext.state.timezone?.id || 'utc',
      format: 'MM/DD HH:mm:ss',
    });
  }, [tpoint?.eta, settingsContext.state.timezone]);
  // memorize passage ete
  const ete = useMemo(() => {
    if (!tpoint?.eta) return '-';
    return formatTimezoneDateFromNow({
      date: new Date(tpoint?.eta),
      timezone: settingsContext.state.timezone?.id || 'utc',
      isNext: !!tpoint?.is_next,
    });
  }, [tpoint?.eta, settingsContext.state.timezone, inspectionContext.state.tick]);
  
  // set inital position
  useEffect(() => {
    // target elements with the "#GT_DRAGGABLE_TRACKING_POINT" class
    Interact('#GT_DRAGGABLE_TRACKING_POINT')
      .draggable({
        inertia: true,
        modifiers: [
          Interact.modifiers.restrictRect({
            restriction: 'parent',
            endOnly: true
          })
        ],
        listeners: {
          move: dragMoveListener
        }
      })
      .styleCursor(false);
  }, []);

  const onTpDelete = (
    id: string,
    token: string,
    notes: any[],
    setLoading: (loading: boolean) => void,
  ) => {
    setConfirmationModal({
      hide: false,
      title: 'Are you sure?',
      text: 'The note will be deleted permanently.',
      onConfirm: () => deleteNote(
        id,
        token,
        notes,
        setLoading,
        inspectionContext.dispatch,
        setConfirmationModal
      ),
      onCancel: () => setConfirmationModal(undefined),
    })
  }

  if (!auth || !inspectionContext.state.run || !tpoint) return null;
  
  return (
    <TrackingPointOverlayContainer id="GT_DRAGGABLE_TRACKING_POINT" maxHeight={maxHeight}>
      <TrackingPointOverlayPresetContainer>
        <SwitchButton
          disabled={inspectionContext.state.is_observer}
          label="Preset"
          on={!!tpoint?.active}
          handleChange={() => {
            toggleActive({
              value: !tpoint?.active,
              id: tpoint?.id,
              token: auth?.token,
              dispatch: notificationContext.dispatch,
            })
          }}
        />

        <TrackingPointOverlayCloseButton
          onClick={() => inspectionContext.dispatch({
            type: 'SET_SELECTED_POINT',
            data: undefined
          })}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="14.2427"
              y="4.34375"
              width="2"
              height="14"
              rx="1"
              transform="rotate(45 14.2427 4.34375)"
              fill="#1a1f36"
            />
            <rect
              x="15.6567"
              y="14.2422"
              width="2"
              height="14"
              rx="1"
              transform="rotate(135 15.6567 14.2422)"
              fill="#1a1f36"
            />
          </svg>
        </TrackingPointOverlayCloseButton>
      </TrackingPointOverlayPresetContainer>

      <TrackingPointOverlayHeader>
        <IconSelector
          showIconSelector={showIconSelector}
          setShowIconSelector={setShowIconSelector}
          row={tpoint}
          border={true}
          fill="#767986"
          permissionType={props.permissionType}
          handleChange={({ value }: { value: any }) => {
            if (!inspectionContext.state.selected_point) return;
            
            updateIcon({
              value,
              id: inspectionContext.state.selected_point,
              token: auth?.token,
              dispatch: notificationContext.dispatch
            });
          }}
        />

        <TrackingPointOverlayHeaderPointNameContainer>
          <TrackingPointOverlayHeaderPointName>{tpoint?.name}</TrackingPointOverlayHeaderPointName>
          <TrackingPointOverlayHeaderPointType>{tpoint?.type || 'AGM'}</TrackingPointOverlayHeaderPointType>
        </TrackingPointOverlayHeaderPointNameContainer>

        <TrackingPointOverlayPassageInformation>
          <TrackingPointOverlayPassageInformationPassage>
            <TrackingPointOverlayPassageTime invalid={passage && !passage.valid}>
              <svg width="14" height="14" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5 0C2.24288 0 0 2.24301 0 5C0 7.75698 2.24302 10 5 10C7.75698 10 10 7.75698 10 5C10 2.24301 7.75698 0 5 0ZM5 9.3752C2.58757 9.3752 0.625029 7.41261 0.625029 5.00023C0.625029 2.58785 2.58762 0.625262 5 0.625262C7.41238 0.625262 9.37497 2.58785 9.37497 5.00023C9.37497 7.41261 7.41238 9.3752 5 9.3752ZM6.99848 7.37779C7.0956 7.52037 7.05888 7.71486 6.91622 7.81198C6.86238 7.8486 6.80107 7.86618 6.74058 7.86618C6.64064 7.86618 6.54252 7.81844 6.48203 7.72962L4.74172 5.1761C4.70637 5.12426 4.68752 5.06295 4.68752 5.00009V1.65128C4.68752 1.47874 4.82745 1.33882 4.99998 1.33882C5.17252 1.33882 5.31245 1.47875 5.31245 1.65128V4.90377L6.99848 7.37779Z" fill="#5850EC"/>
              </svg>
              {passageTime || '---'}
            </TrackingPointOverlayPassageTime>
            <TrackingPointOverlayPassageDate invalid={passage && !passage.valid}>
              <svg width="14" height="14" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.61911 0C2.22749 0 1.90479 0.330572 1.90479 0.731744V1.2195H0.714322C0.322701 1.2195 0 1.55007 0 1.95125V9.26826C0 9.66943 0.322701 10 0.714322 10H9.28568C9.6773 10 10 9.66943 10 9.26826V1.95125C10 1.55007 9.6773 1.2195 9.28568 1.2195H8.09521V0.731744C8.09521 0.330572 7.77251 0 7.38089 0C6.98927 0 6.66657 0.330572 6.66657 0.731744V1.2195H3.33326V0.731744C3.33326 0.330572 3.01056 0 2.61894 0H2.61911ZM2.61911 0.487757C2.75513 0.487757 2.85718 0.592303 2.85718 0.731636V2.19502C2.85718 2.33435 2.75513 2.43889 2.61911 2.43889C2.4831 2.43889 2.38104 2.33435 2.38104 2.19502V0.731636C2.38104 0.592299 2.4831 0.487757 2.61911 0.487757ZM7.38097 0.487757C7.51699 0.487757 7.61905 0.592303 7.61905 0.731636V2.19502C7.61905 2.33435 7.51699 2.43889 7.38097 2.43889C7.24496 2.43889 7.1429 2.33435 7.1429 2.19502V0.731636C7.1429 0.592299 7.24496 0.487757 7.38097 0.487757ZM0.714365 1.70726H1.90483V2.19502C1.90483 2.59619 2.22753 2.92676 2.61915 2.92676C3.01077 2.92676 3.33348 2.59619 3.33348 2.19502V1.70726H6.66678V2.19502C6.66678 2.59619 6.98948 2.92676 7.3811 2.92676C7.77272 2.92676 8.09542 2.59619 8.09542 2.19502V1.70726H9.28589C9.42191 1.70726 9.52396 1.8118 9.52396 1.95114V3.41452H0.47642V1.95114C0.47642 1.8118 0.578477 1.70726 0.714492 1.70726H0.714365ZM0.476293 3.90236H9.52384V9.26817C9.52384 9.40751 9.42178 9.51205 9.28576 9.51205H0.714407C0.578388 9.51205 0.476335 9.4075 0.476335 9.26817V3.90236H0.476293Z" fill="#5850EC"/>
              </svg>
              {passageDate || '---'}
            </TrackingPointOverlayPassageDate>

            <TrackingPointOverlayPassageDataGroup>
              <TrackingPointOverlayPassageDataLabel>ETA:</TrackingPointOverlayPassageDataLabel>
              <TrackingPointOverlayPassageDataValue>{eta}</TrackingPointOverlayPassageDataValue>
            </TrackingPointOverlayPassageDataGroup>

            <TrackingPointOverlayPassageDataGroup>
              <TrackingPointOverlayPassageDataLabel>ETE:</TrackingPointOverlayPassageDataLabel>
              <TrackingPointOverlayPassageDataValue>{ete}</TrackingPointOverlayPassageDataValue>
            </TrackingPointOverlayPassageDataGroup>
          </TrackingPointOverlayPassageInformationPassage>
        </TrackingPointOverlayPassageInformation>
      </TrackingPointOverlayHeader>

      <TrackingPointOverlayMetaDataTableWrapper>
        <TrackingPointOverlayMetaDataTable id="metadata-table">
          {renderMetadata(
            tpoint,
            settingsContext.state.distanceUnit,
            settingsContext.state.speedUnit,
            settingsContext.state.timezone,
            isSeeMore ? Object.keys(tpoint).length : 7,
            auth,
            inspectionContext.state.is_observer
          )}
        </TrackingPointOverlayMetaDataTable>
      </TrackingPointOverlayMetaDataTableWrapper>

      <TrackingPointOverlaySeeMoreButtonWrapper>
        <TrackingPointOverlaySeeMoreButton
          active={isSeeMore}
          id="metadata-button"
          onClick={() => {
            const btn = document.querySelector('#metadata-button') as HTMLButtonElement;
            const container = document.querySelector('#GT_DRAGGABLE_TRACKING_POINT') as HTMLDivElement;
            if (!isSeeMore) {
              setIsSeeMore(true)
              setTimeout(() => {
                const y = btn.getBoundingClientRect().top + container.scrollTop - 500;
                 container?.scrollTo({ top: y, behavior: 'smooth' });
              }, 100);
            } else {
              setIsSeeMore(false);
              setTimeout(() => {
                const y = btn.getBoundingClientRect().top + container.scrollTop - 300;
                container?.scrollTo({ top: y, behavior: 'smooth' });
              }, 100);
            }
          }}
        >
          <svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.96582 0.998596C7.86118 0.998464 7.76083 1.03996 7.68702 1.11388L4.18001 4.61321L0.673177 1.11388C0.573552 1.01447 0.428379 0.97567 0.29224 1.01203C0.156171 1.0484 0.0498759 1.15446 0.0134368 1.29023C-0.0230056 1.426 0.0158796 1.57093 0.115505 1.67033L3.90121 5.44792C3.97654 5.519 4.07629 5.55859 4.18002 5.55859C4.28374 5.55859 4.38349 5.519 4.45889 5.44792L8.24459 1.67033L8.24453 1.67039C8.31847 1.59655 8.36 1.49649 8.36 1.39213C8.36 1.28778 8.31847 1.18772 8.24453 1.11394C8.17058 1.04016 8.07029 0.998659 7.96572 0.998659L7.96582 0.998596Z" fill="#767986"/>
          </svg>

          <span>
            {isSeeMore ? 'See Less' : 'See More'}
          </span>
        </TrackingPointOverlaySeeMoreButton>
      </TrackingPointOverlaySeeMoreButtonWrapper>

      <TrackingPointOverlayMedia>
        <TrackingPointOverlayMediaHeader>
          <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.15385 9L5.46154 7.30769M9 7.46154L7.15385 5.61538L5.46154 7.30769M5.46154 7.30769L2.84615 4.69231L1.30769 6.23077L1 6.53846M1 6.53846L1 2.23077C1 1.55103 1.55104 0.999999 2.23077 1L7.76923 1.00001C8.44897 1.00001 9 1.55105 9 2.23078L9 7.76923C9 8.44896 8.44897 9 7.76923 9L2.23077 9C1.55103 9 1 8.44896 1 7.76923L1 6.53846ZM7.76923 3.15384C7.76923 3.66364 7.35596 4.07692 6.84615 4.07692C6.33635 4.07692 5.92308 3.66364 5.92308 3.15384C5.92308 2.64404 6.33635 2.23077 6.84615 2.23077C7.35596 2.23077 7.76923 2.64404 7.76923 3.15384Z" stroke="#5850EC" strokeWidth="0.8" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
          <strong>Media</strong> ({tpoint?.media_set?.length || 0})
        </TrackingPointOverlayMediaHeader>

        <TrackingPointOverlayMediaContent>
          {
            !inspectionContext.state.is_observer ? (
              <TrackingPointOverlayMediaContentDropzone {...getRootProps()} onClick={open} dropping={isDragActive}>
                <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 96 960 960">
                  <path d="M452 854h60V653l82 82 42-42-156-152-154 154 42 42 84-84v201ZM220 976q-24 0-42-18t-18-42V236q0-24 18-42t42-18h361l219 219v521q0 24-18 42t-42 18H220Zm331-554V236H220v680h520V422H551ZM220 236v186-186 680-680Z" fill="#767986"/>
                </svg>
                <p>Drop here, or browse your files</p>
              </TrackingPointOverlayMediaContentDropzone>
            ) : null  
          }
          {renderMedia(tpoint, inspectionContext.state.medias_dic, props.onOpenGallery)}
        </TrackingPointOverlayMediaContent>

        <TrackingPointOverlayMediaFooter hidden={inspectionContext.state.is_observer}>
          <TrackingPointOverlayMediaFooterMediaGalleryButton onClick={props.onOpenGallery}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960">
              <path d="M100 856q-24.75 0-42.375-17.625T40 796V356q0-24.75 17.625-42.375T100 296h440q24.75 0 42.375 17.625T600 356v440q0 24.75-17.625 42.375T540 856H100Zm620-320q-17 0-28.5-11.5T680 496V336q0-17 11.5-28.5T720 296h160q17 0 28.5 11.5T920 336v160q0 17-11.5 28.5T880 536H720Zm20-60h120V356H740v120ZM100 796h440V356H100v440Zm60-100h320L375 556l-75 100-55-73-85 113Zm560 160q-17 0-28.5-11.5T680 816V656q0-17 11.5-28.5T720 616h160q17 0 28.5 11.5T920 656v160q0 17-11.5 28.5T880 856H720Zm20-60h120V676H740v120Zm-640 0V356v440Zm640-320V356v120Zm0 320V676v120Z" fill="#5850ec"/>
            </svg>
            Gallery
          </TrackingPointOverlayMediaFooterMediaGalleryButton>
        </TrackingPointOverlayMediaFooter>
      </TrackingPointOverlayMedia>

      <TrackingPointOverlayNotes>
        <TrackingPointOverlayNotesHeader>
          <svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 96 960 960" width="18"><path d="M220 976q-24 0-42-18t-18-42V236q0-24 18-42t42-18h361l219 219v521q0 24-18 42t-42 18H220Zm331-554V236H220v680h520V422H551ZM220 236v186-186 680-680Z" fill="#5850EC" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"/></svg>
          <strong>Notes</strong> ({tpoint?.trackingpointnote_set?.length || 0})
        </TrackingPointOverlayNotesHeader>
        
        <TrackingPointOverlayNotesContent>
          <TrackingPointOverlayNoteList>
            {
              nnote ? (
                <NoteCard
                  autoFocus
                  allowEdition
                  note={{
                    id: nnote.id.toString(),
                    text: nnote.text,
                  }}
                  onDelete={() => null}
                  onEdit={(text, setNoteCreationLoading) => {
                    createNote(
                      text,
                      tpoint?.id.toString() || '',
                      auth.token,
                      setNoteCreationLoading,
                      setNnote,
                    );
                  }}
                  onCancel={() => setNnote(undefined)}
                />
              ) : null
            }
          
            {
              !nnote && !tpoint?.trackingpointnote_set?.length ? (
                <p>No notes.</p>
              ) : null
            }
          
            {tpoint.trackingpointnote_set ? renderNotes(
              tpoint.trackingpointnote_set,
              inspectionContext,
              onTpDelete,
              updateNote,
              auth.token,
              props.permissionType || 'observer'
            ) : null}
          </TrackingPointOverlayNoteList>
        </TrackingPointOverlayNotesContent>
            
        <TrackingPointOverlayNotesFooter hidden={inspectionContext.state.is_observer}>
          <TrackingPointOverlayNotesFooterButton
            disabled={inspectionContext.state.is_observer}
            onClick={() =>  setNnote({
              id: 'new',
              text: '',
            })}
          >
            <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="0.25" y="0.25" width="2.5" height="2.5" stroke="#5850EC" strokeWidth="0.5"/>
              <rect x="0.25" y="4.25" width="2.5" height="2.5" stroke="#5850EC" strokeWidth="0.5"/>
              <rect x="4.25" y="0.25" width="2.5" height="2.5" stroke="#5850EC" strokeWidth="0.5"/>
              <rect x="4.25" y="4.25" width="2.5" height="2.5" stroke="#5850EC" strokeWidth="0.5"/>
            </svg>
            Add Note
          </TrackingPointOverlayNotesFooterButton>
        </TrackingPointOverlayNotesFooter>
      </TrackingPointOverlayNotes>

      <ConfirmationModal {...confirmationModal} />
    </TrackingPointOverlayContainer>
  );
};

export default TrackingPointOverlay;
