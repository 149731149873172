import { OperationType } from "app/utils/websocket.utils";
import { InspectionProviderValue } from "./inspection.context.d";
import { NotificationProviderValue } from "../notification/notification.context.d";
import { History } from "history";

interface StatusType {
  status_code: number;
  error?: string; 
}

/**
 *
 * @param operation
 * @param dispatch
 */
const deleteEvent = (
  operation: OperationType,
  dispatch: InspectionProviderValue['dispatch'],
  notificationDispatch: NotificationProviderValue['dispatch'],
) => {
  if (operation._instance_type === 'inspection.serializers.PassageSerializer') {
    notificationDispatch({
      type: 'SET_TOAST',
      data: { type: 'success', text: 'Passage was deleted!' }
    });

    dispatch({
      type: 'DELETE_PASSAGE',
      data: operation as unknown
    });
  }
  if (operation._instance_type === 'inspection.serializers.TrackingPointCascadeSerializer') {
    dispatch({
      type: 'DELETE_TRACKING_POINT',
      data: operation as unknown
    });
  }
  if (operation._instance_type === 'inspection.serializers.EstimationSerializer') {
    dispatch({
      type: 'UPDATE_ESTIMATION',
      data: undefined,
    });
  }
}

/**
 *
 * @param dispatch
 * @param operation
 * @param instanceType
 */
const handleToast = (
  dispatch: NotificationProviderValue['dispatch'],
  notification: any | undefined,
) => {
  if (notification.message === 'passage/create') {
    dispatch({
      type: 'SET_TOAST',
      data: {
        type: notification.status,
        text: 'New passage was created!'
      }
    })
  }

  if (notification.message === 'passage/update') {
    dispatch({
      type: 'SET_TOAST',
      data: {
        type: notification.status,
        text: 'Passage was updated!'
      }
    })
  }

  if (notification.message === 'passage/delete') {
    dispatch({
      type: 'SET_TOAST',
      data: {
        type: notification.status,
        text: 'Passage was deleted!'
      }
    })
  }

  if (notification.message === 'run/launch') {
    dispatch({
      type: 'SET_TOAST',
      data: {
        type: notification.status,
        text: 'Run Launched!'
      }
    })
  }

  if (notification.message === 'run/abort') {
    dispatch({
      type: 'SET_TOAST',
      data: {
        type: notification.status,
        text: 'Run Aborted!'
      }
    })
  }

  if (notification.message === 'run/finish') {
    dispatch({
      type: 'SET_TOAST',
      data: {
        type: notification.status,
        text: 'Run Finished!'
      }
    })
  }

  if (notification.message === 'run/finish-early') {
    dispatch({
      type: 'SET_TOAST',
      data: {
        type: notification.status,
        text: 'Run Finished Early!'
      }
    })
  }
};

/**
 * this manage changes received by socket messages
 */
export const handleOnMessage = (
  payload: unknown[] | unknown,
  dispatch: InspectionProviderValue['dispatch'],
  notificationDispatch: NotificationProviderValue['dispatch'],
  history: History, 
  runid: string,
) => {
  if (!Array.isArray(payload)) {
    const data = payload as StatusType;
    if (data.error) {
      dispatch({ type: 'SET_VERIFIED', data: true });
      dispatch({ type: 'SET_ERROR', data: data.error });
      
      if (data.status_code === 401) {
        history.push(`/runs/${runid}/forbidden`);
      }

      if (data.status_code === 403) {
        history.push(`/runs/${runid}/forbidden`);
      }
      
      if (data.status_code === 404) {
        history.push(`/runs/${runid}/not-found`);
      }
    }

    if (data.status_code === 200) {
      dispatch({ type: 'SET_VERIFIED', data: true });
      dispatch({ type: 'SET_LOADING', data: true });
    }

    return;
  }
  
  for (const o of payload as unknown[]) {
    const operation = o as OperationType;

    if (operation._deleted) {
      return deleteEvent(operation, dispatch, notificationDispatch);
    }

    if (operation._instance_type === 'inspection.serializers.RunCascadeSerializer') {
      dispatch({
        type: 'SET_RUN',
        data: o,
      })
    }

    if (operation._instance_type === 'weather.serializers.ForecastSerializer') {
      dispatch({
        type: 'SET_FORECAST',
        data: o,
      })
    }

    if (operation._instance_type === 'survey.serializers.PipelineSerializer') {
      dispatch({
        type: 'SET_PIPELINE',
        data: o,
      })
    }

    if (operation._instance_type === 'project.serializers.ProjectSerializer') {
      dispatch({
        type: 'SET_PROJECT',
        data: o,
      })
    }

    if (operation._instance_type === 'inspection.serializers.TrackingPointCascadeSerializer') {
      dispatch({
        type: 'UPDATE_POINT',
        data: o,
      })
    }

    if (operation._instance_type === 'inspection.serializers.PassageSerializer') {
      dispatch({
        type: 'UPDATE_PASSAGE',
        data: o,
      })
    }

    if (operation._instance_type === 'detection.serializers.QubeDeviceSerializer') {
      dispatch({
        type: 'UPDATE_QUBE',
        data: o,
      })
    }

    if (operation._instance_type === 'inspection.serializers.TimePinSerializer') {
      dispatch({
        type: 'UPDATE_TIMEPIN',
        data: o,
      })
    }

    if (operation._instance_type === 'customer.serializers.CustomerSerializer') {
      dispatch({
        type: 'SET_CUSTOMER',
        data: o,
      })
    }

    if (operation._instance_type === 'inspection.serializers.TrackingPointNoteSerializer') {
      dispatch({
        type: 'UPDATE_NOTE',
        data: o,
      })
    }

    if (operation._instance_type === 'detection.serializers.TriggerSerializer') {
      dispatch({
        type: 'UPDATE_TRIGGER',
        data: o,
      })
    }

    if (operation._instance_type === 'detection.serializers.TriggerSeenSerializer') {
      dispatch({
        type: 'SET_TRIGGER_SEEN_TRIGGERS',
        data: o,
      })
    }

    if (operation._instance_type === 'survey.serializers.SurveyMediaSerializer') {
      dispatch({
        type: 'UPDATE_MEDIA',
        data: o,
      })
    }

    if (operation._instance_type === 'inspection.serializers.EstimationSerializer') {
      dispatch({
        type: 'UPDATE_ESTIMATION',
        data: o,
      })
    }

    if (operation._instance_type === '_notification') {
      handleToast(notificationDispatch, o)
    }
  }
};

/**
 *
 * @param websocket
 * @param token
 * @param transaction
 */
export const handleOnOpen = (websocket: WebSocket, token: string, lastUpdate: string) => {
  // start server connection, request the snapshot
  const data = { token, last_update: lastUpdate };
  const stringfiedData = JSON.stringify(data);
  websocket.send(stringfiedData);
};
