import styled from 'styled-components';

export const ModalWrapper = styled.dialog`
  position: fixed;
  border: none;
  background-color: rgba(35, 42, 51, 0.85);

  left: 0;
  top: 0;

  z-index: 10;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100vh;

  padding-top: 40px;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;

  @media (min-width: 1024px) {
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 32px;
    padding-top: 87px;
  }
`;

export const Modal = styled.aside`
  width: 100%;
  min-height: calc(100vh - 40px);

  background-color: #ffffff;

  overflow: hidden;

  @media (min-width: 1024px) {
    max-width: 529px;
    min-height: auto;
    border-radius: 6px;
  }
`;

export const Header = styled.header`
  position: relative;

  display: grid;
  grid-template-columns: auto auto;

  border: solid 1px #dfe0e4;

  padding: 21px 27px;
`;

export const AGMTitle = styled.h2`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;

  color: #161e2e;

  margin-bottom: 8px;
`;

export const DeviceSerialNumber = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;

  color: #161e2e;
`;

export const TriggersAmount = styled.p`
  text-align: right;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;

  color: #161e2e;
`;

export const CloseButton = styled.button`
  position: absolute;
  right: 4px;
  top: 4px;

  background-color: transparent;

  cursor: pointer;
`;

export const Content = styled.div`
  padding-top: 20px;
  padding-left: 16px;
  padding-right: 16px;

  max-height: 465px;
  overflow-y: auto;

  &:after {
    content: '';
    display: block;
    height: 20px;
  }

  @media (min-width: 1024px) {
    padding-left: 32px;
    padding-right: 32px;
  }
`;
