import {
  formatDistance,
  formatMps,
  formatTimezoneDate,
} from '@/lib/formatter';
import { SettingsContext, Unit } from 'app/modules/settings/settings.context.d';
import { Chart, registerables } from 'chart.js';
import { InspectionContext } from '../inspection.context.d';
import { RunType } from 'app/modules/inspection/inspection.interfaces.d';

const getSpeedCanvas = (
  run: RunType,
  speedUnit: Unit | undefined,
  trackingpoint_dic: InspectionContext['trackingpoints_dic']
) => {
  const speeds = run.trackingpoint_set?.filter((pointId: number) => {
    const point = trackingpoint_dic[pointId];
    return point && point.passage;
  })
  .map(
    (p: number, index: number) => {
      const point = trackingpoint_dic[p];
      return formatMps({
        distance: index === 0 ? run.predicted_launch_speed : +point.speed,
        unit: speedUnit,
        returnNumber: true,
      }) || 0
    }
  );

  return speeds || [];
};

const getElevationsCanvas = (
  points: number[],
  distanceUnit: Unit | undefined,
  trackingpoint_dic: InspectionContext['trackingpoints_dic']
) => {
  const elevationstoChart = points.map((pointId: number) => {
    const point = trackingpoint_dic[pointId];
    return formatDistance({
      distance: point ? +point.elevation : 0,
      unit: distanceUnit?.id || 'ft',
      returnNumber: true,
    });
  });

  return elevationstoChart;
};

const getInclinationsCanvas = (points: number[], trackingpoint_dic: InspectionContext['trackingpoints_dic']) => {
  const inclinationstoChart = points.map((pointId) => {
    const point = trackingpoint_dic[pointId];
    return point ? point.inclination : undefined;
  });
  return inclinationstoChart;
};

export interface CanvasObj {
  speedChart: Chart;
  elevationChart: Chart;
  inclinationChart: Chart;
}

export const setCanvasPlot = (
  distanceUnit: SettingsContext['distanceUnit'],
  speedUnit: SettingsContext['speedUnit'],
  canvas: CanvasObj | null,
  setCanvas: (data: any) => void,
  run: RunType,
  trackingpoint_dic: InspectionContext['trackingpoints_dic'],
) => {
  if (!document || !run) return;
  if (canvas?.speedChart?.destroy) canvas.speedChart.destroy();
  if (canvas?.elevationChart?.destroy) canvas.elevationChart.destroy();
  if (canvas?.inclinationChart?.destroy) canvas.inclinationChart.destroy();

  const speedChart: any = document.getElementById('report-speed-chart');
  const elevationChart: any = document.getElementById('report-elevation-chart');
  const inclinationChart: any = document.getElementById(
    'report-inclination-chart'
  );
  if (!speedChart || !elevationChart || !inclinationChart) return;
  const speedCtx = speedChart?.getContext('2d');
  const elevationCtx = elevationChart?.getContext('2d');
  const inclinationCtx = inclinationChart?.getContext('2d');

  Chart.register(...registerables);

  const points = run.trackingpoint_set?.filter((pointId: number) => {
    return trackingpoint_dic[pointId] ? trackingpoint_dic[pointId].passage : undefined;
  });
  const distances = points?.map((pointId: number) => {
    const point = trackingpoint_dic[pointId];
    return point?.name?.slice(0, 10)
  }) || [];

  const speeds = getSpeedCanvas(run, speedUnit, trackingpoint_dic);
  const speedsDataset: any[] = [];

  const elevations = getElevationsCanvas(points || [], distanceUnit, trackingpoint_dic);
  const elevationsDataset: any[] = [];

  const inclinations = getInclinationsCanvas(points || [], trackingpoint_dic);
  const inclinationsDataset: any[] = [];

  speedsDataset.push({
    label: `Speed (${speedUnit?.label})`,
    backgroundColor: 'rgb(255, 99, 132)',
    borderColor: 'rgb(255, 99, 132)',
    data: [...speeds],
    tension: 0.1,
  });

  elevationsDataset.push({
    label: `Elevation (${distanceUnit?.label})`,
    backgroundColor: 'rgb(0, 99, 132)',
    borderColor: 'rgb(0, 99, 132)',
    data: [...elevations],
    animation: {
      duration: 0,
    },
  });

  inclinationsDataset.push({
    label: `Inclination (°)`,
    backgroundColor: 'rgb(127, 255, 0)',
    borderColor: 'rgb(127, 255, 0)',
    data: [...inclinations],
    animation: {
      duration: 0,
    },
  });

  const speedsChart = new Chart(speedCtx, {
    type: 'line',
    data: {
      labels: [...distances],
      datasets: speedsDataset,
    },
  });

  const elevationsChart = new Chart(elevationCtx, {
    type: 'line',
    data: {
      labels: [...distances],
      datasets: elevationsDataset,
    },
  });

  const inclinationsChart = new Chart(inclinationCtx, {
    type: 'line',
    data: {
      labels: [...distances],
      datasets: inclinationsDataset,
    },
    options: {},
  });

  setCanvas({
    speedChart: speedsChart,
    elevationChart: elevationsChart,
    inclinationChart: inclinationsChart,
  });

  return ({
    speedChart: speedsChart,
    elevationChart: elevationsChart,
    inclinationChart: inclinationsChart,
  });
};

/**
 * This function map tracking points to table rows
 */
export const mapRows = (
  inspectionState: InspectionContext,
  settingsState: SettingsContext,
) => {
  return inspectionState.run?.trackingpoint_set?.map(
    (pointId: number, index: number) => {
      const run = inspectionState.run;
      const point = inspectionState.trackingpoints_dic[pointId];
      if (!run || !point) return {};
      const passage = point.passage ? inspectionState.passages_dic[point.passage] : undefined;
      const time = formatTimezoneDate({
        date: passage?.tstamp,
        timezone: settingsState.timezone?.id,
        format: 'HH:mm:ss.SSS',
      });
      const date = formatTimezoneDate({
        date: passage?.tstamp,
        timezone: settingsState.timezone?.id,
        format: 'YYYY-MM-DD',
      });
      const tpSet = run?.trackingpoint_set || []

      const prevPointId = index - 1 >= 0 ? tpSet[index - 1] : null;
      const previousPoint = prevPointId ? inspectionState.trackingpoints_dic[prevPointId] : undefined;


      const previousDistance = previousPoint
        ? point.distance - previousPoint.distance
        : 0;

      return {
        launched: run.launched,
        is_finished: run.is_finished,
        status: run.status,
        id: point.id,
        runId: run.id,
        passage: run.launched && passage?.tstamp,
        passageId: run.launched && passage?.id,
        passageDate: run.launched && date,
        passageTime: run.launched && time,
        speed: run.launched && point?.speed,
        speedDelta: run.launched && point?.speed_delta,
        longitude: point?.geometry.coordinates[0],
        latitude: point?.geometry.coordinates[1],
        deviceSN: point?.device_sn,
        tract: point?.tract,
        country: point?.country,
        state: point?.state,
        location: `${(+point?.geometry.coordinates[1]).toFixed(6)},${(+point?.geometry
          .coordinates[0]).toFixed(6)}`,
        locationDescription: point?.location_description,
        pressureDifferential: point?.pressure_differential,
        rawFootage: point?.raw_footage,
        stationingNumber: point?.station_number,
        depthOfCover: point?.depth_of_cover,
        alignmentSheet: point?.alignment_sheet,
        eta: run.launched && point?.eta,
        distance: run.launched && point?.distance,
        ete: passage ? passage?.tstamp : point?.eta,
        name: point?.name,
        index: index + 1,
        timezone: settingsState.timezone,
        milepost: point?.milepost,
        comment: point?.comment || '',
        description: point?.description || '',
        speedUnit: settingsState.speedUnit,
        previousDistance,
        distanceUnit: settingsState.distanceUnit,
      };
    }
  );
};
