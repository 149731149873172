import moment from 'moment-timezone';
import { Subject } from 'rxjs';
import { useReducer, createContext } from 'react';
import PropTypes from 'prop-types';
import {
  StoreValuesType,
  Modals,
  DispatchType,
  ContextInterface,
  ProviderProps,
} from './store.interfaces';
import {
  PointMediaType,
  PointNoteType,
  RunType,
  TrackingPointType,
} from '../interfaces/inspection.interfaces';
import { QubeDevice } from 'app/interfaces/qube.interfaces';
import { applyPointUpdates, readUpdates } from './store.updateIndexes.handlers';
import { createPointSubjects } from './store.point.handler';

const modalsInitialValue: Modals = {
  chartModal: false,
  createProject: false,
  createSurvey: false,
  profile: false,
  updateSurvey: false,
  createEventModal: false,
  onlineUsersModal: false,
  eventListModal: false,
};

const initialSubjects = {
  onlineUsers: {},
  points: {},
  timepins: {},
  estimation: new Subject<any>(),
  onTimepinCreate: new Subject<any>(),
  newOnlineUser: new Subject<any>(),
  qubes: {},
};

const initialValue: StoreValuesType = {
  auth: null,
  last_passage: undefined,
  last_passage_operation: undefined,
  loginVerified: false,
  isSurveyManager: false,
  distanceUnit: {
    id: 'ft',
    label: 'ft',
  },
  triggersSeen: {},
  transaction: 0,
  grid: null,
  snapshotId: 0,
  report: null,
  isObserver: false,
  estimation: null,
  onlineUsers: {},
  scrollBlocked: false,
  lastPassage: null,
  toast: null,
  map: null,
  mapData: null,
  modals: modalsInitialValue,
  focusLocation: null,
  focusTime: null,
  focusMarker: null,
  tick: 0,
  points: {},
  passages: {},
  survey: null,
  surveypoints: {},
  pointNotes: {},
  pointMedias: {},
  reportSelectedComponent: undefined,
  force_sheet_update: false,
  reportHoverComponent: '',
  noConnectionSince: null,
  reconnectionAttempt: 0,
  run: null,
  showOnlineUsers: false,
  project: null,
  speedUnit: {
    id: 'mph',
    label: 'mph',
  },
  timezone: {
    id: moment.tz.guess(),
    label: moment.tz.guess().toLocaleLowerCase(),
  },
  timezoneList: null,
  weatherUnit: {
    id: 'farenheit',
    label: '°F',
  },
  timezoneUnit: null,
  selectedPoint: null,
  selectedNextPoint: null,
  socket: null,
  subjects: { ...initialSubjects },
  registerData: null,
  timepins: {},
  timepinsMarkers: {},
};

const reducer = (
  state: StoreValuesType,
  action: DispatchType
): StoreValuesType => {
  switch (action.type) {
    case 'SET_AUTH':
      return { ...state, auth: action.data };
    case 'SET_REPORT':
      return { ...state, report: action.data };
    case 'SET_REPORT_SELECTED_COMPONENT':
      return { ...state, reportSelectedComponent: action.data };
    case 'SET_SURVEY':
      return { ...state, survey: action.data };
    case 'SET_REPORT_HOVER_COMPONENT':
      return { ...state, reportHoverComponent: action.data };
    case 'LOGIN_VERIFIED':
      return { ...state, loginVerified: true };
    case 'SET_REGISTER_DATA':
      return { ...state, registerData: action.data };
    case 'SET_IS_OBSERVER':
      return { ...state, isObserver: action.data };
    case 'TOGGLE_SHOW_ONLINE_USERS':
      return { ...state, showOnlineUsers: !state.showOnlineUsers };
    case 'UPDATE_ESTIMATION':
      if (state.subjects?.estimation) {
        state.subjects.estimation.next({
          estimation: action.data,
          mapData: state.mapData,
        });
      }
      // Store the last estimation, so that it can be replayed
      return {
        ...state,
        estimation: action.data,
      };
    case 'REPLAY_ESTIMATION':
      /* Initial estimation may arrive before component starts listening
       * to subjects.estimation. This replays last estimation.
       */
      if (state.estimation && state.subjects?.estimation) {
        state.subjects.estimation.next({
          estimation: state.estimation,
          mapData: state.mapData,
        });
      }
      return state;
    case 'TICK':
      return { ...state, tick: action.data };
    case 'SET_TRANSACTION_ID':
      return { ...state, transaction: action.data };
    case 'SET_TOAST':
      return { ...state, toast: action.data };
    case 'SET_ONLINE_USERS':
      return { ...state, onlineUsers: action.data };
    case 'SET_SURVEY_MANAGER':
      return { ...state, isSurveyManager: action.data };
    case 'SCROLL_BLOCK':
      return { ...state, scrollBlocked: action.data };
    case 'UPDATE_POINT_IN_POINTS':
      state.points[action.data.id] = action.data;
      return { ...state };
    case 'SET_RUN':
      return {
        ...state,
        run: { ...state.run, ...action.data },
        balance: action.data?.customer_balance || 0,
      };
    case 'RESET_RUN':
      if (state.socket) state.socket.close();

      return {
        ...state,
        run: null,
        map: null,
        grid: null,
        estimation: null,
        subjects: { ...initialSubjects },
        transaction: 0,
      };
    case 'RUN_SNAPSHOT':
      return handleSnapshot(action.data, state);
    case 'SET_RUN_PIPELINE':
      return  {
        ...state,
        run: {
          ...state.run,
          pipeline: action.data
        } as RunType
      };
    case 'SURVEY_POINTS':
      return handleSurveyPoints(action.data, state);
    case 'UPDATE_INDEXES':
      if (state.run?.trackingpoint_set) {
        return handleUpdates(action.data, state);
      }

      return state;
    case 'UPDATE_RUN_TIME_PIN':
      return handleTimepinUpdates(action.data, state);
    case 'SET_RUN_TIME_PIN_MARKER':
      return { ...state, timepinsMarkers: action.data };
    case 'ADD_AN_RUN_TIME_PIN_MARKER':
      return {
        ...state,
        timepinsMarkers: {
          ...state.timepinsMarkers,
          [action.data.timepin.id]: action.data.marker,
        },
        timepins: {
          ...state.timepins,
          [action.data.timepin.id]: action.data.timepin,
        },
      };
    case 'ADD_TIMEPIN_SUBJECT':
      return handleTimepinSubject(action.data, state);
    case 'SURVEY_POINT_UPDATE':
      return handleSurveyPointUpdate(action.data, state);
    case 'SET_SPEED_UNIT':
      return { ...state, speedUnit: action.data };
    case 'NO_CONNECTION':
      if (state.run === null || action.data === null) {
        // Do not try to reconnect
        return {
          ...state,
          noConnectionSince: null,
          reconnectionAttempt: 0,
        };
      }

      return {
        ...state,
        noConnectionSince: state.noConnectionSince || action.data,
        // This is updates in steps of 0.5. Here it's rounded up to next integer
        reconnectionAttempt: Math.round((state.reconnectionAttempt || 0) + 0.6),
      };
    case 'RECONNECTION_TRIGGER':
      return {
        ...state,
        // By adding 0.5, the reconnection will be triggered
        reconnectionAttempt: (state.reconnectionAttempt || 0) + 0.5,
      };
    case 'SET_TIMEZONE_UNIT':
      return { ...state, timezone: action.data };
    case 'SET_DISTANCE_UNIT':
      return { ...state, distanceUnit: action.data };
    case 'SET_TIMEZONE_LIST':
      return { ...state, timezoneList: action.data };
    case 'SET_WEATHER_UNIT':
      return { ...state, weatherUnit: action.data };
    case 'SET_GRID':
      return { ...state, grid: action.data };
    case 'SET_MAP':
      return { ...state, map: action.data };
    case 'SET_MAP_DATA':
      return { ...state, mapData: { ...state.mapData, ...action.data } };
    case 'SET_SELECTED_POINT':
      return {
        ...state,
        selectedPoint: action.data ? state.points[action.data?.id] : null,
      };
    case 'SET_SELECTED_TIME_PIN':
      return {
        ...state,
        selectedTimePinPoint: state.timepins[action.data?.id] || undefined,
      };
    case 'SET_LAST_PASSAGE_POINT':
      return { ...state, lastPassage: action.data };
    case 'SET_QUBE_DEVICES':
      return handleQubeDevices(state, action.data);
    case 'SET_END_POINT':
      return { ...state, endPoint: action.data };
    case 'SET_SELECTED_NEXT_POINT':
      return { ...state, selectedNextPoint: action.data };
    case 'SET_SOCKET':
      return { ...state, socket: action.data };

    case 'OPEN_MODAL':
      if (action.data.data?.time === 'focusTime') {
        action.data.data.time = state.focusTime;
        return {
          ...state,
          modals: { ...modalsInitialValue, ...action.data },
        };
      }
      return {
        ...state,
        focusTime: null,
        focusLocation: null,
        modals: { ...modalsInitialValue, ...action.data },
      };

    case 'SET_FOCUS':
      return {
        ...state,
        focusLocation: action.data.location,
        focusTime: action.data.time,
        modals: modalsInitialValue,
      };
    case 'SET_FOCUS_MARKER':
      return {
        ...state,
        focusMarker: action.data,
      };
    case 'UPDATE_RUN_USER':
      return handleOnlineUsersUpdates(action.data, state);
    case 'CLOSE_MODAL':
      return {
        ...state,
        modals: modalsInitialValue,
        focusLocation: null,
        focusTime: null,
      };
    case 'SET_TRIGGER_SEEN':
      return handleTriggers(
        state,
        action.data.trigger,
        action.data.tracking_point
      );
    default:
      throw new Error(`Unknown action ${action.type}`);
  }
};

const Store = createContext({} as ContextInterface);

const StoreProvider = ({ children, value }: ProviderProps) => {
  const reducerData = useReducer(reducer, { ...initialValue, ...value });
  const state: StoreValuesType = reducerData[0];
  const dispatch: (data: DispatchType) => void = reducerData[1];

  return (
    <Store.Provider value={{ state, dispatch }}>{children}</Store.Provider>
  );
};

StoreProvider.propTypes = {
  children: PropTypes.node,
};

StoreProvider.defaultProps = {
  children: [],
};

const handleTriggers = (
  state: StoreValuesType,
  trigger: number,
  trackingPoint: number
) => {
  const triggersSeen = { ...state.triggersSeen };
  triggersSeen[trigger] = true;

  const points = deepCopy(state.points || {});
  const point = points[trackingPoint];

  const newState = { ...state, triggersSeen };
  if (point && state.subjects.points?.[trackingPoint]) {
    state.subjects.points[trackingPoint].fields.next({
      state: newState,
      point,
    });
  }

  return newState;
};

const handleSnapshot = (runData: any, state: any) => {
  const points: any = {};
  const timepins: any = {};
  const passages: any = {};
  const pointNotes: any = {};
  const pointMedias: any = {};
  const subjects: any = { ...initialSubjects };

  runData.users = [];

  runData.trackingpoint_set.forEach((point: TrackingPointType, i: number) => {
    point.index = i;

    points[`${point.id}`] = { ...point };

    if (point.passage) {
      passages[`${point.passage.id}`] = { ...point.passage };
    }
    point.trackingpointnote_set?.forEach((note: PointNoteType) => {
      pointNotes[`${note.id}`] = note;
    });

    point.media_set?.forEach((media: PointMediaType) => {
      pointMedias[`${media.id}`] = media;
    });

    subjects.points[`${point.id}`] = createPointSubjects(point);
  });

  runData.timepin_set.forEach((timepin: any, i: number) => {
    timepin.index = i;

    timepins[`${timepin.id}`] = { ...timepin };

    subjects.timepins[`${timepin.id}`] = {
      fields: new Subject<TrackingPointType>(),
    };
  });

  runData.qube_set.forEach((qube) => {
    subjects.qubes[qube.id] = new Subject<QubeDevice>();
  });

  const newState = {
    ...state,
    run: { ...state.run, ...runData },
    balance: runData.customer_balance,
    estimation: runData.estimation,
    snapshotId: state.snapshotId + 1,
    points,
    passages,
    timepins,
    pointNotes,
    pointMedias,
    subjects,
  };

  if (state.selectedPoint?.id) {
    newState.selectedPoint = points[state.selectedPoint.id];
  }

  return newState;
};

const handleSurveyPoints = (surveypoints: any[], state: any) => {
  const points: any = {};
  const subjects: any = {
    onlineUsers: {},
    points: {},
    estimation: new Subject<any>(),
  };

  surveypoints.forEach((point: any, i: number) => {
    point.index = i;

    subjects.points[`${point.id}`] = createPointSubjects(point)

    points[point.id] = point;
  });

  const newState = {
    ...state,
    surveypoints: points,
    subjects,
  };

  if (state.selectedPoint?.id) {
    newState.selectedPoint = points[state.selectedPoint.id];
  }

  return newState;
};

export const deepCopy = (json: any) => JSON.parse(JSON.stringify(json));

const handleOnlineUsersUpdates = (data: any, state: StoreValuesType): any => {
  if (!data.user) return state;

  const subject = state.subjects.onlineUsers?.[data.user.id];
  const newState = { ...state };
  newState.onlineUsers = deepCopy(state.onlineUsers);

  const itsAUpdate = state.onlineUsers[data.user.id];

  newState.onlineUsers[data.user.id] = {
    ...data.user,
    location: data.location,
    online: data.online,
  };

  if (!itsAUpdate) {
    state.subjects.newOnlineUser.next({
      ...data.user,
      online: data.online,
      location: data.location,
    });
  }

  if (subject) {
    subject.online.next({ ...data, showOnlineUsers: state.showOnlineUsers });

    if (data.location) {
      subject.position.next({
        lng: data.location.coordinates[0],
        lat: data.location.coordinates[1],
      });
    } else {
      subject.position.next(null);
    }
  } else {
    const subjects = { ...state.subjects };
    const onlineUsers = { ...subjects.onlineUsers };

    onlineUsers[data.user.id] = {
      online: new Subject<any>(),
      position: new Subject<any>(),
    };

    subjects.onlineUsers = onlineUsers;
    newState.subjects = subjects;
  }
  return newState || state;
};

const handleTimepinSubject = (data: any, state: StoreValuesType): any => {
  const newState = { ...state };
  if (state.subjects.timepins[data.timepin.id]) return state;
  const subject = new Subject<any>();
  newState.subjects.timepins[data.timepin.id] = { fields: subject };
  return newState;
};

const handleTimepinUpdates = (data: any, state: StoreValuesType): any => {
  const newState = { ...state };
  newState.timepins = JSON.parse(JSON.stringify(state.timepins));

  if (data.operation === 'create') {
    state.subjects.onTimepinCreate.next({ timepin: data.timepin });
  } else if (data.operation === 'delete') {
    const subject = state.subjects.timepins[data.timepin];
    if (subject) {
      subject.fields.next({ timepin: data, isDeleted: true });
    }
    delete newState.timepins[data.timepin];
  } else {
    const subject = state.subjects.timepins[data.timepin.id];
    if (subject) {
      subject.fields.next({ timepin: data.timepin });
    }
    newState.timepins[data.timepin.id] = data.timepin;
  }
  return newState;
};

const handleSurveyPointUpdate = (
  updatedPoint: any,
  state: StoreValuesType
): StoreValuesType => {
  const updates: any = {};

  if (!updatedPoint || !updatedPoint.id) return state;

  const points = deepCopy(state.surveypoints || {});
  points[updatedPoint.id] = {
    ...updatedPoint,
    index: points[updatedPoint.id].index,
  };

  updates.surveypoints = points;

  const pointSubject = state.subjects.points[updatedPoint.id];

  const newState = { ...state, ...updates };

  pointSubject.fields.next({ point: updatedPoint, state: newState });

  return newState;
};

const handleUpdates = (data: any[], state: StoreValuesType): StoreValuesType => {
  if (data.length === 0) return state;

  const firstUpdate = data[0];

  const { updates, pointsToUpdate } = readUpdates({ payloadList: data, state });

  const finishedUpdates = applyPointUpdates({
    updates,
    pointsToUpdate,
    state,
  });

  const messages: any = {
    'Passage-create': 'New passage was registered',
    'Passage-update': 'A passage was updated',
    'Passage-delete': 'A passage was deleted',
    'TrackingPoint-create': 'New tracking point was registered',
    'TrackingPointNote-create': 'New tracking point note was registered',
    'TrackingPointNote-update': 'A tracking point note was updated',
    'TrackingPointNote-delete': 'A tracking point note was deleted',
    'TrackingPointMedia-create': 'A media was added in a tracking point',
    'TrackingPointMedia-update': 'A media was added in a tracking point',
    'TrackingPointMedia-delete': 'A tracking point media was deleted',
  };

  const messageKey = `${firstUpdate.instance_type}-${firstUpdate.operation}`;
  let msg = messages[messageKey];
  if (msg) {
    if (firstUpdate.instance_type === 'Passage') {
      finishedUpdates.last_passage = firstUpdate.data;

      if (
        firstUpdate.operation === 'create' &&
        parseInt(firstUpdate.data.distance) === 0
      )
        msg = 'Run was launched!';
      else if (firstUpdate.operation === 'delete' && !finishedUpdates.run?.launched) {
        msg = 'Run was aborted!';
      }
    }

    // this check avoid showing passage create and update toast
    // in sequence

    // TODO: why socket receive an event to create and in senquence another
    // event to update the same passage?
    if (
      firstUpdate.instance_type === 'Passage' &&
      firstUpdate.operation === 'update' &&
      firstUpdate.data.tstamp === state.passages[firstUpdate.data.id].tstamp
    ) {
      return state;
    }

    finishedUpdates.toast = {
      title: '',
      text: msg,
      type: 'success',
    };
  }

  const updatedState = { ...state, ...finishedUpdates }
  return updatedState;
};

/**
 *
 * @param state
 * @param qube
 * @returns
 */
const handleQubeDevices = (state: StoreValuesType, message: any) => {
  const cState = { ...state }; // state copy
  const qubeDevices = cState.run?.qube_set ? [...cState.run.qube_set] : []; // qube copy

  if (message.operation === 'create') {
    qubeDevices.push(message.data);
    // TODO: subscription to create new point on map
  }

  if (message.operation === 'delete') {
    const index = qubeDevices.findIndex(({ id }) => id === message.data);
    qubeDevices.splice(index, 1);
    // TODO: subscription to delete a point on map
  }

  if (message.operation === 'update') {
    const index = qubeDevices.findIndex(({ id }) => id === message.data.id);
    qubeDevices[index] = message.data;
    cState.subjects.qubes[message.data.id].next(message.data);
  }

  const cRun = { ...cState.run }; // run copy
  cRun.qube_set = qubeDevices;

  return cState;
};

export default Store;
