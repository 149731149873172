import { useContext, useEffect, useMemo, useState } from 'react';
import {
  MobileWidgetsModalContent,
  MobileWidgetsModalContentCloseButton,
  MobileWidgetsModalContentHeader,
  MobileWidgetsModalContentTitle,
  MobileWidgetsModalWrapper,
  MobileWidgetsModalWidgetsWeatherList,
  TrackingIcon,
  Timer,
  MobileWidgetsModalWidgetsPigList,
  CanvasContainer,
  ChartCanvas,
  TableWrapper,
  TimerContainer,
} from './mobile-widgets-modal.style';
import MobileWeatherWidget from '../mobile-weather-widget/mobile-weather-widget';
import Speed from 'app/components/common/map/modules/run/components/widgets/icons/Speed';
import { formatDistance, formatMps, formatTimezoneDate } from '@/lib/formatter';
import Elevation from 'app/components/common/map/modules/run/components/widgets/icons/Elevation';
import { getColumns, getInclination, getWeatherText, loadTableContent, setCanvasPlot } from './mobile-widgets-modal.controller';
import { getTrackingpointIcon } from 'app/utils/map.utils';
import MobilePigWidget from '../mobile-pig-widget/mobile-pig-widget';
import { TableContentInterface } from '../chart-modal/chart-modal.d';
import { Chart } from 'chart.js';
import Table from 'app/components/table/table';
import InspectionStore from 'app/modules/inspection/inspection.context';
import SettingsStore from 'app/modules/settings/settings.context';
import { TrackingPointPlainType } from 'app/modules/inspection/inspection.interfaces';

const columnsKey = 'GT@speed-modal/columns';

interface Props {
  onClose: () => void;
}
/**
 *
 * @param props
 * @returns
 */
const MobileWidgetsModal = (props: Props) => {
  const inspectionContext = useContext(InspectionStore);
  const settingsContext = useContext(SettingsStore);
  const [canvas, setCanvas] = useState<Chart>();
  const [selectedChart, setSelectedChart] = useState<string>('speed');
  const [tableContent, setTableContent] = useState<TableContentInterface[]>([]);
  const [lastPointWithPassage, setLastPointWithPassage] = useState<TrackingPointPlainType>();
  const [endPoint, setendPoint] = useState<TrackingPointPlainType>();
  const weatherText = useMemo(() => {
    return getWeatherText(inspectionContext.state.run?.forecast, settingsContext.state.weatherUnit)
  }, [inspectionContext.state.run?.forecast, settingsContext.state.weatherUnit?.id]);
    
  useEffect(() => {
    if (inspectionContext.state.run?.trackingpoint_set) {
      const finalPointId = inspectionContext.state.run?.trackingpoint_set.slice(-1)[0];
      const p = inspectionContext.state.trackingpoints_dic[finalPointId];
      setendPoint(p);
    }
  }, [
    inspectionContext.state.run?.trackingpoint_set,
    inspectionContext.state.trackingpoints_dic
  ]);

  useEffect(() => {
    if (
      inspectionContext.state.run?.last_point_with_passage
    ) {
      const pId = inspectionContext.state.run?.last_point_with_passage;
      const p = inspectionContext.state.trackingpoints_dic[pId];
      setLastPointWithPassage(p);
      setLastPointWithPassage(p);
    }
  }, [inspectionContext.state.run?.last_point_with_passage]);

  useEffect(() => {
    if (
      !inspectionContext.state.run ||
      !settingsContext.state.distanceUnit ||
      !settingsContext.state.speedUnit
    ) return;

    setCanvasPlot(
      settingsContext.state,
      inspectionContext.state,
      canvas,
      setCanvas,
      selectedChart,
    );

    loadTableContent(
      settingsContext.state,
      inspectionContext.state,
      setTableContent
    );
  }, [selectedChart]);

  return (
    <MobileWidgetsModalWrapper onClick={() => props.onClose()}>
      <MobileWidgetsModalContent onClick={(e) => e.stopPropagation()}>

        <MobileWidgetsModalContentHeader>
          <MobileWidgetsModalContentTitle>
            Dashboard panel
          </MobileWidgetsModalContentTitle>

          <MobileWidgetsModalContentCloseButton onClick={props.onClose}>
            <svg
              width="23"
              height="23"
              viewBox="0 0 23 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="16.2637"
                y="4.94922"
                width="2"
                height="16"
                transform="rotate(45 16.2637 4.94922)"
                fill="#99A2AD"
              />
              <rect
                x="17.6777"
                y="16.2637"
                width="2"
                height="16"
                transform="rotate(135 17.6777 16.2637)"
                fill="#99A2AD"
              />
            </svg>
          </MobileWidgetsModalContentCloseButton>
        </MobileWidgetsModalContentHeader>

        <MobileWidgetsModalWidgetsWeatherList>

          <MobileWeatherWidget
            figure={`/assets/icons/weather/${
              inspectionContext.state.run?.forecast?.icon || 'sunny.png'
            }`}
            isLoading={false}
            value={inspectionContext.state.run?.launched ? weatherText : '---'}
            label="Weather"
          />

          <MobileWeatherWidget
            figure={
              <TrackingIcon
                alt=""
                src={getTrackingpointIcon(lastPointWithPassage, 'no-border')?.url}
              />
            }
            isLoading={false}
            value={
              inspectionContext.state.run?.launched ?
              (<TimerContainer>
                <Timer>
                  {formatTimezoneDate({
                    date: lastPointWithPassage?.eta || '',
                    timezone: `${settingsContext.state.timezone?.id}`,
                    format: 'H:mm',
                  })}
                </Timer>
                {formatTimezoneDate({
                  date: lastPointWithPassage?.eta || '',
                  timezone: `${settingsContext.state.timezone?.id}`,
                  format: 'll',
                })}
              </TimerContainer>) : '---'
            }
            label="ETA at Next Point"
          />

          <MobileWeatherWidget
            figure={
              <TrackingIcon
                alt=""
                src={getTrackingpointIcon(endPoint, 'no-border')?.url}
              />
            }
            isLoading={false}
            value={
              inspectionContext.state.run?.launched ? 
              (<TimerContainer>
                <Timer>
                  {formatTimezoneDate({
                    date: endPoint?.eta || '',
                    timezone: `${settingsContext.state.timezone?.id}`,
                    format: 'H:mm',
                  })}
                </Timer>
                {formatTimezoneDate({
                  date: endPoint?.eta || '',
                  timezone: `${settingsContext.state.timezone?.id}`,
                  format: 'll',
                })}
              </TimerContainer>) : '---'
            }
            label="ETA at Receiver"
          />
        </MobileWidgetsModalWidgetsWeatherList>

        <MobileWidgetsModalWidgetsPigList>
          <MobilePigWidget
            figure={<Speed />}
            isSelected={selectedChart === 'speed'}
            handleClick={() => setSelectedChart('speed')}
            isLoading={!inspectionContext.state.run}
            value={
              formatMps({
                distance: inspectionContext.state.run?.is_finished
                  ? lastPointWithPassage?.speed
                  : inspectionContext.state.estimation?.speed || lastPointWithPassage?.speed,
                unit: settingsContext.state.speedUnit,
              }) || '---'

            }
            label="Speed"
          />
          <MobilePigWidget
            isSelected={selectedChart === 'elevation'}
            handleClick={() => setSelectedChart('elevation')}
            figure={<Elevation />}
            isLoading={!inspectionContext.state.run}
            value={
              formatDistance({
                distance: inspectionContext.state.run?.is_finished
                  ? +(lastPointWithPassage?.elevation || 0)
                  : +(inspectionContext.state.estimation?.elevation || lastPointWithPassage?.elevation) || 0,
                unit: `${settingsContext.state.distanceUnit?.id}`,
              }) || '---'
            }
            label="Elev."
          />
          <MobilePigWidget
            figure={'/assets/icons/inclination.png'}
            isSelected={selectedChart === 'inclination'}
            isLoading={!inspectionContext.state.run}
            handleClick={() => setSelectedChart('inclination')}
            value={getInclination(
              !!inspectionContext.state.run?.is_finished,
              !!inspectionContext.state.run?.launched,
              inspectionContext.state.estimation?.inclination,
              lastPointWithPassage
            )}
            label="Incli."
          />
        </MobileWidgetsModalWidgetsPigList>

        <CanvasContainer>
          <ChartCanvas id="gt-chart" />
        </CanvasContainer>

        <TableWrapper>
          <Table
            columns={getColumns(
              columnsKey,
              settingsContext.state.speedUnit,
              settingsContext.state.distanceUnit,
              settingsContext.state.timezone
            )}
            rows={tableContent}
            selectedLine={inspectionContext.state.selected_point}
            customStyle={`
              border: none;
              align-content: start;
              max-width: 100vw;
            `}
            lineHandleClick={() => null}
            onResize={(columns) => {
              window.localStorage.setItem(columnsKey, JSON.stringify(columns));
            }}
          />
        </TableWrapper>
      </MobileWidgetsModalContent>
    </MobileWidgetsModalWrapper>
  );
};

export default MobileWidgetsModal;
