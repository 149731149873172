import styled from 'styled-components';

export const WidgetsContent = styled.div`
  display: grid;
  grid-template-columns: 33% 1fr;
  grid-template-rows: repeat(6, 16.6666667%);
  gap: 10px 14px;
  height: calc(100% - 50px);
  padding-top: 16px;
  padding-bottom: 16px;
`;

export const Widget = styled.div<{ large?: boolean }>`
  display: grid;
  grid-template-columns: ${({ large }) => (large ? '1fr 1px 1fr' : '1fr')};
  grid-template-rows: ${({ large }) => (!large ? '1fr 1px 1fr' : '1fr')};
  /* align-content: center; */
  gap: 8px;


  background: #ffffff;
  box-shadow: 0px 6px 10px rgba(51, 57, 78, 0.2);
  border-radius: 6px;

  padding: 7px 9px;

  > div {
    display: grid;
    align-content: center;
    gap: 1vw;
  }

  > div > svg {
    width: 4.53vw;
    height: 4.53vw;
  }

  > div > label {
    display: block;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 2.4vw;
    line-height: 1.18em;

    color: #5850ec;

    // < 374px
    font-size: ${({ large }) => (!large ? '3vw' : '2.4vw')};

    // > 375px
    @media (min-width: 375px) {
      font-size: 2.4vw;
      z-index: 1;
    }
  }

  > span {
    display: inline-block;
    width: ${({ large }) => (large ? '1px' : '100%')};

    background-color: #dcdfe2;
  }

  > div > div {
    position: relative;
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    top: -3px;
  }

  &:nth-child(1) {
    grid-row: 1 / 4;
  }

  &:nth-child(2) {
    grid-row: 4 / 7;
  }

  &:nth-child(3) {
    grid-row: 1 / 3;
  }

  &:nth-child(4) {
    grid-row: 3 / 5;
  }

  &:nth-child(5) {
    grid-row: 5 / 7;
  }
`;

export const WidgetValue = styled.div<{ big?: boolean }>`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  line-height: 1.18em;
  color: #4b5066;
  margin-top: ${({ big }) => (big ? '0' : '-1vw')};

  // < 374px
  font-size: ${({ big }) => (big ? '4.26vw' : '2.13vw')};

  // > 375px
  @media (min-width: 375px) {
    font-size: ${({ big }) => (big ? '4vw' : '2.4vw')};
    margin-left: ${({ big }) => (!big ? '6.4vw' : '0')};
  }
`;

export const WidgetPointImage = styled.img`
  width: 4.8vw;
  margin-right: 1.3vw;

  vertical-align: middle;
`;

export const WidgetPointName = styled.label`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 2.4vw;
  line-height: 1.2em;

  color: #9ca4ac;

  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
