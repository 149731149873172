import { QubeType } from '../../../interfaces/inspection.interfaces';
import Battery from '../battery/battery';
import {
  DeviceCard,
  DeviceCardContent,
  DeviceCardHeader,
  DeviceInfoList,
  DeviceInfo,
  DeviceInfoLabel,
  DeviceInfoValue,
  DevicePropertiesList,
  DeviceProperty,
  DevicePropertyStatus,
  DevicePropertyLabel,
  DeviceTitleWrapper,
  DeviceTitle,
  DeviceStatusFigure,
} from './device-card.style';

import {
  ArmDisarmButton,
} from '../arm-disarm-button/arm-disarm-button';

import {
  getUptime,
} from '../device-common.controller';

interface Props {
  device: QubeType;
  onClick: (device: QubeType) => void;
  light?: boolean;
}


/**
 *
 * @param props
 * @returns
 */
const Card = (props: Props) => {

  /**
   *
   */
  const handleClick = () => {
    props.onClick(props.device);
  };

  return (
    <DeviceCard light={props.light}>
      <DeviceCardHeader>
        <DeviceStatusFigure>
          <img src="/assets/images/qube-icon.png" />
        </DeviceStatusFigure>
        <DeviceTitleWrapper>
          <DeviceTitle
            onClick={handleClick}
          >{props.device.serial}</DeviceTitle>
        </DeviceTitleWrapper>
      </DeviceCardHeader>

      <DeviceCardContent>
        <DevicePropertiesList>
          <DeviceProperty>
            <DevicePropertyStatus checked={props.device.online} />
            <DevicePropertyLabel>Online</DevicePropertyLabel>
          </DeviceProperty>
          <DeviceProperty>
            <DevicePropertyStatus checked={props.device.armed} />
            <DevicePropertyLabel>{props.device.armed ? 'Armed' : 'Disarmed'}</DevicePropertyLabel>
          </DeviceProperty>
          <DeviceProperty>
            <DevicePropertyStatus checked={props.device.geolocked} />
            <DevicePropertyLabel>GeoLocked</DevicePropertyLabel>
          </DeviceProperty>
        </DevicePropertiesList>

        <DeviceInfoList>
          <DeviceInfo>
            <DeviceInfoLabel>Uptime</DeviceInfoLabel>
            <DeviceInfoValue>{getUptime(props.device.uptime_secs)}</DeviceInfoValue>
          </DeviceInfo>
          <DeviceInfo>
            <DeviceInfoLabel>Battery</DeviceInfoLabel>
            <DeviceInfoValue>
              <Battery
                id={props.device.serial}
                level={props.device.battery_level}
              />
            </DeviceInfoValue>
          </DeviceInfo>
          <DeviceInfo>
            <ArmDisarmButton
              mqttId={props.device.serial}
              armed={props.device.armed}
              style={{
                marginTop: '-17px',
              }}
            />
          </DeviceInfo>
        </DeviceInfoList>
      </DeviceCardContent>
    </DeviceCard>
  );
};

export default Card;
